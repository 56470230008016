import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';

/**
 * 单位用药剂量工具类
 */
export default class WeightHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.weight && RegularHelper.isUnsignedFloat.test(modelRef.weight);
  }

  static hasDoubt(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    dictionaryList: any,
  ): boolean {
    if (!WeightHelper.preCheck(modelRef)) {
      return false;
    }
    // 购药体重
    const weight = +modelRef.weight;
    // 确诊体重
    const visitWeight = +modelRef.visitWeight;
    if (visitWeight) {
      return weight === 0 || weight > 100 || weight - visitWeight < dictionaryList.value.diffBuyMedicineWeight;
    } else {
      return weight === 0 || weight > 100;
    }
  }

  // dictionaryList： 零氪-预警规则参数服务端可配置
  static getDoubtMessage(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    dictionaryList: any,
  ): string {
    if (!WeightHelper.preCheck(modelRef)) {
      return '';
    }

    // 购药体重
    const weight = +modelRef.weight;
    // 确诊体重
    const visitWeight = +modelRef.visitWeight;

    if (weight === 0) {
      return '体重为0';
    } else if (weight > 100) {
      return '体重大于100';
    }
    if (visitWeight && weight - visitWeight < dictionaryList.value.diffBuyMedicineWeight) {
      return `购药体重-确诊体重＜${dictionaryList.value.diffBuyMedicineWeight}kg`;
    }

    return '';
  }
}
// 测试“购药体重-确诊体重＜-5kg”的数据
// http://localhost:8080/form/list/833856376156803072/1313220609020149761
