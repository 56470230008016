import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0057361c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-cascader-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      layout: "inline",
      class: "cascader-block"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { class: "recoder" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.currentRecordUserId,
              onChange: _ctx.handleRecordUserChange,
              placeholder: "请选择录入员"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordUsers, (recordUser) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    value: recordUser.value,
                    key: recordUser.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(recordUser.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.currentFormId,
              onChange: _ctx.handleFormTypeChange,
              style: {"width":"150px"},
              placeholder: "请选择采集模块"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formTypes, (formType) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    value: formType.value,
                    key: formType.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(formType.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.currentCaseId,
              onChange: _ctx.handleCaseChange,
              style: {"width":"200px"},
              placeholder: "请选择上传时间"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formCases, (formCase) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    value: formCase.value,
                    key: formCase.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(formCase.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}