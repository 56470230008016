
  import { computed, CSSProperties, defineComponent, PropType, ref } from 'vue';
  import { get, throttle } from 'lodash';
  import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';

  import { IPreviewImage } from './interface';

  import ImageToolbar from './components/ImageToolbar/index.vue';
  import ImageBody from './components/ImageBody/index.vue';

  export default defineComponent({
    name: 'image-preview',
    components: {
      ImageToolbar,
      ImageBody,
      LeftOutlined,
      RightOutlined,
    },
    props: {
      /* 组件挂载位置 */
      getContainer: {
        type: Function as PropType<() => HTMLElement>,
      },
      /* 组件显示, 隐藏状态, 支持v-model绑定 */
      visible: {
        type: Boolean,
        default: false,
      },
      /* 图片列表 */
      images: {
        type: Array as PropType<IPreviewImage[]>,
        default: () => [],
      },
      /* 当前图片id */
      currentImageId: {
        type: String,
        default: '',
      },
    },
    emits: ['update:visible', 'update:currentImageId', 'close'],
    setup(props, context) {
      const handleDialogClose = () => {
        context.emit('update:visible', false);
        context.emit('close');
        setTimeout(() => {
          reset();
        }, 300);
      };

      /**
       * currentIndex: 当前图片索引
       * currentImage: 当前图片
       * currentImagePath: 当前图片url
       * isFirstImage: 是否为第一张图片
       * isLastImage : 是否为最后一张图片
       */
      const currentIndex = computed(() => {
        const idx = props.images.findIndex((img) => img.id === props.currentImageId);

        return idx === -1 ? 0 : idx;
      });
      const currentImage = computed(() => get(props, `images.${currentIndex.value}`, null));
      const currentImagePath = computed(() => get(props, `images.${currentIndex.value}.url`, null));
      const isFirstImage = computed(() => currentIndex.value === 0);
      const isLastImage = computed(() => currentIndex.value === props.images.length - 1);

      const handleIndexChange = (index: number): void => {
        if (index < 0 || index > props.images.length - 1) {
          return;
        }

        context.emit('update:currentImageId', props.images[index].id);
        reset();
      };

      const imageStyle = ref<CSSProperties>({});
      const handleTooltipChange = (val: CSSProperties) => {
        imageStyle.value = val;
      };

      // TODO: 优化已ref的方式重置子组件状态, 可通过v-model维护
      const imgBodyRef = ref(null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const toolbarRef = ref<any>(null);

      const reset = () => {
        // eslint-disable-next-line
        imgBodyRef.value && (imgBodyRef as any).value.reset();
        // eslint-disable-next-line
        toolbarRef.value && (toolbarRef as any).value.reset();
      };

      const handleMouseWheel = throttle((e: WheelEvent) => {
        if (toolbarRef.value) {
          const magnification = Math.max(Math.abs(e.deltaY) % 10, 1.1);

          if (e.deltaY > 0) {
            toolbarRef.value.handleZoomOut(magnification);
          } else if (e.deltaY < 0) {
            toolbarRef.value.handleZoomIn(magnification);
          }
        }
      }, 50);

      return {
        handleDialogClose,
        currentIndex,
        currentImage,
        currentImagePath,
        isFirstImage,
        isLastImage,
        handleIndexChange,
        imageStyle,
        handleTooltipChange,
        imgBodyRef,
        toolbarRef,
        reset,
        handleMouseWheel,
      };
    },
  });
