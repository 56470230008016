
  import { deleteBusinessMember } from '@/services/manage';
  import { MemberModalPO } from '@/type/businessMember';
  import { message } from 'ant-design-vue';
  import { defineComponent, PropType, reactive, ref } from 'vue';

  export default defineComponent({
    name: 'BusinessDeleteModal',
    props: {
      modalInfo: {
        type: Object as PropType<MemberModalPO>,
        required: true,
        defauult: () => ({ show: false }),
      },
    },
    emits: ['close'],
    setup(props, { emit }) {
      const formRef = ref();
      const loading = ref(false);
      const formState = reactive({ deleteInfo: '' });
      const rules = {
        deleteInfo: [{ required: true, message: '请输入删除原因', trigger: 'blur' }],
      };
      const handleClose = (isRefresh = false) => {
        emit('close', isRefresh);
        formState.deleteInfo = '';
      };

      const handleDelete = () => {
        if (props.modalInfo.member) {
          const member = props.modalInfo.member;
          loading.value = true;
          if (formRef.value) {
            formRef.value
              .validate()
              .then(() => {
                deleteBusinessMember({ id: member.id, deleteInfo: formState.deleteInfo }).then((data) => {
                  if (data) {
                    message.success('删除成功');
                    handleClose(true);
                    loading.value = false;
                  }
                });
              })
              .catch(() => {
                loading.value = false;
              });
          }
        }
      };

      return {
        loading,
        formRef,
        formState,
        rules,
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        handleClose,
        handleDelete,
      };
    },
  });
