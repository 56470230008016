import { FormStatus } from '@/type/formContent';

export const FormOperatHostoryStatusTransMap = {
  [FormStatus.REJECTED]: '驳回',
  [FormStatus.HONG_CHONG]: '红冲',
  [FormStatus.DELETED]: '删除',
  [FormStatus.UN_FILL]: '未填写',
  [FormStatus.SAVED]: '暂存',
  [FormStatus.SUBMITTED]: '提交',
  [FormStatus.NEXTPURCHASETIME]: '更新下次购药时间',
};
