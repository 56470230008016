import { IFormGHOfflineDTO } from '@/type/formGHOfflineDTO';
import { genDefaultBaseFormDTO } from '@/views/form/utils';

export const genGHOfflineFormDTO = (): IFormGHOfflineDTO => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '线下用药信息',
  /** 岗位(辖区)ID */
  jobId: '',
  /** 岗位(辖区)名称 */
  jobName: '',
  /** 工号 */
  workId: '',
  /** 姓名 */
  name: '',
  /** bg */
  bg: '',
  /** 团队 */
  team: '',
  /** 战区 */
  warZoneName: '',
  /** 区域 */
  regionName: '',
  /** 大区 */
  regions: '',
  /** 地区 */
  regional: '',
  /** 省 Id */
  provinceId: '',
  /** 省 名称 */
  provinceName: '',
  /** 市 Id */
  cityId: '',
  /** 市 名称*/
  cityName: '',
  /** 医院ID */
  hospitalId: '',
  /** 医院名称 */
  hospitalName: '',
  /** SAP机构编码 */
  sapMechanismCode: '',
  /** 购药月份 */
  buyTime: '',
  /** 三级品种名称 */
  category: '',
  /** 品规简称 Id */
  specId: '',
  /** 品规简称 名称*/
  specName: '',
  /** 纯销数(支) */
  drugPurchasedNumber: '',
  /** 回款单价(元) */
  collectionUnitPrice: '',
  /** 销售价格 */
  sellingPrice: '',
  /** 金额 */
  amountMoney: '',
  /** 配送方式 */
  deliveryType: '',
  /** 备注 */
  remark: '',
});
