import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69c5d08d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "image-body",
    onMouseup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseUp && _ctx.handleMouseUp(...args))),
    onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseDown && _ctx.handleMouseDown(...args))),
    onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseMove && _ctx.handleMouseMove(...args))),
    draggable: "false",
    style: _normalizeStyle(_ctx.transformStyle)
  }, [
    (_ctx.url)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.url,
          style: _normalizeStyle(_ctx.style),
          draggable: "false"
        }, null, 12, _hoisted_1))
      : _renderSlot(_ctx.$slots, "no-image", { key: 1 }, undefined, true)
  ], 36))
}