import { defaults } from 'lodash';
import { IFormModuleBaseInfo } from './interface';

export const genDefaultFormModuleBaseInfo = (baseInfo: Partial<IFormModuleBaseInfo> = {}): IFormModuleBaseInfo =>
  defaults(baseInfo, {
    projectName: '-',
    subjectNumber: '-',
    moduleName: '-',
    recordName: '-',
    status: '-',
    statusString: '-',
    deleteUserName: '-',
    deleteDate: '-',
    deleteReason: '-',
  });
