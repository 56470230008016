
  import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
  import { SaleDataVo, SALE_STATUS_NAME, SALE_DATASOURCE_NAME, SaleDataQo } from '@/type/saleDataManage';
  import { Column, TableState } from '@/type/common';
  import { DictionaryTypeEnum, RmBaseListQo } from '@/type/api';
  import { Moment } from 'moment';
  import { debounce } from 'lodash';
  import { getHospitalLimit, getSaleDataList } from '@/services/saledata';
  import dictionary from '@/lib/dictionary';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  interface Option {
    label: string;
    value: string | number;
  }
  type OptKey = 'saleStatusOpts' | 'saleSourceOpts' | 'hospitalOpts' | 'domainOpts';
  export default defineComponent({
    name: 'SaleDataManage',
    components: {
      ManageListFrame,
      CustomTable,
    },
    setup() {
      const nameLoading = ref(true);
      const query: RmBaseListQo<Omit<SaleDataQo, 'buyTimeStart' | 'buyTimeEnd'> & { dateRange?: Moment[] }> = reactive(
        {},
      );
      const Opts = reactive<Record<OptKey, Option[]>>({
        saleStatusOpts: [],
        saleSourceOpts: [],
        hospitalOpts: [],
        domainOpts: [],
      });

      const calculateTableState = (): { columns: Column[]; scroll: { x: number } } => {
        let width = 0;
        const columns = [
          { title: '患者编码', dataIndex: 'patientCode', fixed: 'left' },
          { title: '表单ID', dataIndex: 'drugSubjectFormId' },
          { title: '群体', dataIndex: 'treatmentIndicationGroupName' },
          { title: '领域', dataIndex: 'treatmentDomainLabel' },
          { title: '适应症', dataIndex: 'treatmentIndicationName' },
          { title: '就诊机构', dataIndex: 'drugHospitalName' },
          // { title: 'KH', dataIndex: 'drugDoctorName' },
          { title: '三级品种名称', dataIndex: 'drugCategory', width: 120 },
          { title: '事业部', dataIndex: 'divideDepartment' },
          { title: '品规简称', dataIndex: 'drugSpecificationsName' },
          { title: '购药支数', dataIndex: 'drugActualNumber' },
          { title: '购药时间', dataIndex: 'drugBuyMedicineDate' },
          { title: '出库价（元/支）', dataIndex: 'drugActualDeliveryPrice', width: 120 },
          { title: '状态', dataIndex: 'status', slots: { customRender: 'status' }, width: 80 },
          { title: '数据来源', dataIndex: 'salesSource', slots: { customRender: 'salesSource' } },
          // { title: '辖区ID', dataIndex: 'divideJurisdictionId' },
          // { title: '辖区名称', dataIndex: 'divideJurisdictionName' },
          // { title: '审核人员', dataIndex: 'salesTrialUserName' },
        ].map((item) => {
          const column = { ...item, width: item.width || item.title.length * 14 + 30 };
          width += column.width;
          return column as Column;
        });
        return { columns, scroll: { x: width } };
      };
      const tableState = reactive(
        new TableState({
          dataSource: [] as SaleDataVo[],
          key: 'subjectFormId',
          ...calculateTableState(),
        }),
      );
      const getSaleStatusOpts = () => {
        Opts.saleStatusOpts = Object.keys(SALE_STATUS_NAME).map((key) => {
          return { label: SALE_STATUS_NAME[key], value: key };
        });
      };
      const getImportStatusTypeOpts = () => {
        Opts.saleSourceOpts = Object.keys(SALE_DATASOURCE_NAME).map((key) => {
          return { label: SALE_DATASOURCE_NAME[key], value: key };
        });
      };
      const getHospitalOpts = debounce((selectStr = '') => {
        nameLoading.value = true;
        getHospitalLimit(selectStr)
          .then((res) => {
            Opts.hospitalOpts = res.map((item) => {
              return { label: item.name, value: item.jinsaiId };
            });
          })
          .finally(() => {
            nameLoading.value = false;
          });
      }, 300);

      const getDomainOpts = () => {
        dictionary
          .getCurDictionaryWithDefault({
            type: DictionaryTypeEnum.DomainLabel,
          })
          .then((res) => {
            Opts.domainOpts = res.map((item) => {
              return { label: item.name, value: item.jinsaiId };
            });
          });
      };

      const getData = () => {
        const params: SaleDataQo = {
          ...query,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
          ...(query.dateRange
            ? {
                buyTimeStart: query.dateRange[0]?.format('YYYY-MM-DD'),
                buyTimeEnd: query.dateRange[1]?.format('YYYY-MM-DD'),
              }
            : {}),
        };
        if (query.dateRange) {
          Reflect.deleteProperty(params, 'dateRange');
        }
        getSaleDataList(params).then((res) => {
          tableState.dataSource = res.content;
          tableState.pagination.total = res.total;
        });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = tableState.pagination.pageSize !== pageSize ? 1 : page;
        tableState.pagination.pageSize = pageSize;
        getData();
      };
      const handleSearch = () => {
        tableState.pagination.current = 1;
        tableState.pagination.pageSize = 10;
        getData();
      };
      const handleHospitalNameChange = (value: string) => {
        getHospitalOpts(value);
      };

      onMounted(() => {
        // 准备数据
        getSaleStatusOpts();
        getImportStatusTypeOpts();
        getHospitalOpts();
        getDomainOpts();
        // 获取数据
        handleSearch();
      });
      return {
        ...toRefs(Opts),
        query,
        nameLoading,
        tableState,
        SALE_STATUS_NAME,
        SALE_DATASOURCE_NAME,
        handleHospitalNameChange,
        handlePage,
        handleSearch,
      };
    },
  });
