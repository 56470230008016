export enum CONSTANS_KEY {
  SUBJECT_FORM_REFRESH_KEY = 'SUBJECT_FORM_REFRESH_KEY',
  GHOFFLINE_AUDIT_COLUMNS = 'GHOFFLINE_AUDIT_COLUMNS',
  SALE_IMPORT_TOKEN = 'SALE_IMPORT_TOKEN',
}

interface UseLocalStorageItem {
  clearItem: () => void;
  getItem: () => string | null;
  setItem: (value: string) => void;
}

export const useLocalStorageItem = (key: CONSTANS_KEY): UseLocalStorageItem => {
  const clearItem = () => {
    window.localStorage.removeItem(key);
  };

  const getItem = () => {
    return window.localStorage.getItem(key);
  };

  const setItem = (value: string) => {
    window.localStorage.setItem(key, value);
  };

  return { clearItem, setItem, getItem };
};
