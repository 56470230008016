import axios from 'axios';
import { AxiosResponse, Method, AxiosRequestConfig, Canceler } from 'axios';
import config from '@/lib/config';
import { get } from 'lodash';
import { IRequestHandlerConfig } from '@/type/http';
import { RequestBlobOptions, ResponseBlob, BaseRes, IBooleanResponse, ResponseStatus } from '@/type/api';
import { LocalLoginStatus, NotifyLevel, state } from '@/lib/state';
import notify from '@/utils/notify';

// console.log(config);
interface PendingType {
  url: string | undefined;
  method: Method | undefined;
  cancel: (msg: string) => void;
}
export let pending: Array<PendingType> = []; // 请求队列
const whitePending: Array<string> = [
  // 白名单
];
const CancelToken = axios.CancelToken;
const http = axios.create({
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    post: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
  withCredentials: true,
  baseURL: config?.backEnd, //添加api统一后端前缀
});
export const checkPostPending = (config: AxiosRequestConfig): boolean => {
  let flag = false;
  for (const key in pending) {
    const list: PendingType = pending[key];
    if (list.url === config.url && list.method?.toUpperCase() === 'POST') {
      flag = true;
    }
  }
  return flag;
};
const removePending = (config: AxiosRequestConfig) => {
  for (const key in pending) {
    const item: number = +key;
    const list: PendingType = pending[key];
    if (list.url === config.url && list.method === config.method) {
      // list.cancel('重复请求，已取消');
      pending.splice(item, 1);
    }
  }
};
http.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const exist = checkPostPending(request);
    // console.log(request.url, exist);

    removePending(request);
    request.cancelToken = new CancelToken((c: Canceler) => {
      pending.push({
        url: request.url,
        method: request.method,
        cancel: c,
      });
    });
    if (request.url && exist && !whitePending.includes(request.url)) {
      pending[pending.length - 1].cancel('loading');
    }

    state.loading = !!pending.length;
    return request;
  },
  (error: Error) => {
    console.log(error);
  },
);

const CANCEL_ERR_CODE = -9999;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
http.interceptors.response.use(
  (res: AxiosResponse<unknown>) => {
    const errorCode = get(res, 'data.errorCode');

    /**
     * errorCode为-2说明此时系统正在维护中, 只有维护中才将提示信息置为静默模式
     * 当errorCode不为-2, 且notifyLevel为SILENCE时, 说明维护结束, 此时重新开启信息提示
     */
    if (errorCode !== -2 && state.notifyLevel === NotifyLevel.SILENCE) {
      state.notifyLevel = NotifyLevel.ALL;
    }

    switch (errorCode) {
      case 101015: {
        /** 登录失效 */
        state.needLogin = true;
        state.userInfo = null;
        state.jinsaiOAUri = get(res, 'data.redirectLoginUri');
        break;
      }
      case 101016: {
        /** 金赛OA账号，不在本项目 */
        state.userInfo = null;
        state.localLoginStatus = LocalLoginStatus.NOUSER;
        state.jinsaiOAUri = get(res, 'data.redirectLogoutUri');
        break;
      }
      case 101017: {
        /** 金赛OA账号，在本项目无权限 */
        state.userInfo = null;
        state.localLoginStatus = LocalLoginStatus.NOPERMISSION;
        state.jinsaiOAUri = get(res, 'data.redirectLogoutUri');
        break;
      }
      case -2: {
        state.notifyLevel = NotifyLevel.SILENCE; //此处检查/maintenance'路径注意地址维护
        window.location.href.indexOf('/maintenance') > -1 || window.location.replace('/maintenance');
        break;
      }
    }
    // console.log(res);
    // console.log(state);

    removePending(res.config);
    state.loading = !!pending.length;
    return res.data;
  },
  (err) => {
    // console.log(err);
    console.warn(err?.message);

    if (err?.message !== 'loading') {
      pending = [];
      state.loading = !!pending.length;
    }

    const response: BaseRes<void> = {
      status: 1,
      errorCode: CANCEL_ERR_CODE,
      errorMessage: '请求过于频繁',
      data: undefined,
    };

    return response;
  },
);

export const getValueByPath = <T, R>(obj: T, path: string, defaultValue: R): R => {
  const val = get(obj, path, defaultValue) as R;

  if (typeof val === 'number' && val === 0) {
    return val;
  }

  return val || defaultValue;
};

export const requestHandler = async <T>(config: IRequestHandlerConfig<T>): Promise<T> => {
  let isCalledFailCb = false;

  let result: T = config.defaultValue;

  try {
    const response: BaseRes<T> = await config.service();

    if (get(response, 'status') === ResponseStatus.SUCCESS) {
      result = getValueByPath(response, config.dataPath || 'data', config.defaultValue);
      config.onSuccess && config.onSuccess(response);
    } else if (get(response, 'errorCode') === CANCEL_ERR_CODE) {
      throw new Error('loading');
    } else {
      config.onFail && config.onFail(response);
      isCalledFailCb = true;
      throw new Error(get(response, 'errorMessage'));
    }
  } catch (err: unknown) {
    const e = err as { message?: string };
    if (e.message === 'loading') {
      // console.log(e);
    } else {
      notify.error({
        message: config.errorMessage,
        description: e.message || config.errorDescription,
      });

      result = config.defaultValue;

      if (!isCalledFailCb) {
        config.onFail && config.onFail();
      }
    }
  }

  return result;
};

export const requestBooleanHandler = async (config: IRequestHandlerConfig<boolean>): Promise<boolean> => {
  let isCalledFailCb = false;

  let result = config.defaultValue;

  try {
    const response: BaseRes<IBooleanResponse> = await config.service();
    result = getValueByPath(response, 'data.ok', config.defaultValue);

    if (get(response, 'errorCode') === CANCEL_ERR_CODE) {
      throw new Error('loading');
    } else if (get(response, 'status') !== ResponseStatus.SUCCESS || !result) {
      config.onFail && config.onFail(response);
      isCalledFailCb = true;

      throw new Error(response.errorMessage);
    } else {
      config.onSuccess && config.onSuccess(response);
    }
  } catch (err: unknown) {
    const e = err as { message?: string };
    if (e.message === 'loading') {
      // console.log(e);
    } else {
      notify.error({
        message: config.errorMessage,
        description: e.message || config.errorDescription,
      });

      result = config.defaultValue;

      if (!isCalledFailCb) {
        config.onFail && config.onFail();
      }
    }
  }

  return result;
};

/**
 * 接口 返回正常数据 或 下载数据 时使用
 * @param opts
 * @returns
 */
export const requestBlobHandler = <T, C>(opts: RequestBlobOptions<T, C>): Promise<ResponseBlob<C>> => {
  let server: (() => Promise<AxiosResponse<Blob>>) | undefined = undefined;
  const blobHttp = axios.create({
    baseURL: config?.backEnd, //添加api统一后端前缀
    headers: {},
    timeout: 0,
    responseType: 'blob',
  });
  if (opts.type === 'get') {
    server = () => blobHttp.get(opts.url, { params: opts.params });
  } else if (opts.type === 'post') {
    server = () => blobHttp.post(opts.url, opts.params);
  }
  return new Promise((reslove) => {
    if (server) {
      server()
        .then((res) => {
          const r = new FileReader();
          r.onload = function () {
            try {
              console.log('此请求返回普通信息,无法执行文件下载');
              // 能读出来说明返回不是blob对象
              const errRes = JSON.parse(this.result as string);
              // 如果返回了 errorMessage 默认使用后端返回的提示语
              reslove({ type: 0, value: opts.cb(errRes) });
            } catch (e) {
              console.log('此请求返回Blob,执行文件下载');
              // 异常，没读出来，按照blob进行操作
              const blob = new Blob([res.data], { type: res.headers['content-type'] });
              // 下载正常处理
              let fileName = res.headers['content-disposition'];
              // 获取文件名
              if (fileName && fileName.length >= 2) {
                fileName = fileName.split('=')[1];
                console.log(1);
              }
              fileName = decodeURIComponent(fileName);
              if ('download' in document.createElement('a')) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob); // 创建下载的链接
                link.download = fileName; // 下载后文件名
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click(); // 点击下载
                window.URL.revokeObjectURL(link.href); // 释放掉blob对象
                document.body.removeChild(link); // 下载完成移除元素
              }
              reslove({ type: 1 });
            }
          };
          r.readAsText(res.data);
        })
        .catch((e) => {
          console.log(e);
          reslove({ type: -1 });
        });
    } else {
      reslove({ type: -1 });
    }
  });
};

export default http;
