import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "query-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_manage_list_frame = _resolveComponent("manage-list-frame")!

  return (_openBlock(), _createBlock(_component_manage_list_frame, { listName: "合格用药管理" }, {
    query: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_select, {
          allowClear: "",
          "show-search": "",
          placeholder: "请选择用药状态",
          value: _ctx.query.status,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query.status) = $event)),
          onChange: _ctx.handleSearch,
          optionFilterProp: "label",
          class: "query-container_item",
          options: _ctx.saleStatusOpts
        }, null, 8, ["value", "onChange", "options"]),
        _createVNode(_component_a_select, {
          allowClear: "",
          "show-search": "",
          placeholder: "请选择数据来源",
          value: _ctx.query.salesSource,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query.salesSource) = $event)),
          onChange: _ctx.handleSearch,
          optionFilterProp: "label",
          class: "query-container_item",
          options: _ctx.saleSourceOpts
        }, null, 8, ["value", "onChange", "options"]),
        _createVNode(_component_a_select, {
          allowClear: "",
          "show-search": "",
          placeholder: "请选择就诊机构名称",
          value: _ctx.query.hospitalId,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query.hospitalId) = $event)),
          onSearch: _ctx.handleHospitalNameChange,
          onChange: _ctx.handleSearch,
          "filter-option": false,
          "not-found-content": _ctx.nameLoading ? undefined : null,
          class: "query-container_item",
          options: _ctx.hospitalOpts
        }, _createSlots({ _: 2 }, [
          (_ctx.nameLoading)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createVNode(_component_a_spin, { size: "small" })
                ])
              }
            : undefined
        ]), 1032, ["value", "onSearch", "onChange", "not-found-content", "options"]),
        _createVNode(_component_a_select, {
          allowClear: "",
          "show-search": "",
          placeholder: "请选择领域标签",
          value: _ctx.query.domainLabelId,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.query.domainLabelId) = $event)),
          onChange: _ctx.handleSearch,
          optionFilterProp: "label",
          class: "query-container_item",
          options: _ctx.domainOpts
        }, null, 8, ["value", "onChange", "options"]),
        _createVNode(_component_a_range_picker, {
          allowClear: "",
          value: _ctx.query.dateRange,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.query.dateRange) = $event)),
          onChange: _ctx.handleSearch,
          placeholder: ['购药日期起', '购药日期止']
        }, null, 8, ["value", "onChange"])
      ])
    ]),
    table: _withCtx(() => [
      _createVNode(_component_custom_table, {
        tableState: _ctx.tableState,
        onPageChange: _ctx.handlePage
      }, {
        status: _withCtx(({ props }) => [
          _createTextVNode(_toDisplayString(_ctx.SALE_STATUS_NAME[props.record.status]), 1)
        ]),
        salesSource: _withCtx(({ props }) => [
          _createTextVNode(_toDisplayString(_ctx.SALE_DATASOURCE_NAME[props.record.salesSource]), 1)
        ]),
        _: 1
      }, 8, ["tableState", "onPageChange"])
    ]),
    _: 1
  }))
}