
  import { defineComponent, reactive, PropType, ref, toRaw, computed } from 'vue';
  import { IFormEPenDTO } from '@/type/formEPenDTO';
  import { cloneDeep } from 'lodash';
  import { genRules } from './rules';
  import dictionary, { filterAllRegion } from '@/lib/dictionary';
  import { StaticDictionaryTypeEnum } from '@/lib/dictionary';
  import { DictionaryTypeEnum, DictionaryVo } from '@/type/api';
  import { getEPenBatchCode, getEPenStoreNum, getEPenPrice, getEPenSpec } from '@/services/common';

  import { IFormSubmitCallback, ValidateFailedCallback } from '../../interface';
  import { convertEPenForm, deserializationEPenForm } from './utils';
  import {
    computedLabeledValueModel,
    handePopupContainerByClass,
    handleFilterOptionCaseInsensitive,
  } from '../../utils';
  import { disabledDateAfterToday } from '@/lib/utils';
  import { Moment } from 'moment';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import { OPTION_KASHIPING } from './utils';
  export default defineComponent({
    props: {
      formValue: {
        type: Object as PropType<IFormEPenDTO<string>>,
        default: null,
      },
    },
    setup(props) {
      const modelRef = reactive(cloneDeep(toRaw(convertEPenForm(props.formValue)))) as IFormEPenDTO<Moment>;

      const provinces = ref([] as DictionaryVo<StaticDictionaryTypeEnum>[]);
      const hospitals = ref([] as DictionaryVo<StaticDictionaryTypeEnum>[]);
      const specs = ref([] as any[]);
      const deliveryPrices = ref<string[]>([]);
      const epenBatchBalanceInventory = ref();
      const epenBatchNumber = ref();
      const ePenBatchCodeList = ref([] as string[]);
      const formRef = ref();
      dictionary
        .getStaticDictionary({ type: DictionaryTypeEnum.Province })
        .then((data) => (provinces.value = filterAllRegion(data, DictionaryTypeEnum.Province)));
      getEPenSpec().then((data) => (specs.value = data));
      const provinceModel = computedLabeledValueModel(modelRef, {
        idField: 'purchaseProvinceId',
        nameField: 'purchaseProvinceName',
      });
      const handleHospital = (isEffect = false) => {
        if (modelRef.purchaseProvinceId) {
          if (isEffect) {
            modelRef.purchaseHospitalId = '';
            modelRef.purchaseHospitalName = '';
            modelRef.epenBatchNumber = '';
            modelRef.epenBatchBalanceInventory = '';
          }

          dictionary
            .getStaticDictionary({ type: DictionaryTypeEnum.Hospital, provinceId: modelRef.purchaseProvinceId })
            .then((data) => (hospitals.value = data));
        }
      };

      const getSpecName = (jinsaiId: string) => {
        if (jinsaiId && specs.value) {
          return specs.value.find((item) => item.jinsaiId == jinsaiId)?.name;
        } else return '';
      };
      const isKASHIPING = computed(
        () => modelRef.specificationsId && specs.value && getSpecName(modelRef.specificationsId) == OPTION_KASHIPING,
      );

      const purchaseHospitalModel = computedLabeledValueModel(modelRef, {
        idField: 'purchaseHospitalId',
        nameField: 'purchaseHospitalName',
      });

      const handleEPenBatchCodeList = (isEffect = false) => {
        if (modelRef.purchaseHospitalId) {
          if (isEffect) {
            modelRef.epenBatchNumber = '';
            modelRef.epenBatchBalanceInventory = '';
          }
        }
      };

      const getDeliveryPrices = () => {
        if (!isKASHIPING.value) {
          return;
        }
        getEPenPrice({
          hospitalId: modelRef.purchaseHospitalId,
          spec: getSpecName(modelRef.specificationsId) || modelRef.specificationsName,
          batchCode: modelRef.epenBatchNumber,
        }).then((data) => {
          deliveryPrices.value = data;
          // if (!modelRef.epenBatchNumber) {
          //   modelRef.epenBatchNumber = data[0];
          // }
          // epenBatchNumber.value.onFieldChange();
        });
      };

      const handleEpenBatchBalanceInventory = (isEffect = false) => {
        if (isEffect) {
          modelRef.epenBatchBalanceInventory = '';
          modelRef.deliveryPrice = '';
          deliveryPrices.value = [];
        }
        if (modelRef.epenBatchNumber) {
          getEPenStoreNum({
            hospitalId: modelRef.purchaseHospitalId,
            batchCode: modelRef.epenBatchNumber,
            spec: getSpecName(modelRef.specificationsId) || modelRef.specificationsName,
            price: modelRef.deliveryPrice,
          }).then((data) => {
            modelRef.epenBatchBalanceInventory = data;
            epenBatchBalanceInventory.value.onFieldChange();
            getDeliveryPrices();
          });
        }
      };
      const handleSpecificationsChange = (isEffect = false) => {
        if (modelRef.purchaseHospitalId) {
          if (isEffect) {
            modelRef.specificationsName = getSpecName(modelRef.specificationsId);
            modelRef.epenBatchNumber = '';
            modelRef.epenBatchBalanceInventory = '';
            modelRef.deliveryPrice = '';
            modelRef.epenWithdrawalNumber = '';
          }
          const spec = getSpecName(modelRef.specificationsId) || modelRef.specificationsName;
          if (spec) {
            getEPenBatchCode({
              hospitalId: modelRef.purchaseHospitalId,
              spec,
            }).then((data) => {
              ePenBatchCodeList.value = data;
              if (!modelRef.epenBatchNumber) {
                modelRef.epenBatchNumber = data[0];
              }
              handleEpenBatchBalanceInventory(isEffect);
              epenBatchNumber.value.onFieldChange();
            });
          }
        }
      };

      handleHospital();
      handleSpecificationsChange(); // 规格 -> 批号 -> 发货价(元/支) ｜ 库存
      const rules = genRules();
      const getFormValue = () => {
        return deserializationEPenForm(cloneDeep(toRaw(modelRef)));
      };

      const handleSubmit = async (
        cb: IFormSubmitCallback,
        validateFailedCb: ValidateFailedCallback<ValidateErrorEntity<IFormEPenDTO<Moment>>>,
      ) => {
        formRef.value
          .validate()
          .then(() => {
            cb(getFormValue());
          })
          .catch((err: ValidateErrorEntity<IFormEPenDTO<Moment>>) => {
            validateFailedCb(err);
          });
      };

      return {
        modelRef,
        rules,
        provinces,
        specs,
        deliveryPrices,
        hospitals,
        provinceModel,
        handleHospital,
        purchaseHospitalModel,
        handleEPenBatchCodeList,
        ePenBatchCodeList,
        handleEpenBatchBalanceInventory,
        formRef,
        handleSubmit,
        getFormValue,
        handleFilterOptionCaseInsensitive,
        handlePopupContainer: handePopupContainerByClass('elect-pen-form'),
        disabledDate: disabledDateAfterToday,
        epenBatchNumber,
        epenBatchBalanceInventory,
        handleSpecificationsChange,
        isKASHIPING,
      };
    },
  });
