import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    model: _ctx.modelRef,
    rules: _ctx.rules,
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        label: "骨龄检查",
        name: "boneAge",
        id: "boneAge"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.boneAge,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelRef.boneAge) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}