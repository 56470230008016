/* eslint-disable */
import { Form } from 'ant-design-vue';
import { HistoryOutlined } from '@ant-design/icons-vue';
import { get, omit, uniqueId } from 'lodash';
import moment from 'moment';

import { IFormDetailHistory, IFormPatientDTO, IsBoolean } from '@/type/formContent';

import { IFormPreviewGroup } from '../FormPreview/interface';
import { ObjectMap } from '@/type/helper';
import { VNode } from 'vue';
import { formatDateWithNone } from '../../utils';

const FormItem = Form.Item;

const genMobileDict = (
  history: ObjectMap<IFormDetailHistory[]>,
  field: 'mobile' | 'remark',
): ObjectMap<IFormDetailHistory[]> => {
  const mobileHistory = get(history, 'mobiles') || [];
  const mobileMap: ObjectMap<IFormDetailHistory[]> = mobileHistory.reduce((map, current) => {
    const list = get(current, 'value') || [];
    const common = omit(current, ['value']);

    if (Array.isArray(list)) {
      list.forEach((item) => {
        if (!map[item.id]) {
          map[item.id] = [];
        }

        map[item.id].push({
          ...common,
          value: item[field],
        });
      });
    }

    return map;
  }, {});

  return mobileMap;
};


// 是否展示生长激素
const showGrowthHormone = (formValue: IFormPatientDTO) => formValue.isUsedGrowthHormone === IsBoolean.TRUE;

const previewConfig: IFormPreviewGroup<IFormPatientDTO>[] = [
  {
    id: uniqueId('PatientForm'),
    groupName: false,
    items: [
      { label: '患者编码', fieldName: 'patientCode' },
      { label: '是否为网报老患者', fieldName: 'gensciOldPatient' },
    ],
  },
  {
    id: uniqueId('PatientForm1'),
    groupName: '人口学信息',
    items: [
      { label: '患者姓名', fieldName: 'name' },
      { label: '患者性别', fieldName: 'sex' },
      { label: '出生年月', fieldName: 'birthday', format: (birthday) => formatDateWithNone(birthday) },
      { label: '年龄', fieldName: 'age'},
      { label: '现居住省份', fieldName: 'residedProvinceName' },
      { label: '现居住城市', fieldName: 'residedCityName' },
      { label: '现居住地址', fieldName: 'residedAddress' },
      { label: '出生身高(CM)', fieldName: 'birthHeight' },
      { label: '出生体重(KG)', fieldName: 'birthWeight' },
      { label: '遗传身高(CM)', fieldName: 'geneticHeight' },
    ],
  },
  {
    id: uniqueId('PatientForm2'),
    groupName: '家长的信息',
    items: [
      { label: '家长与孩子的关系', fieldName: 'relationShip' },
      { label: '家长姓名', fieldName: 'parentName' },
      {
        label: '手机号',
        fieldName: 'mobiles',
        customRender: (value, formValue, history, showHistoryDialog): VNode[] => {
          const mobileMap = genMobileDict(history, 'mobile');
          const remarkMap = genMobileDict(history, 'remark');
          const mobiles = get(formValue, 'mobiles') || [];

          return [
            (
              <FormItem key="mobile-title" label="手机号" class="form-preview-content-item mobile-preview-item">
                <div class="mobile-preview-item-row">
                  {[
                    <div class="mobile-preview-item-column mobile-preview-item-column-title">号码</div>,
                    <div class="mobile-preview-item-column mobile-preview-item-column-title">备注</div>
                  ]}
                </div>
              </FormItem>
            ),
            ...mobiles.map((item) => (
              <FormItem key={item.id} label=" " class="form-preview-content-item none-colon-form-item">
                <div class="mobile-preview-item-row">
                  {[
                    <div class="mobile-preview-item-column">
                      {[
                        <div class="mobile-preview-item-column-value">{item.mobile}</div>,
                        <HistoryOutlined class="mobile-preview-item-column-icon" onClick={() => showHistoryDialog(mobileMap[item.id], {
                          label: '手机号',
                          fieldName: 'mobiles',
                        })} />,
                      ]}
                    </div>,
                    <div class="mobile-preview-item-column">
                      {[
                        <div class="mobile-preview-item-column-value">{item.remark}</div>,
                        <HistoryOutlined class="mobile-preview-item-column-icon" onClick={() => showHistoryDialog(remarkMap[item.id], {
                          label: '手机号备注',
                          fieldName: 'mobiles',
                        })} />,
                      ]}
                    </div>,
                  ]}
                </div>
              </FormItem>
            ))
          ];
        },
      },
      { label: '父亲身高(CM)', fieldName: 'fatherHeight' },
      { label: '母亲身高(CM)', fieldName: 'motherHeight' },
    ],
  },
  {
    id: uniqueId('PatientForm3'),
    groupName: '人口学信息',
    items: [
      { label: '身份证号', fieldName: 'idNumber' },
      { label: '民族', fieldName: 'nationName' },
      { label: '矮小家族史', fieldName: 'shortFamilyHistory' },
      { label: '是否有过敏史', fieldName: 'isHaveAllergiesHistory' },
      { label: '过敏史', fieldName: 'allergiesHistory', isShow: (formValue: IFormPatientDTO) => formValue.isHaveAllergiesHistory === IsBoolean.TRUE },
      { label: '是否有肿瘤史', fieldName: 'isHaveCancerHistory' },
      { label: '肿瘤史', fieldName: 'cancerHistory', isShow: (formValue: IFormPatientDTO) => formValue.isHaveCancerHistory === IsBoolean.TRUE },
      { label: '是否使用过生长激素', fieldName: 'isUsedGrowthHormone' },
      { label: '使用品牌', fieldName: 'usingBrands', isShow: showGrowthHormone },
      { label: '曾经使用时间___年___月', fieldName: 'userdDate', isShow: showGrowthHormone },
      { label: '累计多少个月', fieldName: 'cumulativeMonths', isShow: showGrowthHormone },
    ],
  },
];

export default previewConfig;
