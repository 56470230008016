/* eslint-disable @typescript-eslint/no-inferrable-types */

import { ICaseData } from '@/type/form';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import { CasePreviewMode } from '../CasePreview/interface';

/**
 * 匹配formId
 * @param {string} str 待匹配的字符串
 * @returns {string} formId
 */
export const matchFormId = (str: string = ''): string => {
  const match = str.match(/^\d+/);
  return match ? match[0] : '';
};

export const orderByUpdate = (cases: ICaseData[], orders: 'desc' | 'asc' = 'desc'): ICaseData[] => {
  return orderBy(cases, (item) => moment(item.updatedAt).valueOf(), orders);
};

/**
 * 根据打回状态获取对应病例
 *
 * @param {ICaseData[]} cases 病例列表
 * @param {boolean} rejected 是否为打回病例
 * @returns {ICaseData[]}
 */
export const getCasesByInjectedStatus = (cases: ICaseData[], rejected: boolean): ICaseData[] => {
  return cases.filter((item) => get(item, 'meta.rejected') === rejected);
};

/**
 * 根据预览类型获取对应病例
 *
 * @param {ICaseData[]} cases 病例列表
 * @param {CasePreviewMode} mode 预览类型
 * @returns {ICaseData[]}
 */
export const getCasesByPreviewMode = (cases: ICaseData[], mode: CasePreviewMode): ICaseData[] => {
  return mode === CasePreviewMode.ALL ? cases : cases.filter((item) => get(item, 'images.length' || 0) > 0);
};

/**
 * 根据录入员id获取对应病例列表
 *
 * @param {ICaseData[]} cases 病例列表
 * @param {string} recordUserId 录入员id
 * @returns {ICaseData[]}
 */
export const getCasesByRecordUserId = (cases: ICaseData[], recordUserId: string): ICaseData[] => {
  return cases.filter((item) => item.recordUserId === recordUserId);
};

/**
 * 根据表单类型id获取对应病例列表
 *
 * @param {ICaseData[]} cases 病例列表
 * @param {string} formId 表单id
 * @returns {ICaseData[]}
 */
export const getCasesByFormId = (cases: ICaseData[], formId: string): ICaseData[] => {
  return cases.filter((item) => get(item, 'meta.formId', '').includes(formId));
};
