import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: "删除手机号",
    onOk: _ctx.handleSubmit,
    onCancel: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formState,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            labelCol: _ctx.rowCol,
            wrapperCol: _ctx.rowCol,
            label: "手机号码"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.mobileNumber), 1)
            ]),
            _: 1
          }, 8, ["labelCol", "wrapperCol"]),
          _createVNode(_component_a_form_item, {
            ref: "deleteReason",
            label: "删除原因",
            name: "deleteReason",
            labelCol: _ctx.rowCol,
            wrapperCol: _ctx.rowCol
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                class: "float-input-count",
                value: _ctx.formState.deleteReason,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.deleteReason) = $event)),
                showCount: "",
                maxlength: 200
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["labelCol", "wrapperCol"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}