import { computed, ComputedRef,Ref,ref } from 'vue';
import { ValidationRule } from 'ant-design-vue/lib/form/Form';

import { ObjectMap } from '@/type/helper';
import { RuleObject } from 'ant-design-vue/lib/form/interface';
import { IFormDoubt } from '../../interface';
import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import { Moment } from 'moment';
import DrugWithdrawalNumberHelper from './itemHelpers/DrugWithdrawalNumberHelper';
import DrugPurchasedNumberHelper from './itemHelpers/DrugPurchasedNumberHelper';
import { generateNonnegativeValidator } from '../../utils';
import { FormOperateType, FormStatus, IBaseFormDTO, IFormDetailHistory, FormSource,} from '@/type/formContent';
import { DictionaryTypeEnum, DictionaryVo } from '@/type/api';

export const genRules = (
  isShowAutarky: ComputedRef<boolean>, // 是否显示 是否自营 点位
  isShowOtherDesc: ComputedRef<boolean>, // 是否显示 用药出现原因备注 点位
  isShowDelayEnterReasonOther: ComputedRef<boolean>, // 是否显示 延迟录入原因备注 点位
  isDelayOrSale: ComputedRef<'DELAY' | 'SALE'>, //是显示 用药出现原因 还是显示 延迟录入原因 点位
  unitDoseDoubt: ComputedRef<IFormDoubt>, // 是否显示 单位用药剂量状态 点位
  isShowDisqualificationReasonType: ComputedRef<boolean>, // 是否显示 不合格原因 点位
  isShowNextPurchaseOtherDesc: ComputedRef<boolean>, // 是否显示 不合格原因备注 点位
  isShowMedicationDurationExplain: ComputedRef<boolean>, // 是否显示 用药时长说明 点位
  isShowMedicationDurationExplainRemark: ComputedRef<boolean>, // 是否显示 用药时长说明备注 点位
  modelRef: UnwrapNestedRefs<IFormMedicationDTO<Moment, string[]>>,
  specifications: any,
  batchNumbers: Ref<string[]>,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
): ComputedRef<ObjectMap<ValidationRule[]>> =>
  computed(() => {
    const rules: ObjectMap<ValidationRule[]> = {
      visitDoctorProvinceId: [{ required: true, message: '请选择就诊省份' }],
      hospitalType: [{ required: true, message: '请选择就诊机构类型' }],
      hospitalId: [{ required: true, message: '请选择就诊机构名称' }],
      doctorId: [
        { required: true, message: '请选择就诊KH' },
        {
          // 判断详情返回的医生名称是否在医生列表中，如果不在，则提示“就诊KH不存在”
          async validator(rule: RuleObject, value: string): Promise<void> {
            if (modelRef.source == FormSource.ORG) {
              if (doctors.value.length) {
                const valueExists = doctors.value.some(item => item.name == modelRef.doctorName);
                if (valueExists) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('就诊KH不存在');
                }
              } else {
                return Promise.reject('就诊KH不存在');
              }
            };
            return Promise.resolve();
          },
          trigger: 'blur',
        },
      ],

      buyMedicineProvinceId: [{ required: true, message: '请选择购药省份' }],
      buyMedicineMechanismType: [{ required: true, message: '请选择购药机构类型' }],
      buyMedicineMechanismId: [{ required: true, message: '请选择购药机构名称' }],

      buyMedicineDate: [{ required: true, message: '请选择购药时间', type: 'object' }],
      // salesIncreaseDate: [{ required: true, message: '请选择用药出现时间', type: 'object' }],
      // salesIncreaseReason: [{ required: true, message: '请选择用药出现原因' }],

      // specificationsId: [{ required: true, message: '请选择规格' }],
      specificationsId: [
        { required: true, message: '请选择规格' },
        {
          // 判断详情返回的规格是否在规格列表中，如果不在，则提示“规格不存在”
          async validator(rule: RuleObject, value: string): Promise<void> {
            if (modelRef.source == FormSource.ORG) {
              if (specifications.value.length) {
                const valueExists = specifications.value.some(item => item.name == modelRef.specificationsName);
                if (valueExists) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('规格不存在');
                }
              } else {
                return Promise.reject('规格不存在');
              }
            };
            return Promise.resolve();
          },
          trigger: 'blur',
        },
      ],
      batchNumber: [
        { required: true, message: '请选择批号' },
        {
          // 判断详情返回的批号是否在批号列表中，如果不在，则提示“批号不存在”
          async validator(rule: RuleObject, value: string): Promise<void> {
            if (modelRef.source == FormSource.ORG) {
              if (batchNumbers.value.length) {
                // 包含为true，不包含为false
                const valueExists = batchNumbers.value.indexOf(modelRef.batchNumber) !== -1;
                if (valueExists) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('批号不存在');
                }
              } else {
                return Promise.reject('批号不存在');
              }
            };
            return Promise.resolve();
          },
          trigger: 'blur',
        },
      ],
      deliveryPrice: [{ required: true, message: '请选择发货价' }],
      actualDeliveryPrice: [
        { required: true, message: '请输入实际出库价' },
        {
          validator: generateNonnegativeValidator('实际出库价', 'float'),
          trigger: 'blur',
        },
      ],
      drugPurchasedNumber: [
        { required: true, message: '请输入购药数量' },
        {
          async validator(rule: RuleObject, value: string): Promise<void> {
            const [verifyStatus, errMessage] = DrugPurchasedNumberHelper.verify(value);

            if (verifyStatus) {
              return Promise.resolve();
            }

            return Promise.reject(errMessage);
          },
          trigger: 'blur',
        },
      ],
      drugWithdrawalNumber: [
        {
          async validator(rule: RuleObject, value: string): Promise<void> {
            const [verifyStatus, errMessage] = DrugWithdrawalNumberHelper.verify(modelRef, value);

            if (verifyStatus) {
              return Promise.resolve();
            }

            return Promise.reject(errMessage);
          },
          trigger: 'blur',
        },
      ],

      injectionDose: [
        { required: true, message: '请输入注射剂量' },
        {
          validator: generateNonnegativeValidator('注射剂量', 'float'),
          trigger: 'blur',
        },
      ],
      injectionUnit: [{ required: true, message: '请选择注射剂量单位' }],
      hit: [
        { required: true, message: '请输入注射频率(打)' },
        {
          validator: generateNonnegativeValidator('注射频率(打)', 'integer'),
          trigger: 'blur',
        },
      ],
      stop: [
        { required: true, message: '请输入注射频率(停)' },
        {
          validator: generateNonnegativeValidator('注射频率(停)', 'integer'),
          trigger: 'blur',
        },
      ],
      weight: [
        { required: true, message: '请输入体重' },
        {
          validator: generateNonnegativeValidator('体重', 'float'),
          trigger: 'blur',
        },
      ],

      statusIdentification: [{ required: true, message: '请选择状态标识' }],
      isDiscount: [{ required: true, message: '请选择是否优惠购药' }],
      discount: [{ required: true, message: '请选择优惠情况' }],
    };

    if (isShowAutarky.value) {
      rules.autarky = [{ required: true, message: '请选择是否自营' }];
    }

    if (isShowOtherDesc.value) {
      rules.otherDesc = [{ required: true, message: '请填写用药出现原因备注' }];
    }
    if (isShowDelayEnterReasonOther.value) {
      rules.delayEnterReasonOther = [{ required: true, message: '请填写延迟录入原因备注' }];
    }
    if (unitDoseDoubt.value.class) {
      rules.unitDoseStatus = [{ required: true, message: '请选择单位用药剂量状态' }];
    }

    if (isShowDisqualificationReasonType.value) {
      if (modelRef.statuSnonconformity) {
        rules.statuSnonconformity = [{ required: true, message: '请选择不合格原因' }];
      }
      rules.disqualificationReason = [{ required: true, message: '请选择不合格原因', type: 'array' }];
      if (isShowNextPurchaseOtherDesc.value) {
        rules.nextPurchaseOtherDesc = [{ required: true, message: '请输入不合格原因备注' }];
      }
    }

    if (isShowMedicationDurationExplain.value) {
      rules.medicationDurationExplain = [{ required: true, message: '请选择用药时长说明' }];

      if (isShowMedicationDurationExplainRemark.value) {
        rules.medicationDurationExplainRemark = [{ required: true, message: '请输入用药时长说明备注' }];
      }
    }

    if (isDelayOrSale.value === 'SALE') {
      rules.salesIncreaseReason = [{ required: true, message: '请选择用药出现原因' }];
    } else {
      rules.delayEnterReason = [{ required: true, message: '请选择延迟录入原因' }];
    }
    return rules;
  });

export default genRules;
