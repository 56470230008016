import { IFormTreatmentDTO, IndicationType } from '@/type/formTreatmentDTO';
import { EffectHandlerHooks } from '../../interface';
import { TreatmentFormEffectField } from './interface';

const EffectHandler: EffectHandlerHooks<IFormTreatmentDTO<moment.Moment>, TreatmentFormEffectField> = (modelRef) => {
  return {
    /**
     * 当前点位: 患者群体
     * 联动点位: 适应症, 适应症备注
     *
     * 当患者群体发生变化时, 清空适应症及其备注信息
     */
    indicationGroupId() {
      modelRef.indicationId = '';
      modelRef.indicationName = '';
      modelRef.indicationRemark = '';
    },

    /**
     * 当前点位: 适应症
     * 联动点位: 适应症备注
     *
     * 当选择值不为其他类型时, 清空备注内容
     *
     * @param nextVal 适应症当前值
     */
    indicationId(nextVal: string) {
      if (
        ![IndicationType.ORHER_CHILDREN, IndicationType.OTHER_ADULT, IndicationType.OTHER_SURGICAL_GROUP].includes(
          nextVal as IndicationType,
        ) &&
        modelRef.indicationRemark
      ) {
        modelRef.indicationRemark = '';
      }
    },

    /**
     * 当前点位: 首诊省份
     * 联动点位: 首诊城市, 首诊医院, 首诊医生
     *
     * 当「首诊省份」发生变化时, 清空「首诊城市, 首诊医院, 首诊医生」点位信息
     */
    visitedProvinceId() {
      modelRef.visitedCityId = '';
      modelRef.visitedCityName = '';

      modelRef.visitedHospitalId = '';
      modelRef.visitedHospitalName = '';

      modelRef.visitedDoctorId = '';
      modelRef.visitedDoctorName = '';
    },

    /**
     * 当前点位: 首诊城市
     * 联动点位: 首诊医院, 首诊医生
     *
     * 当「首诊城市」发生变化时, 清空「首诊医院, 首诊医生」点位信息
     */
    visitedCityId() {
      modelRef.visitedHospitalId = '';
      modelRef.visitedHospitalName = '';

      modelRef.visitedDoctorId = '';
      modelRef.visitedDoctorName = '';
    },

    /**
     * 当前点位: 首诊医院
     * 联动点位: 首诊医生
     *
     * 当「首诊医院」发生变化时, 清空「首诊医生」点位信息
     */
    visitedHospitalId() {
      modelRef.visitedDoctorId = '';
      modelRef.visitedDoctorName = '';
    },
  };
};

export default EffectHandler;
