
  import { defineComponent } from 'vue';
  // import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  export default defineComponent({
    components: {
      // ManageListFrame,
    },
    setup() {
      return {};
    },
  });
