
  import { defineComponent, PropType, reactive, toRaw, computed, watch } from 'vue';
  import { SearchSelectQo } from '@/type/api';
  import { Option } from '@/type/common';
  export default defineComponent({
    props: {
      options: {
        type: Array as PropType<Option[]>,
        required: true,
      },
      query: {
        type: Object as PropType<SearchSelectQo>,
        required: true,
      },
    },
    setup(props, { emit }) {
      const innerQuery = reactive(props.query);
      const { searchType, searchText } = toRaw(props.query);
      const params: SearchSelectQo = reactive({ searchType, searchText });
      params.searchType = toRaw(props.options[0].id);
      const inputPlaceholder = computed(() => {
        return '请输入' + (props.options.find((opt) => opt.id === params.searchType)?.name || '');
      });
      const handleEnterdown = () => {
        emit('pressEnter', Object.assign(props.query, params));
      };
      const handleSearchType = () => {
        emit('pressEnter', Object.assign(props.query, params));
      }
      const handleChange = () => {
        innerQuery.searchText = params.searchText;
      };
      watch(props.query, (newVal, oldVal) => {
        const { searchType, searchText } = toRaw(newVal);
        params.searchType = searchType || toRaw(props.options[0].id)
        params.searchText = searchText

      });
      return { params, inputPlaceholder, handleEnterdown, handleSearchType, handleChange };
    },
  });
