import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_FormSlice = _resolveComponent("FormSlice")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    model: _ctx.modelRef,
    rules: _ctx.rules,
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormSlice, { title: "机构基本信息" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "组织区域-省",
            name: "organizationProvinceId",
            id: "organizationProvinceId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                onChange: _cache[0] || (_cache[0] = (val, opts) => _ctx.handleCitys(val, opts, true)),
                placeholder: "请选择省",
                allowClear: "",
                value: _ctx.modelRef.organizationProvinceId,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelRef.organizationProvinceId) = $event)),
                class: "query-container_item",
                "show-search": "",
                filterOption: _ctx.filterOption,
                optionFilterProp: "name"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinceOpts, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: opt.id,
                      key: opt.id,
                      name: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "组织区域-市",
            name: "organizationAreaId",
            id: "organizationAreaId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                placeholder: "请选择市",
                "show-search": "",
                optionFilterProp: "name",
                filterOption: _ctx.filterOption,
                allowClear: "",
                value: _ctx.modelRef.organizationAreaId,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelRef.organizationAreaId) = $event)),
                class: "query-container_item",
                onChange: _cache[3] || (_cache[3] = (val, opts) => _ctx.handleHospitals(val, opts, true))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityOpts, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: opt.id,
                      key: opt.id,
                      name: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["filterOption", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "机构名称",
            name: "organizationId",
            id: "organizationId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.modelRef.organizationId,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelRef.organizationId) = $event)),
                placeholder: "请选择机构名称",
                "show-search": "",
                filterOption: _ctx.filterOption,
                allowClear: "",
                onChange: _cache[5] || (_cache[5] = (val, opts) => _ctx.getAnotherField(val, opts, 'organizationName'))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hospitalOpts, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: opt.id,
                      key: opt.id,
                      name: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "一级科室",
            name: "firstLevelDepartmentName",
            id: "firstLevelDepartmentName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.firstLevelDepartmentName,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelRef.firstLevelDepartmentName) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "二级科室",
            name: "secondLevelDepartmentName",
            id: "secondLevelDepartmentName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.secondLevelDepartmentName,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelRef.secondLevelDepartmentName) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "KH编码三",
            name: "khCode",
            id: "khCode"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.khCode,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelRef.khCode) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "KH姓名",
            name: "khName",
            id: "khName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.khName,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelRef.khName) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "KHID",
            name: "khId",
            id: "khId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.khId,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modelRef.khId) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "合作门诊名称",
            name: "cooperationDeptId",
            id: "cooperationDeptId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.modelRef.cooperationDeptId,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modelRef.cooperationDeptId) = $event)),
                placeholder: "请选择合作门诊名称",
                "show-search": "",
                allowClear: "",
                filterOption: _ctx.filterOption,
                onChange: _cache[12] || (_cache[12] = (val, opts) => _ctx.getAnotherField(val, opts, 'cooperationDeptName'))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hospitalOpts, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: opt.id,
                      key: opt.id,
                      name: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_FormSlice, { title: "患者信息" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "孩子/用户姓名",
            name: "patientName",
            id: "patientName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.patientName,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modelRef.patientName) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "孩子出生日期",
            name: "patientBirthday",
            id: "patientBirthday"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_date_picker, {
                value: _ctx.modelRef.patientBirthday,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.modelRef.patientBirthday) = $event)),
                "disabled-date": _ctx.disabledDateAfterToday,
                valueFormat: "yyyy-MM-DD"
              }, null, 8, ["value", "disabled-date"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "顾客联系方式",
            name: "phone",
            id: "phone"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.phone,
                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.modelRef.phone) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "用户类型",
            name: "patientType",
            id: "patientType"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.patientType,
                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.modelRef.patientType) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "患者ID",
            name: "patientId",
            id: "patientId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.patientId,
                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.modelRef.patientId) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_FormSlice, { title: "订单信息" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "产品名称",
            name: "productId",
            id: "productId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.modelRef.productId,
                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.modelRef.productId) = $event)),
                placeholder: "请选择产品名称",
                "show-search": "",
                allowClear: "",
                filterOption: _ctx.filterOption,
                onChange: _cache[19] || (_cache[19] = (val, opts) => _ctx.getAnotherField(val, opts, 'productName'))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specsOpts, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: opt.id,
                      key: opt.id,
                      name: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "订单号",
            name: "orderNumber",
            id: "orderNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.orderNumber,
                "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.modelRef.orderNumber) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "支付/结算时间",
            name: "payTime",
            id: "payTime"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_date_picker, {
                value: _ctx.modelRef.payTime,
                "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.modelRef.payTime) = $event)),
                "disabled-date": _ctx.disabledDateAfterToday,
                valueFormat: "yyyy-MM-DD"
              }, null, 8, ["value", "disabled-date"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "销售状态",
            name: "salesStatusId",
            id: "salesStatusId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.modelRef.salesStatusId,
                "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.modelRef.salesStatusId) = $event)),
                placeholder: "请选择销售状态",
                "show-search": "",
                allowClear: "",
                filterOption: _ctx.filterOption,
                onChange: _cache[23] || (_cache[23] = (val, opts) => _ctx.getAnotherField(val, opts, 'salesStatus'))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesStatusOpts, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: opt.id,
                      key: opt.id,
                      name: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "销售数量",
            name: "salesNum",
            id: "salesNum"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_number, {
                value: _ctx.modelRef.salesNum,
                "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.modelRef.salesNum) = $event)),
                valueModifiers: { trim: true },
                style: {"width":"178px"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "赠送数量",
            name: "giveNum",
            id: "giveNum"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_number, {
                value: _ctx.modelRef.giveNum,
                "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.modelRef.giveNum) = $event)),
                valueModifiers: { trim: true },
                style: {"width":"178px"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "终端销售额",
            name: "terminalSales",
            id: "terminalSales"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_number, {
                value: _ctx.modelRef.terminalSales,
                "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.modelRef.terminalSales) = $event)),
                valueModifiers: { trim: true },
                style: {"width":"178px"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "健康顾问",
            name: "healthyAdviser",
            id: "healthyAdviser"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.healthyAdviser,
                "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.modelRef.healthyAdviser) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "首购时间",
            name: "firstBuyTime",
            id: "firstBuyTime"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_date_picker, {
                value: _ctx.modelRef.firstBuyTime,
                "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.modelRef.firstBuyTime) = $event)),
                "disabled-date": _ctx.disabledDateAfterToday,
                valueFormat: "yyyy-MM-DD"
              }, null, 8, ["value", "disabled-date"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "备注",
            name: "remarks",
            id: "remarks"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.remarks,
                "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.modelRef.remarks) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}