
  import { defineComponent } from 'vue';

  import { getSystemStatus } from '@/services/common';

  export default defineComponent({
    name: 'in-maintenance',
    setup() {
      const handleReload = async () => {
        await getSystemStatus();
      };

      return {
        handleReload,
      };
    },
  });
