
  import { defineComponent, PropType, reactive, ref, toRaw, toRefs } from 'vue';
  import { cloneDeep, set } from 'lodash';
  import FormSlice from '../FormSlice/index.vue';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import { disabledDateAfterToday, filterOption } from '@/lib/utils';
  import { IFormNourishmentDTO } from '@/type/formNourishmentDTO';
  import { handePopupContainerByClass } from '../../utils';
  import dictionary from '@/lib/dictionary';
  import { getNutritionSpeclist } from '@/services/nourishment';
  import { IFormSubmitCallback, ValidateFailedCallback } from '../../interface';
  import rules from './rules';
  import { DictionaryTypeEnum } from '@/type/api';
  import { Option } from '@/type/common';

  export default defineComponent({
    components: {
      FormSlice,
    },
    props: {
      formValue: {
        type: Object as PropType<IFormNourishmentDTO>,
        default: null,
      },
    },
    setup(props) {
      const modelRef = reactive(cloneDeep(toRaw(props.formValue))) as IFormNourishmentDTO;
      const formRef = ref();
      const opts = reactive({
        provinceOpts: [] as Option[],
        cityOpts: [] as Option[],
        hospitalOpts: [] as Option[],
        specsOpts: [] as Option[],
        salesStatusOpts: [
          { id: '1', name: '发货' },
          { id: '0', name: '退单' },
        ],
      });
      getNutritionSpeclist().then((data) => (opts.specsOpts = data.map((v) => ({ id: v.jinsaiId, name: v.name }))));

      const getFormValue = () => {
        return cloneDeep(toRaw(modelRef));
      };

      const handleSubmit = async (
        cb: IFormSubmitCallback,
        validateFailedCb: ValidateFailedCallback<ValidateErrorEntity<IFormNourishmentDTO>>,
      ) => {
        formRef.value
          .validate()
          .then(() => {
            cb(getFormValue());
          })
          .catch((err: ValidateErrorEntity<IFormNourishmentDTO>) => {
            validateFailedCb(err);
          });
      };

      const setAnotherField = (key: string, value: string) => {
        set(modelRef, key, value);
      };

      const getAnotherField = (value: string | number, opts: Option, field: keyof IFormNourishmentDTO) => {
        if (opts) {
          setAnotherField(field, opts.name);
          // set(modelRef, field, anthor.name);
        } else {
          setAnotherField(field, '');
        }
      };

      const clearOrganValue = (startKey: string) => {
        let allowClear = false;
        const keys = [
          // 省
          'organizationProvinceId',
          'organizationProvinceName',
          // 市
          'organizationAreaId',
          'organizationAreaName',
          // 机构
          'organizationId',
          'organizationName',
          // 合作门诊
          'cooperationDeptId',
          'cooperationDeptName',
        ];
        keys.forEach((key) => {
          if (key === startKey) {
            allowClear = true;
          } else if (allowClear) {
            if (startKey === 'organizationName' && (key === 'cooperationDeptId' || key === 'cooperationDeptName')) {
              console.log('机构 变更不清空 合作门诊');
            } else {
              set(modelRef, key, '');
            }
          }
        });
      };

      // 选择省-获取市
      const handleCitys = (val, options, setFlag: boolean) => {
        setFlag && getAnotherField(val, options, 'organizationProvinceName');
        clearOrganValue('organizationProvinceName');
        // modelRef.organizationAreaId = '';
        // modelRef.organizationAreaName = '';
        opts.cityOpts = [];
        opts.hospitalOpts = [];
        val &&
          dictionary
            .getStaticDictionary({
              type: DictionaryTypeEnum.City,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              jinsaiParentId: modelRef.organizationProvinceId,
            })
            .then((res) => {
              opts.cityOpts = res.map((city) => {
                return {
                  id: city.jinsaiId,
                  name: city.name,
                };
              });
              // console.log(opts.cityOpts, '???opts.cityOpts');
            });
      };

      // 选择市-获取医院
      const handleHospitals = (val, options, setFlag: boolean) => {
        setFlag && getAnotherField(val, options, 'organizationAreaName');
        clearOrganValue('organizationAreaName');
        // modelRef.cooperationDeptId = '';
        // modelRef.cooperationDeptName = '';
        // modelRef.organizationId = '';
        // modelRef.organizationName = '';
        opts.hospitalOpts = [];
        val &&
          dictionary
            .getStaticDictionary({
              type: DictionaryTypeEnum.Hospital,
              cityId: modelRef.organizationAreaId,
            })
            .then((res) => {
              opts.hospitalOpts = res.map((hospital) => {
                return {
                  id: hospital.jinsaiId,
                  name: hospital.name,
                };
              });
            });
      };

      // 用于回显时下拉
      // 省
      dictionary
        .getStaticDictionary({
          type: DictionaryTypeEnum.Province,
        })
        .then((res) => {
          res.map((province) => {
            opts.provinceOpts.push({
              id: province.jinsaiId,
              name: province.name,
            });
          });
        });
      // 市
      modelRef.organizationProvinceId &&
        dictionary
          .getStaticDictionary({
            type: DictionaryTypeEnum.City,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            jinsaiParentId: modelRef.organizationProvinceId,
          })
          .then((res) => {
            opts.cityOpts = res.map((city) => {
              return {
                id: city.jinsaiId,
                name: city.name,
              };
            });
            // console.log(opts.cityOpts, '???opts.cityOpts');
          });
      // 医院
      modelRef.organizationAreaId &&
        dictionary
          .getStaticDictionary({
            type: DictionaryTypeEnum.Hospital,
            cityId: modelRef.organizationAreaId,
          })
          .then((res) => {
            opts.hospitalOpts = res.map((hospital) => {
              return {
                id: hospital.jinsaiId,
                name: hospital.name,
              };
            });
          });
      return {
        modelRef,
        formRef,
        rules,
        getFormValue,
        handleSubmit,
        disabledDateAfterToday,
        handlePopupContainer: handePopupContainerByClass('elect-pen-form'),
        // EffectHandler,
        ...toRefs(opts),
        handleCitys,
        getAnotherField,
        handleHospitals,
        filterOption,
      };
    },
  });
