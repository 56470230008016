import { IFormPatientDTO } from '@/type/formContent';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import moment, { Moment } from 'moment';

export default class BirthdayHelper {
  // 出生日期-购药日期≤30天  ||  出生日期-派单到零氪的时间 ≤30天；给出提示：出生日期-注册/购药日期≤30天
  static hasDoubt(modelRef: IFormPatientDTO<Moment>, fisrtDetail: IFormMedicationDTO<moment.Moment, string[]> | null, earlyWarningRule: any ): [boolean, string] {
    let message = '';
    let hasDoubt = false;
    const { birthday, createDate } = modelRef || {} // 1694431993000  createDate派单到零氪的时间
    const { buyMedicineDate } = fisrtDetail || {} //"2023-09-10"  购药日期
    const { diffBirthDate } = earlyWarningRule || {}
    const createDateStr = moment(createDate).format('YYYY-MM-DD');
    const createDiff = moment(createDateStr).diff(birthday, 'days');
    const buyMedicineDiff = moment(buyMedicineDate).diff(birthday, 'days');
    console.log('----createDate----', createDateStr)
    console.log('----buyMedicineDate----', buyMedicineDate)
    console.log('----earlyWarningRule-00343049023492---',diffBirthDate, birthday, buyMedicineDate, createDateStr, createDiff, buyMedicineDiff)
    if(createDiff <= diffBirthDate || buyMedicineDiff <= diffBirthDate) {
        message = `出生日期-注册/购药日期≤${diffBirthDate}天`
        hasDoubt = true
    }
    return [hasDoubt, message];
  }
}
