import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'formList',
    path: 'list/:moduleId/:subjectId',
    component: () => import(/* webpackChunkName: "formList" */ `../views/form/list/index.vue`),
  },
  {
    name: 'formContent',
    path: 'content/:moduleId/:subjectId/:subjectFormId',
    component: () => import(/* webpackChunkName: "formContent" */ `../views/form/content/index.vue`),
  },
];

export default routes;
