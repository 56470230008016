import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormPreviewContent = _resolveComponent("FormPreviewContent")!
  const _component_FormSlice = _resolveComponent("FormSlice")!
  const _component_form_item_history_dialog = _resolveComponent("form-item-history-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.config)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.config.groupName === false)
            ? (_openBlock(), _createBlock(_component_FormPreviewContent, {
                configs: _ctx.config.items,
                data: _ctx.data,
                key: _ctx.config.id,
                history: _ctx.history,
                onShowHistory: _ctx.showHistories
              }, null, 8, ["configs", "data", "history", "onShowHistory"]))
            : (_openBlock(), _createBlock(_component_FormSlice, {
                key: 1,
                title: _ctx.config.groupName
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_FormPreviewContent, {
                    configs: _ctx.config.items,
                    data: _ctx.data,
                    key: _ctx.config.id,
                    history: _ctx.history,
                    onShowHistory: _ctx.showHistories
                  }, null, 8, ["configs", "data", "history", "onShowHistory"]))
                ]),
                _: 1
              }, 8, ["title"]))
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_form_item_history_dialog, {
      visible: _ctx.historyDialogVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.historyDialogVisible) = $event)),
      histories: _ctx.currentHistories,
      "onUpdate:histories": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentHistories) = $event)),
      config: _ctx.currentConfig,
      "onUpdate:config": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentConfig) = $event))
    }, null, 8, ["visible", "histories", "config"])
  ], 64))
}