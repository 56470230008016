import { LocalLoginStatus, loginTest, state } from '@/lib/state';
import { menuGroupList } from '@/lib/menu';
import { iamLogin } from '@/services/usercenter';
import routeInstance from '@/router/index';
import config from '@/lib/config';

/**
 * 1、进行用户校验
 * 2、金赛OA回跳后的后端用户验证
 * */
export const useAuthTest = (): void => {
  const currentPath = analysisHref(location.href);
  const pathName = currentPath.pathName;
  const isLogin = isLoginRoute(pathName);
  if (isLogin) {
    // 访问 登录页
    const code = currentPath.searchParams.get('code') || '';
    const target_uri = currentPath.searchParams.get('target_uri') || '';
    if (!target_uri || !code) {
      // 非金赛回调
      useLoginTest();
    } else {
      // 金赛OA登录后的回调
      iamLogin({ code })
        .then((res) => {
          if (res) {
            // 更新用户信息
            state.userInfo = res;
            state.needLogin = false;
            state.localLoginStatus = LocalLoginStatus.SUCCESS;
            const targetUri = analysisHref(target_uri);
            if (isLoginRoute(targetUri.pathName)) {
              redirectFirstRoute();
            } else {
              window.location.href = decodeURIComponent(target_uri);
            }
          } else {
            if ([LocalLoginStatus.NOUSER, LocalLoginStatus.NOPERMISSION].includes(state.localLoginStatus)) {
              setTimeout(() => {
                goJinSaiOA(false);
              }, 1000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } else {
    // 访问 非登录页
    if (pathName !== '/patientImport') {
      useLoginTest();
    }
  }
};

/**
 * 跳转到 金赛OA登录页
 * target_uri 为当前登录页，方便登录成功后金赛OA跳转到Login页面(此为固定页面)验证后再次回到原页面
 *
 * needTarget {boolean} 退出登录、登录失败时为false
 */
export const goJinSaiOA = (needTarget = true): void => {
  if (config.isDev) {
    console.warn('本地环境不跳转金赛OA，由于cookie无法共享，会造成死循环.需要在测试环境登录后将cookie复制到本地环境');
    return;
  }
  if (state.jinsaiOAUri) {
    state.userInfo = null;
    const jinSaiOAUri = state.jinsaiOAUri + (needTarget ? `&target_uri=${encodeURIComponent(location.href)}` : '');
    console.log('goJinSaiOA', jinSaiOAUri);
    location.href = jinSaiOAUri;
  }
};

export const redirectFirstRoute = () => {
  setTimeout(() => {
    let routeName = '';
    const firstChild = menuGroupList.value[0].children[0];
    if (firstChild.type === 'menuItem') {
      routeName = firstChild.key;
    } else if (firstChild.type === 'subMenu') {
      routeName = firstChild.children[0].key;
    }
    console.log('redirectFirstRoute', routeName);
    routeInstance.push({ name: routeName });
  }, 500);
};

/**
 * 校验用户是否已登陆
 * 1、未登录 - 跳转到金赛OA的登录页
 * 2、已登录 - 跳转到目标地址
 */
const useLoginTest = (isRedirect = true) => {
  loginTest().then((res) => {
    if (!res) {
      // 未登录 跳转到金赛OA
      // goJinSaiOA();
    } else {
      if (isRedirect && isLoginRoute(location.pathname)) {
        redirectFirstRoute();
      }
    }
  });
};

/**
 * 解析 Path
 * @param href
 * @returns
 */
const analysisHref = (href: string) => {
  const myLocation = new URL(href);
  return {
    pathName: myLocation.pathname,
    searchParams: new URLSearchParams(myLocation.search),
  };
};

/**
 * 判断当前是否是Login页面
 * @param pathName
 * @returns
 */
export const isLoginRoute = (pathName?: string): boolean => {
  if (!pathName || pathName === '/login') {
    return true;
  }
  return false;
};
