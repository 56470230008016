
  import { defineComponent, reactive, toRefs } from 'vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import { ExportCenterQO, DictionaryTypeEnum } from '@/type/api';
  import { createExport } from '@/services/export';
  import { getDictionaryList } from '@/services/common';
  import moment, { Moment } from 'moment';
  import notify from '@/utils/notify';
  import { cloneDeep } from 'lodash';
  import DownLoadStation from './DownLoadStation.vue';

  // import { StaticDictionaryTypeEnum } from '@/lib/public';
  export default defineComponent({
    components: { ManageListFrame, DownLoadStation },
    setup() {
      const opts = reactive({
        dateTypeOpts: [
          { id: 1, name: '购药日期' },
          { id: 2, name: '录入日期' },
        ],
        typeOpts: [
          { id: 1, name: '用药信息' },
          { id: 2, name: '电子笔信息' },
          { id: 3, name: '患者基本信息' },
        ],
        salesSourceOpts: [
          { id: 0, name: '全部' },
          { id: 1, name: '手动录入' },
          { id: 2, name: '欧电客服' },
          { id: 4, name: '悦米' },
          { id: 6, name: '日战报' },
          { id: 7, name: '机构上传' },
        ],
        specOpts: [] as any,
        hospOpts: [] as any,
      });
      type DeleteFormListQuery = Omit<ExportCenterQO, 'beginDate' | 'endDate'> & {
        dateRange: Moment[];
      };
      getDictionaryList({ type: DictionaryTypeEnum.Spec }).then((res) => {
        if (res) {
          opts.specOpts = res;
        }
      });
      getDictionaryList({ type: DictionaryTypeEnum.Hospital }).then((res) => {
        if (res) {
          opts.hospOpts = res;
        }
      });
      const disabledDate = (current: Moment) => {
        if (!query.dateRange || query.dateRange.length === 0 || query.dateRange.length === 2) {
          return false;
        }
        const diffDate = current.diff(query.dateRange[0], 'days');
        return Math.abs(diffDate) > 61;
      };
      const onCalendarChange = (val: Moment[]) => {
        query.dateRange = val;
      };
      const query: DeleteFormListQuery = reactive({
        dateRange: [moment().subtract(61, 'd'), moment()],
        type: 1,
        dateType: 1,
        // 请选择来源
        salesSource: 0,
        // 仅导出本人任务
        viewMyTasksOnly: false,
      });
      const handleClear = () => {
        delete query.hospitalId;
        delete query.specification;
        Object.assign(query, {
          type: 1,
          dateType: 1,
          dateRange: [moment().subtract(60, 'd'), moment()],
          salesSource: 0,
          viewMyTasksOnly: false,
        });
      };
      const handleChange = (val: number, field: string) => {
        if (field === 'type') {
          // 当用户选择【用药信息】/【电子笔信息】/【患者基本信息】时，均支持【仅导出本人任务】。
          query.viewMyTasksOnly = false;
          if (val === 3) {
            delete query.hospitalId;
            delete query.specification; 
            delete query.salesSource;
          }
          if (val === 2) {
            delete query.specification;
            delete query.salesSource;
          }
          if (val === 1) {
            query.salesSource = 0;
          }
        }
      };

      // const handleExport111 = (isAlert = true) => {
      //   if (query.type == 1 && query.salesSource == undefined) {
      //     query.salesSource = 0;
      //   }
      //   const params = cloneDeep(query) as Partial<DeleteFormListQuery>;
      //   const [startTime, endTime] = params.dateRange as Moment[];
      //   delete params.dateRange;
      //   Object.assign(params, { beginDate: startTime?.format('YYYY-MM-DD'), endDate: endTime?.format('YYYY-MM-DD') });
      //   createExport(params).then((res) => {
      //     if (res && isAlert) {
      //       notify.success({ message: '导出任务创建成功' });
      //     }
      //   });
      // };

      const handleExport = (isAlert = true, exportBatch?) => {
        return new Promise((resolve, reject) => {
          if (query.type == 1 && query.salesSource == undefined) {
            query.salesSource = 0;
          }
          const params = cloneDeep(query) as Partial<DeleteFormListQuery>;
          const [startTime, endTime] = params.dateRange as Moment[];
          delete params.dateRange;
          Object.assign(params, { beginDate: startTime?.format('YYYY-MM-DD'), endDate: endTime?.format('YYYY-MM-DD') });
          if (exportBatch) {
            params.exportBatch = exportBatch;
          }

          // ajax
          createExport(params).then((res) => {
              if (res) {
                if (isAlert) {
                  notify.success({ message: '导出任务创建成功' });
                }
                resolve(true);
              }
            })
            .catch((error) => {
              reject(false);
            });
        });
      };


      return {
        ...toRefs(opts),
        query,
        handleExport,
        handleChange,
        handleClear,
        disabledDate,
        onCalendarChange
      };
    },
  });
