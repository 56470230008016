import { uniqueId } from 'lodash';

import { IFormReturnVisitDTO } from '@/type/formReturnVisitDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';
import { formatDateWithNone } from '../../utils';

const previewConfig: IFormPreviewGroup<IFormReturnVisitDTO>[] = [
  {
    id: uniqueId('ReturnVisitForm'),
    groupName: false,
    items: [
      {
        label: '回访日期',
        fieldName: 'returnVisitDate',
        format: (val) => formatDateWithNone(val),
      },
      { label: '回访目的', fieldName: 'returnVisitObjective' },
      { label: '是否开始用药', fieldName: 'startUseDrug' },
      { label: '回访时身高', fieldName: 'returnVisitHeight' },
      { label: '回访时体重', fieldName: 'returnVisitWeight' },
      {
        label: '下次回访时间',
        fieldName: 'nextReturnVisitDate',
        format: (val) => formatDateWithNone(val),
      },
      { label: '备注', fieldName: 'returnVisitRemark' },
    ],
  },
];

export default previewConfig;
