import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99594f0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "manage-list-container" }
const _hoisted_2 = { class: "manage-list-container_header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "manage-list-container_query" }
const _hoisted_5 = { class: "manage-list-container_table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.listName), 1),
      _renderSlot(_ctx.$slots, "top-tools", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "query", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "table", {}, undefined, true)
    ])
  ]))
}