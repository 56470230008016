import { BaseListQo } from './api';

/** 用药状态 */
export enum SALE_STATUS {
  /** 待审核 */
  WAITCHAEK = '1',
  /** 待划分 */
  WAITSPLIT = '2',
  /** 已划分 */
  SPLITED = '3',
  /** 删除 */
  DELETED = '-2',
  /** 已红冲 */
  HONG_CHONG = '-3',
  /** 驳回 */
  REJECT = '-4',
}

export const SALE_STATUS_NAME: { [key in SALE_STATUS]: string } = {
  [SALE_STATUS.WAITCHAEK]: '待审核',
  [SALE_STATUS.WAITSPLIT]: '待划分',
  [SALE_STATUS.SPLITED]: '已划分',
  [SALE_STATUS.HONG_CHONG]: '已红冲',
  [SALE_STATUS.REJECT]: '驳回',
  [SALE_STATUS.DELETED]: '删除',
};

/** 用药类型 */
export enum SALE_DATASOURCE {
  /** 手动录入 */
  SDLR = '1',
  /** 欧电 */
  ODY = '2',
  /** 悦米 */
  YM = '4',
  /** 日战报 */
  RZB = '6',
}

export const SALE_DATASOURCE_NAME: { [key in SALE_DATASOURCE]: string } = {
  [SALE_DATASOURCE.ODY]: '欧电',
  [SALE_DATASOURCE.YM]: '悦米',
  [SALE_DATASOURCE.RZB]: '日战报',
  [SALE_DATASOURCE.SDLR]: '手动录入',
};

export interface SaleDataQo extends BaseListQo {
  /** 用药状态 */
  status?: SALE_STATUS;
  /** 数据来源 */
  salesSource?: SALE_DATASOURCE;
  /** 就诊机构 */
  hospitalId?: string;
  /** 领域标签 */
  domainLabelId?: string;
  /** 购药时间-起 */
  buyTimeStart?: string;
  /** 购药时间-止 */
  buyTimeEnd?: string;
}

/** 用药数据管理 */
export interface SaleDataVo {
  /** 患者编码 - 患者编码 */
  patientCode: string;
  /** 表单ID */
  drugSubjectFormId: string;
  /** 群体 */
  treatmentIndicationGroupName: string;
  /** 领域 */
  treatmentDomainLabel: string;
  /** 适应症 */
  treatmentIndicationName: string;
  /** 就诊机构 */
  drugHospitalName: string;
  /** KH - 医生 */
  drugDoctorName: string;
  /** 三级品种名称 - 产品大类*/
  drugCategory: string;
  /** 事业部 */
  divideDepartment: string;
  /** 品规简称 */
  drugSpecificationsName: string;
  /** 购药支数 */
  drugActualNumber: string;
  /** 购药时间 */
  drugBuyMedicineDate: string;
  /** 出库价（元/支）*/
  drugActualDeliveryPrice: string;
  /** 状态 */
  status: SALE_STATUS;
  /** 数据来源 */
  salesSource: SALE_DATASOURCE;
  /** 辖区ID */
  divideJurisdictionId: string;
  /** 辖区名称 */
  divideJurisdictionName: string;
  /** 审核人员 */
  salesTrialUserName: string;
}
