import { IFormTreatmentDTO } from '@/type/formTreatmentDTO';
import RegularHelper from '@/utils/regularHelper';

export default class VisitWeightHelper {
  static hasDoubt(modelRef: IFormTreatmentDTO<moment.Moment>, earlyWarningRule: any): [boolean, string] {
    let message = '';
    let hasDoubt = false;

    if (!RegularHelper.isUnsignedFloat.test(modelRef.visitWeight)) {
      return [hasDoubt, message];
    }

    const visitWeight = +modelRef.visitWeight;
    const { minFirstDiagnosisWeight, maxFirstDiagnosisWeight } = earlyWarningRule || {};
    console.log('----maxFirstDiagnosisWeight---', maxFirstDiagnosisWeight)
    if (visitWeight < minFirstDiagnosisWeight) {
      hasDoubt = true;
      message = `首次确诊体重＜${minFirstDiagnosisWeight}kg`;
    }
    // if (visitWeight > maxFirstDiagnosisWeight) {
    //   hasDoubt = true;
    //   message = `首次确诊体重>${maxFirstDiagnosisWeight}kg`;
    // }
    if (visitWeight <= 200) {
      if (visitWeight > maxFirstDiagnosisWeight) {
        hasDoubt = true;
        message = `首次确诊体重>${maxFirstDiagnosisWeight}kg`;
      }
    }
    return [hasDoubt, message];
  }
}
