
  import { Column, IDropdownItem, TableState } from '@/type/common';
  import { onBeforeRouteLeave } from 'vue-router';
  import { computed, createVNode, defineComponent, onMounted, ref, toRefs } from 'vue';
  import { reactive } from 'vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable/CustomTable.vue';
  import { SaleImportCheckQo, SaleImportCheckVo } from '@/type/saleImportCheck';
  import SearchSelect from '@/components/SearchSelect/searchSelect.vue';
  import { Moment } from 'moment';
  import { message, Modal } from 'ant-design-vue';
  import { getHospitalLimit } from '@/services/saledata';
  import {
    getBatchList,
    getSaleSystemList,
    getSaleUploadList,
    getSaleOrgList,
    getSpecList,
    saleOrgCheck,
    saleUploadFileCheck,
    cleanExcelList,
  } from '@/services/saleImportCheck';
  import { RouteLocation, useRouter } from 'vue-router';
  import { FormModuleType } from '@/type/form';
  import { CONSTANS_KEY, useLocalStorageItem } from '@/lib/constans';
  import useWindowCloseOrReload from '@/utils/useWindowCloseOrReload';

  interface IState {
    hasUploadFile: boolean;
    specOpts: IDropdownItem[];
    batchOpts: IDropdownItem[];
    actionTypeOpts: IDropdownItem[];
  }

  type Query = Omit<SaleImportCheckQo, 'startDate' | 'endDate' | 'hospitalIds'> & {
    date: Moment[];
    hospitalIds: string[];
  };
  export default defineComponent({
    name: 'ImportCheck',
    components: {
      ManageListFrame,
      CustomTable,
      SearchSelect,
    },
    setup() {
      const router = useRouter();
      const fileRef = ref<HTMLInputElement>();
      const state = reactive<IState>({
        hasUploadFile: false,
        specOpts: [],
        batchOpts: [],
        actionTypeOpts: [
          { label: '平台录入 比对 导入数据', value: 1 },
          { label: '机构上传 比对 导入数据', value: 2 },
          { label: '机构上传 比对 平台录入', value: 3 },
        ],
      });
      const actionType = ref<number>(1);
      const query = reactive<Query>({
        date: [],
        hospitalIds: [],
        otherEntryClerk: true,
      });
      const searchDisabled = computed(() => {
        let disabled = {
          actionType: true,
          date: true,
          hospitalIds: true,
          specId: true,
          batch: true,
          otherEntryClerk: true,
        };
        if (state.hasUploadFile) {
          return disabled;
        } else {
          disabled.date = false;
          disabled.hospitalIds = false;
          disabled.otherEntryClerk = false;
          disabled.actionType = false;
        }
        if (query.date && query.date.length === 2 && query.hospitalIds && query.hospitalIds.length !== 0) {
          disabled.specId = false;
          if (query.specId) {
            disabled.batch = false;
          }
        }
        return disabled;
      });
      const isShowSearch = computed(() => {
        if (actionType.value === 3) {
          return true;
        }
        return false;
      });
      const importDisabled = computed(() => {
        if (!query.date || query.date.length === 0) {
          return true;
        }
        if (!query.hospitalIds || query.hospitalIds.length === 0) {
          return true;
        }
        return false;
      });
      const searchBtnDisabled = computed(() => {
        if (!query.date || query.date.length === 0) {
          return true;
        }
        if (!query.hospitalIds || query.hospitalIds.length === 0) {
          return true;
        }
        return false;
      });
      const showTips = computed(() => {
        return (
          state.hasUploadFile ||
          systemTable.dataSource.length > 0 ||
          excelTable.dataSource.length > 0 ||
          orgTable.dataSource.length > 0
        );
      });
      const localItem = useLocalStorageItem(CONSTANS_KEY.SALE_IMPORT_TOKEN);

      /** 就诊机构 */
      const getHospitalOpts = (selectStr: string, cb: (list: IDropdownItem[]) => void) => {
        getHospitalLimit(selectStr).then((res) => {
          cb(
            res.map((item) => {
              return { label: item.name, value: item.jinsaiId };
            }),
          );
        });
      };
      /** 规格 */
      const getSpecOpts = () => {
        if (searchDisabled.value.specId) {
          state.specOpts = [];
          return;
        }
        const params = {
          startDate: query.date[0]?.format('YYYY-MM-DD'),
          endDate: query.date[1]?.format('YYYY-MM-DD'),
          hospitalIds: query.hospitalIds.join(','),
          otherEntryClerk: query.otherEntryClerk,
        };
        getSpecList(params).then((res) => {
          if (res) {
            state.specOpts = res.map((item) => ({ label: item.value, value: item.id }));
          }
        });
      };
      /** 批号 */
      const getBatchOpts = () => {
        if (searchDisabled.value.batch) {
          state.batchOpts = [];
          return false;
        }
        const params = {
          startDate: query.date[0]?.format('YYYY-MM-DD'),
          endDate: query.date[1]?.format('YYYY-MM-DD'),
          hospitalIds: query.hospitalIds.join(','),
          specId: query.specId || '',
          otherEntryClerk: query.otherEntryClerk,
        };
        getBatchList(params).then((res) => {
          if (res) {
            state.batchOpts = res.map((item) => ({ label: item, value: item }));
          }
        });
      };
      const getColumns = (isSyeTem = true, isOrg = false) => {
        const columns: Column[] = [
          {
            title: '购药日期',
            dataIndex: 'buyMedicineDate',
            width: 100,
          },
          {
            title: '购药机构',
            dataIndex: 'buyMedicineMechanismName',
            width: 300,
          },
          {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
          },
          {
            title: '规格',
            dataIndex: 'specificationsName',
            width: 150,
          },
          {
            title: '购药数量',
            dataIndex: 'drugPurchasedNumber',
            width: 100,
          },
          {
            title: '购药单价',
            dataIndex: 'actualDeliveryPrice',
            width: 100,
          },
          {
            title: '批号',
            dataIndex: 'batchNumber',
            width: 150,
          },
        ];
        const statusColumn: Column = {
          title: '状态',
          dataIndex: 'status',
          width: 50,
          slots: {
            customRender: 'status',
          },
        };
        if (isSyeTem || isOrg) {
          const column = columns.find((column) => column.title === '姓名');
          if (column) {
            column.customCell = (record: SaleImportCheckVo) => {
              return {
                style: {
                  color: '#52cc78',
                  cursor: 'pointer',
                },
                onclick: () => {
                  goForm(record);
                },
              };
            };
          }
          if (isSyeTem && actionType.value === 3) {
            statusColumn.fixed = 'left';
            columns.unshift(statusColumn);
          } else {
            statusColumn.fixed = 'right';
            columns.push(statusColumn);
          }
        } else {
          statusColumn.fixed = 'left';
          columns.unshift(statusColumn);
        }
        return columns;
      };

      const systemTable = reactive(
        new TableState({
          dataSource: [] as SaleImportCheckVo[],
          key: 'id',
          /**
           * 200 => layoutheader
           * 58 => listname
           * 40 => 平台录入(父高度)
           * 22 => div.tips 高度
           */
          scroll: { x: '100%', y: window.innerHeight - 200 - 58 - 40 - 29 || 300 },
          columns: getColumns(true, false),
        }),
      );
      const excelTable = reactive(
        new TableState({
          dataSource: [] as SaleImportCheckVo[],
          key: 'id',
          scroll: { x: '100%', y: window.innerHeight - 200 - 58 - 40 - 29 || 300 },
          columns: getColumns(false, false),
        }),
      );

      const orgTable = reactive(
        new TableState({
          dataSource: [] as SaleImportCheckVo[],
          key: 'id',
          scroll: { x: '100%', y: window.innerHeight - 200 - 58 - 40 - 29 || 300 },
          columns: getColumns(false, true),
        }),
      );

      const getSystemList = (page: number, size: number) => {
        if (!localItem.getItem()) return;
        getSaleSystemList({ page, size }).then((res) => {
          systemTable.dataSource = res.content;
          systemTable.pagination.total = res.total;
          systemTable.pagination.current = res.page;
          systemTable.pagination.pageSize = res.limit;
        });
      };

      const getExcelList = (page: number, size: number) => {
        if (!localItem.getItem()) return;
        getSaleUploadList({ page, size }).then((res) => {
          excelTable.dataSource = res.content;
          excelTable.pagination.total = res.total;
          excelTable.pagination.current = res.page;
          excelTable.pagination.pageSize = res.limit;
        });
      };

      const getOrgList = (page: number, size: number) => {
        if (!localItem.getItem()) return;
        getSaleOrgList({ page, size }).then((res) => {
          orgTable.dataSource = res.content;
          orgTable.pagination.total = res.total;
          orgTable.pagination.current = res.page;
          orgTable.pagination.pageSize = res.limit;
        });
      };

      const refreshTable = () => {
        getDataList(systemTable.pagination.current);
      };

      const getQueryStr = () => {
        const queryStr = [
          { key: 'type', value: actionType.value || '' },
          { key: 'startDate', value: query.date[0].format('YYYY-MM-DD') },
          { key: 'endDate', value: query.date[1].format('YYYY-MM-DD') },
          { key: 'hospitalIds', value: query.hospitalIds.join(',') },
          { key: 'specId', value: query.specId || '' },
          { key: 'batch', value: query.batch || '' },
          { key: 'otherEntryClerk', value: query.otherEntryClerk || false },
        ]
          .map((item) => `${item.key}=${item.value}`)
          .join('&');
        return queryStr;
      };

      const uploadFile = (e: Event) => {
        const file = (e.target as HTMLInputElement)?.files?.[0];
        if (file) {
          if (file?.size > 5 * 1024 * 1024) {
            message.error('上传文件大小不能超过5M');
            return false;
          }
          saleUploadFileCheck(getQueryStr(), file).then((res) => {
            if (res.success) {
              Modal.success({
                content: createVNode('div', null, [
                  '导入成功，本地共导入',
                  createVNode('span', { style: { color: 'red' } }, res.successCount),
                  '条数据',
                ]),
                onOk: () => {
                  state.hasUploadFile = true;
                  localItem.setItem('1');
                  getDataList();
                  if (fileRef.value) {
                    fileRef.value.value = '';
                  }
                },
              });
            } else {
              Modal.error({
                content: `导入失败，原因：${res.failMsg}。请调整后重试`,
                onOk: () => {
                  state.hasUploadFile = false;
                  if (fileRef.value) {
                    fileRef.value.value = '';
                  }
                },
              });
            }
          });
        }
      };

      const getDataList = (pageNo = 1) => {
        switch (actionType.value) {
          case 1:
            getSystemList(pageNo, systemTable.pagination.pageSize);
            getExcelList(pageNo, excelTable.pagination.pageSize);
            break;
          case 2:
            getOrgList(pageNo, orgTable.pagination.pageSize);
            getExcelList(pageNo, excelTable.pagination.pageSize);
            break;
          case 3:
            getOrgList(pageNo, orgTable.pagination.pageSize);
            getSystemList(pageNo, systemTable.pagination.pageSize);
            break;
        }
      };

      const importExcel = () => {
        if (fileRef.value) {
          fileRef.value.click();
        }
      };

      const activeUpload = () => {
        state.hasUploadFile = false;
      };

      const goForm = (record: SaleImportCheckVo) => {
        let url: RouteLocation & {
          href: string;
        };
        url = router.resolve({
          name: 'formList',
          params: { moduleId: FormModuleType.MEDICATION, subjectId: record.subjectId },
        });
        window.open(url.href, '_blank');
      };

      const handleBtnClick = () => {
        if (state.hasUploadFile) {
          activeUpload();
        } else {
          importExcel();
        }
      };
      const handleSearchClick = () => {
        saleOrgCheck(getQueryStr()).then((res) => {
          if (res.success) {
            state.hasUploadFile = true;
            localItem.setItem('1');
            getDataList();
          } else {
            state.hasUploadFile = false;
            Modal.error({
              content: `对比失败，原因：${res.failMsg}。请调整后重试`,
            });
          }
        });
      };

      const handleActionTypeChange = (value) => {
        actionType.value = value;
        orgTable.columns = getColumns(false, true);
        systemTable.columns = getColumns(true, false);
        excelTable.columns = getColumns(false, false);
      };

      const handleDateChange = () => {
        query.specId = undefined;
        query.batch = undefined;
        getSpecOpts();
        getBatchOpts();
      };

      const handleHospitalChange = () => {
        query.specId = undefined;
        query.batch = undefined;
        getSpecOpts();
        getBatchOpts();
      };

      const handleSpceChange = () => {
        query.batch = undefined;
        getBatchOpts();
      };

      const handleOtherEntryClerkChange = () => {
        query.specId = undefined;
        query.batch = undefined;
        getSpecOpts();
        getBatchOpts();
      };

      const handleOrgPage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        getOrgList(page, pageSize);
      };
      const handleSystemPage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        getSystemList(page, pageSize);
      };

      const handleExcelPage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        getExcelList(page, pageSize);
      };

      useWindowCloseOrReload(
        () => {
          localItem.clearItem();
        },
        () => {
          console.log('刷新');
        },
      );
      onBeforeRouteLeave(() => {
        cleanExcelList()
          .then((res) => {
            console.log(res);
          })
          .catch((e) => console.log(e));
      });

      onMounted(() => {
        getSystemList(1, systemTable.pagination.pageSize);
        getExcelList(1, excelTable.pagination.pageSize);
      });

      return {
        fileRef,
        query,
        actionType,
        orgTable,
        systemTable,
        excelTable,
        isShowSearch,
        searchDisabled,
        importDisabled,
        searchBtnDisabled,
        showTips,
        ...toRefs(state),
        goForm,
        uploadFile,
        handleBtnClick,
        handleSearchClick,
        getHospitalOpts,
        handleActionTypeChange,
        handleDateChange,
        handleHospitalChange,
        handleSpceChange,
        handleOrgPage,
        handleSystemPage,
        handleExcelPage,
        handleOtherEntryClerkChange,
        refreshTable,
      };
    },
  });
