import { FormType } from './form';
import { ObjectMap, WithNull } from './helper';

/**
 * 操作类型
 */
export enum FormOperateType {
  /* 新增 */
  CREATE = '1',

  /* 查看 */
  PREVIEW = '2',

  /* 修改 */
  MODIFY = '3',

  /* 提交并继续 */
  CONTINUE = '4',
}

/**
 * 表单状态枚举
 *
 * @enum
 */
export enum FormStatus {
  /* 已驳回 */
  REJECTED = -4,

  /* 已红冲 */
  HONG_CHONG = -3,

  /* 已删除 */
  DELETED = -2,

  /* 由于模块或者表单的缺失而失效（已作废） */
  INVALID = -1,

  /* 未填写 */
  UN_FILL = 0,

  /* 已暂存 */
  SAVED = 1,

  /* 已提交 */
  SUBMITTED = 2,

  /** 更新下次购药时间 */
  NEXTPURCHASETIME = 3,

  NONE = -999,
}

/**
 * 表单详情查询参数
 */
export interface IFormDetailQo {
  /* 患者ID */
  subjectId: string;

  /* 表单类型ID */
  formId: string;

  /* 表单ID，查看和修改必填 */
  subjectFormId: string;

  /* 操作类型，1：新增；2；查看；3：修改 */
  operateType: FormOperateType;
}

export interface IFormDetailQo2 {
  /* 患者ID */
  subjectId: string;

  /* 表单类型ID */
  formId: string;

  /* 表单ID，查看和修改必填 */
  subjectFormId: string;

  /* 操作类型，1：新增；2；查看；3：修改 */
  operateType: string;
}

/**
 * 表单点位历史
 */
export interface IFormDetailHistory {
  /* 字段值 */
  value: string | IPatientMobileDTO[];

  /* 修改原因 */
  modifyReason: string;

  /* 操作人 */
  operator: string;

  /* 修改时间 */
  modifyDate: Date;
}

/**
 * 表单来源
 *
 * @enum
 */
export enum FormSource {
  /* 系统自建 */
  SYS_CREATE = 1,

  /* 欧电云 */
  ODY = 2,
  /* 悦米 */
  YUEMI = 4,
  /* 日战报 */
  RIZHANBAO = 6,
  /* 机构上传 */
  ORG = 7,
}


/**
 * 自动提交标识
 *
 * @enum
 */
export enum AutoCommit {
  /* 否 */
  NOT = 0,

  /* 欧电云 */
  IS = 1,
}

/**
 * 表单基础信息
 */
export interface IBaseFormDTO {
  /* 患者编码 */
  patientCode: string;

  /* 表单ID，编辑时必填 */
  subjectFormId: string;

  /* 患者ID，编辑时必填 */
  subjectId: string;

  /* 创建者ID */
  creatorId: string;

  /* 创建者姓名 */
  creatorName: string;

  /* 来源，1：系统自建；2：欧电云 */
  source: FormSource;

  /* 自动提交，0：否；1：是 */
  autoCommit: AutoCommit;

  /* 表单状态 */
  status: FormStatus;

  /* 创建时间 */
  createDate: Date;

  /* 更新时间 */
  updateDate: Date;
  /* 实际出库价(元/支) */
  actualDeliveryPrice: string;
}

/**
 * 患者手机号信息
 */
export interface IPatientMobileDTO {
  /* 是否为新增手机号 */
  isNew?: boolean;
  /* 唯一主键 */
  id: string;
  /* 手机号 */
  mobile: string;
  /* 手机号备注 */
  remark: string;
  /* 是否删除 */
  deleted: boolean;
  /* 删除原因 */
  deleteReason?: string;
}

/**
 * 性别
 *
 * @enum
 */
export enum Gender {
  /* 男 */
  MALE = '男',
  /* 女 */
  FEMALE = '女',

  NONE = '',
}

/**
 * 是否选项枚举
 *
 * @enum
 */
export enum IsBoolean {
  TRUE = '是',
  FALSE = '否',
  UNKNOW = '未知',
}

/**
 * 患者信息表单结构
 */
export interface IFormPatientDTO<TDateType = string> extends IBaseFormDTO {
  /* 模块名称 */
  moduleName: '患者信息';

  /* 是否存在四要素相同的其他患者，1：存在；0：不存在；其他：不存在 */
  haveSameBaseInfo: string;

  /* 患者唯一标识 */
  uuid: string;

  /* 零氪患者ID */
  lkPatientId: string;

  /* 相似患者ID */
  sameSubjectIds: string[];

  /* 是否为网报老患者 */
  gensciOldPatient: string;

  /* 患者姓名 */
  name: string;

  /* 患者性别, 选项男，女 */
  sex: Gender;

  /* 出生年月,格式YYYY-MM-DD */
  birthday: WithNull<TDateType>;

  /* 年龄 */
  age: string;

  /* 现居住省份ID */
  residedProvinceId: string;

  /* 现居住省份 */
  residedProvinceName: string;

  /* 居住城市ID */
  residedCityId: string;

  /* 现居住城市 */
  residedCityName: string;

  /* 现居住地址 */
  residedAddress: string;

  /* 出生身高(CM) */
  birthHeight: string;

  /* 出生体重（KG） */
  birthWeight: string;

  /* 遗传身高（CM） */
  geneticHeight: string;

  /* 家长与孩子的关系,选项父亲，母亲，祖父母，外祖父母，其他 */
  relationShip: string;

  /* 家长姓名 */
  parentName: string;

  /* 手机号 */
  mobiles: IPatientMobileDTO[];

  /* 父亲身高(CM) */
  fatherHeight: string;

  /* 母亲身高(CM) */
  motherHeight: string;

  /* 身份证号 */
  idNumber: string;

  /* 民族ID */
  nationId: string;

  /* 民族 */
  nationName: string;

  /* 矮小家族史，是/否 */
  shortFamilyHistory: string;

  /* 是否有过敏史， 是/否/未知 */
  isHaveAllergiesHistory: string;

  /* 过敏史 */
  allergiesHistory: string;

  /* 是否有肿瘤史， 是/否 */
  isHaveCancerHistory: string;

  /* 肿瘤史 */
  cancerHistory: string;

  /* 是否使用过生长激素 , 是/否 */
  isUsedGrowthHormone: string;

  /* 使用品牌 */
  usingBrands: string;

  /* 曾经使用时间___年___月，日期格式不固定 */
  userdDate: string;

  /* 累计多少个月 */
  cumulativeMonths: string;
  /** 该患者是否只存在于机构端、默认为false;onlyInOrg：true（该患者只存在于机构端）；false（该患者存在于双系统） */
  onlyInOrg: boolean;
}

/**
 * 电子笔信息表单结构
 */
export interface IFormEPenDTO<TDateType = string> extends IBaseFormDTO {
  /* 模块名称 */
  moduleName: '电子笔信息';

  /* 是否修改进销存点位 */
  modifyInventoryInfo: boolean;

  /* 库存修改记录ID */
  inventoryNumRecordId: string;

  /* 购买省份ID */
  purchaseProvinceId: string;

  /* 购买省份 */
  purchaseProvinceName: string;

  /* 购买医院ID */
  purchaseHospitalId: string;

  /* 购买医院 */
  purchaseHospitalName: string;

  /* 购买日期，YYYY-MM-DD */
  purchaseDate: TDateType;

  /* 规格 */
  specificationsId: string;

  /* 发货价 */
  deliveryPrice?: string;

  /* 批号 */
  epenBatchNumber: string;

  /* 批号结余库存 */
  epenBatchBalanceInventory: string;

  /* 序列号 */
  serialNumber: string;

  /* 购笔数量 */
  purchaseQuantity?: string;

  /* 退笔数量 */
  epenWithdrawalNumber: string;

  /* 备注 */
  epenRemark: string;
}

/**
 * 表单详情返回结构
 */
export interface IFormDetailVo<T extends IBaseFormDTO = IBaseFormDTO> {
  /* 表单详情数据 */
  content: T;
  /* 表单点位历史 */
  history: ObjectMap<IFormDetailHistory[]>;
}

/**
 * 创建、修改表单Qo
 */
export interface ICreateFormQo<T extends IBaseFormDTO = IBaseFormDTO> {
  /* 表单类型ID */
  formId: FormType;

  /* 是否提交, true: 提交, false: 暂存 */
  submit: boolean;

  /* 表单详情数据 */
  content: T;
}

/**
 * 获取价格列表Qo
 */
export interface IGetPriceQo {
  hospitalId: string;
  spec: string;
  batchCode: string;
}

/**
 * 查询库存Qo
 */
export interface IGetInventoryNumQo {
  /* 医院ID */
  hospitalId: string;

  /* 规格 */
  spec: string;

  /* 批号 */
  batchCode: string;

  /* 价格 */
  price: string;
}

/**
 * 查询批号列表Qo
 */
export interface IGetBatchCodeQo {
  /* 医院ID */
  hospitalId: string;

  /* 规格 */
  spec: string;
}

export interface IFinishTaskQo {
  /* 表单ID */
  subjectFormId: string;

  /* 表单类型ID */
  formId: string;
}

/**
 * 获取首单用药信息参数
 */
export interface IFormFirstSalesQo {
  /* 患者ID */
  subjectId: string;
}

export type PollStatusType = 'LOADING' | 'FAILED' | 'SUCCESS';
