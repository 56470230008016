
  import { defineComponent, reactive, ref, toRefs } from 'vue';
  import { ProfileOutlined } from '@ant-design/icons-vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import SelectInput from '@/components/SelectInput';
  import { TableState } from '@/type/common';
  import { Hospital } from '@/type/api';
  import { getRelList, deleteRel, getHospitalRecorders, addRel, editRel } from '@/services/relationship';
  import { getHospital } from '@/services/common';
  import { setRouter } from './hooks';
  import { UserHospitalQo, RmBaseListQo, UserHospitalVo, DictionaryVo, RecorderVo, RelQo } from '@/type/api';
  import { StaticDictionaryTypeEnum } from '@/lib/dictionary';
  import notify from '@/utils/notify';
  // import { StaticDictionaryTypeEnum} from '@/lib/dictionary';
  export default defineComponent({
    components: {
      ManageListFrame,
      ProfileOutlined,
      CustomTable,
      SelectInput,
    },
    setup() {
      interface HospitalLocal {
        provinceId?: string;
        cityId?: string;
        hospitalId?: string;
      }
      const query: RmBaseListQo<UserHospitalQo> = reactive({});
      const params: RmBaseListQo<UserHospitalQo> = reactive({});
      const relForm: RelQo & HospitalLocal = reactive({ weight: 100 });
      // const opts = reactive({});
      const formModal = ref(false);
      const isEdit = ref(false);
      const formRef = ref();
      const keyWord = ref('');
      const opts = reactive({
        searchOpts: [
          { id: 1, name: '医院名称' },
          { id: 2, name: '录入员名称' },
        ],
        proviceList: [] as DictionaryVo<StaticDictionaryTypeEnum>[],
        cityList: [] as DictionaryVo<StaticDictionaryTypeEnum>[],
        hospitalList: [] as Hospital[],
        recordersList: [] as RecorderVo[],
      });
      const addRules = reactive({
        provinceId: [{ required: true, message: '请选择省份' }],
        cityId: [{ required: true, message: '请选择城市' }],
        hospitalId: [{ required: true, message: '请选择医院' }],
        jinsaiId: [{ required: true, message: '请选择录入员' }],
      });
      const tableState = reactive(
        new TableState({
          dataSource: [] as UserHospitalVo[],
          columns: [
            { title: '医院名称', dataIndex: 'hospitalName', ellipsis: true, width: 300 },
            { title: '医院ID', dataIndex: 'hospitalId' },
            { title: '录入员名称', dataIndex: 'userName' },
            { title: '录入员工号', dataIndex: 'jinsaiId' },
            { title: '分配权重', dataIndex: 'weight' },

            {
              title: '操作',
              dataIndex: 'action',
              slots: {
                customRender: 'action',
              },
            },
          ],
        }),
      );
      const transModal = ref(false);
      getHospitalRecorders(keyWord.value).then((res) => {
        const { data } = res;
        if (data) {
          opts.recordersList = data.filter((item) => item.jinsaiId);
        }
      });
      const hospitalSelect = (hospitalName: string) => {
        console.log(hospitalName);
        getHospital({ hospitalName }).then((res) => {
          console.log(res);
          opts.hospitalList = res;
        });
        // if (type === DictionaryTypeEnum.Province) {
        //   dictionary.getStaticDictionary({ type }).then((res) => {
        //     opts.proviceList = res;
        //     if (!noClear) {
        //       delete relForm.cityId;
        //       delete relForm.hospitalId;
        //     }
        //   });
        // } else if (type === DictionaryTypeEnum.City) {
        //   dictionary.getStaticDictionary({ type, jinsaiParentId: relForm.provinceId }).then((res) => {
        //     opts.cityList = res;
        //     if (!noClear) {
        //       delete relForm.cityId;
        //       // delete relForm.hospitalId;
        //       delete relForm.hospitalId;
        //     }
        //   });
        // } else if (type === DictionaryTypeEnum.Hospital) {
        //   dictionary.getStaticDictionary({ type, cityId: relForm.cityId }).then((res) => {
        //     opts.hospitalList = res;
        //     noClear || delete relForm.hospitalId;
        //   });
        // }
        // dictionary.getStaticDictionary({ type: DictionaryTypeEnum.City });
      };
      // hospitalSelect(DictionaryTypeEnum.Province);
      const getData = (query?: RmBaseListQo<UserHospitalQo>) => {
        getRelList({
          ...query,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        }).then((data) => {
          if (data) {
            tableState.pagination.total = data.total;
            tableState.dataSource = data.content;
          }
        });
      };
      const handleDelete = (id) => {
        console.log(id);

        deleteRel(id)
          .then((res) => {
            if (res) {
              notify.success({ message: '删除成功' });

              getData(params);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData(params);
      };
      const handleSearch = () => {
        Object.assign(params, query);
        tableState.pagination.current = 1;
        getData(params);
      };
      const handleEdit = (val: UserHospitalVo) => {
        Object.assign(relForm, val);

        // hospitalSelect(DictionaryTypeEnum.City, true);
        // hospitalSelect(DictionaryTypeEnum.Hospital, true);
        opts.hospitalList = [
          {
            name: val.hospitalName,
            jinsaiId: val.hospitalId,
          },
        ];
        formModal.value = true;
        isEdit.value = true;
      };
      const handleAdd = () => {
        opts.hospitalList = [];
        formModal.value = true;
        isEdit.value = false;
        delete relForm.provinceId;
        delete relForm.cityId;
        delete relForm.hospitalId;
        delete relForm.jinsaiId;
        relForm.weight = 100;
      };
      const handleOk = () => {
        formRef.value.validate().then((valid) => {
          const handleFunc = isEdit.value ? editRel : addRel;

          if (valid) {
            handleFunc(relForm as RelQo).then((res) => {
              if (res) {
                notify.success({ message: isEdit.value ? '编辑成功' : '添加成功' });

                getData(params);
              }
              formModal.value = false;
            });
          }
        });
      };
      const toRouter = setRouter();
      getData();
      return {
        ...toRefs(opts),
        query,
        tableState,
        handlePage,
        toRouter,
        transModal,
        handleSearch,
        handleDelete,
        formModal,
        relForm,
        hospitalSelect,
        addRules,
        formRef,
        handleOk,
        handleEdit,
        isEdit,
        handleAdd,
      };
    },
  });
