import { Modal } from 'ant-design-vue';
import http, { getValueByPath, requestBooleanHandler, requestHandler } from './http';
import { BaseRes, ResponseStatus } from '@/type/api';
import {
  FormModuleType,
  ICaseData,
  ICycleVisitListQueryParams,
  ICycleVisitListResponse,
  IDeleteFormParams,
  IFormModuleItem,
  IFormOperatHistoryItem,
  IFormOperatHistoryQueryParams,
  IRedPointModuleItem,
  IRejectTaskParams,
} from '@/type/form';
import {
  ICreateFormQo,
  IFinishTaskQo,
  IFormDetailQo,
  IFormDetailQo2,
  IFormDetailVo,
  IGetBatchCodeQo,
  IGetInventoryNumQo,
  IGetPriceQo,
  IFormFirstSalesQo,
  PollStatusType,
} from '@/type/formContent';
import { genDefaultBaseFormDTO, genDefaultCycleVisitListResponse } from '@/views/form/utils';
import { ObjectMap } from '@/type/helper';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import { IMergePatientQo, ISamePatientsQo, ISamePatientsVO, ISplitPatientQo } from '@/type/samePatientsVO';
import notify from '@/utils/notify';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';

/**
 * 获取左侧模块列表
 *
 * @returns Promise<IFormModuleItem[]>
 */
export async function getModuleList(params: {
  moduleId: string | number;
  subjectFormId: string;
}): Promise<IFormModuleItem[]> {
  return requestHandler<IFormModuleItem[]>({
    service: () => http.get('/form/getModuleList', { params }),
    dataPath: 'data.allModules',
    defaultValue: [],
    errorMessage: '获取模块列表失败',
  });
}

/**
 * 获取患者姓名
 *
 * @param subjectId 患者id
 * @returns Promise<string>
 */
export async function getSubjectName(params: { subjectId: string; formId: string | number }): Promise<string> {
  return requestHandler<string>({
    service: () =>
      http.get('/form/getSubjectName', {
        params,
      }),
    defaultValue: '',
    errorMessage: '获取患者姓名失败',
  });
}

/**
 * 获取循环访视列表
 *
 * @param {ICycleVisitListQueryParams} params 查询条件
 * @returns Promise<ICycleVisitListResponse>
 */
export async function getCycleVisitList(params: ICycleVisitListQueryParams): Promise<ICycleVisitListResponse> {
  return requestHandler<ICycleVisitListResponse>({
    service: () => http.get('/form/getFormList', { params }),
    defaultValue: genDefaultCycleVisitListResponse(),
    errorMessage: '获取循环访视列表失败',
  });
}

/**
 * 获取表单操作历史
 *
 * @param {IFormOperatHistoryQueryParams} params 查询条件
 * @returns Promise<IFormOperatHistoryItem[]>
 */
export async function getFormOperatHistory(params: IFormOperatHistoryQueryParams): Promise<IFormOperatHistoryItem[]> {
  return requestHandler<IFormOperatHistoryItem[]>({
    service: () => http.get('/form/getFormOperateLogs', { params }),
    defaultValue: [],
    errorMessage: '获取表单操作历史失败',
  });
}

/**
 * 红点任务
 *
 * 返回的结果会转换为Map, 其结构描述如下
 * Map<moduleId, Map<"formId,subjectFormId", boolean>>
 *
 * @param {string} subjectId 用户唯一id
 * @returns Promise<ObjectMap<ObjectMap<boolean>>>
 */
export async function getRedPoint(params: {
  subjectId: string;
  formId: string | number;
}): Promise<ObjectMap<ObjectMap<boolean>>> {
  const result = await requestHandler<IRedPointModuleItem[]>({
    service: () =>
      http.get('/form/redPoint', {
        params,
      }),
    dataPath: 'data.modules',
    defaultValue: [],
    errorMessage: '获取任务关联条目失败',
  });

  return result.reduce<ObjectMap<ObjectMap<boolean>>>((moduleMap, currentModule) => {
    if (!(currentModule.moduleId in moduleMap)) {
      moduleMap[currentModule.moduleId] = {};
    }

    const formMap = moduleMap[currentModule.moduleId];

    (currentModule.forms || []).forEach((currentForm) => {
      const key = currentForm.subjectFormId;
      // isMine：是自己审核的为true，别人审核的为false
      const isMine = currentForm.isMine;

      if (!(key in formMap)) {
        // 老逻辑
        // formMap[key] = true;
        // 新逻辑
        formMap[key] = isMine;
      }
    });
    return moduleMap;
  }, {});
}

/**
 * 获取病例信息列表
 *
 * @param {string} subjectId 用户唯一id
 * @returns Promise<ICaseData[]>
 */
export async function getCaseList(params: { subjectId: string; formId: string | number }): Promise<ICaseData[]> {
  let result = await requestHandler<ICaseData[]>({
    service: () =>
      http.get('/form/getMetaDatas', {
        params,
      }),
    defaultValue: [],
    errorMessage: '获取病例列表失败',
  });

  result = orderBy(result, (val) => moment(val.updatedAt).valueOf(), 'desc');

  return result;
}

/**
 * 打回病例
 *
 * @param {IRejectTaskParams} data 打回病例数据
 * @returns Promise<boolean>
 */
export async function rejectTask(data: IRejectTaskParams): Promise<boolean> {
  let result = false;

  try {
    const response: BaseRes<{ ok: boolean }> = await http.post('/recordTask/reject', data);
    result = getValueByPath(response, 'data.ok', false);

    if (response.status !== ResponseStatus.SUCCESS || !result) {
      notify.error({
        message: '打回病例失败',
        description: response.errorMessage,
      });
    }
  } catch (e) {
    result = false;
  }

  return result;
}

/**
 * 删除表单
 *
 * @param {IDeleteFormParams} data 删除表单数据
 * @returns Promise<boolean>
 */
export async function deleteForm(data: IDeleteFormParams): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/form/deleteForm', data),
    defaultValue: false,
    errorMessage: '删除表单失败',
  });
}

/**
 * 获取表单详情
 *
 * @param {IFormDetailQo} params 获取表单详情参数
 * @returns Promise<IFormDetailVo>
 */
export async function getFormDetail(params: IFormDetailQo): Promise<IFormDetailVo> {
  return requestHandler<IFormDetailVo>({
    service: () =>
      http.get('/form/getFormDetail', {
        params,
      }),
    defaultValue: {
      content: genDefaultBaseFormDTO(),
      history: {},
    },
    errorMessage: '获取表单详情失败',
  });
}

/**
 * 修改表单
 *
 * @param {IFormDetailQo} params 获取表单详情参数
 * @returns Promise<boolean | string>
 */
export async function modifyForm(data: ICreateFormQo): Promise<boolean | string> {
  return new Promise((resolve) => {
    requestHandler({
      service: () => http.post('/form/createForm', data),
      defaultValue: false,
      errorMessage: '操作失败',
      onSuccess: (response) => {
        const result = get(response, 'data.ok') || false;

        if (!result) {
          throw new Error(get(response, 'errorMessage'));
        }

        resolve(get(response, 'data.subjectFormId'));
        notify.success({ message: '操作成功' });
      },
      async onFail(response) {
        resolve(false);

        const errorCode = get(response, 'errorCode');
        const subjectId = get(data, 'content.subjectId');

        if (errorCode !== 20 || !subjectId) {
          return;
        }

        setTimeout(() => {
          window.location.replace(`/form/list/${FormModuleType.PATIENT}/${subjectId}`);
          window.onload = () => {
            window.location.reload();
          };
        }, 600);
      },
    });
  });
}

// >>> 状态轮询 >>>

/**
 * 轮询状态
 */
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function pollFormStatusMock(retry: number, formId: string): Promise<PollStatusType> {
  console.log('start pollFormStatus api');
  await sleep(1000);
  return new Promise((resolve) => {
    console.log('end pollFormStatus api');
    if (retry < 2) {
      resolve('LOADING');
    } else {
      if (formId.endsWith('4')) {
        resolve('SUCCESS');
      } else {
        resolve('FAILED');
      }
    }
  });
}

export async function pollFormStatus(
  retry: number,
  formId: string,
  subjectFormId: string | boolean,
): Promise<PollStatusType> {
  console.log('start pollFormStatus api');
  await sleep(1000);

  return new Promise((resolve) => {
    requestHandler({
      service: () =>
        http.get(
          '/form/async/result?formId=' + formId + '&subjectFormId=' + subjectFormId + '&timestamp=' + new Date(),
        ),
      defaultValue: 'FAILED',
      errorMessage: 'FAILED',
      onSuccess: (response) => {
        const status = get(response, 'data.status') || 'FAILED';
        resolve(status);
      },
      async onFail() {
        resolve('FAILED');
      },
    });
  });
}

// <<< 状态轮询 <<<

/**
 * 获取价格列表
 *
 * @param {IGetPriceQo} params 获取价格列表参数
 * @returns Promise<string[]>
 */
export async function getPrice(params: IGetPriceQo): Promise<string[]> {
  return requestHandler<string[]>({
    service: () =>
      http.get('/dictionary/getPrice', {
        params,
      }),
    defaultValue: [],
    errorMessage: '获取价格列表失败',
  });
}

/**
 * 获取库存
 *
 * @param {IGetInventoryNumQo} params 获取库存参数
 * @returns Promise<string>
 */
export async function getInventoryNum(params: IGetInventoryNumQo): Promise<string> {
  return requestHandler<string>({
    service: () =>
      http.get('/dictionary/getInventoryNum', {
        params,
      }),
    defaultValue: '',
    errorMessage: '获取库存信息失败',
  });
}

/**
 * 获取价格列表
 *
 * @param {IGetBatchCodeQo} params 获取价格列表参数
 * @returns Promise<string[]>
 */
export async function getBatchCode(params: IGetBatchCodeQo): Promise<string[]> {
  return requestHandler<string[]>({
    service: () =>
      http.get('/dictionary/getBatchCode', {
        params,
      }),
    defaultValue: [],
    errorMessage: '获取批号列表失败',
  });
}

/**
 * 检查任务完成接口
 */
export async function finishTask(data: IFinishTaskQo): Promise<boolean> {
  let result: boolean;

  try {
    const response: BaseRes<string> = await http.post('recordTask/finishTask', data);

    if (response.status === ResponseStatus.SUCCESS) {
      notify.success({ message: response.data });
      result = true;
    } else if (response.status === 2) {
      notify.warning({ message: response.data });
      result = true;
    } else {
      throw new Error(response.errorMessage);
    }
  } catch (e: unknown) {
    notify.error({
      message: '检查任务失败',
      description: (e as { message: string }).message,
    });
    result = false;
  }

  return result;
}

/**
 * 获取相似患者列表
 */
export async function getSamePatients(params: ISamePatientsQo): Promise<ISamePatientsVO[]> {
  return requestHandler<ISamePatientsVO[]>({
    service: () =>
      http.get('/form/getSamePatients', {
        params,
      }),
    defaultValue: [],
    errorMessage: '获取相似患者列表失败',
  });
}

/**
 * 患者拆分(确认为新患者)
 */
export async function splitPatient(data: ISplitPatientQo): Promise<boolean> {
  return new Promise((resolve) => {
    requestBooleanHandler({
      service: () => http.post('/form/splitPatient', data),
      defaultValue: false,
      errorMessage: '患者拆分失败',
      onSuccess(res) {
        Modal.success({
          title: '患者拆分成功',
          content: '即将刷新页面...',
          centered: true,
        });
        resolve(!!get(res, 'data.ok'));
      },
      onFail() {
        resolve(false);
      },
    });
  });
}

/**
 * 患者合并(与此患者为同一患者)
 */
export async function mergePatient(data: IMergePatientQo): Promise<boolean> {
  return new Promise((resolve) => {
    requestBooleanHandler({
      service: () => http.post('/form/mergePatient', data),
      defaultValue: false,
      errorMessage: '患者合并失败',
      onSuccess(res) {
        Modal.success({
          title: '患者合并成功',
          content: '即将刷新页面...',
          centered: true,
        });
        resolve(!!get(res, 'data.ok'));
      },
      onFail() {
        resolve(false);
      },
    });
  });
}

/**
 * 获取首单用药信息（用药首笔用药）
 *
 * @param {IFormFirstSalesQo} params 获取首单用药信息参数
 * @returns Promise<IFormDetailVo>
 */
export async function getFirstSales(
  params: IFormFirstSalesQo,
): Promise<IFormMedicationDTO<moment.Moment, string[]> | null> {
  return requestHandler<IFormMedicationDTO<moment.Moment, string[]> | null>({
    service: () =>
      http.get('/form/getFirstSales', {
        params,
      }),
    defaultValue: null,
    errorMessage: '获取首单用药信息失败',
  });
}

/**
 * 获取首单用药信息（用药首笔用药）
 *
 * @param {IFormFirstSalesQo} params 获取首单用药信息参数
 * @returns Promise<IFormDetailVo>
 */
export async function getCheckDrugRepeat(params: {
  buyMedicineMechanismId: IFormMedicationDTO['buyMedicineMechanismId'];
  buyMedicineDate: IFormMedicationDTO['buyMedicineDate'];
  specificationsId: IFormMedicationDTO['specificationsId'];
  batchNumber: IFormMedicationDTO['batchNumber'];
  drugPurchasedNumber: IFormMedicationDTO['drugPurchasedNumber'];
  subjectId: string;
}): Promise<boolean> {
  // return Promise.resolve(true);
  return requestHandler<boolean>({
    service: () =>
      http.get('/form/checkDrugRepeat', {
        params,
      }),
    dataPath: 'data.repeat',
    defaultValue: false,
    errorMessage: '检查用药信息是否重复失败',
  });
}
  
/**
 * 获取是否是机构上传的数据 true:是，false，不是
 *
 * @param {IFormDetailQo2} params 获取表单详情参数
 * @returns Promise<IFormDetailVo>
 */
export async function checkPatientOnlyInOrg(params: IFormDetailQo2): Promise<boolean> {
  return requestHandler<boolean>({
    service: () =>
      http.get('/form/checkPatientOnlyInOrg', {
        params,
      }),
    defaultValue: false,
    errorMessage: '获取是否是机构上传的数据失败',
  });
}

/**
 * 零氪-预警规则参数服务端可配置
 *
 */
export async function getDictionaryList(params: { type: string }): Promise<string> {
  return requestHandler<string>({
    service: () =>
      http.get('/dictionary/list', {
        params,
      }),
    defaultValue: '',
    errorMessage: '获取预警规则参数数据失败',
  });
}
