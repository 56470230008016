
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import { get } from 'lodash';
  import { computed, defineComponent, PropType, reactive, ref, UnwrapRef } from 'vue';

  import { IPatientMobileDTO } from '@/type/formContent';

  import { IDeleteMobileFormState } from './interface';

  export default defineComponent({
    name: 'mobile-delete-confirm-dialog',
    emits: ['update:visible', 'update:mobile', 'confirm'],
    props: {
      mobile: {
        type: Object as PropType<IPatientMobileDTO>,
        default: null,
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      const formRef = ref();
      const formState: UnwrapRef<IDeleteMobileFormState> = reactive({
        deleteReason: '',
      });
      const rules = {
        deleteReason: [
          { required: true, message: '请输入删除原因' },
          { max: 200, message: '删除原因最多输入200个字符' },
        ],
      };

      const mobileNumber = computed(() => get(props.mobile, 'mobile', ''));

      const handleClose = () => {
        context.emit('update:visible', false);

        setTimeout(() => {
          context.emit('update:mobile', null);
          formRef.value.resetFields();
        }, 300);
      };

      const handleSubmit = () => {
        formRef.value
          .validate()
          .then(() => {
            context.emit('confirm', {
              ...props.mobile,
              deleteReason: formState.deleteReason,
              deleted: true,
            });

            handleClose();
          })
          .catch((error: ValidateErrorEntity<IDeleteMobileFormState>) => {
            console.error('error', error);
          });
      };

      return {
        staticLabelCol: { span: 8 },
        staticWrapperCol: { span: 16 },
        rowCol: { span: 24 },

        formRef,
        formState,
        rules,
        mobileNumber,

        handleClose,
        handleSubmit,
      };
    },
  });
