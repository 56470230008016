
  import { computed, defineComponent, PropType, ref, watchEffect } from 'vue';
  import { RollbackOutlined } from '@ant-design/icons-vue';

  import { ICaseData, ModuleFormTypeMapDict, FormType } from '@/type/form';

  import CasePreview from '../CasePreview/index.vue';
  import CaseCascaderForm from '../CaseCascaderForm/index.vue';
  import CaseModeSwitcher from '../CaseModeSwitcher/index.vue';
  import CaseStatusInfo from '../CaseStatusInfo/index.vue';
  import CasePagination from '../CasePagination/index.vue';
  import CaseRejectDialog from '../CaseRejectDialog/index.vue';
  import { useRoute } from 'vue-router';
  import { get } from 'lodash';

  import { getLastUpdateCase } from './utils';
  import { CasePreviewMode } from '../CasePreview/interface';
  import { getCasesByInjectedStatus, getCasesByPreviewMode } from '../CaseCascaderForm/utils';
  import { message } from 'ant-design-vue';

  export default defineComponent({
    name: 'case-container',
    components: {
      CasePreview,
      CaseCascaderForm,
      CaseModeSwitcher,
      RollbackOutlined,
      CaseStatusInfo,
      CasePagination,
      CaseRejectDialog,
    },
    props: {
      cases: {
        type: Array as PropType<ICaseData[]>,
        default: () => [],
      },
      onlyInOrg: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const route = useRoute();

      let prevModuleId = '';
      let prevSubjectFormId = '';

      /**
       * @params
       * rejected   : 是否只查看已打回状态, true: 只查看已打回状态, false: 只查看非打回表单
       * previewMode: 预览模式, 查看全部或只看图片
       * activeCases: 根据查看打回状态及预览模式筛选后的病例
       * currentCase: 当前查看病例
       * rejectBtnDisabled  : 打回数据按钮禁用状态
       * rejectDialogVisible: 打回数据弹窗显示状态
       */
      const rejected = ref<boolean>(false);
      const previewMode = ref<CasePreviewMode>(CasePreviewMode.ALL);
      const activeCases = computed(() =>
        getCasesByInjectedStatus(getCasesByPreviewMode(props.cases, previewMode.value), rejected.value),
      );
      const currentCase = ref<ICaseData | null | undefined>(props.cases[0] || null);
      const rejectBtnDisabled = computed(() => get(currentCase.value, 'isRepulse') !== true);
      const rejectDialogVisible = ref<boolean>(false);

      const matchDefaultCase = (moduleId: string, subjectFormId: string, cases: ICaseData[]) => {
        if (!moduleId || !subjectFormId) {
          return;
        }

        if (!cases.length) {
          currentCase.value = null;
          return;
        }

        // Step 01: 匹配当前模块对应的病例
        const moduleFormCases = cases.filter((item) =>
          get(item, 'meta.formId', '').includes(ModuleFormTypeMapDict[moduleId]),
        );

        if (moduleFormCases.length) {
          // Step 02: 从当前模块中匹配当前表单对应的最新一条记录
          const subjectFormCases = moduleFormCases.filter((item) =>
            get(item, 'meta.subjectFormId', '').includes(subjectFormId),
          );
          if (subjectFormCases.length) {
            currentCase.value = getLastUpdateCase(subjectFormCases);
            return;
          }

          // Step 03: 若未匹配到当前表单对应的记录, 则匹配当前表单对应模块, 对应的最新一条记录
          currentCase.value = getLastUpdateCase(moduleFormCases);
          return;
        }

        // Step 04: 若上述规则都未命中匹配, 则取所有病例中最新的一条记录作为当前病例
        currentCase.value = getLastUpdateCase(cases);
      };
      const handleShowRejectDialog = () => {
        const formId = get(currentCase.value, 'meta.formId'); //表单类型
        if (props.onlyInOrg && formId === FormType.PATIENT) {
          message.warning('机构自建患者不可打回');
        } else {
          rejectDialogVisible.value = true;
        }
      };
      watchEffect(() => {
        currentCase.value = props.cases[0] || null; // fix activeCases和currentCase相互影响
      });
      watchEffect(() => {
        const currentModuleId = get(route, 'params.moduleId', '');
        const currentSubjectFormId = get(route, 'params.subjectFormId', '');
        if (!props.cases.length) {
          return;
        }

        if (currentModuleId !== prevModuleId || currentSubjectFormId !== prevSubjectFormId) {
          console.log(
            `
        watchEffect
        prevModuleId: ${prevModuleId}, currentModuleId: ${currentModuleId},
        prevSubjectFormId: ${prevSubjectFormId}, currentSubjectFormId: ${currentSubjectFormId},
        cases`,
            props.cases,
          );
          prevModuleId = currentModuleId;
          prevSubjectFormId = currentSubjectFormId;
          matchDefaultCase(currentModuleId, currentSubjectFormId, activeCases.value);
        }
      });

      return {
        activeCases,
        currentCase,
        rejected,
        previewMode,
        rejectBtnDisabled,
        rejectDialogVisible,
        handleShowRejectDialog,
      };
    },
  });
