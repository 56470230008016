import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e78eef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-status-info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, "任务状态: " + _toDisplayString(_ctx.recordStatusText), 1),
    (_ctx.isNotProgressing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "处理人: " + _toDisplayString(_ctx.recordUserName), 1))
      : _createCommentVNode("", true),
    (_ctx.isNotProgressing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "处理时间: " + _toDisplayString(_ctx.recordUpdateTime), 1))
      : _createCommentVNode("", true),
    (_ctx.isRejected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "打回原因: " + _toDisplayString(_ctx.recordRejectReason), 1))
      : _createCommentVNode("", true)
  ]))
}