
  /* eslint-disable @typescript-eslint/ban-types */

  import { get } from 'lodash';
  import { computed, defineComponent, PropType, reactive, ref, toRaw, UnwrapRef, watch } from 'vue';

  import { ICaseData, ImportTypeTransDict } from '@/type/form';

  import { ICaseRejectFormState } from './interface';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import { rejectTask } from '@/services/form';
  import moment from 'moment';
  import { ModuleFormTypeMapDict } from '@/type/form';
  import { useRoute } from 'vue-router';
  import notify from '@/utils/notify';
  import { getDictionaryList } from '@/services/form';
  export default defineComponent({
    name: 'case-reject-dialog',
    emits: ['update:visible', 'after-submit'],
    props: {
      case: {
        type: Object as PropType<ICaseData>,
        default: null,
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      console.log(props, '病例信息接口');
      // 数据来源
      const importType = computed(() => ImportTypeTransDict[get(props.case, 'meta.importType')] || '-');
      // 审核客服
      const recordUserName = computed(() => get(props.case, 'recordUserName', '-'));
      // 审核时间
      const recordUpdateTime = computed(() => {
        const time = get(props.case, 'recordUpdateTime', '-');
        return time ? moment(time).format('YYYY-MM-DD') : '-';
      });
      // 采集模块
      const recordModule = computed(() => get(props.case, 'recordModule', '-'));

      const formRef = ref();
      // 展示的一级原因列表
      const causeRejectionList = ref([]);
      // 展示的二级原因列表
      const secondaryCauseArray = ref([]);
      const route = useRoute();
      const formState: UnwrapRef<ICaseRejectFormState> = reactive({
        // 一级原因[驳回原因]
        // causeRejection: null,
        // 二级原因
        // secondaryCause: null,
        // 文本框
        recordRejectReason: '',
      });
      // 选中的一级原因
      const recordRejectReasonName = ref('');
      // 选中二级原因列表
      const secondaryCauseList = ref([]);
      const rules = {
        causeRejection: [{ required: true, message: '请选择驳回原因', trigger: 'change' }],
        recordRejectReason: [
          { required: true, message: '请输入描述', trigger: 'blur' },
          { max: 200, message: '描述最多输入200个字符', trigger: 'blur' },
        ],
      };
      type EventMy = {
        target: { tagName: string };
        path: { tagName: string }[];
      };
      /**   
       * 取消事件是否是来自取消按钮
       */
      const clickCancelButton = (e: EventMy) => {
        return (e.target || e.path[0] || {}).tagName == 'BUTTON';
      };

      const handleClose = (e?: EventMy) => {
        context.emit('update:visible', false);
        if (!e || clickCancelButton(e)) {
          setTimeout(() => {
            formRef.value.resetFields();
          }, 300);
        }
      };
      // 渲染一级原因
      const getCauseList = async () => {
        const res = (await getDictionaryList({ type: 'Reject_Reason' })) as any;
        if (res && res.length) {
          let causeList: any = [];
          res.forEach((item: any) => {
            if (!item.jinsaiParentId) {
              causeList.push(item);
            }
          });
          formState.recordRejectReason = "";
          causeRejectionList.value = causeList;
        }
      };

      watch(
        () => props.visible,
        (newValue) => {
          if (newValue) {
            getCauseList();
          } else {
            formRef.value.resetFields();
            delete formState.causeRejection;
            delete formState.secondaryCause;
            recordRejectReasonName.value = '';
            causeRejectionList.value = [];
            secondaryCauseArray.value = [];
            secondaryCauseList.value = [];
          }
        },
      );
      // 渲染二级原因
      const causeRejectionChange = async (val) => {
        const causeRejection: any = causeRejectionList.value.find((item: any) => {
          return item.jinsaiId == val;
        }) || {};
        secondaryCauseArray.value = [];
        secondaryCauseList.value = [];
        formState.recordRejectReason = "";
        delete formState.secondaryCause;
        if (causeRejection && causeRejection.name) {
          recordRejectReasonName.value = causeRejection.name;
          let param = { type: 'Reject_Reason', jinsaiParentId: val}
          const res = (await getDictionaryList(param)) as any;
          if (res && res.length) {
            secondaryCauseArray.value = res;
          }
        };
      };

      const secondaryCauseChange = (val) => {
        formState.recordRejectReason = "";
        secondaryCauseList.value = val;
      };


      const handleSubmit11 = () => {
        // const formId = ModuleFormTypeMapDict[get(route, 'params.moduleId')];
        // 获取最新的formId，包括切换select
        const formId = get(props, 'case.meta.formId');
        formRef.value
          .validate()
          .then(async () => {
            const result = await rejectTask({
              odyUniqueId: get(props, 'case.meta.odyUniqueId'),
              subjectFormId: get(props, 'case.meta.subjectFormId'),
              // subjectFormId: get(route, 'params.subjectFormId'),
              reason: toRaw(formState).recordRejectReason,
              formId,
            });

            if (result) {
              handleClose();
              notify.success({ message: '操作成功' });
              setTimeout(() => {
                window.close();
              }, 600);
            }
          })
          .catch((error: ValidateErrorEntity<ICaseRejectFormState>) => {
            console.error('error', error);
          });
      };
      const handleSubmit = () => {
        // 传递给后端的原因
        let erjijiNamecopy = '';
        // formState.recordRejectReason = "";
        // 有一级原因,并且二级原因选择了"自行备注"，并要编写备注
        if (secondaryCauseList.value.length && secondaryCauseList.value.includes('自行备注' as never)) {
          let secondaryCauseCopy = JSON.parse(JSON.stringify(secondaryCauseList.value));
          let index: any = secondaryCauseList.value.indexOf('自行备注' as never); // 获取元素 3 的索引
          if (index > -1) {
            secondaryCauseCopy.splice(index, 1); // 删除索引处的元素
          }
          secondaryCauseCopy.push(`自行备注:${formState.recordRejectReason}`)
          erjijiNamecopy = `${recordRejectReasonName.value}:${secondaryCauseCopy.join()}`;
        }
        // 一级原因选择了其他
        if (recordRejectReasonName.value == '其他') {
          erjijiNamecopy = `${recordRejectReasonName.value}:${formState.recordRejectReason}`;
        } else {
          if (secondaryCauseList.value.length) {
            if (!secondaryCauseList.value.includes('自行备注' as never)) {
              erjijiNamecopy = `${recordRejectReasonName.value}:${secondaryCauseList.value.join()}`;
            }
          } else {
            erjijiNamecopy = `${recordRejectReasonName.value}`;
          }

        }

        // const formId = ModuleFormTypeMapDict[get(route, 'params.moduleId')];
        // 获取最新的formId，包括切换select
        const formId = get(props, 'case.meta.formId');
        formRef.value
          .validate()
          .then(async () => {
            const result = await rejectTask({
              odyUniqueId: get(props, 'case.meta.odyUniqueId'),
              subjectFormId: get(props, 'case.meta.subjectFormId'),
              // subjectFormId: get(route, 'params.subjectFormId'),
              reason: erjijiNamecopy,
              formId,
            });

            if (result) {
              handleClose();
              notify.success({ message: '操作成功' });
              setTimeout(() => {
                window.close();
              }, 600);
            }
          })
          .catch((error: ValidateErrorEntity<ICaseRejectFormState>) => {
            console.error('error', error);
          });
      }

      return {
        staticLabelCol: { span: 8 },
        staticWrapperCol: { span: 16 },
        rowCol: { span: 24 },

        importType,
        recordUserName,
        recordUpdateTime,
        recordModule,

        formRef,
        formState,
        recordRejectReasonName,
        secondaryCauseList,
        rules,
        causeRejectionList,
        secondaryCauseArray,
        handleClose,
        handleSubmit,
        causeRejectionChange,
        secondaryCauseChange,
      };
    },
  });
