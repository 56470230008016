
  import { downloadFile, getDownloadList, restartExport } from '@/services/export';
  import { DownloadVo } from '@/type/api';
  import { defineComponent, reactive, ref } from 'vue';
  import { ProfileOutlined, DownloadOutlined, FieldTimeOutlined } from '@ant-design/icons-vue';
  import SVG from '../../assets/img/svg';
  export default defineComponent({
    name: 'DownLoadStation',
    components: { ProfileOutlined, DownloadOutlined, FieldTimeOutlined,resetIcon: SVG.resetIcon, },
    props: {
      handleExport: {
        type: Function,
        required: false,
        default: null
      },
    },
    setup(props, { emit }) {
      const showDownload = ref(false);
      const activeKey = ref('1');
      const downLoadData = reactive({
        downloading: [] as DownloadVo[],
        downloadComplete: [] as DownloadVo[],
        // 下载失败集合
        downloadError: [] as DownloadVo[],
        loading: false,
      });
      const initDownloadList = () => {
        downLoadData.loading = true;
        getDownloadList()
          .then((res) => {
            if (res) {
              const { downloading, downloadComplete, downloadError } = res;
              downLoadData.downloading = downloading;
              downLoadData.downloadComplete = downloadComplete;
              downLoadData.downloadError = downloadError;
              if (downloading.length > 0) {
                setTimeout(() => {
                  showDownload.value && initDownloadList();
                }, 5000);
              }
            }
          })
          .finally(() => {
            downLoadData.loading = false;
          });
      };
      const download = (id: number) => {
        const url = downloadFile({ taskId: id });
        window.open(url, '_blank');
      };
      // 下载失败tab，点击重新下载，先调导出接口，再刷新导出详情接口
      const restartDownload = async (item) => {
        await props.handleExport(false, item.exportBatch);
        initDownloadList();
      };
      return {
        initDownloadList,
        downLoadData,
        showDownload,
        download,
        activeKey,
        restartDownload,
      };
    },
  });
