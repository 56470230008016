import { IFormPatientDTO } from '@/type/formContent';
import RegularHelper from '@/utils/regularHelper';
import { Moment } from 'moment';

export default class BirthHeightHelper {
  static preCheck(modelRef: IFormPatientDTO<Moment>): boolean {
    return RegularHelper.isUnsignedFloat.test(modelRef.birthHeight);
  }

  static hasDoubt(modelRef: IFormPatientDTO<Moment>): [boolean, string] {
    let message = '';
    let hasDoubt = false;

    if (!RegularHelper.isUnsignedFloat.test(modelRef.birthHeight)) {
      return [hasDoubt, message];
    }

    const birthHeight = +modelRef.birthHeight;

    if (birthHeight === 0) {
      hasDoubt = true;
      message = '出生身高为0';
    } else if (birthHeight > 100) {
      hasDoubt = true;
      message = '出生身高大于100';
    }

    return [hasDoubt, message];
  }
}
