import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5454a5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "form-layout-container-title" }
const _hoisted_2 = {
  key: 0,
  class: "organization-text"
}
const _hoisted_3 = { class: "functional" }
const _hoisted_4 = { class: "loadingMask" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_module_base_info = _resolveComponent("form-module-base-info")!
  const _component_form_layout_module_menu = _resolveComponent("form-layout-module-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_case_container = _resolveComponent("case-container")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "form-layout-container" }, {
    default: _withCtx(() => [
      (_ctx.collapsed)
        ? (_openBlock(), _createBlock(_component_a_layout_sider, {
            key: 0,
            theme: "light",
            class: "sider-container",
            width: "240"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_form_module_base_info, {
                "show-history": !_ctx.isCreatePatient
              }, null, 8, ["show-history"]),
              (!_ctx.isCreatePatient)
                ? (_openBlock(), _createBlock(_component_form_layout_module_menu, {
                    key: 0,
                    "module-list": _ctx.moduleList,
                    "red-point-map": _ctx.redPointMap,
                    onMenuClick: _ctx.handleMenuClick
                  }, null, 8, ["module-list", "red-point-map", "onMenuClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_layout, { id: "form-layout-container-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_header, null, {
            default: _withCtx(() => [
              (_ctx.collapsed)
                ? (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                    key: 0,
                    class: "trigger",
                    onClick: _cache[0] || (_cache[0] = () => (_ctx.collapsed = !_ctx.collapsed))
                  }))
                : (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                    key: 1,
                    class: "trigger",
                    onClick: _cache[1] || (_cache[1] = () => (_ctx.collapsed = !_ctx.collapsed))
                  })),
              _createElementVNode("div", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.moduleTitle) + " ", 1),
                (_ctx.onlyInOrg)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.organizationText), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.isShowCase)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      type: "link",
                      class: "btn-case",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.caseContainerCollapsed = !_ctx.caseContainerCollapsed))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.caseCollapseBtnText), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_a_layout_content, {
            class: _normalizeClass(["content", _ctx.loading ? '_loading' : ''])
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_spin, { size: "large" })
              ], 512), [
                [_vShow, _ctx.loading]
              ]),
              (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$router.path
              }))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      (_ctx.caseContainerCollapsed && _ctx.isShowCase)
        ? (_openBlock(), _createBlock(_component_a_layout_sider, {
            key: 1,
            width: "calc(50vw - 120px)",
            theme: "light",
            class: "case-container",
            id: "case-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_case_container, {
                cases: _ctx.cases,
                onlyInOrg: _ctx.onlyInOrg
              }, null, 8, ["cases", "onlyInOrg"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}