
  import { computed, defineComponent, PropType } from 'vue';

  import { ICaseData } from '@/type/form';
  import { get } from 'lodash';
  import { getCasesByRecordUserId, getCasesByFormId, matchFormId, orderByUpdate } from '../CaseCascaderForm/utils';

  export default defineComponent({
    name: 'case-pagination',
    emits: ['update:case'],
    props: {
      case: {
        type: Object as PropType<ICaseData>,
        defualt: null,
      },
      activeCases: {
        type: Array as PropType<ICaseData[]>,
        default: () => [],
      },
    },
    setup(props, context) {
      const currentRecordUserId = computed(() => get(props, 'case.recordUserId', ''));
      const currentFormId = computed(() => matchFormId(get(props, 'case.meta.formId', '')));

      const formCases = computed((): ICaseData[] => {
        if (!props.activeCases.length || !currentRecordUserId.value || !currentFormId.value) {
          return [];
        }

        const recordUserCases = getCasesByRecordUserId(props.activeCases, currentRecordUserId.value);
        if (!recordUserCases.length) {
          return [];
        }

        return orderByUpdate(getCasesByFormId(recordUserCases, currentFormId.value), 'asc');
      });

      const indexOfCases = computed((): number => {
        if (!props.case || formCases.value.length === 0) {
          return -1;
        }

        return formCases.value.findIndex((item) => get(item, 'id') === get(props, 'case.id'));
      });

      const disablePrevBtn = computed(() => {
        return indexOfCases.value <= 0;
      });

      const disableNextBtn = computed(() => {
        return indexOfCases.value === -1 || indexOfCases.value === formCases.value.length - 1;
      });

      const handlePaginationChange = (step: number) => {
        const nextIndex = indexOfCases.value + step;

        if (nextIndex < 0 || nextIndex >= formCases.value.length) {
          return;
        }

        context.emit('update:case', formCases.value[nextIndex]);
      };

      return {
        disablePrevBtn,
        disableNextBtn,
        handlePaginationChange,
      };
    },
  });
