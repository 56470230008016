import { FormModuleType } from '@/type/form';

/* eslint-disable prettier/prettier */
export const FieldColumnWidthDict = {
  状态标识: 100,
  数据来源: 100,
  就诊机构名称: 220,
  就诊KH: 100,
  购药时间: 120,
  规格: 100,
  批号: 150,
  实际数量: 100,
  实际出库价: 110,
  体重: 80,
  注射剂量: 120,
  注射频率: 120,
  是否赠药: 80,
  表单ID: 210,
  购药省份: 80,
  首诊机构名称: 120,
  购药机构: 300,
  表单状态: 80,
  是否自营:150,
  // 首诊信息
  首诊日期: 120,
  首诊省份: 80,
  首诊医院: 80,
  首诊KH: 80,
  患者群体: 80,
  适应症: 60,
  首诊剂量: 80,
  确诊身高: 80,
  确诊体重: 80,
};

export const operableModule = [
  FormModuleType.PATIENT,
  FormModuleType.TREATMENT,
  FormModuleType.LABORATORY,
  FormModuleType.MEDICATION,
  FormModuleType.E_PEN,
  FormModuleType.NOURISHMENT,
  FormModuleType.GHOFFLINE,
];
