import http, { requestHandler, requestBooleanHandler } from './http';

import { DeleteFormListQO, DeleteFormListVO, ListResData, SubjectDeleteQO } from '@/type/api';

const serviceUrl = '/form';
export function getDeleteFormList(params: DeleteFormListQO): Promise<ListResData<DeleteFormListVO>> {
  return requestHandler<ListResData<DeleteFormListVO>>({
    service: () => http.get(serviceUrl + '/getDeleteForms', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });

  // return http.get(serviceUrl + '/getDeleteForms', { params });
}

export function deleteSubject(params: SubjectDeleteQO): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/deleteSubject', params),
    defaultValue: false,
    errorMessage: '删除失败',
  });
  // return http.post(serviceUrl + '/deleteSubject', params);
}
