
  import { defineComponent, reactive, ref, toRefs, toRaw } from 'vue';
  // import { ProfileOutlined } from '@ant-design/icons-vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import SelectInput from '@/components/SelectInput';
  import { TableState } from '@/type/common';
  import { DeleteFormListVO, DeleteFormListQO, RmBaseListQo } from '@/type/api';
  import { getDeleteFormList } from '@/services/subjectForms';
  import { setRouter, setHandleToForm } from './hooks';
  import { exchangeTime } from '@/lib/utils';
  import moment from 'moment';
  import { Moment } from 'moment';
  import { cloneDeep } from 'lodash';
  import { FormStatus } from '@/type/formContent';
  // import { StaticDictionaryTypeEnum } from '@/lib/public';
  export default defineComponent({
    components: {
      ManageListFrame,
      // ProfileOutlined,
      CustomTable,
      SelectInput,
    },
    setup() {
      type DeleteFormListQuery = Omit<RmBaseListQo<DeleteFormListQO>, 'startTime' | 'endTime'> & {
        dateRange: Moment[];
      };

      const params: RmBaseListQo<DeleteFormListQO> = reactive({
        startTime: moment().subtract(7, 'd').valueOf(),
        endTime: moment().valueOf(),
      });
      const query: DeleteFormListQuery = reactive({
        dateRange: [moment().subtract(7, 'd'), moment()],
        formStatus: '',
      });

      const opts = reactive({
        searchOpts: [
          { id: 1, name: '患者姓名' },
          { id: 2, name: '表单ID' },
        ],
        formStatusOpts: [
          { id: '', name: '全部' },
          { id: FormStatus.DELETED, name: '已删除' },
          { id: FormStatus.HONG_CHONG, name: '已红冲' },
          // { id: FormStatus.REJECTED, name: '已驳回' },
          // { id: FormStatus.INVALID, name: '由于模块或者表单的缺失而失效' },
          // { id: FormStatus.UN_FILL, name: '未填写' },
          // { id: FormStatus.SAVED, name: '已暂存' },
          // { id: FormStatus.SUBMITTED, name: '已提交' },
        ],
      });
      const tableState = reactive(
        new TableState({
          dataSource: [] as (DeleteFormListVO & { formStatusStr?: string })[],
          columns: [
            { title: '表单状态', dataIndex: 'formStatusStr' },
            { title: '患者姓名', dataIndex: 'patientName' },
            { title: '表单分类', dataIndex: 'formName' },
            { title: '删除原因', dataIndex: 'reason' },
            { title: '删除操作人', dataIndex: 'deleteUser' },
            { title: '删除操作日期', dataIndex: 'deleteDate' },
            { title: '表单ID', dataIndex: 'subjectFormId' },
            {
              title: '操作',
              dataIndex: 'action',
              slots: {
                customRender: 'action',
              },
            },
          ],
        }),
      );

      const transModal = ref(false);
      const getData = (query: RmBaseListQo<DeleteFormListQO>) => {
        getDeleteFormList({
          ...query,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        }).then((data) => {
          if (data) {
            tableState.dataSource = exchangeTime(data.content, ['deleteDate']);
            tableState.dataSource.forEach((item) => {
              item.formStatusStr = opts.formStatusOpts.find((opt) => opt.id === item.formStatus)?.name;
            });

            tableState.pagination.total = data.total;
            tableState.dataSource = data.content;
          }
        });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData(params);
      };
      const handleSearch = () => {
        const res = cloneDeep(toRaw(query)) as Partial<DeleteFormListQuery>;
        const [startTime, endTime] = res.dateRange as Moment[];
        delete res.dateRange;
        Object.assign(res, { startTime: startTime?.valueOf(), endTime: endTime?.valueOf() });
        Object.assign(params, res);
        getData(params);
      };
      const toRouter = setRouter();
      const handleToForm = setHandleToForm();
      getData(params);
      return {
        ...toRefs(opts),
        query,
        tableState,
        handlePage,
        toRouter,
        transModal,
        handleSearch,
        handleToForm,
      };
    },
  });
