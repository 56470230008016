import { uniqueId } from 'lodash';

import { IFormNourishmentDTO } from '@/type/formNourishmentDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';
import { formatDateWithNone } from '../../utils';

const previewConfig: IFormPreviewGroup<IFormNourishmentDTO>[] = [
  {
    id: uniqueId('NourishmentForm1'),
    groupName: '机构基本信息',
    items: [
      { label: '组织区域-省', fieldName: 'organizationProvinceName' },
      { label: '组织区域-市', fieldName: 'organizationAreaName' },
      { label: '机构名称', fieldName: 'organizationName' },
      { label: '一级科室', fieldName: 'firstLevelDepartmentName' },
      { label: '二级科室', fieldName: 'secondLevelDepartmentName' },
      { label: 'KH编码三', fieldName: 'khCode' },
      { label: 'KH姓名', fieldName: 'khName' },
      { label: 'KHID', fieldName: 'khId' },
      { label: '合作门诊名称', fieldName: 'cooperationDeptName' },
    ],
  },
  {
    id: uniqueId('NourishmentForm2'),
    groupName: '患者信息',
    items: [
      { label: '孩子/用户姓名', fieldName: 'patientName' },
      { label: '孩子出生日期', fieldName: 'patientBirthday', format: (val) => formatDateWithNone(val) },
      { label: '顾客联系方式', fieldName: 'phone' },
      { label: '用户类型', fieldName: 'patientType' },
      { label: '患者ID', fieldName: 'patientId' },
    ],
  },
  {
    id: uniqueId('NourishmentForm3'),
    groupName: '订单信息',
    items: [
      { label: '产品名称', fieldName: 'productName' },
      { label: '订单号', fieldName: 'orderNumber' },
      { label: '支付/结算时间', fieldName: 'payTime', format: (val) => formatDateWithNone(val) },
      { label: '销售状态', fieldName: 'salesStatus' },
      { label: '销售数量', fieldName: 'salesNum' },
      { label: '赠送数量', fieldName: 'giveNum' },
      { label: '终端销售额', fieldName: 'terminalSales' },
      // { label: '出库额', fieldName: 'deliveryAmount' },
      // { label: '回款', fieldName: 'returnedMoney' },
      { label: '健康顾问', fieldName: 'healthyAdviser' },
      { label: '首购时间', fieldName: 'firstBuyTime', format: (val) => formatDateWithNone(val) },
      // { label: '退货快递单号', fieldName: 'returnOrderNumber' },
      { label: '备注', fieldName: 'remarks' },
    ],
  },
];

export default previewConfig;
