import { uniqueId } from 'lodash';

import { IFormConsumableDTO } from '@/type/formConsumableDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';
import { formatDateWithNone } from '../../utils';

const previewConfig: IFormPreviewGroup<IFormConsumableDTO>[] = [
  {
    id: uniqueId('ConsumableForm'),
    groupName: false,
    items: [
      { label: '购药省份', fieldName: 'consumableBuyMedicineProvinceName' },
      { label: '购药医院', fieldName: 'buyMedicineHospitalName' },
      {
        label: '申请日期(购药日期)',
        fieldName: 'consumableBuyMedicineDate',
        format: (val) => formatDateWithNone(val),
      },
      { label: '购药剂型', fieldName: 'buyMedicineDosageType' },
      { label: '购药规格', fieldName: 'buyMedicineSpec' },
      { label: '购药数量', fieldName: 'buyMedicineNum' },
      { label: '耗材数量', fieldName: 'consumableNum' },
      { label: '耗材领用次数', fieldName: 'consumableReceiveTimes' },
      { label: '注射装置', fieldName: 'injectionDevice' },
      { label: '注射装置品牌', fieldName: 'injectionDeviceBrands' },
      { label: '注射装置规格', fieldName: 'injectionDeviceSpec' },
      { label: '配套针头数量', fieldName: 'needleNum' },
      { label: '备注', fieldName: 'consumableRemark' },
    ],
  },
];

export default previewConfig;
