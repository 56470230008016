
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/ban-types */

  import { computed, defineComponent, inject, ref, Ref, onMounted } from 'vue';
  import { get } from 'lodash';
  import { ColumnProps, TableProps } from 'ant-design-vue/lib/table/interface';
  import { VueNode } from 'ant-design-vue/lib/_util/type';
  import { useRoute } from 'vue-router';
  import { PlusOutlined } from '@ant-design/icons-vue';

  import { FormModuleType, ICycleVisitListResponse, ModuleFormTypeMapDict } from '@/type/form';
  import { ObjectMap } from '@/type/helper';

  import FormDeleteDialog from './components/FormDeleteDialog/index.vue';
  import { FieldColumnWidthDict, operableModule } from './utils';
  import { IFetchCycleVisit } from '../components/FormLayoutContianer/interface';
  import { FormOperateType, FormStatus } from '@/type/formContent';

  export default defineComponent({
    name: 'form-list',
    components: {
      FormDeleteDialog,
      PlusOutlined,
    },
    setup() {
      const route = useRoute();

      const isContainerMounted = inject('isContainerMounted', false);
      const redPointMap = inject<Ref<ObjectMap<ObjectMap<boolean>>>>('redPointMap')!;
      const cycleVisit = inject<Ref<ICycleVisitListResponse>>('cycleVisit')!;
      const isOperableModule = computed(() => operableModule.includes(get(route, 'params.moduleId')));
      const isCreateOperable = computed(
        () => isOperableModule.value && get(route, 'params.moduleId') !== FormModuleType.PATIENT,
      );
      // 机构端上传的数据保留两位小数， 如果有小数则最多保留两位小数，并且不四舍五入
      const TotalTwoFun = (text) => {
        if (!text) return;
        return Number(text.toString().match(/^\d+(?:\.\d{0,2})?/)).toString();
      };
      const columns = computed(() => {
        const moduleId = get(route, 'params.moduleId');

        // 列宽度黑名单, 在此list中的模块不设置width
        const columnWidthBlackList = [FormModuleType.LABORATORY, FormModuleType.MEDICA_DISCONTINUATION];
        // 是否设置宽度
        const isSetWidth = !columnWidthBlackList.includes(moduleId);

        const _columns = (get(cycleVisit.value, 'previewDatas.title', []) as string[]).map<ColumnProps>(
          (title: string) => {
            const forms = get(cycleVisit.value, 'forms') || [];
            return {
              title: (() => (
                <a-tooltip title={title}>
                  <div class="text-ellipsis-1-line">{title}</div>
                </a-tooltip>
              )) as unknown as VueNode,
              dataIndex: title,
              width: isSetWidth ? FieldColumnWidthDict[title] : undefined,
              customRender: ({ text, index }) => {
                if (text === '已暂存') {
                  return (
                    <a-tooltip title={text}>
                      <div class="text-ellipsis-1-line_plus">{text}</div>
                    </a-tooltip>
                  );
                } else if (title === '购药机构') {
                  return (
                    <a-tooltip title={text}>
                      <div>{text}</div>
                    </a-tooltip>
                  );
                } else if (text === '自营') {
                  return (
                    <a-tooltip title={text}>
                      <div style="color: red">{text}</div>
                    </a-tooltip>
                  );
                } else if (title === '实际出库价') {
                  return (
                    <a-tooltip title={text}>
                      <div>{forms[index].source == '7' ? TotalTwoFun(text) : text}</div>
                    </a-tooltip>
                  );
                } else {
                  return (
                    <a-tooltip title={text}>
                      <div>{text}</div>
                    </a-tooltip>
                  );
                }
              },
            };
          },
        );

        if (_columns.length) {
          // _columns.unshift({
          //   title: '序号',
          //   customRender: ({ index }) => index + 1,
          //   width: 50,
          //   customCell: (record) => {
          //     const subjectFormId = record['表单ID'];

          //     const hasRedPoint = get(redPointMap.value, `${moduleId}.${subjectFormId}`, false);

          //     return {
          //       class: {
          //         'list-red-point-my': hasRedPoint,
          //       },
          //     };
          //   },
          // });
          _columns.unshift({
            title: '序号',
            customRender: ({ index }) => index + 1,
            width: 50,
            customCell: (record) => {
              const subjectFormId = record['表单ID'];
              // 老逻辑
              // const hasRedPoint = get(redPointMap.value, `${moduleId}.${subjectFormId}`, false);
              // return {
              //   class: {
              //     'list-red-point-my': hasRedPoint,
              //   },
              // };
              //新逻辑： 获取/form/redPoint接口，根据isMine：是自己审核的为true，别人审核的为false，展示不同的ui
              const hasRedPoint = get(redPointMap.value, `${moduleId}.${subjectFormId}`, redPointMap.value?.moduleId?.subjectFormId);
              return {
                class: {
                  'list-red-point-my': hasRedPoint === true,
                  'list-red-point-other': hasRedPoint === false,
                },
              };
            },
          });
          _columns.push({
            title: '操作',
            dataIndex: 'operation',
            slots: { customRender: 'operation' },
            width: 140,
            fixed: 'right',
          });
        }

        return _columns;
      });

      const scrollX = computed(() => columns.value.reduce((sum, column) => sum + (column.width! as number), 0));

      // let contentEl;
      // let btnEl;
      let scrollY: Ref<number | boolean> = ref(true);
      onMounted(() => {
        // contentEl = document.getElementsByClassName('content')[0];
        // btnEl = document.getElementsByClassName('create-btn')[0];
        // scrollY.value = contentEl
        //   ? contentEl.clientHeight -
        //     parseInt(document.defaultView?.getComputedStyle(contentEl, null).paddingTop as string) -
        //     parseInt(document.defaultView?.getComputedStyle(contentEl, null).paddingBottom as string) -
        //     btnEl.clientHeight -
        //     parseInt(document.defaultView?.getComputedStyle(btnEl, null).marginBottom as string) -
        //     42
        //   : 700;
        scrollY.value = window.innerHeight - 200 - 58 || 300;
        scrollY.value < 300 && (scrollY.value = 300);
      });

      const columnsIndexTitleMap = computed(() => {
        return (get(cycleVisit.value, 'previewDatas.title', []) as string[]).reduce<ObjectMap<string>>(
          (map, title, index) => ({
            ...map,
            [index]: title,
          }),
          {},
        );
      });

      const dataSource = computed(() => {
        const forms = get(cycleVisit.value, 'forms') || [];
        const datas = get(cycleVisit.value, 'previewDatas.datas') || [];

        return datas.reduce((list: any[], row: any[], rowIdx: number) => {
          // if (get(forms, `${rowIdx}.status`) !== FormStatus.DELETED) {
          list.push(
            row.reduce(
              (map, columnValue, index) => ({
                ...map,
                [columnsIndexTitleMap.value[index]]: columnValue,
              }),
              {
                canDelete: get(forms, `${rowIdx}.canDelete`) || false,
                canEdit: get(forms, `${rowIdx}.canEdit`) || false,
                isHongChong:
                  get(forms, `${rowIdx}.status`) === FormStatus.HONG_CHONG ||
                  get(forms, `${rowIdx}.status`) === FormStatus.DELETED,
                hasReturnMedication: get(forms, `${rowIdx}.haveReturnDrug`),
                source: get(forms, `${rowIdx}.source`),
                status: get(forms, `${rowIdx}.status`),
                freeMedicine: get(forms, `${rowIdx}.freeMedicine`) || false,
              },
            ),
          );
          // }

          return list;
        }, [] as any[]);
      });

      const handleRowClassName: TableProps['rowClassName'] = (record) => {
        // console.log(record);
        if (record.isHongChong) {
          return 'hong-chong';
        } else if (record.freeMedicine) {
          //待定处理是否赠药
          return 'free-medicine';
        } else if (record.hasReturnMedication) {
          return 'has-return-medication';
        }

        return '';
      };

      const handlePageJump = (subjectFormId: string, type: FormOperateType) => {
        const moduleId = get(route, 'params.moduleId');
        let subjectId: string;

        if (type === FormOperateType.CREATE) {
          subjectId = get(route, 'params.subjectId');
        } else {
          /**
           * 当type不为新增时, 此时从forms中获取subjectId而不从路由中获取, 原因如下:
           * 当患者合并后, 列表中会出现合并的多个患者的相关表单, 此时若使用路由中的subjectId跳转会导致提交时传输的subjectId不正确
           */
          const formItem = (get(cycleVisit.value, 'forms') || []).find((item) => item.subjectFormId === subjectFormId);
          subjectId = get(formItem, 'subjectId', '');
        }

        if (!moduleId || !subjectId || !subjectFormId) {
          console.warn('参数缺失');
        }

        let contentUrl = `/form/content/${moduleId}/${subjectId}/${subjectFormId}?type=${
          type || FormOperateType.PREVIEW
        }`;

        window.open(`${window.location.origin}${contentUrl}`, '_blank');
      };

      /**
       * 表单删除
       *
       * @params
       * deleteFormDialogVisible: 删除表单弹窗显示隐藏状态
       * currentDeleteForm      : 当前要删除的表单
       *
       * @handler
       * handleDelete       : 弹出表单确认删除框
       * handleDeleteSuccess: 表单删除成功后的操作处理
       */
      const fetchCycleVisit = inject<IFetchCycleVisit>('fetchCycleVisit');

      const deleteFormDialogVisible = ref(false);
      const currentDeleteForm = ref<any>(null);

      const handleDelete = (record: any) => {
        currentDeleteForm.value = record;
        deleteFormDialogVisible.value = true;
      };

      const handleDeleteSuccess = () => {
        const subjectId = get(route, 'params.subjectId');
        const moduleId = get(route, 'params.moduleId');
        const subjectFormId = get(route, 'params.subjectFormId');

        if (!subjectId || !moduleId) {
          return;
        }

        fetchCycleVisit &&
          fetchCycleVisit({
            subjectId,
            formId: ModuleFormTypeMapDict[moduleId],
            onlyRejected: false,
            subjectFormId,
          });
      };

      return {
        isContainerMounted,
        isOperableModule,
        isCreateOperable,
        columns,
        dataSource,
        handleRowClassName,
        columnsIndexTitleMap,
        scrollX,
        scrollY,
        handlePageJump,
        FormOperateType,
        deleteFormDialogVisible,
        currentDeleteForm,
        handleDelete,
        handleDeleteSuccess,
        cycleVisit,
      };
    },
  });
