import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';

export default class InjectionFrequencyStopHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.specificationsName;
  }

  static getDefaultValue(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    if (!InjectionFrequencyStopHelper.preCheck(modelRef)) {
      return '';
    }

    const specificationsName = modelRef.specificationsName || '';

    return specificationsName.includes('54IU') ? '6' : '0';
  }

  static verify(val: string): [boolean, string] {
    if (RegularHelper.isUnsignedInteger.test(val)) {
      return [true, ''];
    }

    return [false, '请输入正整数'];
  }
}
