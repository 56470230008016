import http, { requestBooleanHandler, requestHandler } from './http';
import config from '@/lib/config';
import { ListResData } from '@/type/api';
import * as Nou from '@/type/nourishment';
const serviceUrl = '/formNutrition';
// export const download = async (res: Response, localFileName = '文件.xls') => {
//   const blob = await res.blob();
//   const disposition = res.headers.get('content-disposition');
//   if (!disposition) {
//     console.log('没有文件');
//     return false;
//   }
//   const attachment: string[] = disposition?.split(';') as string[];
//   const fileName =
//     decodeURIComponent(attachment.find((v) => v.includes('filename'))?.split('=')[1] as string) || localFileName;
//   const a = document.createElement('a');
//   a.style.display = 'none';
//   a.href = await blobToUrl(blob, fileName);
//   a.download = fileName;
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
//   window.URL.revokeObjectURL(a.href);
// };
export function upload(file: Blob, url: string): Promise<boolean> {
  console.log(file);
  const formData = new FormData();
  formData.append('fileData', file);

  return requestBooleanHandler({
    service: () =>
      http.post(serviceUrl + url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    defaultValue: false,
    errorMessage: '上传失败',
  });
}
// 下载文件
export function fileDownload(params?: { taskId: string | number }): string {
  return (
    location.origin + (config?.backEnd || '') + serviceUrl + '/download' + (params ? '?taskId=' + params.taskId : '')
  );
}
// 下载模板
export function tempDownload(): string {
  return location.origin + (config?.backEnd || '') + serviceUrl + '/template';
}
export function tempUpload(file: Blob): Promise<boolean> {
  return upload(file, '/import');
}
// 导入列表
export function getDownloadList(params: { taskId: string | number }): Promise<ListResData<Nou.ImportListVo>> {
  return requestHandler<ListResData<Nou.ImportListVo>>({
    service: () => http.get('/formNutrition/importTasks', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
}
// 营养品列表
export function getNouList(params: Nou.SaleQo): Promise<ListResData<Nou.SaleListVo>> {
  return requestHandler<ListResData<Nou.SaleListVo>>({
    service: () => http.get('/form/nutrition/list', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
}
// 检验能否导入
export function checkImport(): Promise<number | string> {
  return requestHandler<number | string>({
    service: () => http.get('/formNutrition/import/check'),
    defaultValue: '',
    errorMessage: '检验能否导入失败',
  });
}
// 删除
export function delNut(params: { subjectFormId: string | number; reason: string }): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/form/nutrition/delete', params),
    defaultValue: false,
    errorMessage: '删除失败',
  });
}
export function getNutritionSpeclist(): Promise<Array<{ jinsaiId: string; name: string }>> {
  return requestHandler<Array<{ jinsaiId: string; name: string }>>({
    service: () => http.get('/dictionary/getNutritionSpeclist'),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取规格失败',
  });
}
