import { computed, ComputedRef } from 'vue';
import { ValidationRule } from 'ant-design-vue/lib/form/Form';

import { ObjectMap } from '@/type/helper';
import { RuleObject } from 'ant-design-vue/lib/form/interface';
import RegularHelper from '@/utils/regularHelper';

export const genRules = (
  showIndicationRemark: ComputedRef<boolean>,
  showAGHDRelated: ComputedRef<boolean>,
  showCGHDRelated: ComputedRef<boolean>,
  showFKHDRelated: ComputedRef<boolean>,
  showDepartmentsOtherRemark: ComputedRef<boolean>,
  showDepartmentLabel: ComputedRef<boolean>,
): ComputedRef<ObjectMap<ValidationRule[]>> =>
  computed(() => {
    const rules: ObjectMap<ValidationRule[]> = {
      visitDate: [{ required: true, message: '请选择首诊日期' }],
      indicationGroupId: [{ required: true, message: '请选择患者群体' }],
      indicationId: [{ required: true, message: '请选择适应症' }],
      indicationDesc: [{ required: true, message: '请输入适应症详细诊断描述' }],
      treatmentDose: [{ required: true, message: '请输入首诊剂量' }],
      visitedProvinceId: [{ required: true, message: '请选择首诊省份' }],
      visitedCityId: [{ required: true, message: '请选择首诊城市' }],
      visitedHospitalId: [{ required: true, message: '请选择首诊医院' }],
      visitedDoctorId: [{ required: true, message: '请选择首诊KH' }],
      // receptionistId: [{ required: true, message: '请选择接待人' }],
      // heightManagerId: [{ required: true, message: '请选择身高管理师' }],
      visitHeight: [
        { required: true, message: '请输入确诊身高' },
        {
          async validator(rule: RuleObject, value: string): Promise<void> {
            if (!RegularHelper.isUnsignedFloat.test(value)) {
              return Promise.reject('确诊身高只能为数字');
            } else if (+value > 200) {
              return Promise.reject('身高超过200CM');
            }
            return Promise.resolve();
          },
          trigger: 'blur',
        },
      ],
      visitWeight: [
        { required: true, message: '请输入确诊体重' },
        {
          async validator(rule: RuleObject, value: string): Promise<void> {
            if (!RegularHelper.isUnsignedFloat.test(value)) {
              return Promise.reject('确诊体重只能为数字');
            } else if (+value > 200) {
              return Promise.reject('体重超过200KG');
            }
            return Promise.resolve();
          },
          trigger: 'blur',
        },
      ],
    };

    if (showIndicationRemark.value) {
      rules.indicationRemark = [{ required: true, message: '请输入适应症备注' }];
    }
    if (showCGHDRelated.value) {
      rules.domainLabel = [{ required: true, message: '请选择领域标签' }];
      if (showDepartmentLabel.value) {
        rules.departmentLabel = [{ required: true, message: '请选择科室标签' }];
      }
    }
    if (showAGHDRelated.value) {
      rules.domainLabel = [{ required: true, message: '请选择领域标签' }];
      rules.departmentLabel = [{ required: true, message: '请选择科室标签' }];

      if (showDepartmentsOtherRemark.value) {
        rules.departmentsOtherRemark = [{ required: true, message: '请输入科室标签备注' }];
      }
    }
    console.log(showFKHDRelated.value, 'ajsnvclkdnvlkndvlkasn');
    if (showFKHDRelated.value) {
      rules.domainLabel = [{ required: true, message: '请选择领域标签' }];
      rules.departmentLabel = [{ required: true, message: '请选择科室标签' }];

      if (showDepartmentsOtherRemark.value) {
        rules.departmentsOtherRemark = [{ required: true, message: '请输入科室标签备注' }];
      }
    }

    return rules;
  });

export default genRules;
