
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'form-slice',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
  });
