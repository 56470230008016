
  import { computed, defineComponent, PropType } from 'vue';
  import { get } from 'lodash';
  import moment from 'moment';

  import { ICaseData, RecordStatus, RecordStatusTransDict } from '@/type/form';

  export default defineComponent({
    name: 'case-status-info',
    props: {
      case: {
        type: Object as PropType<ICaseData>,
        default: null,
      },
    },
    setup(props) {
      const showCase = computed(() => props.case != null);
      const isNotProgressing = computed(
        () => showCase.value && get(props.case, 'recordStatus') !== RecordStatus.PROGRESSING,
      );
      const isRejected = computed(() => showCase.value && get(props.case, 'recordStatus') === RecordStatus.REJECTED);

      const recordStatusText = computed(() => RecordStatusTransDict[get(props.case, 'recordStatus')] || '-');
      const recordUserName = computed(() => get(props.case, 'recordUserName') || '-');
      const recordUpdateTime = computed(() => {
        const time = get(props.case, 'recordUpdateTime');
        return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '-';
      });
      const recordRejectReason = computed(() => get(props.case, 'recordRejectReason') || '-');

      return {
        isNotProgressing,
        isRejected,
        recordStatusText,
        recordUserName,
        recordUpdateTime,
        recordRejectReason,
      };
    },
  });
