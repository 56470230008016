
  import { get, sortBy } from 'lodash';
  import { computed, defineComponent, PropType, ref, toRaw, watchEffect } from 'vue';
  import { Modal } from 'ant-design-vue';

  import { getSamePatients, mergePatient, splitPatient } from '@/services/form';
  import { ISamePatientsVO } from '@/type/samePatientsVO';

  import PatientSplitInfo from '../PatientSplitInfo/index.vue';
  import { genPreviewConfig } from './previewConfig';

  export default defineComponent({
    name: 'patient-split-dialog',
    components: {
      PatientSplitInfo,
    },
    emits: ['update:visible'],
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      subjectIds: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      currentSubjectId: {
        type: String,
        default: '',
      },
    },
    setup(props, context) {
      const samePatients = ref<ISamePatientsVO[]>([]);

      watchEffect(async () => {
        if (props.visible && get(props.subjectIds, 'length', 0)) {
          const list = await getSamePatients({
            subjectIds: props.subjectIds.join(','),
          });

          samePatients.value = sortBy(list, (item, index) =>
            item.subjectId === props.currentSubjectId ? 0 : index + 1,
          );
          console.log('aaa', toRaw(samePatients.value));
        }
      });

      const configs = computed(() => samePatients.value.map((item, index) => genPreviewConfig(index)));

      const handleDialogClose = () => {
        context.emit('update:visible', false);

        setTimeout(() => {
          samePatients.value = [];
        }, 300);
      };

      const handleSplit = (subjectId: string) => {
        Modal.confirm({
          keyboard: false,
          title: '是否确认拆分该患者?',
          okText: '确认',
          async onOk() {
            const result = await splitPatient({
              subjectId,
            });
            if (result) {
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          },
        });
      };

      const handleMerge = (mergeSubjectId: string) => {
        Modal.confirm({
          keyboard: false,
          title: '是否确认合并该患者?',
          okText: '确认',
          async onOk() {
            const result = await mergePatient({
              subjectId: props.currentSubjectId,
              mergeSubjectId: mergeSubjectId,
            });
            if (result) {
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          },
        });
      };

      return {
        samePatients,
        configs,
        handleDialogClose,
        handleSplit,
        handleMerge,
      };
    },
  });
