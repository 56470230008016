import { Form } from 'ant-design-vue';
import { get, uniqueId } from 'lodash';

import { ISamePatientsVO, ISamePatientTreatmentInfo } from '@/type/samePatientsVO';

import { IFormPreviewGroup } from '../FormPreview/interface';
import moment from 'moment';

const FormItem = Form.Item;

const renderTreatmentInfo = (item: ISamePatientTreatmentInfo, index: number) => {
  return (
    <div key={index}>
      {[
        <FormItem class="form-preview-content-item" key={`就诊日期${index}`} label="就诊日期">
          {item.visitDate ? moment(item.visitDate).format('YYYY-MM-DD') : '-'}
        </FormItem>,
        <FormItem class="form-preview-content-item" key={`就诊省份${index}`} label="就诊省份">
          {item.visitedProvinceName}
        </FormItem>,
        <FormItem class="form-preview-content-item" key={`就诊医院${index}`} label="就诊医院">
          {item.visitedHospitalName}
        </FormItem>,
        <FormItem class="form-preview-content-item" key={`就诊KH${index}`} label="就诊KH">
          {item.visitedDoctorName}
        </FormItem>,
      ]}
    </div>
  );
};

export const genPreviewConfig = (idx: number): IFormPreviewGroup<ISamePatientsVO>[] => [
  {
    id: uniqueId('PatientSplitPreview1'),
    groupName: idx === 0 ? '当前患者' : '已存在患者',
    items: [{ label: '患者编码', fieldName: 'patientCode' }],
  },
  {
    id: uniqueId('PatientSplitPreview2'),
    groupName: '基本信息',
    items: [
      { label: '患者姓名', fieldName: 'name' },
      { label: '患者性别', fieldName: 'sex' },
      {
        label: '出生年月',
        fieldName: 'birthday',
        format: (birthday) => (birthday ? moment(birthday).format('YYYY-MM-DD') : '-'),
      },
      { label: '现居住省份', fieldName: 'residedProvinceName' },
      { label: '现居住城市', fieldName: 'residedCityName' },
      { label: '现居住地址', fieldName: 'residedAddress' },
    ],
  },
  {
    id: uniqueId('PatientSplitPreview3'),
    groupName: '家长信息',
    items: [
      { label: '父母姓名', fieldName: 'parentName' },
      {
        label: '手机号',
        fieldName: 'mobiles',
        customRender: (value, formValue) => {
          return formValue.mobiles.map((item, index) => (
            <FormItem
              key={item.id}
              label={index === 0 ? '家长联系方式' : ' '}
              class={{
                'form-preview-content-item': true,
                'none-colon-form-item': index !== 0,
              }}>
              <div class="mobile-preview-item-column">
                <div class="mobile-preview-item-column-value">{item.mobile}</div>
              </div>
            </FormItem>
          ));
        },
      },
    ],
  },
  {
    id: uniqueId('PatientSplitPreview4'),
    groupName: '就诊信息',
    items: [
      {
        label: '就诊信息',
        fieldName: 'treatmentInfos',
        customRender: (value, formValue) => {
          if (!get(formValue, 'treatmentInfos.length')) {
            return renderTreatmentInfo({} as ISamePatientTreatmentInfo, -1);
          }

          return formValue.treatmentInfos.map((item, index) => renderTreatmentInfo(item, index));
        },
      },
    ],
  },
];
