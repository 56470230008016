import http, { requestHandler } from './http';
import { Hospital, ListResData } from '@/type/api';
import { SaleDataQo, SaleDataVo } from '@/type/saleDataManage';

const serviceUrl = '/salesDataFocus';

/** 用药数据管理列表 */
export function getSaleDataList(params: SaleDataQo): Promise<ListResData<SaleDataVo>> {
  return requestHandler<ListResData<SaleDataVo>>({
    service: () => http.get(serviceUrl + '/list', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
}

/** 获取医院名称 */
export function getHospitalLimit(name: string): Promise<Hospital[]> {
  return requestHandler<Hospital[]>({
    service: () => http.get(serviceUrl + '/hospital/list', { params: { name } }),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取医院失败',
  });
}
