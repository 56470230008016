
  import { defineComponent, PropType } from 'vue';

  import FormPreview from '../FormPreview/index.vue';
  import { IFormPreviewGroup } from '../FormPreview/interface';

  export default defineComponent({
    name: 'patient-split-info',
    components: {
      FormPreview,
    },
    props: {
      configs: {
        type: Array as PropType<IFormPreviewGroup[]>,
        default: () => [],
      },
      data: {
        type: Object,
        default: () => ({}),
      },
    },
  });
