import http, { requestHandler, requestBooleanHandler } from './http';
// import { AxiosResponse } from 'axios';
import { BaseRes, ListResData, RecorderVo, UserHospitalVo, UserHospitalQo, RelQo } from '@/type/api';

const serviceUrl = '/relationship';
type ServiceRes<T> = Promise<BaseRes<T>>;
export function getHospitalRecorders(keyword): ServiceRes<RecorderVo[]> {
  return http.get(serviceUrl + `/recorders?keyword=${keyword}`);
}
export function getRelList(params: UserHospitalQo): Promise<ListResData<UserHospitalVo>> {
  return requestHandler<ListResData<UserHospitalVo>>({
    service: () => http.get(serviceUrl + '/list', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });

  // return http.get(serviceUrl + '/list', { params });
}
export function deleteRel(id: number): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/delete', { id }),
    defaultValue: false,
    errorMessage: '删除失败',
  });

  // return http.post(serviceUrl + '/delete', { id });
}
export function addRel(params: RelQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/add', params),
    defaultValue: false,
    errorMessage: '添加失败',
  });
  // return http.post(serviceUrl + '/add', params);
}
export function editRel(params: RelQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/edit', params),
    defaultValue: false,
    errorMessage: '编辑失败',
  });
  // return http.post(serviceUrl + '/add', params);
}
