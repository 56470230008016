import { MemberHospital, MemberRegion, MemberRole } from './businessMember';
import { FormOperateType, FormStatus } from './formContent';

export interface BaseRes<T> {
  data: T;
  errorCode: number;
  errorMessage: string;
  status: number; //0成功，1失败？
}
export interface ListResData<T> {
  content: T[];
  limit: number;
  page: number;
  total: number;
}
export interface BaseListQo {
  page: number;
  size: number;
}
export interface RequestBlobOptions<T, C> {
  type: 'get' | 'post';
  url: string;
  params: T;
  cb: (errRes: BaseRes<C>) => C;
}

export interface ResponseBlob<C> {
  /**
   * 接口返回类型
   * - 1 blob文件，下载成功
   * - 0 非 blob，下载失败
   * - -1 接口异常
   */
  type: 1 | 0 | -1;
  value?: C;
}

export interface SearchSelectQo {
  searchType?: string | number | null;
  searchText?: string;
}
export interface SubjectDeleteQO {
  subjectId: string;
  reason: string;
}
export type RmBaseListQo<T> = Omit<T, 'page' | 'size'>;

export type RecordTaskType = 1 | 2 | 3; //任务类型 1新任务 2修改信息 3不合格用药修改
export interface RecordTaskVo {
  id: number;
  ids: string;
  subjectId: string;
  patientCode: string;
  patientName: string;
  recordModule: string;
  formId: string;
  moduleId: string;
  type: RecordTaskType;
  typeStr?: string;
  sourceType: number;
  status: number;
  recordUser: string;
  recordUserName: string;
  uploadUserName: string;
  createTime: number;
  updateTime: number;
  exceed: boolean;
  drugMechanism: string;
  doJump: boolean;
  drugSpec: string,
  orgType: string,
  taskJumpData: {
    formId: string;
    moduleId: string;
    subjectId: string;
    subjectFormId: string;
    permiss: FormOperateType;
  };
}
export interface SubjectSearchQO extends BaseListQo, SearchSelectQo {
  /**
   * 省ID
   */
  provinceId?: string;
  /**
   * 搜索类型（1：患者名称，2：患者编码，3患者电话）
   */
  // searchType?: string;
  /**
   * 名称 编码 电话
   */
  nameOrCodeOrMobile?: string;
   /**
   * 是否为网报老患者
   */
   gensciOldPatient?: string;
  /**
   * 创建者ID
   */
  creatorId?: string;
  /**
   * 最后修改人
   */
  lastReviserId?: string;
  /**
   * 仅查看已删除患者
   */
  onlyDeleted?: boolean;
}
export interface SubjectSearchVO {
  /**
   * 患者ID
   */
  subjectId: string;
  /**
   * 患者表单ID
   */
  subjectFormId: string;
  /**
   * 患者编码
   */
  patientCode: string;
  /**
   * 患者姓名
   */
  patientName: string;
  /**
   * 是否为网报老患者
   */
  gensciOldPatient: string;
  /**
   * 患者性别
   */
  patientSex: string;
  /**
   * 首诊省份ID
   */
  visitedProvinceId: string;
  /**
   * 首诊省份
   */
  visitedProvinceName: string;
  /**
   * 首诊医院ID
   */
  visitedHospitalId: string;
  /**
   * 首诊医院
   */
  visitedHospitalName: string;
  /**
   * 患者群体
   */
  indicationGroupName: string;
  /**
   * 适应症ID
   */
  indicationId: string;
  /**
   * 适应症
   */
  indicationName: string;
  /**
   * 创建人用户ID
   */
  creatorId: string;
  /**
   * 创建人
   */
  creatorName: string;
  /**
   * 最后修改人用户ID
   */
  lastReviserId: string;
  /**
   * 最后修改人
   */
  lastReviserName: string;
  /**
   * 最后修改时间
   */
  lastModifyDate: number;

  deleteReason: string;
  status: FormStatus;
}
export interface RecordTaskChangeVo {
  patientCode: string;
  patientName: string;
  taskCreateTime: number;
  transferTime: number;
  recordUser: string;
  recordUserName: string;
  originRecordUser: string;
  originRecordUserName: string;
  createUser: string;
  createUserName: string;
}

export interface RecordTaskListQO extends BaseListQo, SearchSelectQo {
  // 更新后拆分
  type?: number;
  status?: number;
  patientName?: string;
  transferType?: number;
  userId?: string;
  userIdList?: string[];
  taskIdList?: number[];
  recordUserId?: string;
  recordUserName?: string;
  createTime?: number;
  lastExceedId?: number;
  uploadUserName?: string;
  viewMyTasksOnly?: boolean;
  drugMechanism?: string;
  assignName?: string;
  timeStartWith?: string;
  timeEndWith?: string;
  createdDate?: number;
  drugDate?: number;
  orgTypeList?: string;
  drugSpecList?: string;
}
export interface TransferRecordTaskQo {
  taskIdList: (number | string)[];
  recordUserId?: string;
}
export type DictionaryStatus = 0 | 1;
export enum DictionaryTypeEnum {
  /**
   * 省
   */
  Province = 'Province',
  /**
   * 市
   */
  City = 'City',
  /**
   * 县区
   */
  County = 'County',
  /**
   * 医院
   */
  Hospital = 'Hospital',
  /**
   * 科室
   */
  Dept = 'Dept',
  /**
   * 医生
   */
  Doctor = 'Doctor',
  /**
   * 适应症组
   */
  IndicationGroup = 'IndicationGroup',
  /**
   * 适应症
   */
  Indication = 'Indication',
  /**
   * 停药一级原因
   */
  PrimaryReasonForDrugWithdrawal = 'PrimaryReasonForDrugWithdrawal',
  /**
   * 停药二级原因
   */
  SecondReasonForDrugWithdrawal = 'SecondReasonForDrugWithdrawal',
  /**
   * 民族
   */
  Nation = 'Nation',
  /**
   * 注射设备
   */
  InjectionDeviceName = 'InjectionDeviceName',
  /**
   * 注射设备品牌
   */
  InjectionDeviceBrand = 'InjectionDeviceBrand',
  /**
   * 注射设备规格
   */
  InjectionDeviceSpec = 'InjectionDeviceSpec',
  /**
   * 客服平台人员
   */
  CustomerServicePlatformStaff = 'CustomerServicePlatformStaff',
  /**
   * 规格
   */
  Spec = 'Spec',
  /**
   * 批号
   */
  BatchCode = 'BatchCode',
  /**
   * 发货价
   */
  Price = 'Price',
  /**
   * 数量
   */
  InventoryNum = 'InventoryNum',
  /**
   * 欧电云用户
   */
  OdyUser = 'OdyUser',
  /**
   * 领域标签
   */
  DomainLabel = 'DomainLabel',
}

export interface DictionaryQo<T extends DictionaryTypeEnum> {
  type?: T;
  jinsaiId?: string;
  jinsaiParentId?: string;
  status?: number;
  hospitalId?: string;
  departmentName?: string;
  sort?: string;
  provinceId?: string;
  cityId?: string;
  provinceName?: string;
  cityName?: string;
  typeList?: T[];
  jinsaiIdList?: string[];
  jinsaiParentIdList?: string[];
  hospitalType?: string;
}

export interface DictionaryVo<T extends DictionaryTypeEnum> {
  children?: DictionaryVo<T>[];
  id: number;
  /**
   * 字典类型
   */
  type: T;
  /**
   * 父级类型
   */
  parentType: string;
  /**
   * 金赛Id
   */
  jinsaiId: string;
  /**
   * 金赛父级Id
   */
  jinsaiParentId: string;
  /**
   * 字典值
   */
  name: string;
  /**
   * 1(启用), 0(禁用)
   */
  status: DictionaryStatus;
  /**
   * 顺序号
   */
  orderNum: number;
  /**
   * 医院ID， 医生字典使用, 欧电用户字典也使用
   */
  hospitalId: string;
  /**
   * 科室名称，医生字典使用
   */
  departmentName: string;
  /**
   * 区号，省市字典使用
   */
  areaCode: string;
  /**
   * 省id，医院字典使用
   */
  provinceId: string;
  /**
   * 市id，医院字典使用
   */
  cityId: string;
  /**
   * 医院类别，医院字典使用
   */
  hospitalType: string;
  /**
   * 医院小分类
   */
  hMiType: string;
  /**
   * 欧电用户所属部门id
   */
  deptId: number;
  /**
   * 欧电用户所属部门
   */
  deptName: string;
  /**
   * 欧电用户所属医院
   */
  hospitalName: string;
  /**
   * 欧电用户手机号
   */
  mobile: string;
  /**
   * 欧电用户角色id
   */
  roleId: number;
  /**
   * 欧电用户角色级别
   */
  roleLevel: string;
  /**
   * 欧电用户角色名称
   */
  roleName: string;
  /**
   * 欧电用户员工编号
   */
  staffNO: string;
  /**
   * 欧电用户用户名
   */
  userName: string;
  /**
   * 网报的唯一ID
   */
  incId: number;
  /**
   * 创建时间
   */
  createDate: number;
  /**
   * 更新时间
   */
  updateDate: number;
}
export type RegionDictionaryVo = DictionaryVo<
  DictionaryTypeEnum.City | DictionaryTypeEnum.Province | DictionaryTypeEnum.County
>;
/**
 * 部分接口返回结构会由 allModules 参数包裹, 当接口为上述情况时, 可使用该interface进行声明
 */
export interface IAllModules<T> {
  allModules: T;
}

/**
 * 接口响应状态
 */
export enum ResponseStatus {
  SUCCESS = 0,
}

export interface UserResetPwdQo {
  validCode: string;
  username: string;
  newPassword: string;
  newPasswordConfirm: string;
}
export interface MessageCodeBase {
  randomCode: string;
  source: string;
  validateCode: string;
}
// export interface MessageResetPwdCodeQo extends MessageCodeBase {
//   username: string;
// }
export interface MessagePhoneCodeQo extends MessageCodeBase {
  phone: string;
}
export interface SingleLoginQo {
  code: string;
}
export interface LoginQo {
  username: string;
  validateCode?: string;
  loginMode?: string;
  password?: string;
}
export interface sendValidCodeQo {
  mobile: string;
  validateCode: string;
}
export interface ResetMobileQo {
  mobile: string;
  validCode: string;
}
export interface ResetPwQo {
  // username: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export interface UserEntityVo {
  id: string;
  createTime: number;
  updateTime: number;
  idRc: number;

  /**
   * 手机号
   */
  mobile: string;

  /**
   * ' 手机号绑定： 1未确认，2已确认',
   */
  authmobile: number;

  /**
   * 邮箱
   */
  email: string;

  /**
   * '邮箱绑定： 1未确认，2已确认',
   */
  authemail: number;

  /**
   * 昵称
   */
  nickName: string;

  /**
   * 密码
   */
  password: string;

  /**
   * 密码 盐
   */
  salt: string;

  /**
   * 真实姓名 作废
   */
  realName: string;

  /**
   * 真实姓名
   */
  accountName: string;

  /**
   * 用户类型： 1医生  2医学生 3 其他身份,4 患者,0未填写
   *
   */
  type: number;

  /**
   * 状态1 未审核 2审核中 3 审核通过 4拒绝通过
   */
  status: number;

  /**
   * 用户来源(0:未知、1:iphone、2:android、3:ipad、4:ipod、5:windows phone、6:windows mobile、11:MacPc、12:WindowsPc、13:RC)
   */
  sourceId: number;

  /**
   * 没有机构/学院名称时，填写其他机构和学院
   * ** 别用 **
   */
  otherInstitution: string;

  /**
   * 机构/学院名称/工作单位
   */
  institution: string;

  /**
   * 职称/学历
   */
  postTitle: string;

  /**
   * 领域/科室/专业
   */
  department: string;

  /**
   * 机构所在地区（省）
   */
  province: string;

  /**
   * 地区市
   */
  city: string;

  /**
   * 用户头像原图
   */
  avatar: string;

  /**
   * 微信的唯一认证
   */
  openId: string;

  /**
   * 冻结状态0未禁用 1禁用
   */
  freezeStatus: string;

  /**
   * 控件数
   */
  controls: number;

  /**
   * '用户类型： 0试用  1付费',
   */
  level: number;

  /**
   * 产品了解渠道: 1线上 2线下 3同事 4学术会议
   */
  acquaintId: number;

  /**
   * 认证图片,多张以逗号按顺序分割
   */
  authImage: string;

  /**
   * 认证理由，供其他用户使用
   */
  authReason: string;

  /**
   * 是否测试账号：0:正式，1:测试
   */
  isTestUser: number;

  /**
   * 详细地址，其他角色使用
   */
  allAddress: string;

  /**
   * 可修改的认证次数
   */
  updateAuthCounts: number;

  /**
   * 下一次可认证时间
   */
  nextAuthTime: number;

  /**
   * 认证失败的理由
   */
  authFail: string;

  /**
   * 是否签名：1:未签名，2:已签名
   */
  sign: number;

  inviteUserId: string;

  inviteUserNickName: string;

  inviteUserRealName: string;

  jinsaiId: string;
  role: Role[];
  /**
   * 是否可以导出：1：可，2:不可
   */
  export: number;
  /**
   * 商务成员管理: 1、可，2:不可
   */
  member: number;
}
export interface RecorderVo {
  userId: string;

  jinsaiId: string;

  recorderName: string;
}
export interface DeleteFormListVO {
  /**
   * 表单ID
   */
  subjectFormId: string;
  /**
   * 患者ID
   */
  subjectId: string;
  /**
   * 模块ID
   */
  moduleId: string;
  /**
   * 表单类型ID
   */
  formId: string;
  /**
   * 患者姓名
   */
  patientName: string;
  /**
   * 表单名称
   */
  formName: string;
  /**
   * 删除原因
   */
  reason: string;
  /**
   * 删除人用户ID
   */
  deleteUserId: string;
  /**
   * 删除人姓名
   */
  deleteUser: string;
  /**
   * 删除时间
   */
  deleteDate: number;
  /**
   * 表单状态
   */
  formStatus: number;
}
export interface DeleteFormListQO extends BaseListQo, SearchSelectQo {
  /**
   * 患者姓名
   */
  // patientName: string;

  // /**
  //  * 表单ID
  //  */
  // subjectFormId: string;

  /**
   * 表单状态
   */
  formStatus?: number | string;

  /**
   * 开始日期
   */

  startTime: number;

  /**
   * 结束日期
   */

  endTime: number;
}

export interface UserHospitalQo extends BaseListQo {
  hospitalName?: string;
  userName?: string;
  provinceId?: string;
  onlyGroup?: boolean;
}
export interface RelQo {
  // 医院ID
  hospitalId?: string;
  // 录入员工号
  jinsaiId?: string;
  // 权重
  weight: number;
}
export interface UserHospitalVo {
  // 主键ID
  id: number;

  // 省ID
  provinceId: string;

  // 省名称
  provinceName: string;

  // 市ID
  cityId: string;

  // 市名称
  cityName: string;

  // 医院ID
  hospitalId: string;

  // 医院名称
  hospitalName: string;

  // 录入员工号
  jinsaiId: string;

  // 录入员名称
  userName: string;

  // 权重
  weight: number;

  // 更新时间
  updateTime: number;

  // 创建时间
  createTime: number;
}
export interface Role {
  id: string;
  /**
   * 角色名称
   */
  name: string;

  /**
   * 英文名称
   */
  enname: string;

  /**
   * 角色有优先级 0-100，0最大
   */
  levelOrder: number;

  /**
   * 创建者id
   */
  createBy: string;

  /**
   * 更新者id
   */
  updateBy: string;

  /**
   * 描述
   */
  description: string;

  /**
   * 备注信息
   */
  remarks: string;

  /**
   * 删除标记 0正常，1删除
   */
  delFlag: string;

  /**
   * 是否分中心角色，0否，1是
   */
  isSite: string;

  /**
   * 默认标记,1 用户自定义，0 系统默认，2 项目管理员
   */
  defaultFlag: string;

  /**
   * 关联项目的id
   */
  projectId: string;

  /**
   * 可邀请角色的列表
   */
  inviteRoles: string;

  /**
   * 权限块id的集合，以逗号分割
   */
  permissionBlockIds: string;

  /**
   * 是否为可手动配置角色
   */
  configFlag: boolean;
}
export interface DeleteProjectMemberQo {
  userId: number;
  id: number;
}
export interface Export {
  /**
   * 用户ID
   */
  userId: string;

  /**
   * 用户名
   */
  userName: string;

  /**
   * 项目ID
   */
  projectId: string;

  /**
   * 类型 0：案例导出 1：质疑问题历史导出 2：CRF导出
   */
  type: string;

  /**
   * 案例状态 0：进行中 1：已完成 9：导出出错
   */
  statues: string;

  /**
   * 路径
   */
  path: string;

  /**
   * 图片下载状态 1：进行中 2：已完成 9：导出出错
   */
  photoStatus: string;

  /**
   * 图片路径
   */
  photoPath: string;

  /**
   * 返回地址
   */
  callbackUrl: string;

  /**
   * 目的
   */
  purpose: string;

  /**
   * 错误信息
   */
  msgError: string;

  /**
   * 是否过期（0-false-未过期）
   */
  expired: boolean;

  /**
   * 文件格式,如excel,pdf,csv,zip等
   */
  fileType: string;

  /**
   * 文件名
   */
  fileName: string;

  /**
   * 资源下载的文件名
   */
  photoFileName: string;

  /**
   * 资源下载的文件格式，默认为zip
   */
  photoFileType: string;

  /**
   * 是否删除，1为已删除，0为未删除
   */
  deleted: boolean;
}

export interface IBooleanResponse {
  ok: boolean;
}
export interface ProjectMemberQo extends BaseListQo {
  provinceId?: string;
  cityId?: string;
  userName?: string;
}

export interface ProjectMemberVo {
  // projectName: string;
  userId: string;
  roleId: string;
  // roleIdNew: string;
  roleName: string;
  invitation: number;
  inviter: string;
  inviteTime: number;
  id: string;
  // trainResult: number;
  // joinTag: number;
  realName: string;
  // nickName: string;
  // email: string;
  avatar: string;
  mobile: string;
  // lastLoginTime: string;
  type: string;
  status: string;
  hospitals: string;
  regions: string;
}

export interface UserQo {
  roleId?: number;
  mobile: string;
  jinsaiId: string;
  username: string;
  userRegion: Array<{ cityId: string }>;
  userHospital: Array<{ hospitalId: string }>;
}
export type UserNameVo = Partial<Pick<UserQo, 'jinsaiId' | 'username'>>;
export interface Hospital {
  name: string;
  jinsaiId: string;
}

export interface ExportCenterQO {
  /**
   * 导出类型
   */
  type?: number;
  /**
   * 来源
   */
  salesSource?: number;
  /**
   * 日期类型
   */
  dateType?: number;

  /**
   * 开始日期
   */

  beginDate?: string;

  /**
   * 结束日期
   */

  endDate?: string;
  /**
   * 机构
   */
  hospitalId?: number | string;
  /**
   * 品规
   */
  specification?: number | string;
  // 仅导出本人任务
  viewMyTasksOnly?: boolean;
  exportBatch?: string;
}
export interface DownloadVo {
  id?: number;
  status?: number;
  fileName?: string;
  fileKey?: string;
  createTime?: string;
}
export interface LogoutVO {
  redirectLogoutUri?: string;
}
export interface BusinessMemberListQo extends ProjectMemberQo {
  status?: string;
  memberName?: string;
  /** 成员类型 0 长期; 1 短期 */
  memberType: number;
  role?: string;
  orderWeight?: number;
  workNum?: string;
  province?: string;
  cityName?: string;
  provinceName?: string;
  hospitalName?: string;
  hospitalId?: any;
  provinceId?: any;
  cityId?: any;
}

export interface BusinessMemberInfoQo {
  id: number;
}

export interface BusinessMemberListVo {
  /** ID */
  id: number;
  /** 成员名称 */
  name: string; // 成员名称
  /** 工号 */
  workNum: string; // 工号
  /** 角色 */
  role: string; // 角色
  /** 医院名称 */
  hospital: string;
  /** 辖区 */
  region: string;
  /** 电话号 */
  phoneNumber: number;
  /** 职级 */
  // rank: string;
  /** 邮箱 */
  email: string;
  /** 权限 */
  // permission: number;
  /** 生效时间范围 */
  effectiveTime: string;
}

export interface BusinessHospitalListVo {
  /** 查询机构关键词 */
  hospitalName: string;
  /** 城市id */
  cityId?: number;
  /** 省份id */
  provinceId?: number;
}

export interface BusinessMemberNameListVo {
  /** ID */
  id: number;
  /** 成员名称 */
  memberName: string;
  /** jinSaiId(工号) */
  jinSaiId: string;
}

export interface BusinessMemberInfoVo {
  /** ID */
  id: number;
  status: number;
  orderWeight: string | number | null;
  /** jinSaiId(工号) */
  jinSaiId: string;
  /** 成员名称 */
  memberName: string;
  /** 角色 */
  role: MemberRole[];
  hospitalIds: any[];
  /** 权限 0 录入审核 ，1 查看 */
  // permission: number;
  /** 辖区 */
  region: MemberRegion[];
  /** 医院 */
  hospital: MemberHospital[];
  /** 开始日期 */
  startDate: string;
  /** 结束日期 */
  endDate: string;
}
