
  import { defineComponent, ref, PropType, onMounted, watchEffect, onUnmounted } from 'vue';
  import { TableState, Column } from '@/type/common';
  import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons-vue';

  export default defineComponent({
    components: {
      PlusSquareOutlined,
      MinusSquareOutlined,
    },
    props: {
      tableState: {
        required: true,
        type: Object as PropType<TableState<unknown>>,
      },
    },
    emits: ['pageChange', 'onScroll'],
    setup(props, { emit }) {
      const y = ref(0);
      const tableRef = ref();
      const expandedRowKeys = ref<string[]>([]);
      const customRenderList: string[] = [];
      const commonRenderList: string[] = [];

      const flattColumn = (columns: Column[]) => {
        let result: Column[] = [];
        columns.forEach((item) => {
          if (item.children) {
            result = result.concat(flattColumn(item.children));
          } else {
            result.push(item);
          }
        });
        return result;
      };
      // 列的监听 变为动态
      watchEffect(() => {
        flattColumn(props.tableState.columns).forEach((column) => {
          if (column.slots?.customRender) {
            customRenderList.push(column.slots?.customRender);
          } else {
            column = Object.assign(column, {
              ellipsis: true,
              slots: {
                customRender: column.dataIndex || column.key,
              },
            });
            commonRenderList.push(column.slots?.customRender as string);
          }
        });
      });

      const handlePage = (page: number, pageSize: number) => {
        emit('pageChange', {
          page,
          pageSize,
        });
      };
      const handleSizePage = (page: number, pageSize: number) => {
        emit('pageChange', {
          page: 1,
          pageSize,
        });
      };
      const handleExpandClick = (record: typeof props.tableState.dataSource, type: 'add' | 'sub') => {
        const recordKey = record[props.tableState.key || 'id'];
        if (type === 'add') {
          expandedRowKeys.value.push(recordKey);
        } else {
          expandedRowKeys.value.splice(expandedRowKeys.value.indexOf(recordKey), 1);
        }
      };
      const handleScroll = (tableBodyRef: any) => {
        emit('onScroll', tableBodyRef);
      };
      onMounted(() => {
        // const instance = getCurrentInstance(); //计算保持列表滚动
        // console.log(instance);

        // y.value = (instance as ComponentInternalInstance).parent?.vnode?.el?.clientHeight - 200 || 300; //.$el.clientHeight;
        y.value = window.innerHeight - 200 - 58 || 300;
        y.value < 300 && (y.value = 300);
        if (tableRef.value) {
          tableRef.value.$el.querySelector('.ant-table-body')?.addEventListener('scroll', handleScroll);
        }
      });
      onUnmounted(() => {
        if (tableRef.value) {
          tableRef.value.$el.querySelector('.ant-table-body')?.removeEventListener('scroll', handleScroll);
        }
      });
      return {
        tableRef,
        y,
        customRenderList,
        commonRenderList,
        expandedRowKeys,
        handlePage,
        handleExpandClick,
        handleSizePage,
      };
    },
  });
