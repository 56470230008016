
  import { get } from 'lodash';
  import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue';
  import { ClockCircleFilled } from '@ant-design/icons-vue';
  import { useRoute } from 'vue-router';
  import moment from 'moment';

  import * as FormService from '@/services/form';
  import { ICycleVisitListResponse, IFormOperatHistoryItem } from '@/type/form';
  import { ObjectMap } from '@/type/helper';
  import FormOperatHistoryDialog from '@/views/form/components/FormOperatHistoryDialog/index.vue';

  import { IFormModuleBaseInfo } from './interface';
  import { genDefaultFormModuleBaseInfo } from './utils';
  import { FormStatus } from '@/type/formContent';
  import { FormModuleType } from '@/type/form';

  export default defineComponent({
    name: 'form-module-base-info',
    components: {
      ClockCircleFilled,
      FormOperatHistoryDialog,
    },
    props: {
      showHistory: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      const route = useRoute();

      const isFormList = computed(() => route.name === 'formList');
      // 是否是营养品表单
      const isNut = computed(() => {
        const { moduleId } = get(route, 'params', {}) as ObjectMap<string>;
        return moduleId === FormModuleType.NOURISHMENT;
      });
      // 是否是GH线下用药表单
      const isGHOffline = computed(() => {
        const { moduleId } = get(route, 'params', {}) as ObjectMap<string>;
        return moduleId === FormModuleType.GHOFFLINE;
      });

      /**
       * 表单操作历史
       *
       * @params
       * formOperatHistory: 表单操作历史列表
       * formOperatHistoryDialogVisible: 表单操作历史弹窗显示状态
       *
       */
      const formOperatHistoryList = ref<IFormOperatHistoryItem[]>([]);
      const formOperatHistoryDialogVisible = ref<boolean>(false);

      const handleFormOperatHistoryButtonClick = () => {
        formOperatHistoryDialogVisible.value = true;
      };

      const fetchFormOperatHistory = async () => {
        const { moduleId, subjectFormId } = get(route, 'params', {}) as ObjectMap<string>;

        if (!moduleId || !subjectFormId) {
          return;
        }

        formOperatHistoryList.value = await FormService.getFormOperatHistory({
          moduleId,
          subjectFormId,
        });
      };

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cycleVisit: ICycleVisitListResponse = inject<ICycleVisitListResponse>('cycleVisit')!;

      onBeforeMount(() => {
        if (!isFormList.value) {
          fetchFormOperatHistory();
        }
      });

      return {
        isFormList,
        formOperatHistoryList,
        formOperatHistoryDialogVisible,
        fetchFormOperatHistory,
        handleFormOperatHistoryButtonClick,
        cycleVisit,
        isNut,
        isGHOffline,
      };
    },
    computed: {
      moduleBaseInfo(): IFormModuleBaseInfo {
        const projectName = '客服业务审核系统';

        const baseInfo: Partial<IFormModuleBaseInfo> = {
          projectName,
          subjectNumber: this.cycleVisit.subjectNumber,
          moduleName: this.cycleVisit.moduleName,
          subjectName: this.cycleVisit.subjectName,
          subjectAge: this.cycleVisit.subjectAge,
          indicationGroupName: this.cycleVisit.indicationGroupName,
          domainLabel: this.cycleVisit.domainLabel,
        };

        if (this.isFormList) {
          return genDefaultFormModuleBaseInfo(baseInfo);
        }

        const subjectFormId = get(this, '$route.params.subjectFormId');

        if (!subjectFormId) {
          return genDefaultFormModuleBaseInfo(baseInfo);
        }

        const subjectForm = (get(this.cycleVisit, 'forms') || []).find((item) => item.subjectFormId === subjectFormId);
        // console.log(this.cycleVisit, 'cycleVisit');
        if (!subjectForm) {
          return genDefaultFormModuleBaseInfo(baseInfo);
        }
        console.log(subjectForm, 'subjectForm');
        return genDefaultFormModuleBaseInfo({
          ...baseInfo,
          recordName: subjectForm.realName as string,
          status: subjectForm.status,
          statusString: subjectForm.statusString,
          deleteUserName: subjectForm.deleteUserName,
          deleteDate: subjectForm.deleteDate ? moment(subjectForm.deleteDate).format('YYYY-MM-DD') : '',
          deleteReason: subjectForm.deleteReason,
        });
      },
      isDeletedForm(): boolean {
        return !this.isFormList && this.moduleBaseInfo.status === FormStatus.DELETED;
      },
    },
    watch: {
      isFormList(val) {
        if (!val) {
          this.fetchFormOperatHistory();
        }
      },
    },
  });
