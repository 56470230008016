
  import { defineComponent, PropType } from 'vue';
  import { FormModuleType, IFormModuleItem } from '@/type/form';
  import { ObjectMap } from '@/type/helper';

  export default defineComponent({
    name: 'form-layout-module-menu',
    emits: ['menu-click'],
    props: {
      moduleList: {
        type: Array as PropType<IFormModuleItem[]>,
        default: [] as IFormModuleItem[],
      },
      redPointMap: {
        type: Object as PropType<ObjectMap<ObjectMap<boolean>>>,
        default: {} as ObjectMap<ObjectMap<boolean>>,
      },
    },
    computed: {
      activeModule(): string[] {
        return [this.$route.params.moduleId as string];
      },
      allowClick(): boolean {
        return ![FormModuleType.NOURISHMENT, FormModuleType.GHOFFLINE].includes(
          this.$route.params.moduleId as FormModuleType,
        );
      },
    },
    methods: {
      handleMenuClick({ key }) {
        if (!this.allowClick) {
          return false;
        }
        this.$router.push(`/form/list/${key}/${this.$route.params.subjectId}`);

        this.$emit('menu-click', key);
      },
    },
  });
