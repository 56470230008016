import { getDictionaryList } from '@/services/common';
import { DictionaryVo, DictionaryQo, DictionaryTypeEnum } from '@/type/api';
import { reactive } from 'vue';
export type StaticDictionaryTypeEnum =
  | DictionaryTypeEnum.Province
  | DictionaryTypeEnum.City
  | DictionaryTypeEnum.Hospital
  | DictionaryTypeEnum.Nation
  | DictionaryTypeEnum.County;
// | DictionaryTypeEnum.County;

class Dictionary {
  // private list = [] as DictionaryVo<DictionaryTypeEnum>[];
  private staticList = [] as DictionaryVo<StaticDictionaryTypeEnum>[];
  async getStaticDictionary(params: DictionaryQo<StaticDictionaryTypeEnum> & { cityIdList?: string[] }) {
    const matchedList = this.staticList.filter((item) => {
      const keys = Object.keys(params);

      let res = true;
      keys.forEach((key) => {
        if (key === 'typeList') {
          res = !!(params[key]?.includes(item.type) && res); //不是很严谨后续处理
        }
        // else if (key === 'cityIdList' && params.type === 'Hospital') {
        //   //进行省市多重筛选 根据jinsaiId和jinsaiParentId进行处理
        //   res = !!(params[key]?.includes(item.cityId) && res);
        // }
        else {
          res = item[key] === params[key] && res;
        }
      });
      return res;
    });

    // if (params.cityIdList?.length) {
    //   this.handleCityIdListHospital(params.cityIdList);
    //   // return;
    // }

    if (matchedList.length) {
      return matchedList;
    }
    this.staticList = (await this.getCurDictionary(params)) || this.staticList;
    console.log(this.staticList);

    return this.staticList;
  }
  async getHospitalByCityIdList(cityIdList: string[]) {
    const existHospitalCityIdList = [
      ...new Set(
        this.staticList.filter((item) => item.type === DictionaryTypeEnum.Hospital).map((item) => item.cityId),
      ),
    ];
    const noExistList: Array<string> = [];
    console.log('existHospitalCityIdList', existHospitalCityIdList);

    cityIdList.forEach((cityId) => {
      existHospitalCityIdList.includes(cityId) || noExistList.push(cityId);
    });

    const res = await Promise.all(
      noExistList.map((cityId) => {
        console.log(cityId);

        return getDictionaryList({ type: DictionaryTypeEnum.Hospital, cityId });
      }),
    );
    console.log(res);
    res.forEach((data) => {
      this.staticList = this.staticList.concat(data);
    });
    console.log(this.staticList);

    return this.staticList.filter(
      (item) => item.type === DictionaryTypeEnum.Hospital && cityIdList.includes(item.cityId),
    );
  }

  async getCurDictionary<T extends DictionaryTypeEnum | StaticDictionaryTypeEnum>(params: DictionaryQo<T>) {
    try {
      const data = await getDictionaryList(params);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getCurDictionaryWithDefault<T extends DictionaryTypeEnum | StaticDictionaryTypeEnum>(params: DictionaryQo<T>) {
    let result: DictionaryVo<T>[];

    try {
      const data = await getDictionaryList(params);
      result = data || [];
    } catch (error) {
      result = [];
      console.log(error);
    }

    return result;
  }
}

/**
 * 过滤全部区域选项
 */
export const filterAllRegion = (
  list: DictionaryVo<StaticDictionaryTypeEnum>[],
  type: DictionaryTypeEnum,
): DictionaryVo<StaticDictionaryTypeEnum>[] => {
  let allRegionId: string;

  switch (type) {
    case DictionaryTypeEnum.Province:
      allRegionId = '14826';
      break;
    case DictionaryTypeEnum.City:
      allRegionId = '14827';
      break;
    default:
      return list;
  }

  return (list || []).filter((item) => item.jinsaiId !== allRegionId);
};

export default reactive(new Dictionary());
