import { ColumnProps } from 'ant-design-vue/lib/table/interface';
import { Ref } from 'vue';

export interface Pagination {
  current: number;
  pageSize: number;
  defaultPageSize: number;
  total: number;
  pageSizeOptions: string[];
  size: 'default' | 'small';
  showSizeChanger: boolean;
  // 'show-quick-changer': boolean;
  hideOnSinglePage: boolean;
}
export type Column = ColumnProps & {
  title: string;
  /** 合并表头时 表明当前列为父列 */
  isParent?: boolean;
  /** 合并表头时 可以用这个字端记录父表头名称 */
  parentTitle?: string;
  dataIndex: string;
  key?: string;
  ellipsis?: boolean;
  width?: number;
  fixed?: boolean | 'left' | 'right';
  slots?: {
    customRender: string;
  };
  children?: Column[];
};
interface Table<T> {
  key?: string | number;
  dataSource: T[];
  columns: Column[];
  bordered?: boolean;
  pagination?: Pagination | false;
  scroll?: { x?: number | string | boolean; y?: number | string | boolean };
  rowSelection?: {
    columnWidth?: string;
    selectedRowKeys?: Ref<string[]>;
    onChange?(selectedRowKeys: string[], selectedRows: T[]): void;
  };
  showExpandRow?: (record: T) => boolean;
}
export class TableState<T> implements Table<T> {
  constructor(props: Table<T>) {
    this.dataSource = props.dataSource;
    this.columns = props.columns;
    this.rowSelection = props.rowSelection;
    this.key = props.key;
    this.scroll = props.scroll;
    this.pagination = {
      current: 1,
      pageSize: 10,
      defaultPageSize: 10,
      total: 0,
      pageSizeOptions: ['10', '30', '50', '100'],
      size: 'small',
      showSizeChanger: true,
      hideOnSinglePage: false,
    };
    this.showExpandRow = props.showExpandRow;
  }
  bordered = true;
  dataSource: T[];
  columns: Column[];
  pagination: Pagination;
  rowSelection?;
  key?: string | number;
  scroll?: { x?: number | string | boolean; y?: number | string | boolean };
  showExpandRow?: (record: T) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IDropdownItem<T = any> {
  label: string;
  value: T;
}
export interface Option {
  id: string | number;
  name: string;
}

export interface CheckboxOption {
  label: string;
  value: string;
  disabled?: boolean;
}
