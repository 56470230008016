import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    class: "elect-pen-form",
    model: _ctx.modelRef,
    rules: _ctx.rules,
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        label: "购买省份",
        name: "purchaseProvinceId",
        id: "purchaseProvinceId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.provinceModel,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.provinceModel) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleHospital(true))),
            "label-in-value": "",
            "get-popup-container": _ctx.handlePopupContainer,
            "show-search": "",
            optionFilterProp: "name"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinces, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: opt.jinsaiId,
                  name: opt.name,
                  value: opt.jinsaiId
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt.name), 1)
                  ]),
                  _: 2
                }, 1032, ["name", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "购买医院",
        name: "purchaseHospitalId",
        id: "purchaseHospitalId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.purchaseHospitalModel,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.purchaseHospitalModel) = $event)),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleEPenBatchCodeList(true))),
            "show-search": "",
            optionFilterProp: "name",
            "label-in-value": "",
            "get-popup-container": _ctx.handlePopupContainer
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hospitals, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: opt.id,
                  value: opt.jinsaiId,
                  name: opt.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "购买日期",
        name: "purchaseDate",
        id: "purchaseDate"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_date_picker, {
            value: _ctx.modelRef.purchaseDate,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelRef.purchaseDate) = $event)),
            "get-calendar-container": _ctx.handlePopupContainer,
            "disabled-date": _ctx.disabledDate
          }, null, 8, ["value", "get-calendar-container", "disabled-date"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "规格",
        name: "specificationsId",
        id: "specificationsId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.modelRef.specificationsId,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelRef.specificationsId) = $event)),
            placeholder: "请选择规格",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer,
            onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleSpecificationsChange(true)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specs, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "批号",
        name: "epenBatchNumber",
        ref: "epenBatchNumber",
        id: "epenBatchNumber"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.modelRef.epenBatchNumber,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelRef.epenBatchNumber) = $event)),
            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleEpenBatchBalanceInventory(true))),
            "get-popup-container": _ctx.handlePopupContainer,
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ePenBatchCodeList, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: opt,
                  value: opt,
                  title: opt
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "get-popup-container", "filter-option"])
        ]),
        _: 1
      }, 512),
      (_ctx.isKASHIPING)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 0,
            label: "发货价(元/支)",
            name: "deliveryPrice",
            id: "deliveryPrice"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.modelRef.deliveryPrice,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelRef.deliveryPrice) = $event)),
                placeholder: "请选择发货价",
                "show-search": "",
                "filter-option": _ctx.handleFilterOptionCaseInsensitive,
                "get-popup-container": _ctx.handlePopupContainer
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryPrices, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item,
                      title: item
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filter-option", "get-popup-container"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form_item, {
        label: "批号结余库存",
        name: "epenBatchBalanceInventory",
        ref: "epenBatchBalanceInventory",
        id: "epenBatchBalanceInventory"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.epenBatchBalanceInventory,
            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modelRef.epenBatchBalanceInventory) = $event)),
            valueModifiers: { trim: true },
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 512),
      _createVNode(_component_a_form_item, {
        label: "序列号",
        name: "serialNumber",
        id: "serialNumber"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.serialNumber,
            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modelRef.serialNumber) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "购笔数量",
        name: "purchaseQuantity",
        id: "purchaseQuantity"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.purchaseQuantity,
            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modelRef.purchaseQuantity) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      (_ctx.isKASHIPING)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 1,
            label: "退笔数量",
            name: "epenWithdrawalNumber",
            id: "epenWithdrawalNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.epenWithdrawalNumber,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modelRef.epenWithdrawalNumber) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form_item, {
        label: "备注",
        name: "epenRemark",
        id: "epenRemark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.epenRemark,
            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.modelRef.epenRemark) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}