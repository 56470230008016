import { Column } from '@/type/common';

const getHeadList = (tableDom: HTMLElement): { list: HTMLTableCellElement[] | undefined; left: number } => {
  const table = tableDom || (document.getElementsByClassName('ant-table') || [])[0];
  if (!table) return { list: undefined, left: 0 };
  const thead = table.getElementsByTagName('thead')[0];
  if (!thead) return { list: undefined, left: 0 };
  const headList = thead.getElementsByTagName('th');
  const list: HTMLTableCellElement[] = [];
  let left = 0;
  for (const item of headList) {
    if (item.className.includes('ant-table-selection-column')) {
      left += item.offsetWidth;
    } else {
      list.push(item);
    }
  }
  return {
    list,
    left,
  };
};

export const addTableCtrl = ({ columns, tableDom }: { columns: Column[]; tableDom?: HTMLElement }): void => {
  const table = tableDom || ((document.getElementsByClassName('ant-table') || [])[0] as HTMLElement);
  if (!table) return;
  const { list: headList, left: headLeft } = getHeadList(table);
  if (!headList) return;
  const nodeList: HTMLDivElement[] = [];
  let left = headLeft;
  let ctrlIndex = -1;
  let startX = -1;
  let rawWidth = 0;
  const userSelect = document.body.style.userSelect;
  const cursor = document.body.style.cursor;
  for (let i = 0; i < headList.length - 1; i++) {
    const item = headList[i];
    const { width } = item.getBoundingClientRect();
    left += width - 2;
    const node = document.createElement('div');
    node.dataset.index = `${i}`;
    Object.assign(node.style, {
      position: 'absolute',
      width: '5px',
      height: '100%',
      // backgroundColor: '#00ffff29',
      top: 0,
      cursor: 'col-resize',
      left: left + 'px',
      zIndex: 0,
    });
    let isInitColumnsWidth = false;
    const initColumnsWidth = () => {
      if (isInitColumnsWidth) return;
      isInitColumnsWidth = true;
      const { list: headList = [] } = getHeadList(table);
      for (let i = 0; i < headList.length; i++) {
        const { width } = headList[i].getBoundingClientRect();
        columns[i].width = width;
      }
    };
    node.addEventListener('mousedown', (e) => {
      initColumnsWidth();
      const nextIndex = +((e.target as HTMLElement).dataset.index || 0);
      ctrlIndex = nextIndex;
      startX = e.x;
      const { list: headList = [] } = getHeadList(table);
      ({ width: rawWidth } = headList[ctrlIndex].getBoundingClientRect());
      document.body.style.userSelect = 'none';
      document.body.style.cursor = 'col-resize';
    });
    nodeList.push(node);
  }
  document.addEventListener('mousemove', (e) => {
    if (ctrlIndex > -1) {
      const moveX = e.x - startX;
      const nextWidht = rawWidth + moveX;
      if (nextWidht > 56) {
        columns[ctrlIndex].width = nextWidht;
      }
    }
  });
  document.addEventListener('mouseup', () => {
    if (ctrlIndex > -1) {
      ctrlIndex = -1;
      startX = -1;
      rawWidth = 0;
      let left = headLeft;
      const { list: headList = [] } = getHeadList(table);
      for (let i = 0; i < headList.length - 1; i++) {
        const item = headList[i];
        const { width } = item.getBoundingClientRect();
        left += width;
        const node = nodeList[i];
        node.style.left = left + 'px';
      }
      document.body.style.userSelect = userSelect;
      document.body.style.cursor = cursor;
    }
  });
  const nodeBox = document.createElement('div');
  Object.assign(nodeBox.style, {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
  });
  nodeBox.append(...nodeList);
  table.appendChild(nodeBox);
  table.style.width = table.offsetWidth + 'px';
  table.style.overflow = 'hidden';
  let scrollLeft = 0;
  setTimeout(() => {
    const antTableBody = table.getElementsByClassName('ant-table-body')[0];
    const antTableHeader = table.getElementsByClassName('ant-table-header')[0];
    antTableBody.addEventListener('scroll', () => {
      if (scrollLeft != antTableBody.scrollLeft) {
        scrollLeft = antTableBody.scrollLeft;
        antTableHeader.scrollLeft = scrollLeft;
        nodeBox.style.left = -scrollLeft + 'px';
        nodeList.forEach((item) => {
          const left = +item.style.left.replace('px', '');
          item.style.zIndex = left > scrollLeft ? '0' : '-1';
        });
      }
    });
  });
};
