
  import { loginV2 } from '@/services/usercenter';
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
  import { defineComponent, ref } from 'vue';
  import { useRouter } from 'vue-router';
  export default defineComponent({
    components: {
      UserOutlined,
      LockOutlined,
    },
    setup() {
      const router = useRouter();
      const formRef = ref();
      const loginFrom: any = ref({});
      const formLoading = ref(false);
      const login = () => {
        formLoading.value = true;
        if (formRef.value) {
          formRef.value
            .validate()
            .then(() => {
              loginV2(loginFrom.value)
                .then((res: any) => {
                  if (res.id) {
                    console.log(location);
                    window.location.href = location.origin + '/manage/taskCenter';
                  }
                })
                .catch(() => {
                  formLoading.value = false;
                });
            })
            .catch(() => {
              formLoading.value = false;
            });
        }
      };
      const rules = {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      };
      return {
        loginFrom,
        login,
        rules,
        formLoading,
        formRef,
      };
    },
  });
