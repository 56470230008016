
  import { defineComponent, reactive } from 'vue';
  import { RollbackOutlined } from '@ant-design/icons-vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import { TableState } from '@/type/common';
  import { RecordTaskVo } from '@/type/api';
  import { getTransRecordList } from '@/services/manage';
  import { setRouter } from './hooks';
  import { exchangeTime } from '@/lib/utils';
  export default defineComponent({
    components: { ManageListFrame, RollbackOutlined, CustomTable },
    setup() {
      const query = reactive({
        patientName: '',
        transferType: 1,
      });
      const params = reactive({
        patientName: '',
      });
      const tableState = reactive(
        new TableState({
          dataSource: [] as RecordTaskVo[],
          columns: [
            { title: '患者编码信息', dataIndex: 'patientCode', ellipsis: true },
            { title: '患者姓名', dataIndex: 'patientName' },
            { title: '创建时间', dataIndex: 'taskCreateTime' },
            { title: '指派给', dataIndex: 'originRecordUserName' },
            { title: '转移给', dataIndex: 'recordUserName' },
            { title: '转移时间', dataIndex: 'transferTime' },
            { title: '操作人', dataIndex: 'createUserName' },
          ],
        }),
      );
      const getData = () => {
        getTransRecordList({
          ...params,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        }).then((data) => {
          if (data) {
            tableState.dataSource = exchangeTime(data.content, ['taskCreateTime', 'transferTime']);
            tableState.pagination.total = data.total;
            // tableState.dataSource = data.content;
          }
        });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData();
      };
      const toRouter = setRouter();
      const handleSearch = () => {
        Object.assign(params, query);
        tableState.pagination.current = 1;
        getData();
      };
      getData();
      return {
        query,
        tableState,
        handlePage,
        toRouter,
        handleSearch,
      };
    },
  });
