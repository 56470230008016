
  import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import SelectInput from '@/components/SelectInput';
  import { TableState } from '@/type/common';
  import { getSubjectList } from '@/services/manage';
  import { getHospitalRecorders } from '@/services/relationship';
  import { setRouter, setHandleToForm, handleTabVisible } from './hooks';
  import dictionary, { filterAllRegion } from '@/lib/dictionary';
  import { exchangeTime } from '@/lib/utils';
  import { deleteSubject } from '@/services/subjectForms';
  import {
    SubjectSearchQO,
    SubjectSearchVO,
    DictionaryTypeEnum,
    RmBaseListQo,
    DictionaryVo,
    RecorderVo,
    SubjectDeleteQO,
  } from '@/type/api';
  import { FormModuleType } from '@/type/form';
  import { addTableCtrl } from '@/utils/handleTable';
  import notify from '@/utils/notify';
  import { ProfileOutlined } from '@ant-design/icons-vue';

  export default defineComponent({
    components: {
      ManageListFrame,
      ProfileOutlined,
      CustomTable,
      SelectInput,
    },
    setup() {
      const delModal = ref(false);
      const reasonModal = ref(false);
      const reasonRel = ref();
      const keyWord = ref('');
      const query: RmBaseListQo<SubjectSearchQO> = reactive({
        searchText: ''
      });
      const params: RmBaseListQo<SubjectSearchQO> = reactive({
        searchText: ''
      });
      const delQo: SubjectDeleteQO = reactive({
        subjectId: '',
        reason: '',
      });
      const opts = reactive({
        provinceList: [] as DictionaryVo<DictionaryTypeEnum.Province>[],
        recordersList: [] as RecorderVo[],
        searchOpts: [
          { id: 1, name: '患者姓名' },
          { id: 2, name: '手机号' },
          { id: 3, name: '患者编码' },
        ],
        typeOpts: [
          { id: 1, name: '是' },
          { id: 2, name: '否' },
          // { id: 4, name: '患者姓名' },
        ],
        delReason: [
          { id: 0, name: '测试数据' },
          { id: 1, name: '系统原因' },
          { id: 2, name: '资料提供错误' },
          { id: 3, name: '重复上传任务重复录入' },
          { id: 4, name: '合并患者，用药重复' },
          { id: 5, name: '用药录入调整' },
          { id: 6, name: '匿名购药认领' },
          { id: 7, name: '疑似数据' },
          { id: 8, name: '重名患者' },
          { id: 9, name: '其他' },
        ],
      });
      const tableState = reactive(
        new TableState({
          dataSource: [] as SubjectSearchVO[],
          columns: [
            { title: '患者编码', dataIndex: 'patientCode', ellipsis: true },
            { title: '患者姓名', dataIndex: 'patientName' },
            // { title: '是否为网报老患者', dataIndex: 'gensciOldPatient' },
            { title: '性别', dataIndex: 'patientSex' },
            { title: '首诊省份', dataIndex: 'visitedProvinceName' },
            { title: '首诊医院', dataIndex: 'visitedHospitalName' },
            { title: '患者群体', dataIndex: 'indicationGroupName', ellipsis: true },
            { title: '适应症', dataIndex: 'indicationName' },
            { title: '最后修改人', dataIndex: 'lastReviserName' },
            { title: '最后修改时间', dataIndex: 'lastModifyDate' },
            {
              title: '操作',
              dataIndex: 'action',
              width: 170,
              slots: {
                customRender: 'action',
              },
            },
          ],
        }),
      );
      const transModal = ref(false);
      dictionary
        .getStaticDictionary({
          type: DictionaryTypeEnum.Province,
        })
        .then((data) => {
          opts.provinceList = filterAllRegion(
            data,
            DictionaryTypeEnum.Province,
          ) as DictionaryVo<DictionaryTypeEnum.Province>[];
        });

      const handleSearchPatient = (value) => {
        keyWord.value = value;
        getHospitalRecorders(keyWord.value).then((res) => {
          const { data } = res;
          if (data) {
            opts.recordersList = data;
          }
        });
      };
      const getData = () => {
        getSubjectList({
          ...params,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        }).then((data) => {
          if (data) {
            tableState.dataSource = exchangeTime(data.content, ['lastModifyDate']);
            tableState.pagination.total = data.total;
            tableState.dataSource = data.content;
            handleSearchPatient('')
          }
        });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData();
      };
      const handleSearch = () => {
        Object.assign(params, query);
        tableState.pagination.current = 1;
        getData();
      };
      const handleDel = async () => {
        try {
          const valid = await reasonRel.value.validate();
          console.log(valid);

          if (valid) {
            deleteSubject(delQo).then((res) => {
              if (res) {
                notify.success({ message: '删除成功' });
                delModal.value = false;
                getData();
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      };
      const handleViewPatient = (subjectId) => {
        window.open(`/form/list/${FormModuleType.PATIENT}/${subjectId}`, '_blank');
      };
      const handleDelConfirm = (subjectId) => {
        delQo.subjectId = subjectId;
        delQo.reason = '';
        delModal.value = true;
        console.log(subjectId);
      };
      const toRouter = setRouter();
      const handleToForm = setHandleToForm();
      const toCreatePatient = () => {
        window.open('/form/content/824077282368286720/NEW/CREATE?type=1', '_blank');
      };
      const handleReset = () => {
        query.searchText = '';
        query.provinceId = undefined;
        query.searchType = undefined;
        query.gensciOldPatient = undefined;
        query.creatorId = undefined;
        query.lastReviserId = undefined;
        query.onlyDeleted = undefined;
        handleSearch();
      };
      getData();
      handleTabVisible(getData);
      onMounted(() => {
        addTableCtrl({
          columns: tableState.columns,
        });
      });
      return {
        ...toRefs(opts),
        reasonRel,
        reasonModal,
        query,
        tableState,
        handlePage,
        toRouter,
        transModal,
        handleSearch,
        handleDel,
        delModal,
        delQo,
        handleViewPatient,
        handleDelConfirm,
        toCreatePatient,
        handleToForm,
        handleSearchPatient,
        handleReset
      };
    },
  });
