
  import { computed, CSSProperties, defineComponent, ref } from 'vue';
  import { PlusOutlined, MinusOutlined, UndoOutlined, RedoOutlined } from '@ant-design/icons-vue';

  export default defineComponent({
    name: 'image-toolbar',
    components: {
      PlusOutlined,
      MinusOutlined,
      UndoOutlined,
      RedoOutlined,
    },
    emits: ['change'],
    setup(props, context) {
      // eslint-disable-next-line
      const broadcastDispatcher = (handler: Function) => (...args: any) => {
          handler(...args);

          const style: CSSProperties = {
            transform: `scale(${zoom.value / 100}) rotate(${rotate.value}deg)`,
          };
          console.log(style);
          context.emit('change', style);
        };

      // 缩放
      const zoom = ref<number>(100);
      const fixedZoom = computed(() => zoom.value.toFixed(0));
      const handleZoomIn = broadcastDispatcher((magnification = 1.5) => {
        const nextZoom = zoom.value * magnification;
        if (nextZoom < 1000) {
          zoom.value = nextZoom;
        }
      });
      const handleZoomOut = broadcastDispatcher((magnification = 1.5) => {
        const nextZoom = zoom.value / magnification;
        if (nextZoom > 30) {
          zoom.value = nextZoom;
        }
      });

      // 旋转
      const rotate = ref<number>(0);
      const hanlleRotate = broadcastDispatcher((angle: number) => (rotate.value += angle));

      const reset = broadcastDispatcher(() => {
        zoom.value = 100;
        rotate.value = 0;
      });

      return {
        zoom,
        fixedZoom,
        handleZoomIn,
        handleZoomOut,
        rotate,
        hanlleRotate,
        reset,
      };
    },
  });
