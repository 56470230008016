import { uniqueId } from 'lodash';

import { IFormLaboratoryDTO } from '@/type/formLaboratoryDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';

const previewConfig: IFormPreviewGroup<IFormLaboratoryDTO>[] = [
  {
    id: uniqueId('LaboratoryForm'),
    groupName: false,
    items: [{ label: '骨龄检查', fieldName: 'boneAge' }],
  },
];

export default previewConfig;
