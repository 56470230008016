
  import { cloneDeep, get } from 'lodash';
  import { computed, defineComponent, PropType, reactive, ref, toRaw, inject, watch, watchEffect, Ref, provide, nextTick } from 'vue';
  import { Moment } from 'moment';
  import { Modal } from 'ant-design-vue';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import {
    IFormMedicationDTO,
    MedicationDurationExplainTypes,
    QualifiedStatusType,
    QualifiedStatusTypes,
    SalesIncreaseReasonTypes,
    UnitDoseStatusTypes,
    delayEnterReasonTypes,
  } from '@/type/formMedicationDTO';
  import FormSlice from '../FormSlice/index.vue';
  import {
    handePopupContainerByClass,
    handleFilterOptionCaseInsensitive,
    truthFalseLists,
    isDiscounts,
    discounts,
  } from '../../utils';
  import EffectHandler from './effectHandler';
  import {
    genDoctorModel,
    genHospitalModel,
    genVisitDoctorProvince,
    genHospitalTypeModel,
    disabledDateAfterToday,
    genPrescriptionValidityHandler,
    genInspectionReportValidityHandler,
    genDeliveryPriceModel,
    genBatchNumberModel,
    genUnitDoseModel,
    genWeightModel,
    genPrescriptionValidityModel,
    genMedicationDurationExplainRemarkModel,
    genMedicationDurationExplainModel,
    genInjectionDoseModel,
    genInjectionUnitModel,
    genInspectionReportValidityModel,
    genSpecificationsModel,
    genBuyMedicineMechanismModel,
    genautarkyModel,
    genBuyMedicineMechanismTypeModel,
    genBuyMedicineProvinceModel,
    genSalesIncreaseDateHandler,
    genOtherDescModel,
    getDelayEnterReasonOtherModel,
    genDisqualificationReasonModel,
    genNextPurchaseOtherDescModel,
    genDrugPurchasedNumber,
    genDrugWithdrawalNumber,
    buyMedicineDateDoubtFun,
    injectionUnitDoubtFun,
    genStopModel,
  } from './optionModel';
  import { convertMedicationForm, deserializationMedicationForm, computedAutarky } from './utils';
  import genRules from './rules';
  import { IFormSubmitCallback, ValidateFailedCallback } from '../../interface';
  import moment from 'moment';
  import { getCheckDrugRepeat, getDictionaryList as dictionaryListAjax } from '@/services/form';
  import { useRoute } from 'vue-router';
  import bus from '@/utils/EventBus';
  // 定义一个函数数据类型
  type IFunction = () => void;

  export default defineComponent({
    name: 'medication-form',
    components: {
      FormSlice,
    },
    props: {
      formValue: {
        type: Object as PropType<IFormMedicationDTO<string, string>>,
        default: null,
      },
    },
    setup(props) {
      const route = useRoute();
      const loading = ref(false);
      const handleLoadingChange = (val: boolean) => (loading.value = val);
      // 机构端用药信息详情点击提交并继续，表单不应该置灰
      const continueDisabled = ref(true);
      // 零氪-预警规则参数服务端可配置
      // dictionaryList = ref<any>({});
      // 门诊不合格原因
      const OutpatientService = ref([]);
      // 医院不合格原因
      const Hospital = ref([]);
      // 新的不合格原因
      const disqualificationReasonTypesNew = ref([]);
      // 默认老数据
      const reasonOld = ref(true);
      // 如果有小数则最多保留两位小数，并且不四舍五入
      const TotalTwoFun = (text) => {
        if (!text) return;
        return Number(text.toString().match(/^\d+(?:\.\d{0,2})?/)).toString();
      };

      const modelRef: any = reactive(cloneDeep(toRaw(convertMedicationForm(props.formValue))));

      // 机构端用药信息详情点击提交并继续，表单不应该置灰
      const continueDisabledChange = () => {
        if (modelRef.source == '7' && get(route, 'query.type') == 1) {
          continueDisabled.value = false;
        }
      };
      continueDisabledChange();
      provide('continueDisabledChange', continueDisabledChange);
 
      // 接收数据 零氪-预警规则参数服务端可配置
      // dictionaryList:Ref<any> = inject('earlyWarningRule', ref(null));
      const dictionaryList: Ref<any> = inject('earlyWarningRule', ref(null));
      // bus.on('dictionaryList', (val: any) => {
      //   // 机构上传为true
      //   dictionaryList.value = val;
      // })


      // 点击取消的时候关闭loading
      const handleAfterSave = inject<IFunction>('handleAfterSave');

      const formRef = ref();

      const effectHandler = EffectHandler(modelRef, formRef);

      /**
       * * * * * * * * * * * * * * * 处方信息 * * * * * * * * * * * * * * *
       */
      const [doctorModel, doctors] = genDoctorModel(modelRef, formRef);
      const [hospitalModel, hospitals, handleHospitalChange] = genHospitalModel(
        modelRef,
        effectHandler,
        doctors,
        handleLoadingChange,
      );
      // visitDoctorProvinceModel 就诊省份匹配省份的id
      // visitDoctorProvinces 就诊省份list
      const [visitDoctorProvinceModel, visitDoctorProvinces, handleVisitDoctorProvinceChange] = genVisitDoctorProvince(
        modelRef,
        effectHandler,
        doctors,
        hospitals,
        handleLoadingChange,
      );
      const [hospitalTypeModel, hospitalTypes, handleHospitalTypeChange] = genHospitalTypeModel(
        modelRef,
        effectHandler,
        doctors,
        hospitals,
        handleLoadingChange,
      );
      const prescriptionValidityDoubt = genPrescriptionValidityModel(modelRef);
      const prescriptionValidityDisabledHandler = genPrescriptionValidityHandler(modelRef);
      const inspectionReportValidityDisabledHandler = genInspectionReportValidityHandler(modelRef);
      const inspectionReportValidityDoubt = genInspectionReportValidityModel(modelRef);
      /**
       * * * * * * * * * * * * * * * 购药信息 * * * * * * * * * * * * * * *
       */
      // 发货价
      const [deliveryPriceModel, deliveryPrices, handleDeliveryPriceChange] = genDeliveryPriceModel(
        modelRef,
        effectHandler,
        handleLoadingChange,
      );

      // 购药数量
      const [drugPurchasedNumberDoubt, handleDrugPurchasedNumberChange] = genDrugPurchasedNumber(
        modelRef,
        effectHandler,
        (fields: string[]) => {
          formRef.value && formRef.value.validateFields(fields);
        },
      );

      // 退药数量
      const drugWithdrawalNumberDoubt = genDrugWithdrawalNumber(modelRef);

      // 批号
      const [batchNumberModel, batchNumbers, handleBatchNumberChange] = genBatchNumberModel(
        modelRef,
        effectHandler,
        formRef,
        deliveryPrices,
        handleLoadingChange,
      );

      // 单位用药剂量
      const unitDoseDoubt = genUnitDoseModel(modelRef, effectHandler, dictionaryList);

      // 体重
      const [weightDoubt, handleWeightChange] = genWeightModel(modelRef, effectHandler, dictionaryList);

      // 用药时长说明备注(其他，请描述)
      const [isShowMedicationDurationExplainRemark] = genMedicationDurationExplainRemarkModel(modelRef);

      // 用药时长说明
      const [handleMedicationDurationExplainChange, isShowMedicationDurationExplain] =
        genMedicationDurationExplainModel(modelRef, effectHandler);

      // 注射剂量
      const [handleInjectionDoseChange, injectionDoseDoubt] = genInjectionDoseModel(modelRef, effectHandler);

      const salesIncreaseDateDisabledHandler = genSalesIncreaseDateHandler(modelRef);

      // 注射剂量单位
      const [injectionUnitModel, injectionUnits, handleInjectionUnitChange] = genInjectionUnitModel(
        modelRef,
        effectHandler,
      );

      // 规格
      const [specificationsModel, specifications, handleSpecificationsChange] = genSpecificationsModel(
        modelRef,
        effectHandler,
        formRef,
        deliveryPrices,
        batchNumbers,
        handleLoadingChange,
      );

      const [isShowOtherDesc] = genOtherDescModel(modelRef);

      const [isShowDelayEnterReasonOther] = getDelayEnterReasonOtherModel(modelRef);

      // 购药机构名称
      const [buyMedicineMechanismModel, buyMedicineMechanisms, handleBuyMedicineMechanismChange] =
        genBuyMedicineMechanismModel(
          modelRef,
          effectHandler,
          formRef,
          deliveryPrices,
          batchNumbers,
          handleLoadingChange,
        );
      // 录入日期(用药信息创建时间)-购药日期＞90天，提示语： 录入日期-购药日期超过90天
      // 购药日期-派单日期（用药信息创建时间）＞0，提示语： 购药日期-派单日期＞0
      const buyMedicineDateDoubt = buyMedicineDateDoubtFun(modelRef, dictionaryList);
      // 如果用药规格为：【GH54IU】/【GH54IU(卡式瓶)】，在 剂量信息 部分中的字段【注射剂量单位】如果选中IU/w时，提示：长效注射单位疑似异常
      const injectionUnitDoubt = injectionUnitDoubtFun(modelRef, dictionaryList);

      const subjectFormId = inject<string>('subjectFormId') || '';

      // 首单用药，如果是首单则需要对是否自营做默认值
      const fisrtDetail: Ref<IFormMedicationDTO<moment.Moment, string[]> | null> = inject('fisrtDetail', ref(null));

      // 是否自营
      const [autarkyModel, autarkies, isShowAutarky] = genautarkyModel(modelRef);
      watchEffect(() => {
        let autarky = computedAutarky(
          fisrtDetail.value,
          subjectFormId,
          modelRef.buyMedicineMechanismType,
          modelRef.hospitalId,
          modelRef.buyMedicineMechanismId,
        );
        autarky && (autarkyModel.value = autarky);
      });

      // 购药机构类型
      const [buyMedicineMechanismTypeModel, buyMedicineMechanismTypes, handleBuyMedicineMechanismTypeChange] =
        genBuyMedicineMechanismTypeModel(modelRef, effectHandler, buyMedicineMechanisms, handleLoadingChange);

      // 购药省份
      const [buyMedicineProvinceModel, buyMedicineProvinces, handleBuyMedicineProvinceChange] =
        genBuyMedicineProvinceModel(modelRef, effectHandler, buyMedicineMechanisms, handleLoadingChange);

      // 展示用药出现原因或延迟录入原因
      effectHandler.delayOrSale();

      const [disqualificationReasonTypes, isShowDisqualificationReasonType] = genDisqualificationReasonModel(modelRef);
      const [isShowNextPurchaseOtherDesc] = genNextPurchaseOtherDescModel(modelRef);

      const isDelayOrSale = computed(() => modelRef.delayOrSale);
      const rules = genRules(
        isShowAutarky,
        isShowOtherDesc,
        isShowDelayEnterReasonOther,
        isDelayOrSale,
        unitDoseDoubt,
        isShowDisqualificationReasonType,
        isShowNextPurchaseOtherDesc,
        isShowMedicationDurationExplain,
        isShowMedicationDurationExplainRemark,
        modelRef,
        specifications,
        batchNumbers,
        doctors,
      );
      // 点击不合格原因:门诊/医院
      const snonconformityChange = async (clear = true) => {
        if (clear) {
          // 清空被选中的不合格原因
          modelRef.disqualificationReason = [];
          // 清空被选中的不合格原因:"其他"
          modelRef.nextPurchaseOtherDesc = '';
        }
        if (modelRef.statuSnonconformity == '1') {
          disqualificationReasonTypesNew.value = OutpatientService.value;
        }
        if (modelRef.statuSnonconformity == '2') {
          disqualificationReasonTypesNew.value = Hospital.value;
        }
      };
      // 判断一个数组里面是否包含另外一个数组中的元素,包含返回true
      function containsAnyValue(arr1, arr2) {
        return arr2.some((item) => arr1.includes(item));
      };
      // 不合格原因高亮的是门诊还是医院tab
      const checkReason = () => {
        // 新数据数组的第一项应该是："门诊：***"或者"医院：***"
        let firstIndex = modelRef.disqualificationReason[0] || '';
        // 把数组第一项中的【门诊：】/【医院：】去掉，才能匹配到真正的不合格原因
        let firstItem = '';
        // 用户上次点的是合格
        if (!firstIndex) {
          reasonOld.value = false;
          return;
        }
        // 用户上次点的是不合格，并且选择了门诊或者医院
        if (firstIndex.includes('门诊：')) {
          modelRef.statuSnonconformity = '1';
          firstItem = firstIndex.replace(/门诊：/g, '');
          modelRef.disqualificationReason[0] = firstItem;
          reasonOld.value = false;
        } else if (firstIndex.includes('医院：')) {
          modelRef.statuSnonconformity = '2';
          firstItem = firstIndex.replace(/医院：/g, '');
          modelRef.disqualificationReason[0] = firstItem;
          reasonOld.value = false;
        } else {
          reasonOld.value = true;
          modelRef.statuSnonconformity = '';
        }
      };

      // 获取不合格原因list
      const getResultList = async () => {
        disqualificationReasonTypesNew.value = [];
        try {
          const [res1, res2] = await Promise.all([
            dictionaryListAjax({ type: 'Reason_OutpatientService' }) as any,
            dictionaryListAjax({ type: 'Reason_Hospital' }) as any,
          ]);
          return [res1, res2];
        } catch (error) {
          console.error(error);
        }
      };
      let getFirstIndex = modelRef.disqualificationReason[0] || '';

      getResultList().then(([res1, res2]: any) => {
        if (res1 && res1.length) {
          const result = res1.map((val: any) => {
            return val.name;
          });
          OutpatientService.value = result;
        }
        if (res2 && res2.length) {
          const result = res2.map((val: any) => {
            return val.name;
          });
          Hospital.value = result;
        }
        checkReason();
        snonconformityChange(false);
      });
      // 判断展示新数据还是老数据
      const showTitle = () => {
        if (!getFirstIndex) {
          reasonOld.value = false;
        } else {
          if (getFirstIndex.includes('门诊：') || getFirstIndex.includes('医院：')) {
            reasonOld.value = false;
          } else {
            reasonOld.value = true;
            modelRef.statuSnonconformity = '';
          }
        }
        if (!reasonOld.value) {
          // 不包含门诊和医院的数据
          if (modelRef.buyMedicineMechanismType == '公立医院' || modelRef.buyMedicineMechanismType == '民营医院') {
            modelRef.statuSnonconformity = '2';
          }
          if (modelRef.buyMedicineMechanismType == '常驻合作门诊' || modelRef.buyMedicineMechanismType == '非常驻合作门诊') {
            modelRef.statuSnonconformity = '1';
          }
        }
        snonconformityChange();
      };

      // 监听是否合格
      watch(
        () => modelRef.statusIdentification,
        (newValue) => {
          if (newValue == '不合格') {
            showTitle();
          };
        },
        // { immediate: true, deep: true },
      );
      // 监听购药机构类型
      watch(
        () => modelRef.buyMedicineMechanismType,
        (newValue) => {
          if (modelRef.statusIdentification == '不合格') {
            showTitle();
          }
        },
        // { immediate: true, deep: true },
      );

      const getFormValue = () => {
        return deserializationMedicationForm(cloneDeep(toRaw(modelRef)));
      };
      const getDateValue = (fieldValue: unknown): number => {
        let result = '';
        if (moment.isMoment(fieldValue)) {
          result = fieldValue.format('YYYY/MM/DD');
        } else {
          result = (fieldValue as string) || '';
        }
        return new Date(result).valueOf();
      };
      const checkBuyMedicineDate = () => {
        return new Promise<void>((resolve, reject) => {
          let status = 0;
          if (modelRef.prescriptionDate || modelRef.inspectionReportDate) {
            // 购药时间 < 处方日期
            const isBefore1 = modelRef.prescriptionDate
              ? getDateValue(modelRef.buyMedicineDate) < getDateValue(modelRef.prescriptionDate)
              : false;
            if (isBefore1) {
              status += 1;
            }
            // 购药时间 < 检查报告单日期
            const isBefore2 = modelRef.inspectionReportDate
              ? getDateValue(modelRef.buyMedicineDate) < getDateValue(modelRef.inspectionReportDate)
              : false;
            if (isBefore2) {
              status += 2;
            }
          }
          let content = '';
          if (status == 3) {
            content = '购药日期早于处方日期和检查报告单日期，请确认是否提交';
          } else if (status == 2) {
            content = '购药日期早于检查报告单日期，请确认是否提交';
          } else if (status == 1) {
            content = '购药日期早于处方日期，请确认是否提交';
          }
          if (content) {
            Modal.confirm({
              title: '是否继续',
              content,
              okText: '提交',
              cancelText: '取消',
              onOk() {
                resolve();
              },
              onCancel() {
                reject();
              },
            });
          } else {
            resolve();
          }
        });
      };
      const checkDrugRepeat = () => {
        return new Promise<void>((resolve, reject) => {
          getCheckDrugRepeat({
            buyMedicineMechanismId: modelRef.buyMedicineMechanismId,
            buyMedicineDate: modelRef.buyMedicineDate.format
              ? modelRef.buyMedicineDate.format('YYYY-MM-DD')
              : modelRef.buyMedicineDate,
            specificationsId: modelRef.specificationsId,
            batchNumber: modelRef.batchNumber,
            drugPurchasedNumber: modelRef.drugPurchasedNumber,
            subjectId: get(route, 'params.subjectId'),
          }).then(
            (res) => {
              if (res) {
                Modal.confirm({
                  title: '是否继续',
                  content: '该购药机构下已经有相同数量的品规药品的录入，是否继续提交',
                  okText: '提交',
                  cancelText: '取消',
                  onOk() {
                    resolve();
                  },
                  onCancel() {
                    reject();
                  },
                });
              } else {
                resolve();
              }
            },
            () => {
              reject();
            },
          );
        });
      };
      const handleSubmit = async (
        cb: IFormSubmitCallback,
        validateFailedCb: ValidateFailedCallback<ValidateErrorEntity<IFormMedicationDTO<Moment, string[]>>>,
      ) => {
        formRef.value
          .validate()
          .then(() => {
            checkBuyMedicineDate()
              .then(checkDrugRepeat)
              .then(
                () => {
                  cb(getFormValue());
                },
                () => {
                  // 点击取消的时候关闭loading
                  // cb(handleAfterSave());
                  handleAfterSave?.();
                  console.log('取消');
                },
              );
          })
          .catch((err: ValidateErrorEntity<IFormMedicationDTO<Moment, string[]>>) => {
            validateFailedCb(err);
          });
      };
      // provide('dictionaryList', dictionaryList.value);
      const fetchCases = inject<() => void>('fetchCases')!;


      const resetModel = async () => {
        await formRef.value.resetFields();
        const nextFormValue = cloneDeep(toRaw(convertMedicationForm(props.formValue)));
        nextFormValue.subjectFormId = '';
        Object.keys(nextFormValue).forEach((key) => {
          modelRef[key] = nextFormValue[key];
        });

        // 重新查询库存
        if (nextFormValue.deliveryPrice) {
          handleDeliveryPriceChange(nextFormValue.deliveryPrice);
        }
        // 重新获取右边数据（主要是为了重新获取是否可以被打回）
        fetchCases();
      };

      if (modelRef.deliveryPrice) {
        handleDeliveryPriceChange(modelRef.deliveryPrice, true);
      }
      const setZeroTimeObj = { hour: 0, minute: 0, second: 0, millisecond: 0 };
      const changeStatusIdentification = () => {
        // 购药时间>处方有效期 || 购药时间>检查报告单有效期 || 够药时间 < 处方日期时
        const buyMedicineDate = modelRef.buyMedicineDate?.set(setZeroTimeObj).valueOf(); // 购药时间
        const prescriptionValidity = modelRef.prescriptionValidity?.set(setZeroTimeObj).valueOf(); // 处方有效期
        const inspectionReportValidity = modelRef.inspectionReportValidity?.set(setZeroTimeObj).valueOf(); // 检查报告单有效期
        const prescriptionDate = modelRef.prescriptionDate?.set(setZeroTimeObj).valueOf(); // 检查报告单有效期
        let status = QualifiedStatusType.NONE;
        if (!buyMedicineDate) {
          status = QualifiedStatusType.NONE;
        } else if (prescriptionValidity && buyMedicineDate > prescriptionValidity) {
          status = QualifiedStatusType.UNQUALIFIED;
        } else if (inspectionReportValidity && buyMedicineDate > inspectionReportValidity) {
          status = QualifiedStatusType.UNQUALIFIED;
        } else if (prescriptionDate && buyMedicineDate < prescriptionDate) {
          status = QualifiedStatusType.UNQUALIFIED;
        } else {
          status = QualifiedStatusType.NONE;
        }
        if (modelRef.buyMedicineMechanismType == '公立医院' || modelRef.buyMedicineMechanismType == '民营医院') {
          status = QualifiedStatusType.NONE;
        }

        modelRef.statusIdentification = status;
      };
      /** 系统判断是否合格 */
      watch(
        () => [
          modelRef.buyMedicineDate,
          modelRef.prescriptionValidity,
          modelRef.inspectionReportValidity,
          modelRef.prescriptionDate,
          modelRef.buyMedicineMechanismType,
        ],
        changeStatusIdentification,
      );
      if (modelRef.statusIdentification == QualifiedStatusType.NONE) {
        changeStatusIdentification();
      }
      const [stopDoubt] = genStopModel(modelRef);
      return {
        formRef,
        modelRef,
        effectHandler,
        rules,

        doctorModel,
        doctors,
        hospitalModel,
        hospitals,
        handleHospitalChange,
        visitDoctorProvinceModel,
        visitDoctorProvinces,
        handleVisitDoctorProvinceChange,
        hospitalTypeModel,
        hospitalTypes,
        handleHospitalTypeChange,
        disabledDateAfterToday,
        prescriptionValidityDoubt,
        prescriptionValidityDisabledHandler,
        inspectionReportValidityDisabledHandler,
        inspectionReportValidityDoubt,

        deliveryPriceModel,
        deliveryPrices,
        handleDeliveryPriceChange,

        drugPurchasedNumberDoubt,
        handleDrugPurchasedNumberChange,
        drugWithdrawalNumberDoubt,
        buyMedicineDateDoubt,
        injectionUnitDoubt,

        batchNumberModel,
        batchNumbers,
        handleBatchNumberChange,

        unitDoseDoubt,

        stopDoubt,
        weightDoubt,
        handleWeightChange,

        isShowMedicationDurationExplainRemark,

        handleMedicationDurationExplainChange,
        isShowMedicationDurationExplain,
        MedicationDurationExplainTypes,

        handleInjectionDoseChange,
        injectionDoseDoubt,

        salesIncreaseDateDisabledHandler,
        SalesIncreaseReasonTypes,

        delayEnterReasonTypes,

        injectionUnitModel,
        injectionUnits,
        handleInjectionUnitChange,

        UnitDoseStatusTypes,

        specificationsModel,
        specifications,
        handleSpecificationsChange,

        isShowOtherDesc,
        isShowDelayEnterReasonOther,

        buyMedicineMechanismModel,
        buyMedicineMechanisms,
        handleBuyMedicineMechanismChange,

        autarkyModel,
        autarkies,
        isShowAutarky,

        QualifiedStatusTypes,

        buyMedicineMechanismTypeModel,
        buyMedicineMechanismTypes,
        handleBuyMedicineMechanismTypeChange,

        buyMedicineProvinceModel,
        buyMedicineProvinces,
        handleBuyMedicineProvinceChange,

        disqualificationReasonTypes,
        disqualificationReasonTypesNew,
        isShowDisqualificationReasonType,

        isShowNextPurchaseOtherDesc,

        truthFalseLists,
        isDiscounts,
        discounts,

        handleFilterOptionCaseInsensitive,

        getFormValue,
        handleSubmit,
        resetModel,

        handlePopupContainer: handePopupContainerByClass('medication-form'),
        handleAfterSave,
        continueDisabled,
        dictionaryList,
        snonconformityChange,
        getResultList,
        OutpatientService,
        Hospital,
        reasonOld,
      };
    },
  });
