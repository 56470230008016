
  import { defineComponent, onMounted, reactive, ref, toRefs, toRaw } from 'vue';
  import { ProfileOutlined } from '@ant-design/icons-vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import { TableState } from '@/type/common';
  import { RecordTaskListQO, RecordTaskVo, RmBaseListQo, RecorderVo, TransferRecordTaskQo } from '@/type/api';
  import { createTaskCenterExport, getRecordTaskList, transRecordTask, getDictionaryList } from '@/services/manage';
  import { getHospitalRecorders } from '@/services/relationship';
  import { setRouter, handleTabVisible } from './hooks';
  import { exchangeTime } from '@/lib/utils';
  import SelectInput from '@/components/SelectInput';
  import { Moment } from 'moment';
  import notify from '@/utils/notify';
  import { addTableCtrl } from '@/utils/handleTable';
  import { RouteLocation, useRouter } from 'vue-router';

  // import { StaticDictionaryTypeEnum } from '@/lib/public';
  export default defineComponent({
    components: { ManageListFrame, ProfileOutlined, CustomTable, SelectInput },
    setup() {
      const toRouter = setRouter();
      const router = useRouter();
      const opts = reactive({
        searchOpts: [
          { id: 4, name: '患者姓名' },
          { id: 2, name: '购药机构名称' },
          { id: 1, name: '审核人' },
          { id: 3, name: '客服姓名' },
        ],
        typeOpts: [
          { id: 1, name: '新任务' },
          { id: 2, name: '修改任务' },
          { id: 3, name: '修改不合格' },
          // { id: 4, name: '患者姓名' },
        ],
        statusOpts: [
          { id: 1, name: '未处理' },
          { id: 2, name: '已驳回' },
        ],
        recordersList: [] as RecorderVo[],
        newPatientOpts: [
          { id: 0, name: '否' },
          { id: 1, name: '是' },
        ],
        orgTypeOptions:[] as any[],
        drugSpecificationOptions:[] as any[]
      });

      const defaultQuery = {
        status: 1,
      };
      const query: Omit<RmBaseListQo<any>, 'createdDate' | 'drugDate' | 'orgTypeList' | 'drugSpecList'> & {
        createdDate?: Moment;
        drugDate?: Moment;
        orgTypeList?: any[],
        drugSpecList?: any[],
      } = reactive({ ...defaultQuery });
      const params: RmBaseListQo<RecordTaskListQO> = reactive({ ...defaultQuery });

      const selectedRows = reactive({
        rows: [] as RecordTaskVo[],
      });
      const selectedRowKeys = ref<string[]>([]);
      const transForm: TransferRecordTaskQo = reactive({
        taskIdList: [],
        // recordUserId: '',
      });

      const typeList = {
        1: '新任务',
        2: '修改信息',
        3: '不合格⽤药修改',
      };
      const keyWord = ref('');
      const tableState = reactive(
        new TableState({
          key: 'id',
          dataSource: [] as RecordTaskVo[],
          columns: [
            { title: '患者编码', dataIndex: 'patientCode', ellipsis: true },
            {
              title: '任务性质',
              dataIndex: 'typeStr',
              slots: {
                customRender: 'exceed',
              },
            },
            { title: '患者姓名', dataIndex: 'patientName' },
            { title: '审核⼈', dataIndex: 'recordUserName' },
            { title: '客服姓名', dataIndex: 'uploadUserName' },
            { title: '创建时间', dataIndex: 'createTime' },
            { title: '购药机构名称', dataIndex: 'drugMechanism', ellipsis: true },
            { title: '购药机构类型', dataIndex: 'orgType' },
            { title: '规格', dataIndex: 'drugSpec' },
            { title: '采集模块', dataIndex: 'recordModule' },
            {
              title: '操作',
              dataIndex: 'action',
              slots: {
                customRender: 'action',
              },
            },
          ],
          rowSelection: {
            selectedRowKeys: selectedRowKeys,
            onChange: (keys: string[], rows: RecordTaskVo[]) => {
              selectedRowKeys.value = keys;
              selectedRows.rows = rows;
              console.log(`selectedRowKeys: ${selectedRowKeys.value}`, 'selectedRows: ', selectedRows);
            },
          },
        }),
      );

      getDictionaryList({type: 'OrgType'}).then((res) => {
        opts.orgTypeOptions = Array.isArray(res) ? res || [] : []
      })

      getDictionaryList({type: 'DrugSpecification'}).then((res) => {
        opts.drugSpecificationOptions = Array.isArray(res) ? res || [] : []
    })

      getHospitalRecorders(keyWord.value).then((res) => {
        // opts.recordersList = res.data;
        let list = JSON.parse(JSON.stringify(res.data));
        // list.map((item) => {
        //   item.jinsaiId = item.jinsaiId.includes('GS') ? item.jinsaiId.replace('GS', 'AD') : item.jinsaiId;
        // });
        opts.recordersList = list;
      });

      const handleTrans = () => {
        if (transForm.recordUserId) {
          transForm.taskIdList = selectedRows.rows.map((row) => row.ids.split(',')).flat();
          transRecordTask(transForm).then((res) => {
            res && notify.success({ message: '转移成功' });
            transModal.value = false;
            selectedRows.rows = [];
            selectedRowKeys.value = [];
            handleSearch();
          });
        } else {
          notify.error({ message: '请选择录入人员' });
        }
      };
      const transModal = ref(false);
      const getData = () => {
        getRecordTaskList({
          ...params,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        }).then((data) => {
          // const { data } = res;
          if (data) {
            tableState.dataSource = exchangeTime(data.content, ['createTime']);
            tableState.dataSource.forEach((row) => {
              row.typeStr = typeList[row.type];
            });
            tableState.pagination.total = data.total;
            // tableState.dataSource = data.content;
          }
        });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        selectedRows.rows = [];
        selectedRowKeys.value = [];
        getData();
      };
      const handleSearch = () => {
        Object.assign(params, query);
        params.createdDate = query.createdDate?.valueOf();
        params.drugDate = query.drugDate?.valueOf();
        params.orgTypeList = query.orgTypeList?.join();
        params.drugSpecList = query.drugSpecList?.join();
        tableState.pagination.current = 1;
        getData();
      };
      const handleReset = () => {
        query.status = 1;
        query.type = undefined;
        query.searchType = undefined;
        query.searchText = undefined;
        query.createdDate = undefined;
        query.drugDate = undefined;
        query.isNewPatient = undefined;
        query.orgTypeList = undefined;
        query.drugSpecList = undefined;
        query.viewMyTasksOnly = undefined;
        handleSearch()
      };
      const handleExport = () => {
        Object.assign(params, query);
        params.createdDate = query.createdDate?.valueOf();
        params.drugDate = query.drugDate?.valueOf();
        createTaskCenterExport(params).then((res) => {
          if (res) {
            notify.success({ message: '导出任务创建成功' });
          }
        });
      };

      getData();
      handleTabVisible(getData);
      const handleToForm = (record: RecordTaskVo) => {
        let url: RouteLocation & {
          href: string;
        };
        if (!record.doJump) {
          url = router.resolve({
            name: 'formList',
            params: { moduleId: record.moduleId, subjectId: record.subjectId },
          });
        } else {
          const { moduleId, subjectId, subjectFormId, permiss } = record.taskJumpData;
          url = router.resolve({
            name: 'formContent',
            params: { moduleId, subjectId, subjectFormId },
            query: { type: permiss },
          });
        }
        window.open(url.href, '_blank');
      };
      const handleChange = (value) => {
        keyWord.value = value;
        getHospitalRecorders(keyWord.value).then((res) => {
          let list = JSON.parse(JSON.stringify(res.data));
          // list.map((item) => {
          //   item.jinsaiId = item.jinsaiId.includes('GS') ? item.jinsaiId.replace('GS', 'AD') : item.jinsaiId;
          // });
          opts.recordersList = list;
        });
      };
      onMounted(() => {
        addTableCtrl({
          columns: tableState.columns,
        });
      });
      return {
        ...toRefs(opts),
        query,
        tableState,
        handlePage,
        toRouter,
        selectedRows,
        transModal,
        transForm,
        handleSearch,
        handleTrans,
        handleToForm,
        handleExport,
        handleChange,
        handleReset,
      };
    },
  });
