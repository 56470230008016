import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    width: 1000,
    title: "表单操作历史",
    footer: false,
    maskClosable: false,
    keyboard: false,
    onOk: _ctx.handleDialogClose,
    onCancel: _ctx.handleDialogClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        rowKey: "id",
        columns: _ctx.columns,
        dataSource: _ctx.operatHistoryList,
        pagination: false,
        size: "small"
      }, null, 8, ["columns", "dataSource"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}