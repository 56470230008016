import { IFormPatientDTO } from '@/type/formContent';
import RegularHelper from '@/utils/regularHelper';
import { Moment } from 'moment';

export default class BirthWeightHelper {
  static preCheck(modelRef: IFormPatientDTO<Moment>): boolean {
    return RegularHelper.isUnsignedFloat.test(modelRef.birthWeight);
  }

  static hasDoubt(modelRef: IFormPatientDTO<Moment>): [boolean, string] {
    let message = '';
    let hasDoubt = false;

    if (!RegularHelper.isUnsignedFloat.test(modelRef.birthWeight)) {
      return [hasDoubt, message];
    }

    const birthWeight = +modelRef.birthWeight;

    if (birthWeight === 0) {
      hasDoubt = true;
      message = '出生体重为0';
    } else if (birthWeight > 15) {
      hasDoubt = true;
      message = '出生体重大于15';
    }

    return [hasDoubt, message];
  }
}
