import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: "删除表单",
    onOk: _ctx.handleSubmit,
    onCancel: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formState,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            ref: "deleteReason",
            label: "删除原因",
            name: "deleteReason",
            labelCol: _ctx.rowCol,
            wrapperCol: _ctx.rowCol
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                allowClear: "",
                class: "query-container_item",
                value: _ctx.formState.deleteReason,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.deleteReason) = $event)),
                placeholder: "请选择删除原因",
                "show-search": "",
                optionFilterProp: "name"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.delReason, (opt) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      name: opt.name,
                      key: opt.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }, 8, ["labelCol", "wrapperCol"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}