
  import { defineComponent, PropType } from 'vue';

  import { IBaseFormDTO, IFormDetailHistory } from '@/type/formContent';
  import { ObjectMap } from '@/type/helper';
  import { genDefaultBaseFormDTO } from '@/views/form/utils';

  import FormPreviewGroup from './components/FormPreviewGroup/index.vue';

  import { IFormPreviewGroup } from './interface';

  export default defineComponent({
    name: 'form-preview',
    components: {
      FormPreviewGroup,
    },
    props: {
      history: {
        type: Object as PropType<ObjectMap<IFormDetailHistory[]>>,
        default: () => ({}),
      },
      data: {
        type: Object as PropType<IBaseFormDTO>,
        default: () => genDefaultBaseFormDTO(),
      },
      configs: {
        type: Array as PropType<IFormPreviewGroup[]>,
        default: () => [],
      },
    },
  });
