import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    model: _ctx.formState,
    autocomplete: "off",
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        label: "辖区ID",
        name: "jobCode"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.jobCode,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.jobCode) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "辖区名称",
        name: "jobName"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.jobName,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.jobName) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "工号",
        name: "workId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.workId,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.workId) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "姓名",
        name: "name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.name,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.name) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "患者群体",
        name: "patientGroup"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.patientGroup,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.patientGroup) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "领域标签",
        name: "domainLabel"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.domainLabel,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.domainLabel) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "BG",
        name: "bg"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.bg,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.bg) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "团队",
        name: "team"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.team,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.team) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "战区",
        name: "warZoneName"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.warZoneName,
            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.warZoneName) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "区域",
        name: "regionName"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.regionName,
            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.regionName) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "大区",
        name: "regions"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.regions,
            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.regions) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "地区",
        name: "regional"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.regional,
            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formState.regional) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "省",
        name: "provinceId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            allowClear: "",
            "show-search": "",
            optionFilterProp: "name",
            class: "query-container_item",
            filterOption: _ctx.filterOption,
            value: _ctx.formState.provinceId,
            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formState.provinceId) = $event)),
            onChange: _cache[13] || (_cache[13] = (val, opt) => _ctx.handleProvince(val, opt)),
            disabled: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinceOpts, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: opt.id,
                  key: opt.id,
                  name: opt.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["filterOption", "value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "市",
        name: "cityId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            allowClear: "",
            "show-search": "",
            optionFilterProp: "name",
            class: "query-container_item",
            filterOption: _ctx.filterOption,
            value: _ctx.formState.cityId,
            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formState.cityId) = $event)),
            onChange: _cache[15] || (_cache[15] = (val, opt) => _ctx.handleCity(val, opt)),
            disabled: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityOpts, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: opt.id,
                  key: opt.id,
                  name: opt.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["filterOption", "value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "医院",
        name: "hospitalId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            allowClear: "",
            "show-search": "",
            optionFilterProp: "name",
            class: "query-container_item",
            filterOption: _ctx.filterOption,
            value: _ctx.formState.hospitalId,
            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formState.hospitalId) = $event)),
            onChange: _cache[17] || (_cache[17] = (val, opt) => _ctx.handleHospital(val, opt)),
            disabled: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hospitalOpts, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: opt.id,
                  key: opt.id,
                  name: opt.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["filterOption", "value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "SAP机构编码",
        name: "sapMechanismCode"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.sapMechanismCode,
            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formState.sapMechanismCode) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "购药日期",
        name: "buyTime"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.buyTime,
            "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.formState.buyTime) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "三级品种名称",
        name: "category"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            allowClear: "",
            "show-search": "",
            optionFilterProp: "name",
            class: "query-container_item",
            filterOption: _ctx.filterOption,
            value: _ctx.formState.category,
            "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.formState.category) = $event)),
            disabled: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryOpts, (opt) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: opt.id,
                  key: opt.id,
                  name: opt.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(opt.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["filterOption", "value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "品规简称",
        name: "specId",
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.specName,
            "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.formState.specName) = $event)),
            disabled: ""
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "纯销数(支)",
        name: "drugPurchasedNumber"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_number, {
            style: {"width":"178px"},
            value: _ctx.formState.drugPurchasedNumber,
            "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.formState.drugPurchasedNumber) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "备注",
        name: "remark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.formState.remark,
            "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.formState.remark) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}