import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, _mergeProps({
    allowClear: "",
    "show-search": "",
    value: _ctx.value,
    options: _ctx.opts,
    "filter-option": false,
    placeholder: _ctx.placeholder,
    "not-found-content": _ctx.nameLoading ? undefined : null,
    disabled: _ctx.disabled
  }, _ctx.bindProps, {
    onSearch: _ctx.handleSearch,
    onChange: _ctx.handleChange,
    onDropdownVisibleChange: _ctx.handleDropDownVisible
  }), _createSlots({ _: 2 }, [
    (_ctx.nameLoading)
      ? {
          name: "notFoundContent",
          fn: _withCtx(() => [
            _createVNode(_component_a_spin, { size: "small" })
          ])
        }
      : undefined
  ]), 1040, ["value", "options", "placeholder", "not-found-content", "disabled", "onSearch", "onChange", "onDropdownVisibleChange"]))
}