import { DepartmentLabelType, DomainLabelType, HeightPType, IFormTreatmentDTO } from '@/type/formTreatmentDTO';
import { convert2MomentForm, deserializationMomentForm, genDefaultBaseFormDTO } from '@/views/form/utils';
import moment from 'moment';

export const convertTreatmentForm = (form: IFormTreatmentDTO<string>): IFormTreatmentDTO<moment.Moment> => {
  return convert2MomentForm(form, ['visitDate']);
};

export const deserializationTreatmentForm = (form: IFormTreatmentDTO<moment.Moment>): IFormTreatmentDTO<string> => {
  return deserializationMomentForm(form, [{ field: 'visitDate' }]);
};

export const genDefaultFormTreatmentDTO = (): IFormTreatmentDTO<moment.Moment> => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '首诊信息',

  /* 首诊日期 */
  visitDate: null,

  /* 患者群体ID */
  indicationGroupId: '',

  /* 患者群体 */
  indicationGroupName: '',

  /* 适应症ID */
  indicationId: '',

  /* 适应症 */
  indicationName: '',

  /** 适应症详细诊断描述 */
  indicationDesc: '',

  /* 适应症备注,-适应症：选择其他（儿童）、其他（成人）后，需要手动录入备注内容 */
  indicationRemark: '',

  /* 领域标签 */
  domainLabel: '',

  /* 科室标签 */
  departmentLabel: '',

  /* 科室标签的其他请描述 */
  departmentsOtherRemark: '',

  /* 首诊剂量 */
  treatmentDose: '',

  /* 首诊省份ID */
  visitedProvinceId: '',

  /* 首诊省份 */
  visitedProvinceName: '',

  /* 首诊城市ID */
  visitedCityId: '',

  /* 首诊城市 */
  visitedCityName: '',

  /* 首诊医院ID */
  visitedHospitalId: '',

  /* 首诊医院 */
  visitedHospitalName: '',

  /* 首诊医生ID */
  visitedDoctorId: '',

  /* 首诊医生 */
  visitedDoctorName: '',

  /* 接待人ID */
  receptionistId: '',

  /* 接待人 */
  receptionist: '',

  /* 身高管理师ID */
  heightManagerId: '',

  /* 身高管理师 */
  heightManager: '',

  /* 身高P值 */
  heightPValue: '',

  /* 性发育进程 */
  sexualDevelopmentProcess: '',

  /* 首诊身高(CM) */
  visitHeight: '',

  /* 首诊体重(KG) */
  visitWeight: '',

  /* 客服备注 */
  customerRemark: '',

  /* 备注 */
  remark: '',
});

export const domainLabels: string[] = [
  DomainLabelType.CLASSIC_AGHD,
  DomainLabelType.ANDROLOGY,
  DomainLabelType.ANTIFADING,
  // DomainLabelType.OTHER,
];

export const departmentLabelsForChild: string[] = [
  // DepartmentLabelType.CHILDNEPHROLOGYINNER,
  DepartmentLabelType.CHILDNEPHROLOGY,
  // DepartmentLabelType.CHILDNEPHROLOGYINNERABBR,
  // DepartmentLabelType.NEPHROLOGYINNER,
  // DepartmentLabelType.NEPHROLOGY,
  DepartmentLabelType.RHEUMATISMIMMUNITY,
  // DepartmentLabelType.RHEUMATISM,
  // DepartmentLabelType.IMMUNITY,
];
export const departmentLabelsForAdult: string[] = [
  DepartmentLabelType.ENDOCRINE,
  DepartmentLabelType.REHABILITATION,
  DepartmentLabelType.NEUROSURGERY,
  DepartmentLabelType.SEVERE_NICU,
  DepartmentLabelType.ANDROLOGY,
  DepartmentLabelType.GYNAECOLOGY,
  DepartmentLabelType.ANTIFADING,
  DepartmentLabelType.INFECTIOUS_DISEASE,
  DepartmentLabelType.GERIATRICS,
  DepartmentLabelType.GASTROENTEROLOGY,
  DepartmentLabelType.INTERNALNEUROSURGERY,
  DepartmentLabelType.UROLOGY,
  DepartmentLabelType.CHINESEMEDICINEANDROLOGY,
  DepartmentLabelType.REPRODUCTIVEANDROLOGY,
  DepartmentLabelType.SLEEP,
  DepartmentLabelType.CHINESEMEDICINE,
  DepartmentLabelType.PLASTICSURGERY,
  DepartmentLabelType.GENERALPRACTICE,
  DepartmentLabelType.OTHERS,
];

export const heightPs: string[] = [
  HeightPType.LESS_EQUAL_P3,
  HeightPType.FROM_P3_TO_P10,
  HeightPType.FROM_P10_TO_P25,
  HeightPType.FROM_P25_TO_P50,
  HeightPType.MORE_EQUAL_P50,
];
