// 对于ie，谷歌，360：
// 页面加载时只执行onload
// 页面刷新时，刷新之前执行onbeforeunload事件，在新页面即将替换旧页面时onunload事件，最后onload事件。
// 页面关闭时，先onbeforeunload事件，再onunload事件。
// 对于火狐：
// 页面刷新时，只执行onunload；页面关闭时，只执行onbeforeunload事件
// 刷新的时候先onbeforeunload，然后取服务端请求数据，在新页面即将替换旧页面时onunload事件，而页面关闭时，先onbeforeunload事件，再立即onunload事件。那么在刷新的时候，onbeforeunload与onunload之间的时间肯定比关闭的时候时间长，经过测试确实如此。

/**
 * 利用 beforeunload、unload 两个时间差判断当前是关闭还是刷新
 * @param closecb // 关闭事件
 * @param reloadCb // 刷新事件
 */
const useWindowCloseOrReload = (closecb: () => void, reloadCb: () => void): void => {
  let _beforeUnload_time = 0,
    _gap_time = 0; //_gap_time时间差
  const is_fireFox = navigator.userAgent.indexOf('Firefox') > -1; //是否是火狐浏览器
  window.addEventListener('unload', () => {
    _gap_time = new Date().getTime() - _beforeUnload_time;
    if (_gap_time <= 30) {
      // 关闭
      closecb();
    } else {
      // 刷新
      reloadCb();
    }
  });
  window.addEventListener('beforeunload', () => {
    _beforeUnload_time = new Date().getTime();
    if (is_fireFox) {
      //火狐关闭执行
      closecb();
    }
  });
};

export default useWindowCloseOrReload;
