/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IDropdownItem } from '@/type/common';
import { ICaseData } from '@/type/form';
import {
  getCasesByFormId,
  getCasesByInjectedStatus,
  getCasesByPreviewMode,
  getCasesByRecordUserId,
} from '../CaseCascaderForm/utils';
import { getLastUpdateCase } from '../CaseContainer/utils';
import { CasePreviewMode } from '../CasePreview/interface';

export const previewModes: IDropdownItem<CasePreviewMode>[] = [
  { label: '全部', value: CasePreviewMode.ALL },
  { label: '图片', value: CasePreviewMode.IMAGE },
];

export const getCasesByParams = (
  cases: ICaseData[],
  recordUserId: string,
  formId: string,
  mode: CasePreviewMode,
  rejected: boolean,
): ICaseData | null => {
  /**
   * Step 01: 根据当前选择的预判模式及打回状态筛选对应「激活病例」
   * 若不存在对应病例, 则将当前病例置为null
   */
  const activeCases = getCasesByInjectedStatus(getCasesByPreviewMode(cases, mode), rejected);

  if (!activeCases.length) {
    return null;
  }

  /**
   * Step 02: 在「激活病例」中筛选当前录入员是否存在对应病例, 得到「录入员病例」
   * 若当录入员为空或不存在对应病例, 则设置「激活病例」中最新一条记录作为当前病例
   */
  const recordUserCases = getCasesByRecordUserId(activeCases, recordUserId);
  if (!recordUserId || !recordUserCases.length) {
    return getLastUpdateCase(activeCases)!;
  }

  /**
   * Step 03: 在「录入员病例」中筛选当前表单类型是否存在对应病例, 得到「表单类型病例」
   * 若当前表单类型为空或不存在对应病例, 则设置「录入员病例」中最新一条记录作为当前病例
   */
  const formCases = getCasesByFormId(recordUserCases, formId);
  if (!formId || !formCases.length) {
    return getLastUpdateCase(recordUserCases)!;
  }

  /**
   * Step 04: 设置「表单类型病例」中最新一条记录作为当前病例
   */
  return getLastUpdateCase(formCases)!;
};
