
  import { computed, defineComponent, ref, toRefs } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { setRouter } from './hooks';
  // import { getJumpUrl } from '@/services/yuemi-delete';
  import SVG from '../../assets/img/svg';
  import Avatar from '@/components/avatar';
  import { state } from '@/lib/state';
  import { menuGroupList } from '@/lib/menu';
  import { logout, setPwd } from '@/services/usercenter';
  import {
    CaretDownOutlined,
    DeleteOutlined,
    CheckSquareOutlined,
    TeamOutlined,
    SolutionOutlined,
    DownloadOutlined,
    QuestionCircleOutlined,
  } from '@ant-design/icons-vue';
  import { goJinSaiOA } from '@/components/Login/useLogin';
  import { message } from 'ant-design-vue';
  export default defineComponent({
    components: {
      EdcIcLogo: SVG.edcIcLogo,
      // CloudDownloadOutlined,
      CaretDownOutlined,
      CheckSquareOutlined,
      TeamOutlined,
      SolutionOutlined,
      DownloadOutlined,
      QuestionCircleOutlined,
      DeleteOutlined,
      // CloseOutlined,
      // TransCard,
      Avatar,
    },
    setup() {
      const toRouter = setRouter();
      const route = useRoute();
      const router = useRouter();
      const { userInfo } = toRefs(state);
      const status = ref(false);
      const editModal = ref(false);
      const formLoading = ref(false);

      const formRef = ref();
      const passwordForm: any = ref({});
      const selectedKeys = computed(() => {
        return [route.meta?.menuName ? route.meta.menuName : route.name];
      });
      const openedKeys = computed(() => {
        return [route.meta?.subName];
      });
      const handleClick = (e: { key: string; keyPath: string[] }) => {
        console.log(e.keyPath[0]);
        toRouter(e.key);
        if (e.keyPath[0] == 'problemFeedback') {
          var iframe = document.getElementById('iframe');
          let user: any = state.userInfo;
          if (user.prWorkNo) {
            iframe?.setAttribute(
              'src',
              `https://quality.dgtmeta.com/home/MyQualityProblem?userId=${user.prWorkNo}&appId=QC001&appType=WEB`,
            );
          }
          status.value = true;
        } else {
          status.value = false;
        }
      };
      const handleLogout = () => {
        logout().then((data) => {
          if (data) {
            if (data.redirectLogoutUri) {
              state.userInfo = null;
              state.jinsaiOAUri = data.redirectLogoutUri;
              // goJinSaiOA(false);
              router.push('/login');
            } else {
              message.error('登出失败');
            }
          }
        });
      };
      const openEditModal = () => {
        editModal.value = true;
      };
      const handleOk = () => {
        formLoading.value = true;
        if (formRef.value) {
          formRef.value
            .validate()
            .then(() => {
              setPwd(passwordForm.value).then((res: any) => {
                console.log(res);
                formLoading.value = false;
                if (res) {
                  message.success('您已修改成功');
                  handleClose();
                  handleLogout();
                }
              });
            })
            .catch(() => {
              formLoading.value = false;
            });
        }
      };
      const handleClose = () => {
        formRef.value.resetFields();
        editModal.value = false;
      };
      const VerifyNewPassword = async (rule, value) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,}$/;
        if (!value) {
          return Promise.reject('请输入新密码');
        }
        if (!passwordRegex.test(value)) {
          return Promise.reject('请输入大小写字母+数字');
        }
        if (value.length > 20) {
          return Promise.reject('新密码长度不能超过20');
        }
      };
      const VerifyConfirmPassword = async (rule, value) => {
        if (!value) {
          return Promise.reject('请确认新密码');
        }
        if (value != passwordForm.value.newPwd) {
          return Promise.reject('两次输入的密码不一致');
        }
      };
      const rules = {
        oldPwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPwd: [{ required: true, validator: VerifyNewPassword, trigger: 'blur' }],
        reNewPwd: [{ required: true, validator: VerifyConfirmPassword, trigger: 'blur' }],
      };
      // const handleToConfig = () => {
      //   const url = router.resolve({ name: 'accountInfo' });
      //   window.open(url.href, '_blank');
      // };
      /**
       * 使用金赛 OA登录，金米云相关内容先隐藏
       */
      // const linkToYuemi = () => {
      //   getJumpUrl().then((res: string) => {
      //     window.open(res, '_blank');
      //   });
      // };
      const setStatus = () => {
        status.value = false;
        console.log(state.userInfo);
      };
      const goSaleSys = () => {
        window.open('https://wb.genscigroup.com:4431');
      };
      window.onload = function () {
        if (document.getElementById('iframe')) {
          console.log(document.getElementById('iframe'));
        }
      };
      return {
        userInfo,
        openedKeys,
        selectedKeys,
        menuGroupList,
        handleClick,
        toRouter,
        handleLogout,
        openEditModal,
        // handleToConfig,
        // linkToYuemi,
        goSaleSys,
        status,
        setStatus,
        editModal,
        formRef,
        rules,
        passwordForm,
        handleOk,
        formLoading,
        handleClose,
      };
    },
  });
