import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57fcfe2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_select, {
      value: _ctx.params.searchType,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.searchType) = $event)),
      placeholder: "请选择",
      onChange: _ctx.handleSearchType
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: opt.id,
            value: _ctx.params.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tooltip, {
                title: opt.name
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(opt.name), 1)
                ]),
                _: 2
              }, 1032, ["title"])
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChange"]),
    _createVNode(_component_a_input, {
      allowClear: "",
      onPressEnter: _ctx.handleEnterdown,
      onChange: _ctx.handleChange,
      value: _ctx.params.searchText,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.searchText) = $event)),
      placeholder: _ctx.inputPlaceholder
    }, null, 8, ["onPressEnter", "onChange", "value", "placeholder"])
  ]))
}