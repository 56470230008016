import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';
import { NONE_DOUBT_TUPLE } from '../../../utils';
import { IDoubtTuple } from '../../../interface';
import moment, { Moment } from 'moment';
/**
 * 购药时间提示
 */
// dictionaryList： 零氪-预警规则参数服务端可配置
export default class DrugWithdrawalNumberHelper {
  static hasDoubt(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    dictionaryList: any,
  ): IDoubtTuple {
    // 录入日期(用药信息创建时间)-购药日期＞90天，提示语： 录入日期-购药日期超过90天
    // 购药日期-派单日期（用药信息创建时间）＞0，提示语： 购药日期-派单日期＞0
    // 录入日期
    const createDate = moment(modelRef.createDate).format('YYYY-MM-DD');
    // 购药日期
    const buyMedicineDate = modelRef.buyMedicineDate as Moment;
    const diff = moment(createDate).diff(buyMedicineDate, 'days');
    if (diff > dictionaryList.value.diffMedicineDate) {
      return [true, `录入日期-购药日期＞${dictionaryList.value.diffMedicineDate}天`];
    }
    if (diff < dictionaryList.value.diffBuyMedicineDate) {
      return [true, `购药日期-派单日期＞${dictionaryList.value.diffBuyMedicineDate}`];
    }
    return [false, ''];
  }
}


// export default class DrugWithdrawalNumberHelper {
//   static hasDoubt(
//     modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
//     dictionaryList: any,
//   ): IDoubtTuple {
//     // 录入日期(用药信息创建时间)-购药日期＞90天，提示语： 录入日期-购药日期超过90天
//     // 购药日期-派单日期（用药信息创建时间）＞0，提示语： 购药日期-派单日期＞0
//     // 录入日期
//     const createDate = moment(modelRef.createDate).format('YYYY-MM-DD');
//     // 购药日期
//     const buyMedicineDate = modelRef.buyMedicineDate as Moment;
//     const diff = moment(buyMedicineDate).diff(createDate, 'days');
//     // console.log(diff, 'diff===')
//     console.log(createDate, '录入日期createDate==派单日期', diff, 'diff===')
//     if (diff > dictionaryList.value.diffMedicineDate) {
//       return [true, `录入日期-购药日期＞${dictionaryList.value.diffMedicineDate}天`];
//     }
//     if (diff < dictionaryList.value.diffBuyMedicineDate) {
//       return [true, `购药日期-派单日期＞${dictionaryList.value.diffBuyMedicineDate}`];
//     }
//     return [false, ''];
//   }
// }