
  import { IBaseFormDTO, IFormDetailHistory } from '@/type/formContent';
  import { ColumnProps } from 'ant-design-vue/lib/table/interface';
  import { get } from 'lodash';
  import moment from 'moment';
  import { computed, defineComponent, PropType } from 'vue';
  import { IFormPreviewConfig } from '../FormPreview/interface';

  export default defineComponent({
    name: 'form-item-history-dialog',
    emits: ['update:visible', 'update:histories', 'update:config'],
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      histories: {
        type: Array as PropType<IFormDetailHistory[]>,
        default: () => [],
      },
      config: {
        type: Object as PropType<IFormPreviewConfig<IBaseFormDTO>>,
        default: null,
      },
    },
    setup(props, context) {
      const modalTitle = computed(() => {
        const label = get(props.config, 'label') || '';

        return label ? `变量历史: ${label}` : '变量历史';
      });

      const columns: ColumnProps[] = [
        { title: '#', dataIndex: 'idx', slots: { customRender: 'idx' } },
        { title: '字段值', dataIndex: 'value' },
        { title: '修改原因', dataIndex: 'modifyReason' },
        { title: '操作人', dataIndex: 'operator' },
        {
          title: '修改时间',
          dataIndex: 'modifyDate',
          width: 180,
          customRender: ({ text }) => {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          },
        },
      ];

      const handleDialogClose = () => {
        context.emit('update:visible', false);

        setTimeout(() => {
          context.emit('update:histories', []);
          context.emit('update:config', null);
        }, 300);
      };

      return {
        modalTitle,
        columns,
        handleDialogClose,
      };
    },
  });
