import { IFormPatientDTO } from '@/type/formContent';
import { Moment } from 'moment';
import { computed, ComputedRef, Ref, inject, ref  } from 'vue';
import { IFormDoubt } from '../../interface';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import { EMPTY_DOUBT } from '../../utils';
import BirthdayHelper from  './itemHelpers/birthdayHelper';
import BirthHeightHelper from './itemHelpers/birthHeightHelper';
import BirthWeightHelper from './itemHelpers/birthWeightHelper';
import ParentHeightHelper from './itemHelpers/parentHeightHelper';

export const genBirthdayDoubt = (modelRef: IFormPatientDTO<Moment>,  fisrtDetail: Ref<IFormMedicationDTO<moment.Moment, string[]> | null>, earlyWarningRule: Ref<any> ): ComputedRef<IFormDoubt> => { 
    return computed<IFormDoubt>(() => {
        const [hasDoubt, message] = BirthdayHelper.hasDoubt(modelRef, fisrtDetail.value, earlyWarningRule.value);
        return hasDoubt
          ? {
              class: 'form-item-warning',
              help: message,
            }
          : EMPTY_DOUBT;
      });
}

export const genBirthHeightDoubt = (modelRef: IFormPatientDTO<Moment>): ComputedRef<IFormDoubt> => {
  return computed<IFormDoubt>(() => {
    const [hasDoubt, message] = BirthHeightHelper.hasDoubt(modelRef);

    return hasDoubt
      ? {
          class: 'form-item-warning',
          help: message,
        }
      : EMPTY_DOUBT;
  });
};

export const genBirthWeightDoubt = (modelRef: IFormPatientDTO<Moment>): ComputedRef<IFormDoubt> => {
  return computed<IFormDoubt>(() => {
    const [hasDoubt, message] = BirthWeightHelper.hasDoubt(modelRef);

    return hasDoubt
      ? {
          class: 'form-item-warning',
          help: message,
        }
      : EMPTY_DOUBT;
  });
};

export const genParentHeightDoubt = (modelRef: IFormPatientDTO<Moment>, key: string, earlyWarningRule: Ref<any> ): ComputedRef<IFormDoubt> => {
  return computed<IFormDoubt>(() => {
    const [hasDoubt, message] = ParentHeightHelper.hasDoubt(modelRef, key, earlyWarningRule.value);

    return hasDoubt
      ? {
          class: 'form-item-warning',
          help: message,
        }
      : EMPTY_DOUBT;
  });
};
