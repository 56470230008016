
  import { defineComponent, ref } from 'vue';
  import { SearchOutlined } from '@ant-design/icons-vue';
  export default defineComponent({
    name: 'searchInput',
    components: { SearchOutlined },
    props: {
      value: {
        required: true,
        type: [String, Number],
      },
      placeholder: {
        type: [String],
      },
    },
    setup(props, { emit }) {
      const val = ref(props.value);
      const handleSearch = () => {
        emit('search', val.value);
      };
      const handleInput = (inputEvent: InputEvent) => {
        emit('update:value', val.value);

        console.log(inputEvent);
      };
      const handleEnterdown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          emit('search', val.value);
          emit('enter', val.value);
        }
      };
      return {
        handleSearch,
        handleInput,
        val,
        handleEnterdown,
      };
    },
  });
