
  import { defineComponent, PropType } from 'vue';
  import { ColumnProps } from 'ant-design-vue/lib/table/interface';
  import moment from 'moment';

  import { IFormOperatHistoryItem } from '@/type/form';

  import { FormOperatHostoryStatusTransMap } from './utils';
  import { get } from 'lodash';

  export default defineComponent({
    name: 'form-operat-history-dialog',
    props: {
      visible: {
        type: Boolean,
      },
      operatHistoryList: {
        type: Array as PropType<IFormOperatHistoryItem[]>,
        default: () => [],
      },
    },
    setup(props, context) {
      const handleDialogClose = () => {
        context.emit('update:visible', false);
      };

      const columns: ColumnProps[] = [
        {
          title: '操作',
          dataIndex: 'status',
          customRender: ({ record }) => FormOperatHostoryStatusTransMap[get(record, 'status')],
        },
        { title: '操作人', dataIndex: 'creatorSnapShot.realName' },
        // { title: '登录账号', dataIndex: 'creatorSnapShot.mobile' },
        { title: '角色', dataIndex: 'roleName' },
        {
          title: '操作时间',
          dataIndex: 'updatedAt',
          customRender: ({ record }) => {
            const operateDate = get(record, 'operateDate');
            return operateDate ? moment(operateDate).format('YYYY-MM-DD HH:mm:ss') : '-';
          },
        },
      ];

      return {
        handleDialogClose,
        columns,
      };
    },
  });
