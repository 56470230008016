import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import moment, { Moment } from 'moment';

/**
 * 处方有效期工具类
 */
export default class PrescriptionValidityHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.prescriptionValidity && !!modelRef.buyMedicineDate;
  }

  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return (
      PrescriptionValidityHelper.preCheck(modelRef) &&
      (modelRef.buyMedicineDate as Moment).diff(moment(modelRef.prescriptionValidity?.format('YYYY-MM-DD')), 'days') > 0
    );
  }

  static getDoubtMessage(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    if (!PrescriptionValidityHelper.preCheck(modelRef)) {
      return '';
    }
    return '购药时间超过处方有效期';
  }
}
