import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO, InjectionUnitType } from '@/type/formMedicationDTO';

export default class InjectionUnitHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.specificationsName && !!modelRef.specificationsId;
  }

  static getDefaultValue(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    if (!InjectionUnitHelper.preCheck(modelRef)) {
      return '';
    }

    const specificationsName = modelRef.specificationsName || '';

    return specificationsName.includes('54IU') ? InjectionUnitType.MG_PER_WEEK : InjectionUnitType.IU_PER_DAY;
  }
}
