import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    width: 800,
    title: _ctx.modalTitle,
    footer: false,
    maskClosable: false,
    keyboard: false,
    onOk: _ctx.handleDialogClose,
    onCancel: _ctx.handleDialogClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        rowKey: "id",
        columns: _ctx.columns,
        dataSource: _ctx.histories,
        pagination: false,
        size: "small"
      }, {
        idx: _withCtx(({ index }) => [
          _createTextVNode(_toDisplayString(index + 1), 1)
        ]),
        _: 1
      }, 8, ["columns", "dataSource"])
    ]),
    _: 1
  }, 8, ["visible", "title", "onOk", "onCancel"]))
}