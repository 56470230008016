import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    class: "treatment-form",
    model: _ctx.modelRef,
    rules: _ctx.rules,
    autocomplete: "off",
    labelCol: { style: { width: '150px' } }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        label: "首诊日期",
        name: "visitDate",
        id: "visitDate"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_date_picker, {
            value: _ctx.modelRef.visitDate,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelRef.visitDate) = $event)),
            "get-calendar-container": _ctx.handlePopupContainer
          }, null, 8, ["value", "get-calendar-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "患者群体",
        name: "indicationGroupId",
        id: "indicationGroupId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.indicationGroupModel,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.indicationGroupModel) = $event)),
            placeholder: "请选择患者群体",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer,
            onChange: _cache[2] || (_cache[2] = (val) => _ctx.handleIndicationGroupChange(val))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indicationGroups, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      (_ctx.showAGHDRelated || _ctx.showCGHDRelated || _ctx.showFKHDRelated)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 0,
            label: "领域标签",
            name: "domainLabel",
            id: "domainLabel"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.modelRef.domainLabel,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelRef.domainLabel) = $event)),
                placeholder: "请选择领域标签",
                "show-search": "",
                "filter-option": _ctx.handleFilterOptionCaseInsensitive,
                "get-popup-container": _ctx.handlePopupContainer,
                onChange: _ctx.handleDomainLabelChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.domainLabels, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.name,
                      title: item.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filter-option", "get-popup-container", "onChange"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showAGHDRelated || _ctx.showDepartmentLabel)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 1,
            label: "科室标签",
            name: "departmentLabel",
            id: "departmentLabel"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_radio_group, {
                value: _ctx.modelRef.departmentLabel,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelRef.departmentLabel) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departmentLabels, (item) => {
                    return (_openBlock(), _createBlock(_component_a_radio, {
                      key: item,
                      value: item
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showDepartmentsOtherRemark)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 2,
            label: "其他，请描述",
            name: "departmentsOtherRemark",
            id: "departmentsOtherRemark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.departmentsOtherRemark,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelRef.departmentsOtherRemark) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form_item, {
        label: "适应症",
        name: "indicationId",
        id: "indicationId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.indicationModel,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.indicationModel) = $event)),
            placeholder: "请选择适应症",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer,
            onChange: _ctx.handleIndicationChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indications, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "适应症详细诊断描述",
        name: "indicationDesc",
        id: "indicationDesc"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.indicationDesc,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelRef.indicationDesc) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      (_ctx.showIndicationRemark)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 3,
            label: "其他，请描述",
            name: "indicationRemark",
            id: "indicationRemark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.indicationRemark,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modelRef.indicationRemark) = $event)),
                valueModifiers: { trim: true }
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form_item, {
        label: "首诊剂量",
        name: "treatmentDose",
        id: "treatmentDose"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.treatmentDose,
            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelRef.treatmentDose) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "首诊省份",
        name: "visitedProvinceId",
        id: "visitedProvinceId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.visitedProvinceModel,
            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.visitedProvinceModel) = $event)),
            placeholder: "请选择首诊省份",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer,
            onChange: _cache[11] || (_cache[11] = (val) => _ctx.handleProvinceChange(val))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitedProvinces, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "首诊城市",
        name: "visitedCityId",
        id: "visitedCityId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.visitedCityModel,
            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.visitedCityModel) = $event)),
            placeholder: "请选择首诊城市",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer,
            onChange: _cache[13] || (_cache[13] = (val) => _ctx.handleCityChange(val))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitedCities, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "首诊医院",
        name: "visitedHospitalId",
        id: "visitedHospitalId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.visitedHospitalModel,
            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.visitedHospitalModel) = $event)),
            placeholder: "请选择首诊医院",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer,
            onChange: _cache[15] || (_cache[15] = (val) => _ctx.handleHospitalChange(val))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitedHospitals, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "首诊KH",
        name: "visitedDoctorId",
        id: "visitedDoctorId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.visitedDoctorModel,
            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.visitedDoctorModel) = $event)),
            placeholder: "请选择首诊KH",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitedDoctors, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "接待人",
        name: "receptionistId",
        id: "receptionistId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.receptionistModel,
            "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.receptionistModel) = $event)),
            placeholder: "请选择接待人",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.odyUsers, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "身高管理师",
        name: "heightManagerId",
        id: "heightManagerId"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            value: _ctx.heightManagerModel,
            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.heightManagerModel) = $event)),
            placeholder: "请选择身高管理师",
            "label-in-value": "",
            "show-search": "",
            "filter-option": _ctx.handleFilterOptionCaseInsensitive,
            "get-popup-container": _ctx.handlePopupContainer
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.odyUsers, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.jinsaiId,
                  title: item.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "filter-option", "get-popup-container"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "身高P值",
        name: "heightPValue",
        id: "heightPValue"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio_group, {
            value: _ctx.modelRef.heightPValue,
            "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.modelRef.heightPValue) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.heightPs, (item) => {
                return (_openBlock(), _createBlock(_component_a_radio, {
                  key: item,
                  value: item
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "性发育进程",
        name: "sexualDevelopmentProcess",
        id: "sexualDevelopmentProcess"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.sexualDevelopmentProcess,
            "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.modelRef.sexualDevelopmentProcess) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, _mergeProps({
        label: "确诊身高(CM)",
        name: "visitHeight",
        id: "visitHeight"
      }, _ctx.visitHeightDoubt), {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.visitHeight,
            "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.modelRef.visitHeight) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 16),
      _createVNode(_component_a_form_item, _mergeProps({
        label: "确诊体重(KG)",
        name: "visitWeight",
        id: "visitWeight"
      }, _ctx.visitWeightDoubt), {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.visitWeight,
            "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.modelRef.visitWeight) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 16),
      (_ctx.modelRef.customerRemark)
        ? (_openBlock(), _createBlock(_component_a_form_item, {
            key: 4,
            label: "客服备注",
            name: "customerRemark",
            id: "customerRemark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.modelRef.customerRemark,
                "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.modelRef.customerRemark) = $event)),
                valueModifiers: { trim: true },
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form_item, {
        label: "备注",
        name: "remark",
        id: "remark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: _ctx.modelRef.remark,
            "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.modelRef.remark) = $event)),
            valueModifiers: { trim: true }
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}