import { IFormPatientDTO } from '@/type/formContent';
import RegularHelper from '@/utils/regularHelper';
import { Moment } from 'moment';

export default class ParentHeightHelper {
  // static preCheck(modelRef: IFormPatientDTO<Moment>): boolean {
  //   return RegularHelper.isUnsignedFloat.test(modelRef.birthWeight);
  // }

  static hasDoubt(modelRef: IFormPatientDTO<Moment>, key: string, earlyWarningRule: any): [boolean, string] {
    let message = '';
    let hasDoubt = false;
    const { minFatherHeight, maxFatherHeight, minMotherHeight, maxMotherHeight, diffHeight } = earlyWarningRule || {};
    console.log('-----minFatherHeight99342940238-----',earlyWarningRule, minFatherHeight, maxFatherHeight, minMotherHeight, maxMotherHeight, diffHeight)

    if (!RegularHelper.isUnsignedFloat.test(modelRef[key])) {
      return [hasDoubt, message];
    }
    const fatherHeight = +modelRef.fatherHeight;
    const motherHeight = +modelRef.motherHeight;
    let diffMsg = ''
    switch (key) {
      case 'fatherHeight':
        if (fatherHeight < minFatherHeight) {
          hasDoubt = true;
          message = `父亲身高＜${minFatherHeight}cm`;
        }
        // if (fatherHeight > maxFatherHeight) {
        //   hasDoubt = true;
        //   message = `父亲身高>${maxFatherHeight}cm`;
        // }
        if (fatherHeight <= 200) {
          if (fatherHeight > maxFatherHeight) {
            hasDoubt = true;
            message = `父亲身高>${maxFatherHeight}cm`;
          }
        }
        break;
      case 'motherHeight':
        //父亲身高-母亲身高＜-10cm
        // if (fatherHeight - motherHeight < diffHeight ) {
        //     hasDoubt=true
        //     diffMsg = `父亲身高-母亲身高＜${diffHeight}cm`
        //     message = `父亲身高-母亲身高＜${diffHeight}cm`
        // }
        if (motherHeight <= 200) {
          if (fatherHeight - motherHeight < diffHeight ) {
            hasDoubt = true
            diffMsg = `父亲身高-母亲身高＜${diffHeight}cm`
            message = `父亲身高-母亲身高＜${diffHeight}cm`
          }
        }
        if (motherHeight < minMotherHeight) {
          hasDoubt = true;
          message = `母亲身高＜${minMotherHeight}cm${diffMsg ? `,${diffMsg}`: ''}`;
        }
        // if (motherHeight > maxMotherHeight) {
        //   hasDoubt = true;
        //   message = `母亲身高>${maxMotherHeight}cm${diffMsg ? `,${diffMsg}`: ''}`;
        // }
        if (motherHeight <= 200) {
          if (motherHeight > maxMotherHeight) {
            hasDoubt = true;
            message = `母亲身高>${maxMotherHeight}cm${diffMsg ? `,${diffMsg}`: ''}`;
          }
        }
        break;
      default:
    }
    return [hasDoubt, message];
  }
}
