import moment from 'moment';
import { Gender, IFormPatientDTO, IPatientMobileDTO } from '@/type/formContent';
import { convert2MomentForm, deserializationMomentForm, genDefaultBaseFormDTO } from '@/views/form/utils';
import { IDropdownItem } from '@/type/common';
import { get, omit, uniqueId } from 'lodash';

export const convertPatientForm = (form: IFormPatientDTO<string>): IFormPatientDTO<moment.Moment> => {
  return convert2MomentForm(form, ['birthday']);
};

export const deserializationPatientForm = (form: IFormPatientDTO<moment.Moment>): IFormPatientDTO<string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deserializationForm: any = deserializationMomentForm(form, [{ field: 'birthday' }]);

  deserializationForm.mobiles = form.mobiles.map((item) => {
    const nextItem = omit(item, ['isNew']);

    if (item.isNew) {
      nextItem.id = '';
    }

    return nextItem;
  });

  return deserializationForm;
};

export const preSerializationPatientForm = (form: IFormPatientDTO): IFormPatientDTO => {
  if (!get(form, 'mobiles') || !get(form, 'mobiles.length')) {
    form.mobiles = [genPatientMobileDTO()];
  }

  return form;
};

export const genPatientMobileDTO = (): IPatientMobileDTO => ({
  isNew: true,
  id: uniqueId('patientMobile'),
  mobile: '',
  remark: '',
  deleted: false,
});

export const genDefaultFormPatientDTO = (): IFormPatientDTO<moment.Moment> => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '患者信息',

  /* 是否存在四要素相同的其他患者，1：存在；0：不存在；其他：不存在 */
  haveSameBaseInfo: '',

  /* 患者唯一标识 */
  uuid: '',

  /* 零氪患者ID */
  lkPatientId: '',

  /* 相似患者ID */
  sameSubjectIds: [],

  /* 是否为网报老患者 */
  gensciOldPatient: '',

  /* 患者姓名 */
  name: '',

  /* 患者性别, 选项男，女 */
  sex: Gender.NONE,

  /* 出生年月,格式YYYY-MM-DD */
  birthday: null,
  /** 年龄 */
  age: '',

  /* 居住省份ID */
  residedProvinceId: '',

  /* 居住省份 */
  residedProvinceName: '',

  /* 居住城市ID */
  residedCityId: '',

  /* 居住城市 */
  residedCityName: '',

  /* 居住地址 */
  residedAddress: '',

  /* 出生身高(CM) */
  birthHeight: '',

  /* 出生体重（KG） */
  birthWeight: '',

  /* 遗传身高（CM） */
  geneticHeight: '',

  /* 家长与孩子的关系,选项父亲，母亲，祖父母，外祖父母，其他 */
  relationShip: '',

  /* 家长姓名 */
  parentName: '',

  /* 手机号 */
  mobiles: [genPatientMobileDTO()],

  /* 父亲身高(CM) */
  fatherHeight: '',

  /* 母亲身高(CM) */
  motherHeight: '',

  /* 身份证号 */
  idNumber: '',

  /* 民族ID */
  nationId: '',

  /* 民族 */
  nationName: '',

  /* 矮小家族史，是/否 */
  shortFamilyHistory: '',

  /* 是否有过敏史， 是/否/未知 */
  isHaveAllergiesHistory: '',

  /* 过敏史 */
  allergiesHistory: '',

  /* 是否有肿瘤史， 是/否 */
  isHaveCancerHistory: '',

  /* 肿瘤史 */
  cancerHistory: '',

  /* 是否使用过生长激素 , 是/否 */
  isUsedGrowthHormone: '',

  /* 使用品牌 */
  usingBrands: '',

  /* 曾经使用时间___年___月，日期格式不固定 */
  userdDate: '',

  /* 累计多少个月 */
  cumulativeMonths: '',
  /** 该患者是否只存在于机构端、默认为false;onlyInOrg：true（该患者只存在于机构端）；false（该患者存在于双系统） */
  onlyInOrg: false,
});

export const genders: IDropdownItem<Gender>[] = [
  { label: '男', value: Gender.MALE },
  { label: '女', value: Gender.FEMALE },
];

export const relationships: string[] = ['父亲', '母亲', '祖父母', '外祖父母', '其他'];
