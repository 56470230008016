
  import { defineComponent, onMounted, reactive, toRefs } from 'vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import { TableState } from '@/type/common';
  import SearchInput from '@/components/searchInput';
  import { patientImportList, patientImportHospitalList } from '@/services/patientImport';
  export default defineComponent({
    components: {
      ManageListFrame,
      CustomTable,
      SearchInput,
    },
    setup() {
      const tableContent = reactive({
        tableScrollY: 300,
        scrollLeft: 0,
        expandTableWidth: 600,
      });
      const query = reactive<any>({});
      const Opts = reactive<any>({
        /** 机构 */
        hospitalOpts: [],
        typeOpts: [
          {
            id: 0,
            name: '否',
          },
          {
            id: 1,
            name: '是',
          },
        ],
        statusOpts: [
          {
            id: 0,
            name: '否',
          },
          {
            id: 1,
            name: '是',
          },
        ],
      });
      const tableState = reactive(
        new TableState({
          dataSource: [],
          columns: [
            { title: '创建时间', dataIndex: 'createDate' },
            { title: '订单编号', dataIndex: 'orderCode' },
            { title: '机构名称', dataIndex: 'hospitalName' },
            { title: '机构编码', dataIndex: 'hospitalCode' },
            { title: '患者统一编码', dataIndex: 'patientCode' },
            { title: '是否匹配成功', dataIndex: 'isMate' },
            { title: '是否停药', dataIndex: 'isStopDrug' },
            { title: '是否审核', dataIndex: 'autarky' },
          ],
        }),
      );
      /** 设置 机构 */
      const setHospitalOpts = () => {
        patientImportHospitalList().then((res: any) => {
          console.log(res, '这是医院');
          Opts.hospitalOpts = res.data.map((v) => ({ name: v.hospitalName, id: v.hospitalCode }));
        });
      };
      const getData = () => {
        const params = {
          ...query,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        };
        if (query.createDate) {
          params.createDate = query.createDate?.valueOf();
        }
        patientImportList(params).then((data: any) => {
          if (!data) {
            return;
          }
          console.log(params, '这是参数');
          console.log(data, '这是list数据');
          tableState.dataSource = data.content.reverse();
          tableState.pagination.total = data.total;
        });
      };
      const handleSearch = () => {
        tableState.pagination.current = 1;
        tableState.pagination.pageSize = 10;
        getData();
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData();
      };
      const handleExport = () => {
        window.open('patientImport', '_blank');
      };
      const resetting = () => {
        query.createDate = undefined;
        query.hospitalCode = undefined;
        query.isMate = undefined;
        query.isStopDrug = undefined;
        query.orderCode = undefined;
        getData();
      };
      onMounted(() => {
        setHospitalOpts();
        getData();
        tableContent.tableScrollY = window.innerHeight - 200 - 58 - 40 - 48 - 50 || 300;
        tableContent.expandTableWidth = window.innerWidth - 256 - 32 - 50 - 16 - 30 || 500;
      });
      return {
        ...toRefs(Opts),
        tableState,
        query,
        tableContent,
        handleExport,
        handlePage,
        handleSearch,
        resetting,
      };
    },
  });
