import http, { requestHandler } from './http';

export function upload(fileData): Promise<boolean> {
  return http.post('/patientImport/import', fileData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
export function downLoadMoudle() {
  return http.get('/patientImport/moudleExport', {
    responseType: 'blob',
  });
}
export function downLoadPatientError(sign) {
  return http.get(`/patientImport/errorExport?sign=${sign}`, {
    responseType: 'blob',
  });
}
export function patientImportList(params) {
  return requestHandler({
    service: () => http.get('/patientImport/list', { params }),
    defaultValue: { content: [], limit: 0, page: 0, total: 0 },
    errorMessage: '获取列表失败',
  });
}

export function patientImportHospitalList() {
  return http.get(`/patientImport/hospitalList`);
}
