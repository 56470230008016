import { NamePath, ValidateOptions } from 'ant-design-vue/lib/form/interface';

/**
 * 表单模块类型
 *
 * @enum
 */
export enum FormModuleType {
  /* 患者信息 */
  PATIENT = '824077282368286720',
  /* 首诊信息 */
  TREATMENT = '833865802406252544',
  /* 实验室信息 */
  LABORATORY = '833865407273455616',
  /* 用药信息 */
  MEDICATION = '833856376156803072',
  /* 耗材信息 */
  CONSUMABLES = '833864700768112640',
  /* 电子笔信息 */
  E_PEN = '833864944802676736',
  /* 回访信息 */
  RETURN_VISIT = '833865082756276224',
  /* 停药信息 */
  MEDICA_DISCONTINUATION = '833865271957135360',
  /* 营养品信息 */
  NOURISHMENT = '833865082957135127',
  /* GH线下用药信息 */
  GHOFFLINE = '833865083748297294',
}

/**
 * 表单类型
 *
 * @enum
 */
export enum FormType {
  /* 患者信息 */
  PATIENT = '824077282603167744',
  /* 首诊信息 */
  TREATMENT = '833865830524866560',
  /* 实验室检查信息 */
  LABORATORY = '833865434240204800',
  /* 用药信息 */
  MEDICATION = '833863020718989312',
  /* 耗材信息 */
  CONSUMABLES = '833864732091490304',
  /* 电子笔信息 */
  E_PEN = '833864969532293120',
  /* 回访信息 */
  RETURN_VISIT = '833865106567340032',
  /* 停药信息 */
  MEDICA_DISCONTINUATION = '833865294228574208',
  /* 营养品信息 */
  NOURISHMENT = '833865295106593148',
  /* GH线下用药信息 */
  GHOFFLINE = '833865295106573953',
}

/**
 * 表单类型枚举翻译
 */
export const FormTypeTransDict = {
  [FormType.PATIENT]: '患者信息',
  [FormType.TREATMENT]: '首诊信息',
  [FormType.LABORATORY]: '实验室检查信息',
  [FormType.MEDICATION]: '用药信息',
  [FormType.CONSUMABLES]: '耗材信息',
  [FormType.E_PEN]: '电子笔信息',
  [FormType.RETURN_VISIT]: '回访信息',
  [FormType.MEDICA_DISCONTINUATION]: '停药信息',
  [FormType.NOURISHMENT]: '营养品信息',
  [FormType.GHOFFLINE]: '离线用药信息',
};

/**
 * 表单模块/表单类型映射字典
 *
 * 基于历史背景原因, 系统中存在表单模块和表单类型的概念
 * 其为一对一关系, 所以可以认为表单模块对应唯一的表单类型, 其为等价关系
 */
export const ModuleFormTypeMapDict = {
  [FormModuleType.PATIENT]: FormType.PATIENT,
  [FormModuleType.TREATMENT]: FormType.TREATMENT,
  [FormModuleType.LABORATORY]: FormType.LABORATORY,
  [FormModuleType.MEDICATION]: FormType.MEDICATION,
  [FormModuleType.CONSUMABLES]: FormType.CONSUMABLES,
  [FormModuleType.E_PEN]: FormType.E_PEN,
  [FormModuleType.RETURN_VISIT]: FormType.RETURN_VISIT,
  [FormModuleType.MEDICA_DISCONTINUATION]: FormType.MEDICA_DISCONTINUATION,
  [FormModuleType.NOURISHMENT]: FormType.NOURISHMENT,
  [FormModuleType.GHOFFLINE]: FormType.GHOFFLINE,
};

/**
 * 表单类型/表单模块映射字典
 *
 * 原因同上
 */
export const FormTypeModuleMapDict = {
  [FormType.PATIENT]: FormModuleType.PATIENT,
  [FormType.TREATMENT]: FormModuleType.TREATMENT,
  [FormType.LABORATORY]: FormModuleType.LABORATORY,
  [FormType.MEDICATION]: FormModuleType.MEDICATION,
  [FormType.CONSUMABLES]: FormModuleType.CONSUMABLES,
  [FormType.E_PEN]: FormModuleType.E_PEN,
  [FormType.RETURN_VISIT]: FormModuleType.RETURN_VISIT,
  [FormType.MEDICA_DISCONTINUATION]: FormModuleType.MEDICA_DISCONTINUATION,
  [FormType.NOURISHMENT]: FormModuleType.NOURISHMENT,
  [FormType.GHOFFLINE]: FormModuleType.GHOFFLINE,
};

/**
 * 模块默认状态, 用于加载默认模块
 *
 * @enum
 */
export enum DefaultFormModuleFlag {
  /* 非默认模块 */
  NOT = '0',
  /* 默认模块 */
  IS_DEFAULT = '1',
}

/**
 * 左侧模块列表
 */
export interface IFormModuleItem {
  /* 模块id */
  id: FormModuleType;
  /* 模块名称 */
  name: string;
  /* 是否为默认模块 */
  defFlag: DefaultFormModuleFlag;
}

/**
 * 循环访视表单列表查询条件
 */
export interface ICycleVisitListQueryParams {
  /* 患者Id */
  subjectId: string;
  /* 表单类型ID */
  formId: string;
  /* 仅显示已驳回记录表单 */
  onlyRejected: boolean;
  subjectFormId: string;
}

/**
 * 循环访视表单列表项
 */
export interface ICycleVisitListItemForm {
  /* 表单类型ID */
  formId: string;
  /* 表单名称 */
  name: string;
  /* 表单状态，-4：已驳回；-3：已红冲；-2：已删除；0：未填写；1：已暂存；2：已提交 */
  status: number;
  /* 表单ID */
  subjectFormId: string;
  /* 更新时间 */
  updatedAt: number;
  /* 最后一次更新时间距离现在是否大于72小时 */
  isSeventyTwoHours: boolean;
  /* 表单状态文本文案 */
  statusString: string;
  /* 退药数量（用药信息使用） */
  drugWithdrawalNumber: number;
  /* 患者ID */
  subjectId: string;
  /* 录入员用户ID */
  userId: string | null;
  /* 录入员真实姓名 */
  realName: string | null;
  /* 当前用户能否删除，true：能；false：不能 */
  canDelete: boolean;
  /* 当前用户能否编辑，true：能；false：不能 */
  canEdit: boolean;
  /* 是否赠药，true：是；false：否 */
  freeMedicine: boolean;
  /* 删除人用户姓名 */
  deleteUserName: string;
  /* 删除时间 */
  deleteDate: string;
  /* 删除原因 */
  deleteReason: string;
  /* 来源 */
  source: string;
}

/**
 * 循环访视表单列表预览信息及表头配置
 */
export interface ICycleVisitListItemPreviewData {
  /* 表单列表数据 */
  datas: string[];
  /* 标题 */
  title: string[];
}

export interface ICycleVisitListResponse {
  forms: ICycleVisitListItemForm[];
  /* 列表数据 */
  previewDatas: ICycleVisitListItemPreviewData;
  /* 模块ID */
  moduleId: string;
  /* 模块名称 */
  moduleName: string;
  /* 患者编码 */
  subjectNumber: string;
  /** 患者姓名 */
  subjectName: string;
  /** 患者年龄 */
  subjectAge: string;
  /** 患者领域标签 用最新的	*/
  indicationGroupName: string;
  /** 患者领域标签 用最新的 */
  domainLabel: string;
}

/**
 * 表单历史查询条件
 */
export interface IFormOperatHistoryQueryParams {
  /* 模块ID */
  moduleId: string;
  /* 表单ID */
  subjectFormId: string;
}

/**
 * 表单创建人快照
 */
export interface IFormCreateorSnapShot {
  /* 操作人用户ID */
  id: string;
  /* 操作人姓名 */
  realName: string;
  /* 操作人昵称 */
  nickName: string;
  /* 操作时间 */
  createTime: number;
  /* 操作人邮箱 */
  email: string;
  /* 操作人电话 */
  mobile: string;
  /* 操作人角色名称 */
  roleName: string;
  /* 操作人金赛工号 */
  jinsaiId: string;
}

/**
 * 表单操作历史记录
 */
export interface IFormOperatHistoryItem {
  id: string;
  /* 表单历史状态，-4：已驳回；-3：已红冲；-2：已删除；0：未填写；1：已暂存；2：已提交 */
  status: number;
  /* 表单类型ID */
  formId: string;
  /* 表单名称 */
  formName: string;
  /* 表单ID */
  subjectFormId: string;
  /* 患者ID */
  subjectId: string;
  /* 患者编码 */
  subjectNumber: string;
  /* 模块名称 */
  moduleName: null;
  /* 模块ID */
  moduleId: string;
  /* 角色名称 */
  roleName: string;
  /* 操作人姓名 */
  userName: string;
  /* 表单创建人快照 */
  creatorSnapShot: IFormCreateorSnapShot;
  /* 备注（驳回/删除原因） */
  content: string;
  /* 更新时间 */
  updatedAt: Date;
}

/**
 * 红点任务提醒 - 循环访视表单
 */
export interface IRedPointFormItem {
  /* 表单ID */
  subjectFormId: string;
  /* 表单类型ID */
  formId: string;
  /* 是自己审核的为true，别人审核的为false */
  isMine: boolean;
}

/**
 * 红点任务提醒 - 模块
 */
export interface IRedPointModuleItem {
  /* 模块ID */
  moduleId: string;
  /* 模块名称 */
  moduleName: string;
  /* 表单列表 */
  forms: IRedPointFormItem[];
}

/**
 * 红点任务响应结构
 */
export interface IRedPointResponse {
  modules: IRedPointModuleItem[];
}

/**
 * 原始数据类型
 */
export enum CaseMetaType {
  /* 用药修改 */
  SALES_MODIFICATION = 0,
  /* 首诊信息 */
  TREATMENT = 1,
  /* 耗材申请 */
  CONSUMABLE_APPLICATION = 2,
  /* 回访信息 */
  RETURN_VISIT = 3,
  /* 停药信息 */
  MEDICA_DISCONTINUATION = 4,
  /* 处方/病历 */
  PRESCRIPTION_AND_CASE = 5,
  /* 检查单 */
  CHECKLIST = 6,
  /* 用药上传 */
  SALES_UPLOAD = 7,
  /* 患者端上传 */
  PATIENT_UPLOAD = 8,
}
 
/**
 * 数据来源
 */
export enum ImportType {
  ODY = 'ody',
  ORG = 'org',
}

export const ImportTypeTransDict = {
  [ImportType.ODY]: '欧电云',
  [ImportType.ORG]: '机构上传',
};

/**
 * 病例元数据
 */
export interface ICaseMeta {
  /* 数据产生的日期 */
  createDate: string;
  /* 数据来源，现在只有欧电云（ody）, 机构进销存V2-第二版（2024.5月迭代）新增机构端 */
  importType: string;
  /* 患者ID */
  subjectId: string;
  /* 表单ID */
  subjectFormId: string;
  /* 表单名称 */
  formName: string;
  /* 表单类型ID */
  formId: string;
  /* 任务ID */
  taskId: number;
  /* 是否打回 */
  rejectedFlag: boolean;
  /* 原始数据类型 */
  type: CaseMetaType;
  /* 欧电任务ID */
  odyUniqueId: string;
  /* 是否打回 */
  rejected: boolean;
}

/**
 * 病例图片
 */
export interface ICaseImage {
  /* 备注 */
  remark: string;
  /* 图片所在bucket */
  bucket: string;
  /* 图片key */
  key: string;
  /* 图片描述 */
  desc: string;
  /* 分类 */
  type: string;
  /* 图片地址 */
  url: string;
}

/**
 * 病例文本内容
 */
export interface ICaseText {
  label: string;
  value: string;
}

/**
 * 任务处理状态
 *
 * @enum
 */
export enum RecordStatus {
  /* 进行中 */
  PROGRESSING = 1,
  /* 已打回 */
  REJECTED = 2,
  /* 已完成 */
  COMPLETED = 3,
}

/**
 * 任务处理状态 枚举/翻译 字典
 */
export const RecordStatusTransDict = {
  [RecordStatus.PROGRESSING]: '进行中',
  [RecordStatus.REJECTED]: '已打回',
  [RecordStatus.COMPLETED]: '已完成',
};

/**
 * 病例信息
 */
export interface ICaseData {
  /* 病历ID */
  id: number;
  /* 创建时间 */
  createdAt: string;
  /* 更新时间 */
  updatedAt: string;
  /* 病例元数据 */
  meta: ICaseMeta;
  /* 病例文本内容 */
  texts: ICaseText[];
  /* 病例图片 */
  images: ICaseImage[];
  /* 录入员用户ID */
  recordUserId: string;
  /* 录入员姓名 */
  recordUserName: string;
  /* 录入模块名称 */
  recordModule: string;
  /* 是否可以打回数据 */
  isRepulse: boolean;
  /* 任务处理状态 */
  recordStatus: RecordStatus;
  /* 任务创建时间 */
  recordCreateTime: string;
  /* 任务处理时间 */
  recordUpdateTime: string;
  /* 任务打回原因 */
  recordRejectReason: string;
}

/**
 * 打回病例请求参数
 */
export interface IRejectTaskParams {
  /* 欧电推送任务唯一ID */
  odyUniqueId: string;
  /* 驳回原因 */
  reason: string;
  /** 患者表单ID */
  subjectFormId: string;
  // 表单类型ID
  formId: string;
}

/**
 * 表单删除参数
 */
export interface IDeleteFormParams {
  /* 表单类型ID */
  formId: string;
  /* 表单ID */
  subjectFormId: string;
  /* 删除原因 */
  deleteReason: string;
}

/**
 * ant design form表单相关方法, 官方的npm包中未将以下方法的定义暴露出来
 */
export interface IFormResetFields {
  (name: NamePath): void;
}

export interface IFormClearValidate {
  (name: NamePath): void;
}

export interface IFormValidate {
  (nameList?: NamePath[], options?: ValidateOptions): void;
}

export interface IFormValidateFields {
  (nameList?: NamePath[], options?: ValidateOptions): void;
}

export interface IFormScrollToField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (name: NamePath, options: any): void;
}

export interface IFormMethod {
  validate: IFormValidate;
  validateFields: IFormValidateFields;
  scrollToField: IFormScrollToField;
  resetFields: IFormResetFields;
  clearValidate: IFormClearValidate;
}
