import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';
export default class InjectionDose {
  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): [boolean, string] {
    if (!RegularHelper.isUnsignedFloat.test(modelRef.injectionDose)) {
      return [false, ''];
    }

    const injectionDose = +modelRef.injectionDose;
    let result = false;
    let message = '';

    if (injectionDose === 0) {
      result = true;
      message = '注射剂量不能为0';
    } else if (injectionDose > 15) {
      result = true;
      message = '注射剂量大于15';
    }

    return [result, message];
  }

  static verify(val: string): [boolean, string] {
    if (RegularHelper.isUnsignedFloat.test(val)) {
      return [false, '请输入正实数'];
    }

    return [true, ''];
  }
}
