import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-712fe917"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "patient-split-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_preview = _resolveComponent("form-preview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_preview, {
      configs: _ctx.configs,
      data: _ctx.data
    }, null, 8, ["configs", "data"])
  ]))
}