
  import { CSSProperties, defineComponent, PropType, ref } from 'vue';

  export default defineComponent({
    name: 'iamge-body',
    props: {
      url: {
        type: String,
        default: '',
      },
      style: {
        type: Object as PropType<CSSProperties>,
        default: () => ({}),
      },
    },
    emits: [''],
    setup() {
      let isMouseDown = false;
      let mousePos = { x: 0, y: 0 };
      let offsetX = 0;
      let offsetY = 0;

      let transformStyle = ref<CSSProperties>({});

      const handleMouseUp = () => {
        isMouseDown = false;
      };

      const handleMouseDown = (e: MouseEvent) => {
        const event = e;
        e.preventDefault();
        let nx = event.clientX;
        let ny = event.clientY;
        mousePos.x = nx - offsetX;
        mousePos.y = ny - offsetY;
        isMouseDown = true;
      };

      const handleMouseMove = (e: MouseEvent) => {
        if (isMouseDown) {
          const event = e;
          e.preventDefault();
          let nx = event.clientX;
          let ny = event.clientY;
          let _offsetX = nx - mousePos.x;
          let _offsetY = ny - mousePos.y;
          offsetX = _offsetX;
          offsetY = _offsetY;

          transformStyle.value = {
            transform: `translate(${_offsetX}px, ${_offsetY}px)`,
          };
        }
      };

      const reset = () => {
        transformStyle.value = {};
        isMouseDown = false;
        mousePos = { x: 0, y: 0 };
        offsetX = 0;
        offsetY = 0;
      };

      return {
        handleMouseUp,
        handleMouseDown,
        handleMouseMove,
        transformStyle,
        reset,
      };
    },
  });
