/* eslint-disable @typescript-eslint/no-explicit-any */
import { Gender, IFormPatientDTO, IsBoolean } from '@/type/formContent';
import RegularHelper from '@/utils/regularHelper';

import { EffectHandlerHooks } from '../../interface';

export const calcHeight = (gender: string, fatherHeight: string, motherHeight: string): string => {
  if (
    !gender ||
    !RegularHelper.isUnsignedFloat.test(fatherHeight + '') ||
    !RegularHelper.isUnsignedFloat.test(motherHeight + '')
  ) {
    return '';
  }

  let bodyHeight: string;

  switch (gender) {
    case Gender.MALE:
      bodyHeight = +((+fatherHeight + +motherHeight + 13) / 2).toFixed(2) + ' ± 5';
      break;
    case Gender.FEMALE:
      bodyHeight = +((+fatherHeight + +motherHeight - 13) / 2).toFixed(2) + ' ± 5';
      break;
    default:
      bodyHeight = '';
  }

  return bodyHeight;
};

const EffectHandler: EffectHandlerHooks<
  IFormPatientDTO<moment.Moment>,
  'sex' | 'fatherHeight' | 'motherHeight' | 'isHaveAllergiesHistory' | 'isHaveCancerHistory' | 'isUsedGrowthHormone'
> = (modelRef) => {
  return {
    /**
     * 联动点位: 性别, 父亲身高, 母亲身高
     * 目标点位: 遗传身高
     *
     * 点位计算规则:
     * 若为男性, 遗传身高 = (父亲身高 + 母亲身高 + 13) / 2 ± 5;
     * 若为女性, 遗传身高 = (父亲身高 + 母亲身高 - 13) / 2 ± 5;
     */
    sex(nextVal: string) {
      modelRef.geneticHeight = calcHeight(nextVal, modelRef.fatherHeight, modelRef.motherHeight);
    },
    fatherHeight(nextVal: string) {
      modelRef.geneticHeight = calcHeight(modelRef.sex, nextVal, modelRef.motherHeight);
    },
    motherHeight(nextVal: string) {
      modelRef.geneticHeight = calcHeight(modelRef.sex, modelRef.fatherHeight, nextVal);
    },
    /**
     * 联动点位: 是否有过敏史
     * 目标点位: 过敏史
     *
     * 联动规则:
     * 当「是否有过敏史」选择否、未知时, 清空过敏史信息
     */
    isHaveAllergiesHistory(nextVal: string) {
      if (nextVal === IsBoolean.FALSE || nextVal === IsBoolean.UNKNOW) {
        modelRef.allergiesHistory = '';
      }
    },
    /**
     * 联动点位: 是否有肿瘤史
     * 目标点位: 肿瘤史
     *
     * 联动规则:
     * 当「是否有肿瘤史」选择否时, 清空肿瘤史信息
     */
    isHaveCancerHistory(nextVal: string) {
      if (nextVal === IsBoolean.FALSE) {
        modelRef.cancerHistory = '';
      }
    },
    /**
     * 联动点位: 是否使用过生长激素
     * 目标点位: 使用品牌, 曾经使用时间, 累计__个月
     *
     * 联动规则:
     * 当「是否使用过生长激素」选择否时, 清空使用品牌, 曾经使用时间, 累计__个月点位
     */
    isUsedGrowthHormone(nextVal: string) {
      if (nextVal === IsBoolean.FALSE) {
        modelRef.usingBrands = '';
        modelRef.userdDate = '';
        modelRef.cumulativeMonths = '';
      }
    },
  };
};

export default EffectHandler;
