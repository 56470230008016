import { MessageApi } from 'ant-design-vue/lib/message';
import { App, FunctionalComponent, Component } from 'vue';
import { SearchInputIns } from '@/components/searchInput';
import {
  Tabs,
  Pagination,
  message,
  ConfigProvider,
  Button,
  PageHeader,
  Card,
  Steps,
  Form,
  Input,
  Select,
  Alert,
  DatePicker,
  Radio,
  Row,
  Col,
  Badge,
  Popconfirm,
  Table,
  Space,
  Tag,
  Modal,
  InputNumber,
  Tree,
  Spin,
  Popover,
  Tooltip,
  Checkbox,
  Empty,
  Upload,
  Menu,
  Breadcrumb,
  Layout,
  Image,
  List,
  TreeSelect,
  TimePicker,
} from 'ant-design-vue';

export type Message = MessageApi;

interface ComponentList {
  name: string;
  component: FunctionalComponent | Component;
}
// 仅限于函数式组件
const list = [{ name: '$message', component: message }];

//fix: 日历、下拉弹框跟随滚动条滚动问题
const setDefauleWrapper = (triggerNode) => triggerNode.parentNode;
Select.props.getPopupContainer.default = setDefauleWrapper;
TreeSelect.props.getPopupContainer.default = setDefauleWrapper;
DatePicker.props.getCalendarContainer.default = setDefauleWrapper;
TimePicker.props.getPopupContainer.default = setDefauleWrapper;

const comps = [
  Tabs,
  SearchInputIns,
  ConfigProvider,
  Button,
  PageHeader,
  Breadcrumb,
  Card,
  Steps,
  Form,
  Menu,
  Pagination,
  // lkInput, //全局注册
  Input, //test
  Select,
  Alert,
  DatePicker,
  Radio,
  Row,
  Col,
  Badge,
  Popconfirm,
  Table,
  Space,
  Tag,
  Modal,
  InputNumber,
  Tree,
  Popover,
  Spin,
  Tooltip,
  Checkbox,
  Empty,
  Upload,
  Layout,
  Image,
  List,
  TreeSelect,
  TimePicker,
];

function registryComponents(app: App, list: Array<ComponentList>, comps: Array<FunctionalComponent | Component>) {
  // 注册全局函数组件, 在.vue内可通过inject(name)来调用，可通过引入类型声明来进行ts约束
  /**
   *  示例：
   *  import { Message } from 'relativePath/appConfig.ts'
   *  const message = inject('$message') as Message;
   *  messsage.info('提示信息');
   *  message.success('成功信息');
   */
  list.forEach((comp) => {
    app.provide(comp.name as string, comp.component);
  });

  comps.forEach((comp) => {
    app.use(comp as any);
  });
  // app.component('Option', Select.Option);
  app.component('Select', Select);
  // app.use(SearchInput);
}

export default function initApp(app: App): void {
  registryComponents(app, list, comps);

  app.mount('#app');
}
