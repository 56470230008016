
  import { computed, defineComponent, PropType, ref } from 'vue';
  import { get } from 'lodash';
  import moment from 'moment';

  import ImagePreview from '@/components/ImagePreview/index.vue';
  import { ICaseData, ICaseImage, ICaseText } from '@/type/form';

  import { CasePreviewMode } from './interface';
  import { IPreviewImage } from '@/components/ImagePreview/interface';

  const EMPTY_TEXT = '-';
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  export default defineComponent({
    name: 'case-preview',
    components: {
      ImagePreview,
    },
    props: {
      case: {
        type: Object as PropType<ICaseData>,
        default: null,
      },
      mode: {
        type: String as PropType<CasePreviewMode>,
        default: CasePreviewMode.ALL,
      },
      // 机构上传的数据返回true
      onlyInOrg: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const texts = computed<ICaseText[]>(() => (get(props.case, 'texts') || []).filter((item) => item.value !== ''));


      const images = computed<ICaseImage[]>(() => get(props.case, 'images') || []);
      const previewImages = computed<IPreviewImage[]>(() =>
        images.value.map((item) => ({
          id: item.key,
          url: item.url,
        })),
      );
      const createdAt = computed<string>(() => {
        const time = get(props.case, 'createdAt');
        return time ? moment(time).format(DATE_FORMAT) : EMPTY_TEXT;
      });
      const updatedAt = computed<string>(() => {
        const time = get(props.case, 'updatedAt');
        return time ? moment(time).format(DATE_FORMAT) : EMPTY_TEXT;
      });

      const isAll = computed(() => props.mode === CasePreviewMode.ALL);
      const showTexts = computed(() => isAll.value && texts.value.length > 0);
      const showImages = computed(() => images.value.length > 0);
      const showCreateAt = computed(() => createdAt.value !== EMPTY_TEXT);
      const showUpdatedAt = computed(() => updatedAt.value !== EMPTY_TEXT);
      const showTimeBlock = computed(() => isAll.value && (showCreateAt.value || showUpdatedAt.value));

      const currentImageId = ref<string>('');
      const handleImageClick = (item: ICaseImage) => {
        currentImageId.value = item.key;
        imgPreviewVisible.value = true;
      };

      const imgPreviewVisible = ref(false);
      const getContainer = () => {
        return document.querySelector('#case-container');
      };

      return {
        isAll,
        texts,
        images,
        previewImages,
        createdAt,
        updatedAt,
        showTexts,
        showImages,
        showCreateAt,
        showUpdatedAt,
        showTimeBlock,
        currentImageId,
        handleImageClick,
        imgPreviewVisible,
        getContainer,
      };
    },
  });
