
  import { defineComponent, PropType, reactive, ref, toRaw } from 'vue';
  import { cloneDeep } from 'lodash';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';

  import { IFormLaboratoryDTO } from '@/type/formLaboratoryDTO';

  import { IFormSubmitCallback, ValidateFailedCallback } from '../../interface';
  import rules from './rules';

  export default defineComponent({
    props: {
      formValue: {
        type: Object as PropType<IFormLaboratoryDTO>,
        default: null,
      },
    },
    setup(props) {
      const modelRef = reactive(cloneDeep(toRaw(props.formValue))) as IFormLaboratoryDTO;
      const formRef = ref();

      const getFormValue = () => {
        return cloneDeep(toRaw(modelRef));
      };

      const handleSubmit = async (
        cb: IFormSubmitCallback,
        validateFailedCb: ValidateFailedCallback<ValidateErrorEntity<IFormLaboratoryDTO>>,
      ) => {
        formRef.value
          .validate()
          .then(() => {
            cb(getFormValue());
          })
          .catch((err: ValidateErrorEntity<IFormLaboratoryDTO>) => {
            validateFailedCb(err);
          });
      };

      return {
        modelRef,
        formRef,
        rules,
        getFormValue,
        handleSubmit,
      };
    },
  });
