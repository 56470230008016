
  import { Form } from 'ant-design-vue';
  import { get } from 'lodash';
  import { defineComponent, PropType } from 'vue';
  import { HistoryOutlined } from '@ant-design/icons-vue';

  import { IBaseFormDTO, IFormDetailHistory } from '@/type/formContent';
  import { ObjectMap } from '@/type/helper';
  import { genDefaultBaseFormDTO } from '@/views/form/utils';

  import { IFormPreviewConfig } from '../../interface';

  const FormItem = Form.Item;

  export default defineComponent({
    name: 'form-preview-content',
    emits: ['show-history'],
    props: {
      history: {
        type: Object as PropType<ObjectMap<IFormDetailHistory[]>>,
        default: () => ({}),
      },
      data: {
        type: Object as PropType<IBaseFormDTO>,
        default: () => genDefaultBaseFormDTO(),
      },
      configs: {
        type: Array as PropType<IFormPreviewConfig<IBaseFormDTO>[]>,
        default: () => [],
      },
    },
    methods: {
      handleShowHistory(histories: IFormDetailHistory[], item: IFormPreviewConfig<IBaseFormDTO>) {
        this.$emit('show-history', histories, item);
      },
    },
    render() {
      return this.configs.map((item) => {
        const histories = get(this.history, item.fieldName) || [];

        if (!(item.isShow ? item.isShow(this.data) : true)) {
          return null;
        }

        return item.customRender ? (
          item.customRender(this.data[item.fieldName], this.data, this.history, this.handleShowHistory)
        ) : (
          <FormItem key={item.fieldName} label={item.label} class="form-preview-content-item">
            <div class="content-block">
              {[
                <div class="value-block">
                  {item.format ? item.format(this.data[item.fieldName], this.data, item) : this.data[item.fieldName]}
                </div>,
                histories.length > 0 && (
                  <HistoryOutlined class="history-icon" onClick={() => this.handleShowHistory(histories, item)} />
                ),
              ]}
            </div>
          </FormItem>
        );
      });
    },
  });
