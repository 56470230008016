import { useRouter, RouteParamsRaw, LocationQueryRaw } from 'vue-router';
import { state } from '@/lib/state';
export const setRouter = (): ((
  name: string,
  routerParams?: { params?: RouteParamsRaw; query?: LocationQueryRaw },
) => void) => {
  const router = useRouter();
  return (name, routerParams?) => {
    router.push({
      name,
      ...routerParams,
    });
  };
};
export const setHandleToForm = (): ((moduleId: string, subjectId: string) => void) => {
  // const router = useRouter();

  const router = useRouter();

  return (moduleId: string, subjectId: string, subjectFormId?: string) => {
    // const moduleId = FormTypeModuleMapDict[formId];
    console.log(moduleId);

    const url = subjectFormId
      ? router.resolve({ name: 'formContent', params: { moduleId, subjectId, subjectFormId } })
      : router.resolve({ name: 'formList', params: { moduleId, subjectId } });
    window.open(url.href, '_blank');
  };
};
export const handleTabVisible = (cb: () => void): void => {
  let i = 0;
  document.onvisibilitychange = () => {
    i++;
    if (i % 2 == 0) {
      state.userInfo && cb();
    }
    // console.log(e);
  };
};
