import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import manage from './manage';
import form from './form';
// import config from './config';
// import sale from './sale';
export const routes: Array<RouteRecordRaw> = [
  /** 默认重定向到 Login */
  {
    name: 'default',
    path: '/:pathMatch(.*)*',
    redirect: { name: 'login', query: {} },
    meta: {
      h5Page: '/views/login/layout',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ `../views/Login.vue`),
    meta: {
      h5Page: '/views/login/layout',
    },
  },
  {
    name: 'patientImport',
    path: '/patientImport',
    component: () => import(/* webpackChunkName: "login" */ `../views/manage/PatientImport.vue`),
  },
  {
    name: 'noweb',
    path: '/noweb',
    component: () => import(/* webpackChunkName: "login" */ `../views/NoWeb.vue`),
    meta: {},
  },
  {
    name: 'manage',
    path: '/manage',
    component: () => import(/* webpackChunkName: "work-manage" */ `../views/manage/ManageLayout.vue`),
    meta: {},
    children: manage,
  },
  {
    name: 'form',
    path: '/form',
    component: () => import(/* webpackChunkName: "form" */ `../views/form/components/FormLayoutContianer/index.vue`),
    meta: {
      menuTitle: '表单',
    },
    children: form,
  },
  // 账号配置 不再需要
  // {
  //   name: 'config',
  //   path: '/config',
  //   component: () => import(/* webpackChunkName: "work-bench" */ `../views/config/UserConfig.vue`),
  //   meta: {},
  //   children: config,
  // },
  {
    name: 'maintenance',
    path: '/maintenance',
    component: () => import(/* webpackChunkName: "maintenance" */ `../views/InMaintenance.vue`),
    meta: {},
  },
  // 用药划分
  // {
  //   name: 'sale',
  //   path: '/sale',
  //   component: () => import(/* webpackChunkName: "work-manage" */ `../views/manage/ManageLayout.vue`),
  //   meta: {},
  //   children: sale,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
