import {
  AutarkyType,
  HospitalType,
  IFormMedicationDTO,
  MedicationDurationExplainType,
  QualifiedStatusType,
  SalesIncreaseReasonType,
  delayEnterReasonType,
} from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';
import moment from 'moment';
import { EffectHandlerHooks } from '../../interface';
import { OPTION_OTHER } from '../../utils';
import { FormMedicationEffectField, MedicationFormEffectHandler } from './interface';
import MedicationDurationHelper from './itemHelpers/MedicationDurationHelper';
import UnitDoseHelper from './itemHelpers/UnitDoseHelper';
import InjectionFrequencyHitHelper from './itemHelpers/InjectionFrequencyHitHelper';
import InjectionFrequencyStopHelper from './itemHelpers/InjectionFrequencyStopHelper';
import InjectionUnitHelper from './itemHelpers/InjectionUnitHelper';

const EffectHandler: EffectHandlerHooks<IFormMedicationDTO<moment.Moment, string[]>, FormMedicationEffectField> = (
  modelRef,
): MedicationFormEffectHandler => {
  /**
   * 就诊医生
   */
  const doctorId = () => {
    modelRef.doctorId = '';
    modelRef.doctorName = '';
  };

  /**
   * 就诊机构名称
   */
  const hospitalId = () => {
    modelRef.hospitalId = '';
    modelRef.hospitalName = '';
    doctorId();
  };

  /**
   * 就诊机构类型
   */
  const hospitalType = () => {
    hospitalId();
  };

  /**
   * 就诊省份
   */
  const visitDoctorProvinceId = () => {
    hospitalId();
  };

  /**
   * 处方有效期
   */
  const prescriptionValidity = () => {
    if (!modelRef.prescriptionDate) {
      return;
    }

    modelRef.prescriptionValidity = moment(modelRef.prescriptionDate).add(83, 'days');
  };

  /**
   * 处方日期
   */
  const prescriptionDate = () => {
    prescriptionValidity();
  };

  /**
   * 检查报告单有效期
   */
  const inspectionReportValidity = () => {
    if (!modelRef.inspectionReportDate) {
      return;
    }

    modelRef.inspectionReportValidity = moment(modelRef.inspectionReportDate).add(6, 'months').subtract(1, 'days');
  };

  /**
   * 检查报告单日期
   */
  const inspectionReportDate = () => {
    inspectionReportValidity();
  };

  /**
   * 用药出现时间
   */
  // const salesIncreaseDate = () => {
  //   if (!modelRef.buyMedicineDate) {
  //     return;
  //   }

  //   modelRef.salesIncreaseDate = moment(modelRef.buyMedicineDate);
  //   formRef?.value?.validateFields(['salesIncreaseDate']);
  // };

  /**
   * 展示用药出现原因或延迟录入原因
   */
  const delayOrSale = () => {
    if (modelRef.delayTime && modelRef.source === 1) {
      modelRef.delayOrSale =
        moment(modelRef.delayTime).diff(moment(moment(modelRef.buyMedicineDate).format('YYYY-MM-DD')), 'days') > -1
          ? 'DELAY'
          : 'SALE';
    }
    if (modelRef.delayOrSale === 'DELAY') {
      modelRef.salesIncreaseReason = SalesIncreaseReasonType.NONE;
      modelRef.otherDesc = '';
    } else {
      modelRef.salesIncreaseReason = SalesIncreaseReasonType.REGULAR_OFFER;
      modelRef.delayEnterReason = delayEnterReasonType.NONE;
      modelRef.delayEnterReasonOther = '';
    }
  };

  /**
   * 购药时间
   */
  const buyMedicineDate = () => {
    // salesIncreaseDate();
    delayOrSale();
  };

  /**
   * 用药出现原因备注(其他，请描述)
   */
  const otherDesc = () => {
    if (modelRef.salesIncreaseReason !== SalesIncreaseReasonType.OTHER && modelRef.otherDesc) {
      modelRef.otherDesc = '';
    }
  };

  /**
   * 用药出现原因
   */
  const salesIncreaseReason = () => {
    otherDesc();
  };

  /**
   * 延迟录入原因备注(其他，请描述)
   */
  const delayEnterReasonOther = () => {
    if (modelRef.delayEnterReason !== delayEnterReasonType.OTHER && modelRef.delayEnterReasonOther) {
      modelRef.delayEnterReasonOther = '';
    }
  };

  /**
   * 延迟录入原因
   */
  const delayEnterReason = () => {
    delayEnterReasonOther();
  };

  /**
   * 预计下次购药数量（支）
   */
  const estimateNextPurchaseQuantity = () => {
    if (!RegularHelper.isUnsignedInteger.test(modelRef.drugPurchasedNumber)) {
      modelRef.estimateNextPurchaseQuantity = '';
      return;
    }

    if (modelRef.estimateNextPurchaseQuantity !== modelRef.drugPurchasedNumber) {
      modelRef.estimateNextPurchaseQuantity = modelRef.drugPurchasedNumber;
    }
  };

  /**
   * 实际数量（支）
   */
  const actualNumber = () => {
    if (
      !RegularHelper.isUnsignedInteger.test(modelRef.drugPurchasedNumber) ||
      !(RegularHelper.isUnsignedInteger.test(modelRef.drugWithdrawalNumber) || !modelRef.drugWithdrawalNumber)
    ) {
      modelRef.actualNumber = '';
      return;
    }

    modelRef.actualNumber = (+modelRef.drugPurchasedNumber - (+modelRef.drugWithdrawalNumber || 0)).toString();
  };

  /**
   * 购药数量（支）
   */
  const drugPurchasedNumber = () => {
    estimateNextPurchaseQuantity();
    actualNumber();
    medicationDuration();
  };

  /**
   * 退药数量（支）
   */
  const drugWithdrawalNumber = () => {
    actualNumber();
    medicationDuration();
  };

  /**
   * 不合格原因备注(其他，请描述)
   */
  const nextPurchaseOtherDesc = () => {
    if (!(modelRef.disqualificationReason as string[]).includes(OPTION_OTHER) && modelRef.nextPurchaseOtherDesc) {
      modelRef.nextPurchaseOtherDesc = '';
    }
  };

  /**
   * 不合格原因
   */
  const disqualificationReason = () => {
    if (modelRef.statusIdentification !== QualifiedStatusType.UNQUALIFIED && modelRef.disqualificationReason) {
      modelRef.disqualificationReason = [];
      nextPurchaseOtherDesc();
    }
  };

  /**
   * 状态标识
   */
  const statusIdentification = () => {
    disqualificationReason();
  };

  /**
   * 实际出库价(元/支)
   */
  const actualDeliveryPrice = () => {
    // 如果机构上传的数据，实际出库价(元/支)展示的是详情接口中的actualDeliveryPrice字段
    if (modelRef.source != 7) {
      modelRef.actualDeliveryPrice = modelRef.deliveryPrice;
    };
  };

  /**
   * 发货价(元/支)
   */
  const deliveryPrice = (nextVal?: string) => {
    modelRef.deliveryPrice = nextVal || '';
    actualDeliveryPrice();
    batchBalanceInventory();
  };

  /**
   * 批号结余库存
   */
  const batchBalanceInventory = () => {
    modelRef.batchBalanceInventory = '';
  };

  /**
   * 批号
   */
  const batchNumber = () => {
    modelRef.batchNumber = '';
    deliveryPrice();
    batchBalanceInventory();
  };

  /**
   * 购药机构名称
   */
  const buyMedicineMechanismId = () => {
    modelRef.buyMedicineMechanismId = '';
    modelRef.buyMedicineMechanismName = '';
    batchNumber();
  };

  /**
   * 是否自营
   */
  const autarky = () => {
    const operatedOpts = [HospitalType.NON_RESIDENT_COOPERATIVE_CLINIC, HospitalType.RESIDENT_COOPERATIVE_CLINIC];
    const isShowOperatedType = operatedOpts.includes(modelRef.buyMedicineMechanismType as HospitalType);

    if (!isShowOperatedType && modelRef.autarky) {
      modelRef.autarky = '';
      return;
    }

    if (isShowOperatedType) {
      modelRef.autarky = AutarkyType.NOT_SELF_OPERATE;
    }
  };

  /**
   * 购药机构类型
   */
  const buyMedicineMechanismType = () => {
    buyMedicineMechanismId();
    autarky();
  };

  /**
   * 购药省份
   */
  const buyMedicineProvinceId = () => {
    buyMedicineMechanismId();
  };

  /**
   * 用药时长说明备注(其他，请描述)
   */
  const medicationDurationExplainRemark = () => {
    if (
      modelRef.medicationDurationExplain !== MedicationDurationExplainType.OTHER &&
      modelRef.medicationDurationExplainRemark
    ) {
      modelRef.medicationDurationExplainRemark = '';
    }
  };

  /**
   * 用药时长说明
   */
  const medicationDurationExplain = () => {
    if (!RegularHelper.isUnsignedInteger.test(modelRef.medicationDuration)) {
      return;
    }

    if (+modelRef.medicationDuration < 84) {
      modelRef.medicationDurationExplain = '';
      medicationDurationExplainRemark();
    }
  };

  /**
   * 用药时长显示（天）
   */
  const medicationDuration = () => {
    console.log('medicationDuration');
    if (!MedicationDurationHelper.preCheck(modelRef)) {
      modelRef.medicationDuration = '';
      medicationDurationExplain();
      return;
    }

    modelRef.medicationDuration = MedicationDurationHelper.calc(modelRef);
    medicationDurationExplain();
  };

  /**
   * 单位用药剂量状态
   */
  const unitDoseStatus = () => {
    if (!UnitDoseHelper.hasDoubt(modelRef) && modelRef.unitDoseStatus) {
      modelRef.unitDoseStatus = '';
    }
  };

  /**
   * 单位用药剂量
   */
  const unitDose = () => {
    modelRef.unitDose = UnitDoseHelper.preCheck(modelRef) ? UnitDoseHelper.calc(modelRef) : '';
    unitDoseStatus();
  };

  /**
   * 体重
   */
  const weight = () => {
    unitDose();
  };

  /**
   * 注射剂量
   */
  const injectionDose = () => {
    unitDose();
    medicationDuration();
  };

  /**
   * 注射频率(打)
   */
  const hit = () => {
    modelRef.hit = InjectionFrequencyHitHelper.getDefaultValue(modelRef);
    medicationDuration();
  };

  /**
   * 注射频率(打)
   */
  const stop = () => {
    modelRef.stop = InjectionFrequencyStopHelper.getDefaultValue(modelRef);
    medicationDuration();
  };

  /**
   * 注射计量单位
   */
  const injectionUnit = () => {
    modelRef.injectionUnit = InjectionUnitHelper.getDefaultValue(modelRef);
    medicationDuration();
    unitDose();
  };

  /**
   * 规格
   */
  const specificationsId = () => {
    hit();
    stop();
    injectionUnit();
    batchNumber();
  };

  return {
    doctorId,
    hospitalId,
    hospitalType,
    visitDoctorProvinceId,

    prescriptionValidity,
    prescriptionDate,

    inspectionReportValidity,
    inspectionReportDate,

    // salesIncreaseDate,
    delayOrSale,
    buyMedicineDate,

    otherDesc,
    salesIncreaseReason,

    delayEnterReasonOther,
    delayEnterReason,

    estimateNextPurchaseQuantity,
    actualNumber,
    drugPurchasedNumber,
    drugWithdrawalNumber,

    nextPurchaseOtherDesc,
    disqualificationReason,
    statusIdentification,

    actualDeliveryPrice,
    deliveryPrice,
    batchBalanceInventory,
    batchNumber,
    buyMedicineMechanismId,
    autarky,
    buyMedicineMechanismType,
    buyMedicineProvinceId,
    medicationDurationExplainRemark,
    medicationDurationExplain,
    medicationDuration,
    unitDoseStatus,
    unitDose,
    weight,
    injectionDose,
    hit,
    stop,
    injectionUnit,
    specificationsId,
  };
};

export default EffectHandler;
