import { computed, ComputedRef } from 'vue';
import { ValidationRule } from 'ant-design-vue/lib/form/Form';

import { ObjectMap } from '@/type/helper';
// import { RuleObject } from 'ant-design-vue/lib/form/interface';
// import RegulerHelper from '@/utils/regularHelper';

export const genRules = (): ComputedRef<ObjectMap<ValidationRule[]>> =>
  computed(() => {
    const rules: ObjectMap<ValidationRule[]> = {
      purchaseProvinceId: [{ required: true, message: '请选择购买省份' }],
      purchaseHospitalId: [{ required: true, message: '请选择购买医院' }],
      purchaseDate: [{ required: true, message: '请选择购买日期' }],
      specificationsId: [{ required: true, message: '请选择规格' }],
      epenBatchNumber: [{ required: true, message: '请选择批号' }],
      // deliveryPrice: [{ required: true, message: '请选择发货价' }],
      epenBatchBalanceInventory: [{ required: true, message: '请选择结余库存' }],
      // serialNumber: [{ required: true, message: '请输入序列号' }],
      purchaseQuantity: [{ required: true, message: '请输入购笔数量' }],
      // epenWithdrawalNumber: [{ required: true, message: '请输入退笔数量' }],
      // epenRemark: [{ required: true, message: '请选择首诊' }],
    };

    return rules;
  });

export default genRules;
