import { FormType } from '@/type/form';
import { FormStatus } from '@/type/formContent';
import { preSerializationPatientForm } from './components/PatientForm/utils';

// eslint-disable-next-line
export const preSerializationFormValue = (formValue: any, formType: FormType, isCreate: boolean): any => {
  if (isCreate) {
    formValue.status = FormStatus.UN_FILL;
  }

  switch (formType) {
    case FormType.PATIENT:
      return preSerializationPatientForm(formValue);
    default:
      return formValue;
  }
};
