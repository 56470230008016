import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, createSlots as _createSlots, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1887af9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-table-container" }
const _hoisted_2 = { class: "custom-table-container_footer" }
const _hoisted_3 = { class: "custom-table-container_footer_total-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusSquareOutlined = _resolveComponent("PlusSquareOutlined")!
  const _component_MinusSquareOutlined = _resolveComponent("MinusSquareOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "customTableHeader", {}, undefined, true),
    _createVNode(_component_a_table, {
      ref: "tableRef",
      scroll: { y: _ctx.y, ...(_ctx.tableState.scroll ? _ctx.tableState.scroll : {}) },
      pagination: false,
      bordered: "",
      size: "small",
      columns: _ctx.tableState.columns,
      "data-source": _ctx.tableState.dataSource,
      rowSelection: _ctx.tableState.rowSelection,
      rowKey: _ctx.tableState.key,
      expandedRowKeys: _ctx.expandedRowKeys
    }, _createSlots({
      expandIcon: _withCtx((props) => [
        (_ctx.tableState.showExpandRow && _ctx.tableState.showExpandRow(props.record))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!_ctx.expandedRowKeys.includes(props.record[_ctx.tableState.key]))
                ? (_openBlock(), _createBlock(_component_PlusSquareOutlined, {
                    key: 0,
                    onClick: ($event: any) => (_ctx.handleExpandClick(props.record, 'add'))
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_MinusSquareOutlined, {
                    key: 1,
                    onClick: ($event: any) => (_ctx.handleExpandClick(props.record, 'sub'))
                  }, null, 8, ["onClick"]))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.tableState.showExpandRow)
        ? {
            name: "expandedRowRender",
            fn: _withCtx((props) => [
              _renderSlot(_ctx.$slots, "expandedRowRender", { props: props }, undefined, true)
            ])
          }
        : undefined,
      _renderList(_ctx.customRenderList, (name) => {
        return {
          name: name,
          fn: _withCtx((props) => [
            _renderSlot(_ctx.$slots, name, { props: props }, undefined, true)
          ])
        }
      }),
      _renderList(_ctx.commonRenderList, (name) => {
        return {
          name: name,
          fn: _withCtx(({ record }) => [
            _createVNode(_component_a_tooltip, {
              placement: "topLeft",
              overlayClassName: "custom-tooltip"
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(record[name]), 1)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(record[name]), 1)
              ]),
              _: 2
            }, 1024)
          ])
        }
      })
    ]), 1032, ["scroll", "columns", "data-source", "rowSelection", "rowKey", "expandedRowKeys"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createTextVNode("共计：" + _toDisplayString(_ctx.tableState.pagination.total) + "条数据 ", 1),
        _renderSlot(_ctx.$slots, "footerSlot", {}, undefined, true)
      ]),
      _createVNode(_component_a_pagination, {
        class: "custom-table-container_footer_page",
        current: _ctx.tableState.pagination.current,
        pageSize: _ctx.tableState.pagination.pageSize,
        defaultPageSize: _ctx.tableState.pagination.defaultPageSize,
        total: _ctx.tableState.pagination.total,
        pageSizeOptions: _ctx.tableState.pagination.pageSizeOptions,
        size: _ctx.tableState.pagination.size,
        showSizeChanger: _ctx.tableState.pagination.showSizeChanger,
        hideOnSinglePage: _ctx.tableState.pagination.hideOnSinglePage,
        onChange: _ctx.handlePage,
        onShowSizeChange: _ctx.handleSizePage
      }, null, 8, ["current", "pageSize", "defaultPageSize", "total", "pageSizeOptions", "size", "showSizeChanger", "hideOnSinglePage", "onChange", "onShowSizeChange"])
    ])
  ]))
}