import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, {
    theme: "light",
    mode: "inline",
    selectedKeys: _ctx.activeModule,
    onClick: _ctx.handleMenuClick
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleList, (module) => {
        return (_openBlock(), _createBlock(_component_a_menu_item, {
          class: _normalizeClass({ 'form-menu-item': true, 'form-menu-item-unClick': !_ctx.allowClick }),
          key: module.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'with-red-point': module.id in _ctx.redPointMap })
            }, _toDisplayString(module.name), 3)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["selectedKeys", "onClick"]))
}