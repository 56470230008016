
  import { get, uniqBy } from 'lodash';
  import { computed, defineComponent, PropType } from 'vue';

  import { IDropdownItem } from '@/type/common';
  import { FormTypeTransDict, ICaseData } from '@/type/form';

  import { getLastUpdateCase } from '../CaseContainer/utils';
  import {
    matchFormId,
    getCasesByInjectedStatus,
    getCasesByPreviewMode,
    getCasesByRecordUserId,
    getCasesByFormId,
    orderByUpdate,
  } from './utils';
  import { CasePreviewMode } from '../CasePreview/interface';
  import moment from 'moment';

  export default defineComponent({
    name: 'case-cascader-form',
    emits: ['update:case'],
    props: {
      case: {
        type: Object as PropType<ICaseData>,
        defualt: null,
      },
      rejected: {
        type: Boolean,
        default: false,
      },
      previewMode: {
        type: String as PropType<CasePreviewMode>,
        default: CasePreviewMode.ALL,
      },
      cases: {
        type: Array as PropType<ICaseData[]>,
        default: () => [],
      },
    },
    setup(props, context) {
      // 根据可打回状态&预览模式筛选后可展示的病例
      const activeCases = computed(() =>
        getCasesByInjectedStatus(getCasesByPreviewMode(props.cases, props.previewMode), props.rejected),
      );

      /**
       * 录入员
       *
       * @params
       * currentRecordUserId: 当前病例录入员id
       * recordUsers        : 录入员列表
       *
       * @handler
       * handleRecordUserChange: 根据选择的录入员id匹配相应病例
       */
      const currentRecordUserId = computed(() => get(props, 'case.recordUserId', ''));

      const recordUsers = computed(() =>
        uniqBy(
          activeCases.value.map<IDropdownItem<string>>((item) => ({
            label: item.recordUserName || '--',
            value: item.recordUserId,
          })),
          'value',
        ),
      );

      const handleRecordUserChange = (recordUserId: string) => {
        const currentCaseFormId = matchFormId(get(props, 'case.meta.formId', ''));
        if (!currentCaseFormId || !activeCases.value.length) {
          return;
        }

        // Step 01: 匹配病例中录入员为当前选择人员的条目
        const recordUserCases = getCasesByRecordUserId(activeCases.value, recordUserId);
        if (!recordUserCases.length) {
          return;
        }

        // Step 02: 在当前录入员对应的病例中, 匹配当前表单类型的条目
        const recordUserFormCases = getCasesByFormId(recordUserCases, currentCaseFormId);
        if (recordUserFormCases.length) {
          // Step 03-A: 若存在相关条目, 则设置当前录入员在当前表单类型下最新一条的记录作为当前病例
          context.emit('update:case', getLastUpdateCase(recordUserFormCases));
        } else {
          // Step 03-B: 若未命中匹配, 则取当前录入员下最新一次传输的记录
          context.emit('update:case', getLastUpdateCase(recordUserCases));
        }
      };

      /**
       * 表单类型
       *
       * @params
       * currentFormId: 当前病例表单类型id
       * formTypes    : 当前录入员对应的表单类型
       *
       * @handler
       * handleFormTypeChange: 根据选择的表单类型id匹配当前录入员的相应病例
       */
      const currentFormId = computed(() => matchFormId(get(props, 'case.meta.formId', '')));

      const formTypes = computed((): IDropdownItem<string>[] => {
        if (!activeCases.value.length) {
          return [];
        }

        // Step 01: 匹配病例中录入员为当前选择人员的条目
        const recordUserCases = getCasesByRecordUserId(activeCases.value, currentRecordUserId.value);

        // Step 02: 对当前录入员对应的病例根据表单类型id进行去重
        const recordUserFormCases = uniqBy(recordUserCases, (item) => matchFormId(get(item, 'meta.formId', ''))).reduce<
          IDropdownItem<string>[]
        >((list, current) => {
          const formId = matchFormId(get(current, 'meta.formId', ''));

          if (formId) {
            list.push({
              label: FormTypeTransDict[formId],
              value: formId,
            });
          }

          return list;
        }, []);

        return recordUserFormCases;
      });

      const handleFormTypeChange = (formId: string) => {
        // Step 01: 根据打回状态&预览类型匹配对应病例条目
        const cases = getCasesByInjectedStatus(getCasesByPreviewMode(props.cases, props.previewMode), props.rejected);
        if (!cases.length) {
          return;
        }

        // Step 02: 匹配病例中录入员为当前选择人员的条目
        const recordUserCases = getCasesByRecordUserId(cases, currentRecordUserId.value);
        if (!recordUserCases.length) {
          return;
        }

        // Step 03: 在当前录入员对应的病例中, 匹配当前选择的表单类型对应的条目
        const recordUserFormCases = getCasesByFormId(recordUserCases, formId);

        if (recordUserFormCases.length) {
          context.emit('update:case', getLastUpdateCase(recordUserFormCases));
        } else {
          context.emit('update:case', getLastUpdateCase(recordUserCases));
        }
      };

      /**
       * 病历记录
       *
       * @params
       * currentCaseId: 当前选择病例id
       * formCases    : 当前录入员及表单类型对应的病例列表
       *
       * @handler
       * handleCaseChange: 选择病例后的回调
       */
      const currentCaseId = computed(() => get(props, 'case.id', ''));

      const formCases = computed((): IDropdownItem<number>[] => {
        if (!activeCases.value.length || !formTypes.value.length) {
          return [];
        }

        // Step 01: 匹配病例中录入员为当前选择人员的条目
        const recordUserCases = getCasesByRecordUserId(activeCases.value, currentRecordUserId.value);

        // Step 02: 根据当前录入员及表单类型id筛选相应病例条目
        const recordUserFormCases = orderByUpdate(
          getCasesByFormId(recordUserCases, currentFormId.value),
          'desc',
        ).reduce<IDropdownItem<number>[]>((list, current) => {
          list.push({
            label: current.recordCreateTime ? moment(current.recordCreateTime).format('YYYY-MM-DD HH:mm:ss') : '--',
            value: current.id,
          });

          return list;
        }, []);

        return recordUserFormCases;
      });

      const handleCaseChange = (caseId: number) => {
        console.log(caseId);
        const target = activeCases.value.find((item) => item.id === caseId);

        if (target) {
          context.emit('update:case', target);
        }
      };

      return {
        currentRecordUserId,
        recordUsers,
        handleRecordUserChange,

        currentFormId,
        formTypes,
        handleFormTypeChange,

        currentCaseId,
        formCases,
        handleCaseChange,
      };
    },
  });
