import { ValidationRule } from 'ant-design-vue/lib/form/Form';
import { ObjectMap } from '@/type/helper';
import { RuleObject } from 'ant-design-vue/lib/form/interface';
import RegularHelper from '@/utils/regularHelper';
// import { checkSapSame } from '@/services/ghoffline';

const useRules = (subjectFormId: string): ObjectMap<ValidationRule[]> => {
  const numValidator = (rule: RuleObject, value: string): Promise<string | void> => {
    if (!RegularHelper.isUnsignedInteger.test(value)) {
      return Promise.reject('请输入有效整数');
    }
    if (String(value).length > 4) {
      return Promise.reject('请输入小于10000的整数');
    }
    return Promise.resolve();
  };

  const drugNumValidator = (rule: RuleObject, value: string): Promise<string | void> => {
    if (!value || String(value).length === 0) {
      return Promise.resolve();
    }
    return numValidator(rule, value);
  };

  // const SPAValidator = (rule: RuleObject, sapMechanismCode: string): Promise<string | void> => {
  //   return checkSapSame(sapMechanismCode).then((idList) => {
  //     if (idList.length > 1 || idList[0] !== subjectFormId) {
  //       Promise.reject('SAP机构编码已存在');
  //     }
  //     return Promise.resolve();
  //   });
  // };

  const rules: ObjectMap<ValidationRule[]> = {
    // jobId: [{ required: true, message: '请选择辖区' }],
    // jobName: [],
    // team: [
    //   { required: true, message: '请输入团队', trigger: 'blur' },
    //   { min: 1, max: 500, message: '最多500个字符', trigger: 'change' },
    // ],
    // provinceId: [{ required: true, message: '请选择省', trigger: 'change' }],
    // cityId: [{ required: true, message: '请选择市', trigger: 'change' }],
    // hospitalId: [{ required: true, message: '请选择医院', trigger: 'change' }],
    // sapMechanismCode: [
    //   { required: true, message: '请输入SAP机构编码', trigger: 'blur' },
    //   { validator: SPAValidator, trigger: 'blur' },
    //   { min: 1, max: 500, message: '最多500个字符', trigger: 'change' },
    // ],
    // category: [{ required: true, message: '请选择三级品种', trigger: 'change' }],
    // specId: [{ required: true, message: '请选择品规简称', trigger: 'change' }],
    /** 纯销数(支) */
    drugPurchasedNumber: [
      { required: true, message: '请输入纯销数' },
      { validator: drugNumValidator, trigger: 'blur' },
    ],
    // deliveryType: [{ required: true, message: '请选择配送方式', trigger: 'change' }],
  };
  return rules;
};

export default useRules;
