import { uniqueId } from 'lodash';

import { IFormGHOfflineDTO } from '@/type/formGHOfflineDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';

const previewConfig: IFormPreviewGroup<IFormGHOfflineDTO>[] = [
  {
    id: uniqueId('GHOfflineForm'),
    groupName: false,
    items: [
      { label: '辖区ID', fieldName: 'jobCode' },
      { label: '辖区名称', fieldName: 'jobName' },
      { label: '工号', fieldName: 'workId' },
      { label: '姓名', fieldName: 'name' },
      { label: '患者群体', fieldName: 'patientGroup' },
      { label: '领域标签', fieldName: 'domainLabel' },
      { label: 'BG', fieldName: 'bg' },
      { label: '团队', fieldName: 'team' },
      { label: '战区', fieldName: 'warZoneName' },
      { label: '区域', fieldName: 'regionName' },
      { label: '大区', fieldName: 'regions' },
      { label: '地区', fieldName: 'regional' },
      { label: '省', fieldName: 'provinceName' },
      { label: '市', fieldName: 'cityName' },
      { label: '医院名称', fieldName: 'hospitalName' },
      { label: 'SAP机构编码', fieldName: 'sapMechanismCode' },
      { label: '购药日期', fieldName: 'buyTime' },
      { label: '三级品种名称', fieldName: 'category' },
      { label: '品规简称', fieldName: 'specName' },
      { label: '纯销数(支)', fieldName: 'drugPurchasedNumber' },
      // { label: '回款单价(元)', fieldName: 'collectionUnitPrice' },
      // { label: '金额', fieldName: 'amountMoney' },
      // { label: '配送方式', fieldName: 'deliveryType' },
      { label: '备注', fieldName: 'remark' },
    ],
  },
];

export default previewConfig;
