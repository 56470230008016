import edcIcLogo from './edc-ic-logo.svg';
import edcIcTransport from './edc-ic-transport.svg';
import edcBatchImport from './edc-ic-template.svg';
import resetIcon from './reset-icon.svg';
const SVG = {
  edcIcLogo,
  edcIcTransport,
  edcBatchImport,
  resetIcon,
};
export default SVG;
