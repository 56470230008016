
  import { computed, ComputedRef, defineComponent, inject, PropType, ref, toRaw, Ref, provide } from 'vue';
  import { Modal } from 'ant-design-vue';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';

  import { FormModuleType, FormType, ModuleFormTypeMapDict } from '@/type/form';
  import { FormOperateType, FormStatus, IBaseFormDTO, ICreateFormQo, PollStatusType } from '@/type/formContent';
  import { IFormMedicationDTO } from '@/type/formMedicationDTO';
  import { IFormEPenDTO } from '@/type/formEPenDTO';

  import PatientForm from '../PatientForm/index.vue';
  import TreatmentForm from '../TreatmentForm/index.vue';
  import LaboratoryForm from '../LaboratoryForm/index.vue';
  import MedicationForm from '../MedicationForm/index.vue';
  import EPenForm from '../EPenForm/index.vue';
  import NourishmentForm from '../NourishmentForm/index.vue';
  import GHOfflineForm from '../GHOfflineForm/index.vue';
  import { get, isEqual, cloneDeep } from 'lodash';
  import { useRoute } from 'vue-router';
  import { finishTask, modifyForm, getFirstSales, pollFormStatus, getDictionaryList } from '@/services/form';
  import { trimFormValue } from '../../utils';
  import { checkAutarky } from '../MedicationForm/utils';
  import moment from 'moment';
  import { CONSTANS_KEY, useLocalStorageItem } from '@/lib/constans';
  import { CREATE_SUBJECT_ID } from '@/views/form/utils';
    // 定义一个函数数据类型
  type IFunction = () => void;

  export default defineComponent({
    name: 'form-factory',
    emits: ['submit', 'continue', 'beforeSave', 'afterSave'],
    components: {
      PatientForm,
      TreatmentForm,
      LaboratoryForm,
      MedicationForm,
      EPenForm,
      NourishmentForm,
      GHOfflineForm,
    },
    props: {
      formValue: {
        type: Object as PropType<IBaseFormDTO>,
        default: null,
      },
      moduleType: {
        type: String as PropType<FormModuleType>,
        default: null,
      },
      isSubmiting: {
        type: Boolean,
        required: true,
      },
    },
    setup(props, { emit }) {
      // >>> 状态轮询 >>>
      const isPolling = ref(false);
      const pollStatus = ref<PollStatusType>('LOADING');
      const pollTitle = ref<string>('处理中');

      const hide = () => {
        console.log('有没有执行执行执行');
        isPolling.value = false;
        pollStatus.value = 'LOADING';
        pollTitle.value = '处理中';
      };

      // <<< 状态轮询 <<<

      const route = useRoute();
      const formRef = ref();

      const operateType = inject<ComputedRef<FormOperateType>>('operateType');
      // eslint-disable-next-line @typescript-eslint/ban-types
      const isCreatePatient = inject('isCreatePatient', ref(false));

      // 机构端用药信息详情点击提交并继续，底部按钮应该置灰
      const continueDisabledChange = inject<IFunction>('continueDisabledChange');

      // 首单用药，如果是首单则需要对是否自营做默认值
      const fisrtDetail: Ref<IFormMedicationDTO<moment.Moment, string[]> | null> = ref(null);
      getFirstSales({ subjectId: isCreatePatient.value ? '' : get(route, 'params.subjectId') }).then((res) => {
        fisrtDetail.value = res || null;
      });
      provide('fisrtDetail', fisrtDetail);

      const earlyWarningRule:Ref<any> = ref<any>({});
      getDictionaryList({type: 'EarlyWarningRule'}).then((res:any) => {
        const { remark } = Array.isArray(res) && res.length ? res[0] : {} as any;
        const remarkObj:any = remark && JSON.parse(remark) || {}
        earlyWarningRule.value = remarkObj;
      });
     
      provide('earlyWarningRule', earlyWarningRule);

      const subjectFormId = get(route, 'params.subjectFormId');

      provide('subjectFormId', subjectFormId);

      const submit = (
        isContinue = false,
        isStay = false,
        continueCallback?: (subjectFormId: string) => Promise<void>,
      ) => {
        if (formRef.value) {
          emit('beforeSave');
          formRef.value.handleSubmit(
            async (formValue: IBaseFormDTO) => {
              //首单不校验
              if (
                fisrtDetail.value &&
                fisrtDetail.value.subjectFormId !== subjectFormId &&
                props.moduleType === FormModuleType.MEDICATION
              ) {
                const canSubmit = await checkAutarky(
                  fisrtDetail.value,
                  formValue as IFormMedicationDTO<moment.Moment, string[]>,
                );
                if (canSubmit) {
                  saveForm(formValue, true, isContinue, isStay, continueCallback);
                }
              } else {
                saveForm(formValue, true, isContinue, isStay, continueCallback);
              }
            },
            (err: ValidateErrorEntity<IBaseFormDTO>) => {
              console.error('error', err);
              emit('afterSave');
              if (err.errorFields[0].name.length > 1) {
                document.getElementById(err.errorFields[0].name.join('-'))?.scrollIntoView();
              } else {
                // window.location.hash = '#' + err.errorFields[0].name[0];
                document.getElementById(err.errorFields[0].name[0].toString())?.scrollIntoView();
              }

              Modal.error({
                title: '校验未通过',
                content: '表单中存在未通过校验的条目, 请根据表单中的提示修改后重新提交',
              });
            },
          );
        }
      };

      const temporarySubmit = (isContinue = false, isStay = false) => {
        if (formRef.value) {
          emit('beforeSave');
          saveForm(formRef.value.getFormValue(), false, isContinue, isStay);
        }
      };

      const saveForm = async <T extends IBaseFormDTO>(
        formValue: T,
        /** 是否提交 */
        isSubmit: boolean,
        /** 是否继续 */
        isContinue = false,
        /** 是否继续 为 true 时，是否停留在当前页面 */
        isStay = false,
        continueCallback?: (subjectFormId: string) => Promise<void>,
      ): Promise<void> => {
        try {
          const localItem = useLocalStorageItem(CONSTANS_KEY.SUBJECT_FORM_REFRESH_KEY);
          emit('beforeSave');
          const formId = ModuleFormTypeMapDict[get(route, 'params.moduleId')];
          const params: ICreateFormQo<any> = {
            formId,
            submit: isSubmit,
            content: trimFormValue(formValue),
          };

          /*
        忽略电子笔批号结余库存和用药信息批号结余库存的不同
        */

          let formValue_old = cloneDeep(toRaw(props.formValue));
          let formValue_new = cloneDeep(formValue);

          (formValue_old as unknown as IFormEPenDTO).epenBatchBalanceInventory = '0';
          (formValue_new as unknown as IFormEPenDTO).epenBatchBalanceInventory = '0';
          (formValue_old as unknown as IFormMedicationDTO).batchBalanceInventory = '0';
          (formValue_new as unknown as IFormMedicationDTO).batchBalanceInventory = '0';

          const isFormChange = !isEqual(formValue_old, formValue_new);

          console.log(formValue);
          if (
            !isFormChange &&
            formValue.status === FormStatus.SUBMITTED &&
            props.moduleType === FormModuleType.MEDICATION
          ) {
            console.log('isFormChange', isFormChange);

            const subjectFormId = get(route, 'params.subjectFormId');

            /**
             * 当表单未改变值时调取检查任务完成接口
             */
            const result = await finishTask({ subjectFormId, formId });

            emit('afterSave');

            if (!result) {
              return;
            }

            // 提交、暂存成功后 更新 localStorage
            localItem.setItem('1');

            if (isContinue) {
              continueCallback && (await continueCallback(subjectFormId));
              if (!isStay) {
                // 非停留，需要清空表单
                formRef.value.resetModel();
              }
            } else {
              setTimeout(() => {
                window.close();
              }, 800);
            }

            return;
          }

          if (isCreatePatient.value) {
            params.content.subjectId = '';
          } else {
            const subjectId = get(route, 'params.subjectId');
            if (props.moduleType === FormModuleType.GHOFFLINE && subjectId === CREATE_SUBJECT_ID) {
              params.content.subjectId = '';
            } else {
              params.content.subjectId = subjectId;
            }
          }
          if (operateType && operateType.value === FormOperateType.CREATE) {
            params.content.subjectFormId = '';
          }
          if (params.content.isDiscount === '否') {
            params.content.discount = '';
          }
          const result = await modifyForm(params);

          emit('afterSave');
          if (!result) {
            hide();
            return;
          }

          // >>> 状态轮询 >>>
          console.log('debug: ', isSubmit, 'sjibushi tijiao');
          if (isSubmit) {
            console.log(
              'debug: ',
              'props: ' + JSON.stringify(props),
              'params: ' + JSON.stringify(params),
              'result: ' + result,
            );

            if (props.moduleType === FormModuleType.MEDICATION || props.moduleType === FormModuleType.E_PEN) {
              const formId = params.formId;
              const subjectFormId = result;

              isPolling.value = true;
              let retry = 1;
              let status: PollStatusType = 'LOADING';

              console.log(
                'start ---> ',
                'formType:' + props.moduleType,
                'formId:' + formId,
                'subjectFormId:' + subjectFormId,
                'pollStatus:' + status,
              );

              while (status === 'LOADING') {
                status = await pollFormStatus(retry, formId, subjectFormId);
                if (status === 'LOADING') {
                  pollStatus.value = 'LOADING';
                  //轮询超过10次，默认为失败
                  // if (retry++ >= 10) {
                  //   status = 'FAILED';
                  //   break;
                  // }
                }
                console.log('status ---> ', status);
              }

              if (status !== 'SUCCESS') {
                pollStatus.value = status;
                return;
              }

              isPolling.value = false;
              pollStatus.value = 'SUCCESS';
              pollTitle.value = '处理完成';
            }
            // <<< 状态轮询 <<<
          }

          // 提交、暂存成功后 更新 localStorage
          localItem.setItem('1');

          if (isContinue) {
            console.log('提交并继续');
            continueDisabledChange?.();
            continueCallback && (await continueCallback(result as string));
            if (!isStay) {
              // 非停留，需要清空表单
              formRef.value.resetModel();
            }
          } else {
            if (formId === FormType.PATIENT && !isCreatePatient.value) {
              setTimeout(() => {
                window.location.reload();
              }, 300);
            } else {
              setTimeout(() => {
                // 提交需要还原=================
                window.close();
              }, 300);
            }
          }
        } catch (e) {
          emit('afterSave');
        }
      };

      const currentComponent = computed(() => {
        let cmpt: string;

        switch (props.moduleType) {
          case FormModuleType.PATIENT:
            cmpt = 'PatientForm';
            break;
          case FormModuleType.TREATMENT:
            cmpt = 'TreatmentForm';
            break;
          case FormModuleType.MEDICATION:
            cmpt = 'MedicationForm';
            break;
          case FormModuleType.LABORATORY:
            cmpt = 'LaboratoryForm';
            break;
          case FormModuleType.E_PEN:
            cmpt = 'EPenForm';
            break;
          case FormModuleType.NOURISHMENT:
            cmpt = 'NourishmentForm';
            break;
          case FormModuleType.GHOFFLINE:
            cmpt = 'GHOfflineForm';
            break;
          default:
            cmpt = '';
            break;
        }

        return cmpt;
      });

      return {
        isPolling,
        pollStatus,
        pollTitle,
        hide,
        formRef,
        submit,
        temporarySubmit,
        currentComponent,
      };
    },
  });
