/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICycleVisitListItemPreviewData, ICycleVisitListResponse } from '@/type/form';
import { AutoCommit, FormSource, IBaseFormDTO } from '@/type/formContent';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { IDeserializationMomentConfig } from './interface';

export const genDefaultCycleVisitListItemPreviewData = (): ICycleVisitListItemPreviewData => ({
  datas: [],
  title: [],
});

export const genDefaultCycleVisitListResponse = (): ICycleVisitListResponse => ({
  forms: [],
  previewDatas: genDefaultCycleVisitListItemPreviewData(),
  subjectNumber: '',
  moduleName: '',
  moduleId: '',
  /** 患者姓名 */
  subjectName: '',
  /** 患者年龄 */
  subjectAge: '',
  /** 患者领域标签 用最新的	*/
  indicationGroupName: '',
  /** 患者领域标签 用最新的	*/
  domainLabel: '',
});

export const genDefaultBaseFormDTO = (): IBaseFormDTO => ({
  patientCode: '',
  subjectFormId: '',
  subjectId: '',
  creatorId: '',
  creatorName: '',
  source: FormSource.SYS_CREATE,
  autoCommit: AutoCommit.IS,
  status: -1,
  createDate: new Date(),
  updateDate: new Date(),
  actualDeliveryPrice: '',
});

export const convert2MomentForm = <OriginType, TargetType, K extends keyof OriginType>(
  originForm: OriginType,
  convertFields: K[],
): TargetType => {
  const formCopy = cloneDeep(originForm);

  convertFields.forEach((key) => {
    const fieldVal = formCopy[key];

    formCopy[key] = (!fieldVal ? null : moment(fieldVal)) as any;
  });

  return formCopy as unknown as TargetType;
};

export const deserializationMomentForm = <OriginType, TargetType, K extends keyof OriginType>(
  valueForm: OriginType,
  convertConfig: IDeserializationMomentConfig<K>[],
): TargetType => {
  const formCopy = cloneDeep(valueForm);

  convertConfig.forEach((item) => {
    const fieldValue = formCopy[item.field];

    let result;

    if (moment.isMoment(fieldValue)) {
      result = fieldValue.format(item.format || 'YYYY-MM-DD');
    } else {
      result = fieldValue || '';
    }

    formCopy[item.field] = result;
  });

  return formCopy as unknown as TargetType;
};

export const CREATE_SUBJECT_ID = 'NEW';
