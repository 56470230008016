import { FormType } from '@/type/form';
import { IBaseFormDTO } from '@/type/formContent';
import { genDefaultFormLaboratoryDTO } from './components/LaboratoryForm/utils';
import { genDefaultFormPatientDTO } from './components/PatientForm/utils';
import { genDefaultFormTreatmentDTO } from './components/TreatmentForm/utils';
import { genDefaultEPenFormDTO } from './components/EPenForm/utils';
import { genNourishmentFormDTO } from './components/NourishmentForm/utils';
import { genGHOfflineFormDTO } from './components/GHOfflineForm/utils';

export const createFormDetailByType = (formId: FormType): IBaseFormDTO | null => {
  let result: IBaseFormDTO | null;

  switch (formId) {
    case FormType.PATIENT:
      result = genDefaultFormPatientDTO();
      break;
    case FormType.TREATMENT:
      result = genDefaultFormTreatmentDTO();
      break;
    case FormType.LABORATORY:
      result = genDefaultFormLaboratoryDTO();
      break;
    case FormType.E_PEN:
      result = genDefaultEPenFormDTO();
      break;
    case FormType.NOURISHMENT:
      result = genNourishmentFormDTO();
      break;
    case FormType.GHOFFLINE:
      result = genGHOfflineFormDTO();
      break;
    default:
      result = null;
      break;
  }

  return result;
};

export default createFormDetailByType;
