import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO, InjectionUnitType } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';

/**
 * 单位用药剂量工具类
 */
export default class UnitDoseHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return (
      RegularHelper.isUnsignedFloat.test(modelRef.injectionDose) &&
      RegularHelper.isUnsignedFloat.test(modelRef.weight) &&
      !!modelRef.injectionUnit
    );
  }

  static calc(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    if (!UnitDoseHelper.preCheck(modelRef)) {
      return '';
    }

    // 注射剂量
    const injectionDose = +modelRef.injectionDose;
    // 体重
    const weight = +modelRef.weight;

    if (!injectionDose || !weight) {
      return '';
    }

    let result: number;

    switch (modelRef.injectionUnit) {
      /**
       * 注射剂量单位: IU/d
       * 计算公式: 注射剂量 / 体重
       */
      case InjectionUnitType.IU_PER_DAY:
        result = Math.max(injectionDose / weight, 0);
        break;
      /**
       * 注射剂量单位: mg/w
       * 计算公式: 注射剂量 * 6 / (体重 * 7)
       */
      case InjectionUnitType.MG_PER_WEEK:
        result = Math.max((injectionDose * 6) / (weight * 7), 0);
        break;
      /**
       * 注射剂量单位: IU/w
       * 计算公式: 注射剂量 / (体重 * 7)
       */
      case InjectionUnitType.IU_PER_WEEK:
        result = Math.max(injectionDose / (weight * 7), 0);
        break;
      default:
        return '';
    }

    if (!Number.isFinite(result)) {
      return '';
    }

    return result.toFixed(2);
  }

  static hasDoubt(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    dictionaryList?: any,
  ): boolean {
    if (!UnitDoseHelper.preCheck(modelRef) || !RegularHelper.isUnsignedFloat.test(modelRef.unitDose)) {
      return false;
    }

    const IuTypes = [InjectionUnitType.IU_PER_DAY, InjectionUnitType.IU_PER_WEEK];
    const unitDose = +modelRef.unitDose;
    if (
      modelRef.specificationsName.indexOf('GH54IU') > -1 ||
      IuTypes.includes(modelRef.injectionUnit as InjectionUnitType)
    ) {
      // return unitDose < 0.1 || unitDose > 0.2;
      return unitDose < dictionaryList?.minUnitDose || unitDose > dictionaryList?.maxUnitDose;
    } else if (modelRef.injectionUnit === InjectionUnitType.MG_PER_WEEK) {
      return unitDose < 0.2 || unitDose > 0.25;
    }

    return false;
  }

  static getDoubtMessage(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    dictionaryList: any,
  ): string {
    if (!UnitDoseHelper.preCheck(modelRef) || !RegularHelper.isUnsignedFloat.test(modelRef.unitDose)) {
      return '';
    }

    const IuTypes = [InjectionUnitType.IU_PER_DAY, InjectionUnitType.IU_PER_WEEK];
    const unitDose = +modelRef.unitDose;
    if (
      modelRef.specificationsName.indexOf('GH54IU') > -1 ||
      IuTypes.includes(modelRef.injectionUnit as InjectionUnitType)
    ) {
      // 如果注射剂量单位为'IU/d'或者'IU/w'，走下面的判断
      if (unitDose < dictionaryList.value.minUnitDose) {
        return `单位用药剂量小于${dictionaryList.value.minUnitDose}`;
      } else if (unitDose > dictionaryList.value.maxUnitDose) {
        return `单位用药剂量大于${dictionaryList.value.maxUnitDose}`;
      }
    } else if (modelRef.injectionUnit === InjectionUnitType.MG_PER_WEEK) {
      // 如果注射剂量单位为'mg/w'，走下面的判断
      if (unitDose < 0.2) {
        return '单位用药剂量小于0.2';
      } else if (unitDose > 0.25) {
        return '单位用药剂量大于0.25';
      }
    }

    return '';
  }
}
