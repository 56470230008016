import { uniqueId } from 'lodash';

import { IFormStopDrugDTO } from '@/type/formStopDrugDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';

const previewConfig: IFormPreviewGroup<IFormStopDrugDTO>[] = [
  {
    id: uniqueId('StopDrugForm'),
    groupName: false,
    items: [{ label: '停药状态', fieldName: 'stopDrugStatus' }],
  },
];

export default previewConfig;
