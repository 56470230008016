
  import { defineComponent, ref } from 'vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import { RollbackOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons-vue';
  import { exportDownload, upload } from '@/services/export';
  import { setRouter } from './hooks';
  import notify from '@/utils/notify';

  export default defineComponent({
    components: { ManageListFrame, RollbackOutlined, UploadOutlined, DownloadOutlined },
    setup() {
      const fileList = ref([]);
      const download = () => {
        window.open(exportDownload(), '_blank');
      };
      // const upload = () => {
      //   upload();
      // };
      const beforeUpload = (file: Blob) => {
        upload(file).then((res) => {
          console.log(res);

          if (res) {
            notify.success({ message: '上传成功' });
          }
        });
        return false;
      };
      const handleChange = () => {
        console.log(fileList.value);
        fileList.value = [];
      };
      const toRouter = setRouter();
      return { download, fileList, beforeUpload, handleChange, toRouter };
    },
  });
