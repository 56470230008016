import moment, { Moment } from 'moment';
export function timer<T extends () => void>(interval: number, func: T, maxRounds = 100): void {
  let count = 0;
  function intervalFunc() {
    const timeout = setTimeout(() => {
      func();
      count++;
      clearTimeout(timeout);
      if (count < maxRounds) {
        intervalFunc();
      }
    }, interval);
  }
  intervalFunc();
}
export function exchangeTime<T>(list: T[], keys: string[], format = 'YYYY-MM-DD HH:mm'): T[] {
  //注意T有类型转换
  return list?.map((item) => {
    keys.forEach((key) => {
      if (item[key]) {
        item[key] = moment(item[key]).format(format);
      } else if (key === 'lastModifyDate') {
        //11-29 #16852需求
        item[key] = '--';
      }
    });
    return item;
  });
}
export const disabledDateAfterToday = (current: Moment): boolean => {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
};

export function exchangeQuery(): Record<string, string> {
  const { href } = window.location;
  const query: Record<string, string> = {};
  const queryString = href.slice(href.indexOf('?') + 1);
  const arr = queryString.split('&').map((str) => {
    const res = {} as Record<string, string>;
    res[str.slice(0, str.indexOf('='))] = str.slice(str.indexOf('=') + 1);
    return res;
  });
  arr.forEach((obj) => {
    Object.assign(query, obj);
  });
  return query;
}

// 用于字典型下拉框的模糊搜索
export const filterOption = (input: string, option: { name: string }): boolean => {
  return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

/** 校验当前访问设备是否为 h5 */
export const isPc = (): boolean => {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? false : true;
};
