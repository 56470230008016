import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import moment, { Moment } from 'moment';

/**
 * 检查报告单有效期工具类
 */
export default class InspectionReportValidityHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.inspectionReportValidity && !!modelRef.buyMedicineDate;
  }

  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return (
      InspectionReportValidityHelper.preCheck(modelRef) &&
      (modelRef.buyMedicineDate as Moment).diff(
        moment(modelRef.inspectionReportValidity?.format('YYYY-MM-DD')),
        'days',
      ) > 0
    );
  }

  static getDoubtMessage(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    if (!InspectionReportValidityHelper.preCheck(modelRef)) {
      return '';
    }
    return '购药时间超过检查报告单有效期';
  }
}
