import http, { requestBooleanHandler, requestHandler } from './http';
import config from '@/lib/config';
import { ExportCenterQO, DownloadVo } from '@/type/api';
const serviceUrl = '/export';
// export function getImageAddress(params: { key: string }): ServiceRes<string> {
//   return http.post(serviceUrl + '/getImageAddress', params);
// }
export function upload(file: Blob): Promise<boolean> {
  // console.log(file);
  const formData = new FormData();
  formData.append('fileData', file);

  return requestBooleanHandler({
    service: () =>
      http.post(serviceUrl + '/userTemplateImport', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    defaultValue: false,
    errorMessage: '上传失败',
  });

  // http.post(serviceUrl + '/userTemplateImport', formData, {
  //   headers: { 'Content-Type': 'multipart/form-data' },
  // });
}
export function exportDownload(): string {
  return location.origin + (config?.backEnd || '') + serviceUrl + '/exportDownload';
}

export function createExport(params: ExportCenterQO): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/exportCenter/export', params),
    dataPath: 'data',
    defaultValue: false,
    errorMessage: '导出失败',
  });
}

export function getDownloadList(): Promise<{
  downloading: DownloadVo[];
  downloadComplete: DownloadVo[];
  downloadError: DownloadVo[];
}> {
  return requestHandler<{ downloading: DownloadVo[]; downloadComplete: DownloadVo[]; downloadError: DownloadVo[] }>({
    service: () => http.get('/exportCenter/export'),
    defaultValue: { downloading: [], downloadComplete: [], downloadError: [] },
    errorMessage: '获取列表失败',
  });
}

export function downloadFile(params: { taskId: number }): string {
  return '/api' + '/exportCenter/download?taskId=' + params.taskId;
  // return requestBooleanHandler({
  //   service: () => http.get('/exportCenter/download', { params }),
  //   dataPath: 'data',
  //   defaultValue: false,
  //   errorMessage: '导出失败',
  // });
}
// 零氪-导出管理-导出详情-增加下载失败重新下载接口
export function restartExport(params: { taskId: number }): string {
  return '/api' + '/exportCenter/restartExport?taskId=' + params.taskId;
}
