import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO, InjectionUnitType } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';

/**
 * 用药时长Hepler
 */
export default class MedicationDurationHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    const specificationMatch =
      !!modelRef.specificationsId &&
      !!modelRef.specificationsName &&
      modelRef.specificationsName.match(RegularHelper.matchUnsignedFloat);

    return !!(
      RegularHelper.isInteger.test(modelRef.actualNumber) &&
      RegularHelper.isUnsignedFloat.test(modelRef.injectionDose) &&
      RegularHelper.isUnsignedInteger.test(modelRef.hit) &&
      RegularHelper.isUnsignedInteger.test(modelRef.stop) &&
      modelRef.injectionUnit &&
      specificationMatch
    );
  }

  static calc(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    const specificationMatch =
      modelRef.specificationsId &&
      modelRef.specificationsName &&
      modelRef.specificationsName.match(RegularHelper.matchUnsignedFloat);

    if (!MedicationDurationHelper.preCheck(modelRef) || !specificationMatch) {
      return '';
    }
    // 规格
    const specification = +specificationMatch[0];
    // 购买支数
    const actualNumber = +modelRef.actualNumber;
    // 注射剂量
    const injectionDose = +modelRef.injectionDose;
    // 打
    const hit = +modelRef.hit;
    // 停
    const stop = +modelRef.stop;
    const hitStopSum = hit + stop;
    // 来源
    const source = +modelRef.source;
    // 规格名称
    const specificationsName = modelRef.specificationsName;
    //来源为自建、欧电和机构的数据 && 当【规格】GH54IU / GH54IU(卡式瓶)时，【用药时长显示】的计算逻辑中，specification（规格）需要多乘以1.1
    const isLongEffect =
      [1, 2, 7].indexOf(source) !== -1 && ['GH54IU', 'GH54IU(卡式瓶)'].indexOf(specificationsName) !== -1;
    console.log('-----isLongEffect----', isLongEffect, specification, actualNumber, injectionDose, hit, hitStopSum);
    let result: number;

    switch (modelRef.injectionUnit) {
      case InjectionUnitType.IU_PER_DAY:
        if (isLongEffect) {
          result = (specification * 1.1 * actualNumber) / ((injectionDose * hit) / hitStopSum);
        } else {
          result = (specification * actualNumber) / ((injectionDose * hit) / hitStopSum);
        }
        break;
      case InjectionUnitType.MG_PER_WEEK:
        if (isLongEffect) {
          result = (specification * 1.1 * actualNumber) / ((injectionDose * 6 * hit) / hitStopSum);
        } else {
          result = (specification * actualNumber) / ((injectionDose * 6 * hit) / hitStopSum);
        }
        break;
      case InjectionUnitType.IU_PER_WEEK:
        if (isLongEffect) {
          result = (specification * 1.1 * actualNumber) / ((injectionDose * hit) / hitStopSum);
        } else {
          result = (specification * actualNumber) / ((injectionDose * hit) / hitStopSum);
        }
        break;
      default:
        return '';
    }

    if (!Number.isFinite(result)) {
      return '';
    }

    return Math.round(result).toString();
  }
}
