import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "image-preview-pages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_body = _resolveComponent("image-body")!
  const _component_image_toolbar = _resolveComponent("image-toolbar")!
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_RightOutlined = _resolveComponent("RightOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    "wrap-class-name": "image-preview-container",
    class: "image-preview-container-dialog",
    mask: false,
    visible: _ctx.visible,
    footer: false,
    getContainer: _ctx.getContainer,
    width: "100%",
    onCancel: _ctx.handleDialogClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_image_body, {
        url: _ctx.currentImagePath,
        style: _normalizeStyle(_ctx.imageStyle),
        ref: "imgBodyRef",
        onMousewheel: _ctx.handleMouseWheel
      }, null, 8, ["url", "style", "onMousewheel"]),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.currentIndex + 1) + " / " + _toDisplayString(_ctx.images.length), 1),
      _createVNode(_component_image_toolbar, {
        onChange: _ctx.handleTooltipChange,
        ref: "toolbarRef"
      }, null, 8, ["onChange"]),
      _createVNode(_component_a_button, {
        class: "image-preview-switch image-preview-switch-left",
        disabled: _ctx.isFirstImage,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleIndexChange(_ctx.currentIndex - 1)))
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_LeftOutlined)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_a_button, {
        class: "image-preview-switch image-preview-switch-right",
        disabled: _ctx.isLastImage,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleIndexChange(_ctx.currentIndex + 1)))
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_RightOutlined)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["visible", "getContainer", "onCancel"]))
}