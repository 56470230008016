import { BaseListQo } from '@/type/api';
import { BatchListQo, SaleImportCheckListVo, SpecListQo, SpecListVo, UploadFileVo } from '@/type/saleImportCheck';
import http, { requestHandler } from './http';

const serviceUrl = '/dataImportVer';

/** 获取规格列表 */
export function getSpecList(params: SpecListQo): Promise<SpecListVo[]> {
  return requestHandler<SpecListVo[]>({
    service: () => http.get(serviceUrl + '/spec/list', { params }),
    defaultValue: [],
    errorMessage: '获取规格列表失败',
  });
}

/** 获取规格列表 */
export function getBatchList(params: BatchListQo): Promise<string[]> {
  return requestHandler<string[]>({
    service: () => http.get(serviceUrl + '/batch/list', { params }),
    defaultValue: [],
    errorMessage: '获取规格列表失败',
  });
}

export function saleUploadFileCheck(queryStr: string, file: Blob): Promise<UploadFileVo> {
  const data = new FormData();
  data.append('file', file);
  return requestHandler<UploadFileVo>({
    service: () =>
      http.post(`${serviceUrl}/upload?${queryStr}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    defaultValue: { success: false, successCount: 0, failMsg: '上传失败' },
    errorMessage: '上传失败',
  });
}

export function saleOrgCheck(queryStr: string): Promise<UploadFileVo> {
  const data = new FormData();
  return requestHandler<UploadFileVo>({
    service: () =>
      http.post(`${serviceUrl}/upload?${queryStr}`, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    defaultValue: { success: false, successCount: 0, failMsg: '对比失败' },
    errorMessage: '对比失败',
  });
}

export function getSaleSystemList(params: BaseListQo): Promise<SaleImportCheckListVo> {
  return requestHandler<SaleImportCheckListVo>({
    service: () => http.get(serviceUrl + '/drugList', { params }),
    defaultValue: { total: 0, content: [], page: params.page, limit: params.size },
    errorMessage: '获取系统数据失败',
  });
}

export function getSaleUploadList(params: BaseListQo): Promise<SaleImportCheckListVo> {
  return requestHandler<SaleImportCheckListVo>({
    service: () => http.get(serviceUrl + '/importList', { params }),
    defaultValue: { total: 0, content: [], page: params.page, limit: params.size },
    errorMessage: '获取上传文件数据失败',
  });
}

export function getSaleOrgList(params: BaseListQo): Promise<SaleImportCheckListVo> {
  return requestHandler<SaleImportCheckListVo>({
    service: () => http.get(serviceUrl + '/orgDrugList', { params }),
    defaultValue: { total: 0, content: [], page: params.page, limit: params.size },
    errorMessage: '获取机构数据失败',
  });
}

//清除导入数据
export function cleanExcelList(): Promise<SpecListVo[]> {
  return requestHandler<SpecListVo[]>({
    service: () => http.delete(serviceUrl + '/cleanImport'),
    defaultValue: [],
    errorMessage: '清除数据失败',
  });
}
