import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';

/**
 * 注射频率工具类
 */
export default class FrequencyHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return RegularHelper.isUnsignedInteger.test(modelRef.hit) && RegularHelper.isUnsignedInteger.test(modelRef.stop);
  }

  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): [boolean, string] {
    console.log(modelRef.weight);
    if (!FrequencyHelper.preCheck(modelRef)) {
      return [false, ''];
    }

    const specificationsName = modelRef['specificationsName'];
    const isGH54IU = specificationsName.startsWith('GH54IU');
    const is16 = +modelRef.hit === 1 && +modelRef.stop === 6;
    const notOK = isGH54IU ? !is16 : is16;
    if (notOK) {
      return [true, '频率有问题'];
    }
    return [false, ''];
  }
}
