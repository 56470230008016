import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';
import { NONE_DOUBT_TUPLE } from '../../../utils';
import { IDoubtTuple } from '../../../interface';

/**
 * 退药数量工具类
 */
export default class DrugWithdrawalNumberHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    if (!modelRef.drugWithdrawalNumber) {
      return true;
    }

    return !!modelRef.drugWithdrawalNumber && RegularHelper.isUnsignedInteger.test(modelRef.drugWithdrawalNumber);
  }

  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): IDoubtTuple {
    if (
      !DrugWithdrawalNumberHelper.preCheck(modelRef) ||
      !modelRef.drugWithdrawalNumber ||
      !DrugWithdrawalNumberHelper.verify(modelRef, modelRef.drugWithdrawalNumber)[0]
    ) {
      return NONE_DOUBT_TUPLE;
    }

    const value = +modelRef.drugWithdrawalNumber;

    if (value === 0) {
      return [true, '退药数量为0'];
    } else if (value >= 100) {
      return [true, '退药数量大于或等于100'];
    }

    return NONE_DOUBT_TUPLE;
  }

  static verify(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    value: string,
  ): [boolean, string] {
    if (!value) {
      return [true, ''];
    }

    if (RegularHelper.isUnsignedInteger.test(value)) {
      if (
        RegularHelper.isUnsignedInteger.test(modelRef.drugPurchasedNumber) &&
        +modelRef.drugPurchasedNumber < +value
      ) {
        return [false, '退药数量不得大于购药数量'];
      }

      return [true, ''];
    }

    if (RegularHelper.isInteger.test(value)) {
      return [false, '退药数量不得为负数'];
    }

    if (RegularHelper.isFloat.test(value)) {
      return [false, '退药数量不得包含小数'];
    }

    return [false, '退药数量只能为数字'];
  }
}
