export default class RegularHelper {
  /* 判断是否为实数 */
  static isFloat = /^-?\d+(\.?\d+)?$/;

  /* 是否为正实数 */
  static isUnsignedFloat = /^\d+(\.?\d+)?$/;

  /* 是否为整数 */
  static isInteger = /^-?\d+$/;

  /* 是否为正整数 */
  static isUnsignedInteger = /^\d+$/;

  /* 是否为身份证号码 */
  static isIdNumber = /^\d{17}[\d|X|x]$/;

  /* 是否为手机号码 */
  static isMobilePhone = /^\d{11}$/;

  /* 匹配浮点数 */
  static matchUnsignedFloat = /\d+(\.?\d+)?/;

  /* 匹配空字符串(包含空格) */
  static isEmptyString = /^ *$/;
}
