
  import { deleteForm } from '@/services/form';
  import { IDeleteFormParams, ModuleFormTypeMapDict } from '@/type/form';
  import notify from '@/utils/notify';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import { get } from 'lodash';
  import { defineComponent, reactive, ref, toRaw, UnwrapRef } from 'vue';
  import { useRoute } from 'vue-router';

  export default defineComponent({
    name: 'form-delete-dialog',
    emits: ['update:visible', 'after-delete-success'],
    props: {
      form: {
        type: Object,
        default: null,
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      const route = useRoute();

      const formRef = ref();
      const formState: UnwrapRef<IDeleteFormParams> = reactive({
        formId: get(route, 'query.formId'),
        subjectFormId: get(props.form, '表单ID'),
        deleteReason: '',
      });
      const delReason = reactive([
        { id: 0, name: '测试数据' },
        { id: 1, name: '系统原因' },
        { id: 2, name: '资料提供错误' },
        { id: 3, name: '重复上传任务重复录入' },
        { id: 4, name: '合并患者，用药重复' },
        { id: 5, name: '用药录入调整' },
        { id: 6, name: '匿名购药认领' },
        { id: 7, name: '疑似数据' },
        { id: 8, name: '其他' },
      ]);
      const rules = {
        deleteReason: [
          { required: true, message: '请选择删除原因', trigger: 'blur' },
          // { max: 200, message: '删除原因最多输入200个字符', trigger: 'blur' },
        ],
      };

      const handleClose = () => {
        context.emit('update:visible', false);

        setTimeout(() => {
          formRef.value.resetFields();
        }, 300);
      };

      const handleSubmit = () => {
        formRef.value
          .validate()
          .then(async () => {
            const result = await deleteForm({
              formId: ModuleFormTypeMapDict[get(route, 'params.moduleId')],
              subjectFormId: get(props.form, '表单ID'),
              deleteReason: toRaw(formState).deleteReason,
            });

            if (result) {
              handleClose();
              context.emit('after-delete-success');
              notify.success({ message: '删除成功' });
            }
          })
          .catch((error: ValidateErrorEntity<IDeleteFormParams>) => {
            console.error('error', error);
          });
      };

      return {
        rowCol: { span: 24 },
        formRef,
        formState,
        rules,
        handleClose,
        handleSubmit,
        delReason,
      };
    },
  });
