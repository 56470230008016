
  import { defineComponent, reactive, toRefs } from 'vue';
  import { ProfileOutlined } from '@ant-design/icons-vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  import CustomTable from '@/components/customTable';
  import SearchInput from '@/components/searchInput';
  import { TableState, Option } from '@/type/common';
  import { exchangeTime } from '@/lib/utils';
  import { setRouter } from './hooks';
  import { ProjectMemberQo, RmBaseListQo, ProjectMemberVo, DictionaryTypeEnum } from '@/type/api';
  import dictionary from '@/lib/dictionary';
  import { getProjectMemberListWeb, deleteProjectMember } from '@/services/manage';
  import notify from '@/utils/notify';

  export default defineComponent({
    components: {
      ManageListFrame,
      ProfileOutlined,
      CustomTable,
      SearchInput,
    },
    setup() {
      const query: RmBaseListQo<ProjectMemberQo> = reactive({});
      const params: RmBaseListQo<ProjectMemberQo> = reactive({});

      const opts = reactive({
        provinceOpts: [] as Option[],
        cityOpts: [] as Option[],
      });
      // const provinceOpts = [] as Option[];
      // const cityOpts = [] as Option[];

      // computed(async () => {
      //   if (query.provinceId) {
      //     const res = await dictionary.getStaticDictionary({
      //       type: DictionaryTypeEnum.City,
      //       provinceId: query.provinceId,
      //     });
      //   }
      //   return [];
      // });
      const tableState = reactive(
        new TableState({
          dataSource: [] as ProjectMemberVo[],
          columns: [
            { title: '成员名称', dataIndex: 'realName', ellipsis: true, width: 120 },
            { title: '角色', dataIndex: 'roleName', width: 100 },
            { title: '医院', dataIndex: 'hospitals', ellipsis: true },
            { title: '区域', dataIndex: 'regions', ellipsis: true },
            { title: '手机号', dataIndex: 'mobile', width: 160 },
            // { title: '邮箱', dataIndex: 'weight' },
            { title: '邀请人', dataIndex: 'inviter' },
            { title: '邀请时间', dataIndex: 'inviteTime' },

            {
              title: '操作',
              dataIndex: 'action',
              slots: {
                customRender: 'action',
              },
            },
          ],
        }),
      );
      const handleCitys = () => {
        handleSearch();
        dictionary
          .getStaticDictionary({
            type: DictionaryTypeEnum.City,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            jinsaiParentId: query.provinceId,
          })
          .then((res) => {
            opts.cityOpts = res.map((city) => {
              return {
                id: city.jinsaiId,
                name: city.name,
              };
            });
          });
      };
      const getData = (query?: RmBaseListQo<ProjectMemberQo>) => {
        getProjectMemberListWeb({
          ...query,
          page: tableState.pagination.current,
          size: tableState.pagination.pageSize,
        }).then((data) => {
          if (data) {
            tableState.pagination.total = data.total;

            tableState.dataSource = exchangeTime(data.content, ['inviteTime']);
          }
        });
      };
      const handlePage = ({ page, pageSize }: { page: number; pageSize: number }) => {
        tableState.pagination.current = page;
        tableState.pagination.pageSize = pageSize;
        getData(params);
      };
      const handleSearch = () => {
        Object.assign(params, query);
        tableState.pagination.current = 1;

        getData(params);
      };
      const handleDelete = (row: ProjectMemberVo) => {
        const { id, userId } = row;
        deleteProjectMember({ id, userId }).then((res) => {
          if (res) {
            notify.success({ message: '删除成功' });
            getData();
          }
        });
      };
      const toRouter = setRouter();
      dictionary
        .getStaticDictionary({
          type: DictionaryTypeEnum.Province,
        })
        .then((res) => {
          res.map((province) => {
            opts.provinceOpts.push({
              id: province.jinsaiId,
              name: province.name,
            });
          });
        });
      getData();
      return {
        query,
        tableState,
        handlePage,
        toRouter,
        handleCitys,
        handleSearch,
        handleDelete,
        ...toRefs(opts),
      };
    },
  });
