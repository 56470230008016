
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    onUnmounted,
    provide,
    readonly,
    ref,
    watchEffect,
  } from 'vue';
  import { RouteLocationNormalizedLoaded, useRoute, useRouter } from 'vue-router';
  import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons-vue';
  import { get } from 'lodash';

  import * as FormService from '@/services/form';
  import {
    IFormModuleItem,
    ICycleVisitListResponse,
    ICaseData,
    ModuleFormTypeMapDict,
    FormTypeTransDict,
    FormModuleType,
  } from '@/type/form';
  import { ObjectMap } from '@/type/helper';

  import FormLayoutModuleMenu from '../FormLayoutModuleMenu/index.vue';
  import FormModuleBaseInfo from '../FormModuleBaseInfo/index.vue';
  import CaseContainer from '../CaseContainer/index.vue';
  import { CREATE_SUBJECT_ID, genDefaultCycleVisitListResponse } from '../../utils';
  import { getLastUpdateCase } from '../CaseContainer/utils';
  import { IFetchCycleVisit } from './interface';
  import { FormOperateType, FormStatus } from '@/type/formContent';
  import notify from '@/utils/notify';
  import bus from '@/utils/EventBus';
  /**
   * 表单布局容器
   *
   * @author 孟宪邦<xianbangmeng@linkdoc.com>
   */
  export default defineComponent({
    name: 'form-layout-container',

    components: {
      MenuUnfoldOutlined,
      MenuFoldOutlined,
      FormLayoutModuleMenu,
      FormModuleBaseInfo,
      CaseContainer,
    },

    setup() {
      const route = useRoute();
      const router = useRouter();
      const loading = ref(false);
      let loadingTimer: number;
      // 机构上传展示文案，用药信息详情和患者信息使用
      const onlyInOrg = ref(false);
      // 用药信息详情和患者信息展示不同的文案
      const organizationText = ref('');
      /**
       * 标记当前组件是否挂在完毕,
       * 该属性会进行 provide, 各子组件可根据需要进行 inject
       * 该属性可用于控制 teleport 的挂载时机
       */
      const isContainerMounted = ref(false);

      const isCreatePatient = computed(() => {
        const subjectId: string = get(route, 'params.subjectId');
        const moduleId: string = get(route, 'params.moduleId');

        return moduleId === FormModuleType.PATIENT && subjectId === CREATE_SUBJECT_ID;
      });
      provide('isCreatePatient', isCreatePatient);


      // 接收数据
      // bus.on('formValue', (formValue: any) => {
      //   // 机构上传为true
      //   onlyInOrg.value = formValue && formValue.onlyInOrg;
      // });
      /**
       * 获取患者姓名, 并设置为文档标题
       */
      const fetchSubjectName = async () => {
        const subjectId: string = get(route, 'params.subjectId');
        const moduleId = get(route, 'params.moduleId');

        if (!subjectId || isCreatePatient.value) {
          return;
        }

        const subjectName = await FormService.getSubjectName({ subjectId, formId: ModuleFormTypeMapDict[moduleId] });
        if (!subjectName) {
          return;
        }

        document.title = subjectName;
      }; 

      // 获取是否是机构上传的数据 true:是，false，不是
      const checkPatientOnlyInOrg = async () => {
        // 路由跳转状态置空
        onlyInOrg.value = false;
        organizationText.value = '';
        const formId = ModuleFormTypeMapDict[get(route, 'params.moduleId')];
        const subjectId = get(route, 'params.subjectId');
        const subjectFormId = get(route, 'params.subjectFormId');
        /* query.type： 新增 1、查看 2、修改 3 、提交并继续 4*/
        const operateType = get(route, 'query.type');
        if (!formId || !subjectId) {
          console.error('获取是否是机构上传参数不足');
          return;
        }
        const res = await FormService.checkPatientOnlyInOrg({
          formId,
          subjectId: FormTypeTransDict[formId] == '患者信息' ? subjectId : '',
          subjectFormId,
          operateType,
        });
        // route.name == 'formContent' : 进入表单详情页面
        if (route.name && route.name == 'formContent' && FormTypeTransDict[formId] == '用药信息' && operateType != 1) {
          onlyInOrg.value = res;
          organizationText.value = '机构上传';
        } else if (FormTypeTransDict[formId] == '患者信息') {
          onlyInOrg.value = res;
          organizationText.value = '机构自建患者';
        }
      };


      /**
       * 循环访视列表及模块基础信息
       *
       * @params
       * cycleVisit: 循环访视列表及模块基础信息
       */
      const cycleVisit = ref<ICycleVisitListResponse>(genDefaultCycleVisitListResponse());
      provide('cycleVisit', readonly(cycleVisit));

      const fetchCycleVisit: IFetchCycleVisit = async (params, notNeedClear) => {
        if (isCreatePatient.value) {
          return;
        }
        if (!notNeedClear) {
          cycleVisit.value = genDefaultCycleVisitListResponse();
          loadingTimer = setTimeout(() => {
            loading.value = true;
          }, 100);
        }

        const cycleVisitValue = await FormService.getCycleVisitList(params);

        const moduleId = get(route, 'params.moduleId');
        // console.log(moduleId);
        // console.log(cycleVisitValue);
        if (moduleId !== cycleVisitValue.moduleId) {
          //更新处理网速慢的影响
          console.log('列表请求与当前不符');

          return;
        }
        if (moduleId === FormModuleType.PATIENT) {
          const subjectId = get(route, 'params.subjectId');
          /**
           * 这里补充说明一下
           * 患者合并后患者信息的列表中可能存在多条记录, 需要根据subjectId匹配出当前患者未删除的表单, 并进行自动跳转
           */
          const form = (get(cycleVisitValue, 'forms') || []).filter((item) => {
            return get(item, 'subjectId') === subjectId && get(item, 'status') !== FormStatus.DELETED;
          })[0];
          const subjectFormId = get(form, 'subjectFormId');
          const isNotFormContent = get(route, 'name') !== 'formContent';

          // console.log(moduleId);
          // console.log(routerParams);

          if (isNotFormContent) {
            if (!subjectFormId) {
              notify.error({
                message: '错误',
                description: '未匹配到相应患者信息',
              });
            } else {
              router.replace(`/form/content/${moduleId}/${subjectId}/${subjectFormId}?type=${FormOperateType.PREVIEW}`);
            }
          }
        }

        cycleVisit.value = cycleVisitValue;
        clearTimeout(loadingTimer);
        loading.value = false;
      };
      provide('fetchCycleVisit', fetchCycleVisit);

      const handleMenuClick = (moduleId: string) => {
        fetchCycleVisit({
          formId: ModuleFormTypeMapDict[moduleId],
          subjectId: route.params.subjectId as string,
          onlyRejected: false,
          subjectFormId: get(route, 'params.subjectFormId'),
        });
      };

      const handleReactiveTab = () => {
        if (get(route, 'name') === 'formList' && document.visibilityState === 'visible') {
          fetchCycleVisit(
            {
              formId: ModuleFormTypeMapDict[get(route, 'params.moduleId')],
              subjectId: get(route, 'params.subjectId'),
              onlyRejected: false,
              subjectFormId: get(route, 'params.subjectFormId'),
            },
            true,
          );
          fetchRedPointMap();
        }
      };

      /**
       * 模块列表相关
       *
       * @params
       * moduleList: 存放模块列表
       * moduleMap: 以Map<moduleId, mdoule>的方式缓存模块信息, 以便通过id查找相应模块时能以O(1)的访问速度
       */
      const moduleList = ref<IFormModuleItem[]>([]);
      const moduleMap = ref<ObjectMap<IFormModuleItem>>({});

      const fetchModuleList = async () => {
        const list = await FormService.getModuleList({
          moduleId: get(route, 'params.moduleId'),
          subjectFormId: get(route, 'params.subjectFormId'),
        });
        console.log(list, '模块名');
        moduleList.value = list;
        moduleMap.value = list.reduce(
          (map, module) => ({
            ...map,
            [module.id]: module,
          }),
          {},
        );
      };


      const moduleTitle = computed(() => get(moduleMap.value, `${route.params.moduleId}.name`, ''));

      /**
       * 红点任务相关
       */

      const redPointMap = ref<ObjectMap<ObjectMap<boolean>>>({});

      const fetchRedPointMap = async () => {
        const subjectId: string = get(route, 'params.subjectId');
        if (!subjectId || isCreatePatient.value) {
          return;
        }

        redPointMap.value = await FormService.getRedPoint({
          subjectId,
          formId: ModuleFormTypeMapDict[get(route, 'params.moduleId')],
        });
      };

      provide('redPointMap', readonly(redPointMap));
      provide('fetchRedPointMap', readonly(fetchRedPointMap));

      /**
       * 病例相关
       * form 中共有两个路由配置: formList 和 formContent
       * 其中只有 formContent 需要展示病例模块和相应入口, 其余模块无需展示
       *
       * 在展开状态文本后还需展示「当前表单对应的任务」中, 「最近一次数据传输」的图片数量
       *
       * @params
       * isShowCase: 是否展示病例模块
       * caseContainerCollapsed: 病例区域收起/展开状态
       *    true : 展开
       *    false: 收起
       *
       * @handler
       * handleShowCase
       */
      const isShowCase = ref<boolean>(false);
      const caseContainerCollapsed = ref<boolean>(true);
      const caseCollapseBtnText = computed(() => {
        const title = caseContainerCollapsed.value ? '关闭病例' : '显示病例';

        // 当前表单最后一次数据传输图片数量
        const subjectFormId = get(route, 'params.subjectFormId', '');
        const subjectFormCase = getLastUpdateCase(
          cases.value.filter((item) => get(item, 'meta.subjectFormId', '').includes(subjectFormId)),
        );
        const imgCount = get(subjectFormCase, 'images.length', 0) || 0;

        return `${title} (${imgCount})`;
      });

      /**
       * 根据当前路由名称判断设置病例展示状态
       * @params {string} routeName 路由名称
       */
      const handleShowCase = (routeName: RouteLocationNormalizedLoaded['name']) => {
        const isFormContent = routeName === 'formContent';

        isShowCase.value = isFormContent && !isCreatePatient.value;

        // 只要进入表单详情页面默认展开病例信息
        if (isFormContent) {
          caseContainerCollapsed.value = true;
        }
        /** 默认隐藏 病例模块 */
        const moduleId: string = get(route, 'params.moduleId');
        if (moduleId === FormModuleType.GHOFFLINE) {
          isShowCase.value = false;
          caseContainerCollapsed.value = false;
        }
      };

      // 如果有小数则最多保留两位小数，并且不四舍五入
      const TotalTwoFun = (text) => {
        if (!text) return;
        return Number(text.toString().match(/^\d+(?:\.\d{0,2})?/)).toString();
      };
      /**
       * 病例列表
       */
      const cases = ref<ICaseData[]>([]);
      const fetchCases = async () => {
        const subjectId = get(route, 'params.subjectId', '');

        if (subjectId && !isCreatePatient.value) {
          cases.value = await FormService.getCaseList({
            subjectId,
            formId: ModuleFormTypeMapDict[get(route, 'params.moduleId')] as string,
          });
          // 机构端上传的数据保留两位小数
          if (onlyInOrg.value) {
            cases.value.map((item, index) => {
              item.texts.map((item1, index1) => {
                if (item1.label == '用药信息|实际出库价') {
                  item1.value = TotalTwoFun(item1.value) as string;
                }
              });
            });
          };

        }
      };
      provide('fetchCases', fetchCases);

      watchEffect(() => {
        handleShowCase(route.name);
        checkPatientOnlyInOrg();
      });

      onBeforeMount(async () => {
        handleShowCase(route.name);
        fetchSubjectName();
        fetchModuleList();
        fetchRedPointMap();
        fetchCycleVisit({
          subjectId: route.params.subjectId as string,
          formId: ModuleFormTypeMapDict[get(route, 'params.moduleId')] as string,
          onlyRejected: false,
          subjectFormId: get(route, 'params.subjectFormId'),
        });
        fetchCases();
        window.addEventListener('visibilitychange', handleReactiveTab);
      });

      onMounted(() => {
        isContainerMounted.value = true;
      });

      onUnmounted(() => {
        window.removeEventListener('visibilitychange', handleReactiveTab);
        clearTimeout(loadingTimer);
        loading.value = false;
      });

      provide('isContainerMounted', readonly(isContainerMounted));

      return {
        isContainerMounted,
        selectedKeys: ref(['1']),
        collapsed: ref(true),
        isShowCase,
        caseContainerCollapsed,
        caseCollapseBtnText,
        moduleList,
        moduleMap,
        moduleTitle,
        cycleVisit,
        fetchCycleVisit,
        handleMenuClick,
        redPointMap,
        cases,
        fetchCases,
        isCreatePatient,
        loading,
        onlyInOrg,
        organizationText,
        FormOperateType
      };
    },
  });
