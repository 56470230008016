import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';

export default class InjectionFrequencyHitHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.specificationsName && !!modelRef.specificationsId;
  }

  static getDefaultValue(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): string {
    if (!InjectionFrequencyHitHelper.preCheck(modelRef)) {
      return '';
    }

    const specificationsName = modelRef.specificationsName || '';

    return specificationsName.includes('54IU') ? '1' : '7';
  }

  static verify(val: string): [boolean, string] {
    if (RegularHelper.isUnsignedInteger.test(val)) {
      return [true, ''];
    }

    return [false, '请输入正整数'];
  }
}
