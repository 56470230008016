import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import RegularHelper from '@/utils/regularHelper';
import { NONE_DOUBT_TUPLE } from '../../../utils';
import { IDoubtTuple } from '../../../interface';

/**
 * 购药数量工具类
 */
export default class DrugPurchasedNumberHelper {
  static preCheck(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): boolean {
    return !!modelRef.drugPurchasedNumber && RegularHelper.isUnsignedInteger.test(modelRef.drugPurchasedNumber);
  }

  static hasDoubt(modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>): IDoubtTuple {
    if (!DrugPurchasedNumberHelper.preCheck(modelRef)) {
      return NONE_DOUBT_TUPLE;
    }

    const value = +modelRef.drugPurchasedNumber;

    if (value === 0) {
      return [true, '购药数量为0'];
    } else if (value >= 100) {
      return [true, '购药数量大于或等于100'];
    }

    return NONE_DOUBT_TUPLE;
  }

  static verify(value: string): [boolean, string] {
    if (RegularHelper.isUnsignedInteger.test(value)) {
      return [true, ''];
    }

    if (RegularHelper.isInteger.test(value)) {
      return [false, '购药数量不得为负数'];
    }

    if (RegularHelper.isFloat.test(value)) {
      return [false, '购药数量不得包含小数'];
    }

    return [false, '购药数量只能为数字'];
  }
}
