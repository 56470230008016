
  import { computed, defineComponent, PropType } from 'vue';

  import { FormModuleType } from '@/type/form';
  import { IBaseFormDTO, IFormDetailHistory } from '@/type/formContent';
  import { ObjectMap } from '@/type/helper';
  import { genDefaultBaseFormDTO } from '@/views/form/utils';

  import FormPreview from '../FormPreview/index.vue';

  import { IFormPreviewGroup } from '../FormPreview/interface';
  import PatientFormPreviewConfig from '../PatientForm/preview';
  import TreatmentFormPreviewConfig from '../TreatmentForm/preview';
  import LaboratoryFormPreviewConfig from '../LaboratoryForm/preview';
  import ConsumableFormPreviewConfig from '../ConsumableForm/preview';
  import ReturnVisitFormPreviewConfig from '../ReturnVisitForm/preview';
  import StopDrugFormPreviewConfig from '../StopDrugForm/preview';
  import EPenFormPreviewConfig from '../EPenForm/preview';
  import NourishmentFormPreviewConfig from '../NourishmentForm/preview';
  import GHOffLineFormPreviewConfig from '../GHOfflineForm/preview';
  import MedicationFormPreviewConfig from '../MedicationForm/preview';

  export default defineComponent({
    name: 'form-preview-factory',
    components: {
      FormPreview,
    },
    props: {
      moduleType: {
        type: String as PropType<FormModuleType>,
        default: null,
      },
      history: {
        type: Object as PropType<ObjectMap<IFormDetailHistory[]>>,
        default: () => ({}),
      },
      data: {
        type: Object as PropType<IBaseFormDTO>,
        default: () => genDefaultBaseFormDTO(),
      },
    },
    setup(props) {
      const formClass = computed(() => {
        let cls: string;

        switch (props.moduleType) {
          case FormModuleType.PATIENT:
            cls = 'patient-form-preview';
            break;
          case FormModuleType.TREATMENT:
            cls = 'treantment-form-preview';
            break;
          case FormModuleType.MEDICATION:
            cls = 'medication-form-preview';
            break;
          case FormModuleType.LABORATORY:
            cls = 'laboratory-form-preview';
            break;
          case FormModuleType.CONSUMABLES:
            cls = 'consumables-form-preview';
            break;
          case FormModuleType.RETURN_VISIT:
            cls = 'return-visit-form-preview';
            break;
          case FormModuleType.MEDICA_DISCONTINUATION:
            cls = 'stop-drug-form-preview';
            break;
          case FormModuleType.E_PEN:
            cls = 'e-pen-form-preview';
            break;
          case FormModuleType.NOURISHMENT:
            cls = 'nutriment-form-preview';
            break;
          case FormModuleType.GHOFFLINE:
            cls = 'ghoffline-form-preview';
            break;
          default:
            cls = '';
            break;
        }

        return cls;
      });

      const configs = computed(() => {
        let conf: IFormPreviewGroup<IBaseFormDTO>[];

        switch (props.moduleType) {
          case FormModuleType.PATIENT:
            conf = PatientFormPreviewConfig;
            break;
          case FormModuleType.TREATMENT:
            conf = TreatmentFormPreviewConfig;
            break;
          case FormModuleType.MEDICATION:
            conf = MedicationFormPreviewConfig;
            break;
          case FormModuleType.LABORATORY:
            conf = LaboratoryFormPreviewConfig;
            break;
          case FormModuleType.CONSUMABLES:
            conf = ConsumableFormPreviewConfig;
            break;
          case FormModuleType.RETURN_VISIT:
            conf = ReturnVisitFormPreviewConfig;
            break;
          case FormModuleType.MEDICA_DISCONTINUATION:
            conf = StopDrugFormPreviewConfig;
            break;
          case FormModuleType.E_PEN:
            conf = EPenFormPreviewConfig;
            break;
          case FormModuleType.NOURISHMENT:
            conf = NourishmentFormPreviewConfig;
            break;
          case FormModuleType.GHOFFLINE:
            conf = GHOffLineFormPreviewConfig;
            break;
          default:
            conf = [];
            break;
        }

        return conf;
      });

      return {
        formClass,
        configs,
      };
    },
  });
