import { NotifyLevel, state } from '@/lib/state';
import { notification } from 'ant-design-vue';
import { NotificationArgsProps, IconType } from 'ant-design-vue/lib/notification';

export interface INotifyFunc {
  (config: NotificationArgsProps): void;
}

export type INotify = {
  [key in IconType]: INotifyFunc;
};

const open = (type: IconType, config: NotificationArgsProps) => {
  if (state.notifyLevel & NotifyLevel.ALL) {
    notification[type](config);
  }
};

const notifyTypes: IconType[] = ['info', 'success', 'warning', 'error'];

// eslint-disable-next-line
const notify: any = {};

notifyTypes.forEach((type) => {
  notify[type] = ((config) => open(type, config)) as INotifyFunc;
});

export default notify as INotify;
