import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "case-preview-container" }
const _hoisted_2 = {
  key: 0,
  class: "case-preview-item"
}
const _hoisted_3 = {
  key: 1,
  class: "case-preview-item"
}
const _hoisted_4 = {
  key: 0,
  class: "img-text"
}
const _hoisted_5 = {
  key: 1,
  class: "img-text"
}
const _hoisted_6 = {
  key: 2,
  class: "img-text"
}
const _hoisted_7 = ["src", "onClick"]
const _hoisted_8 = {
  key: 2,
  class: "case-preview-item"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_preview = _resolveComponent("image-preview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showTexts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.texts, (text) => {
            return (_openBlock(), _createElementBlock("div", {
              key: text.label
            }, _toDisplayString(text.label) + "：" + _toDisplayString(text.value), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showImages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "case-preview-item-img",
              key: img.key
            }, [
              (_ctx.onlyInOrg)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, "上传图片" + _toDisplayString(index + 1), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, "图" + _toDisplayString(index + 1) + "-" + _toDisplayString(img.type), 1)),
              (img.desc)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(img.desc), 1))
                : _createCommentVNode("", true),
              _createElementVNode("img", {
                src: img.url,
                onClick: ($event: any) => (_ctx.handleImageClick(img))
              }, null, 8, _hoisted_7)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showTimeBlock)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.showCreateAt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, "创建时间：" + _toDisplayString(_ctx.createdAt), 1))
            : _createCommentVNode("", true),
          (_ctx.showUpdatedAt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, "更新时间：" + _toDisplayString(_ctx.updatedAt), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_image_preview, {
      visible: _ctx.imgPreviewVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.imgPreviewVisible) = $event)),
      "current-image-id": _ctx.currentImageId,
      "onUpdate:current-image-id": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentImageId) = $event)),
      "get-container": _ctx.getContainer,
      images: _ctx.previewImages,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentImageId = ''))
    }, null, 8, ["visible", "current-image-id", "get-container", "images"])
  ]))
}