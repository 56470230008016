import { IFormNourishmentDTO } from '@/type/formNourishmentDTO';
import { genDefaultBaseFormDTO } from '@/views/form/utils';

export const genNourishmentFormDTO = (): IFormNourishmentDTO => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '营养品信息',
  organizationName: '', //机构名称
  secondLevelDepartmentCode: '',
  firstLevelDepartmentCode: '',
  khCode: '', //客户编码3
  khName: '', //客户姓名
  khId: '', //客户姓名
  cooperationDeptName: '', //合作门诊名称
  patientName: '', //孩子/用户姓名
  patientBirthday: '', //生日
  phone: '', //顾客联系方式
  patientType: '', //用户类型
  patientId: '', //用户ID
  productName: '', //产品名称
  organizationAreaId: '', //组织区域
  orderNumber: '', //订单号
  payTime: '', //支付/结算时间
  salesNum: '', //销售数量
  giveNum: '', //赠送数量
  terminalSales: '', //终端销售额
  healthyAdviser: '', //健康顾问
  uploadPaymentVoucher: '', //上传支付凭证
  firstBuyTime: '', //首购时间
  remarks: '', //备注
  firstCommitDate: '', //首次提交时间
  jobId: '', //岗位ID
  jurisdictionId: '', //岗位ID(岗位表自增ID)
  jobName: '', //岗位名称
  jobCode: '', //岗位编码
  patientCode: '', //患者编码
  subjectFormId: '', //表单ID，编辑时必填
  subjectId: '', //患者ID，编辑时必填
  creatorId: '', //创建者ID
  creatorName: '', //创建者姓名
  // source: 1, //来源，1：系统自建；2：欧电云
  autoCommit: 0, //自动提交，0：否；1：是
  status: 0, //表单状态
  returnOrderNumber: '',
  deliveryAmount: '',
  returnedMoney: '',
  organizationProvinceName: '', //'省'
  organizationId: '',
  cooperationDeptId: '',
  productId: '',
  organizationAreaName: '',
  salesStatus: '',
  salesStatusId: '',
  firstLevelDepartmentName: '',
  secondLevelDepartmentName: '',
  organizationProvinceId: '',
});
