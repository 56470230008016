import http, { requestHandler, requestBooleanHandler } from './http';
import {
  UserResetPwdQo,
  MessagePhoneCodeQo,
  LoginQo,
  UserEntityVo,
  ResetMobileQo,
  sendValidCodeQo,
  ResetPwQo,
  SingleLoginQo,
  LogoutVO,
} from '@/type/api';
const serviceUrl = '/usercenter';
export function resetPwd(params: UserResetPwdQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/resetpwd', params),
    defaultValue: false,
    errorMessage: '重设密码失败',
  });
}
export function sendResetPwdCode(params: MessagePhoneCodeQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/sendResetPwdCode', params),
    defaultValue: false,
    errorMessage: '发送验证码失败',
  }); //http.post(serviceUrl + '/sendResetPwdCode', params);
}
export function sendPhoneCodeForLogin(params: MessagePhoneCodeQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/sendPhoneCodeForLogin', params),
    defaultValue: false,
    errorMessage: '发送验证码失败',
  });
  // return http.post(serviceUrl + '/sendPhoneCodeForLogin', params);
}
export function login(params: LoginQo): Promise<UserEntityVo | null> {
  return requestHandler({
    service: () => http.post(serviceUrl + '/login', params),
    defaultValue: null,
    errorMessage: '登录失败',
  });
  return http.post(serviceUrl + '/login', params);
}
export function loginV2(params: LoginQo): Promise<UserEntityVo | null> {
  return requestHandler({
    service: () => http.post('/v2/login', params),
    defaultValue: null,
    errorMessage: '登录失败',
  });
  return http.post(serviceUrl + '/login', params);
}
export function iamLogin(params: SingleLoginQo): Promise<UserEntityVo | null> {
  return requestHandler({
    service: () => http.get('/iam' + '/login', { params }),
    defaultValue: null,
    errorMessage: '登录失败',
  });
}
export function logout(): Promise<LogoutVO> {
  return requestHandler<LogoutVO>({
    service: () => http.post(serviceUrl + '/logout'),
    defaultValue: { redirectLogoutUri: '' },
    errorMessage: '登出失败',
  });
  // return http.post(serviceUrl + '/logout');
}
// 修改密码
export function setPwd(params): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/setPwd', params),
    defaultValue: false,
    errorMessage: '修改失败',
  });
}
export function sendResetBindCode(params: sendValidCodeQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/sendResetPwdCode', params),
    defaultValue: false,
    errorMessage: '发送验证码失败',
  });
  // return http.post(serviceUrl + '/sendResetBindCode', params);
}
export function resetMobile(params: ResetMobileQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/changeMobileOrEmail', params),
    defaultValue: false,
    errorMessage: '更新手机号码失败',
  });
  // return http.post(serviceUrl + '/changeMobileOrEmail', params);
}
export function changePwd(params: ResetPwQo): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post(serviceUrl + '/changepwd', params),
    defaultValue: false,
    errorMessage: '更新密码失败',
  });
  // return http.post(serviceUrl + '/changepwd', params);
}
export function authz(): Promise<UserEntityVo | null> {
  return requestHandler<UserEntityVo | null>({
    service: () => http.post(serviceUrl + '/authz'),
    defaultValue: null,
    errorMessage: '获取授权失败',
  });

  // return http.get(serviceUrl + '/authz');
}
// export function getCaptcha(): ServiceRes<any> {
//   return http.get(serviceUrl + '/getCaptcha');
// }
