import { IFormEPenDTO } from '@/type/formEPenDTO';
import { convert2MomentForm, deserializationMomentForm, genDefaultBaseFormDTO } from '@/views/form/utils';
import moment from 'moment';

export const OPTION_KASHIPING = '赛增笔 DZ-IC';

export const convertEPenForm = (form: IFormEPenDTO<string>): IFormEPenDTO<moment.Moment> => {
  return convert2MomentForm(form, ['purchaseDate']);
};

export const deserializationEPenForm = (form: IFormEPenDTO<moment.Moment>): IFormEPenDTO<string> => {
  return deserializationMomentForm(form, [{ field: 'purchaseDate' }]);
};

export const genDefaultEPenFormDTO = (): IFormEPenDTO<moment.Moment> => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '电子笔信息',

  /**
   * 是否修改进销存点位
   */
  modifyInventoryInfo: false,

  /**
   * 库存修改记录ID
   */
  inventoryNumRecordId: '',

  /**
   * 购买省份ID
   */

  purchaseProvinceId: '',

  /**
   * 购买省份
   */

  purchaseProvinceName: '',

  /**
   * 购买医院ID
   */

  purchaseHospitalId: '',

  /**
   * 购买医院
   */

  purchaseHospitalName: '',

  /**
   * 购买日期，YYYY-MM-DD
   */

  purchaseDate: null,

  /**
   * 批号
   */

  epenBatchNumber: '',

  /**
   * 批号结余库存
   */
  epenBatchBalanceInventory: '',

  /**
   * 序列号
   */
  serialNumber: '',
  /* 规格 */
  specificationsId: '',
  specificationsName: '',
  /* 发货价 */
  deliveryPrice: '',
  /**
   * 购笔数量
   */

  purchaseQuantity: '1',

  /**
   * 退笔数量
   */

  epenWithdrawalNumber: '1',

  /**
   * 备注
   */
  epenRemark: '',
});
