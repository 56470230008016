const isDev = process.env.NODE_ENV === 'development';
let isPro = true;
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'tes') {
  isPro = false;
}
console.log(process.env, '环境变量');
const config = {
  isDev,
  permission: {
    router: true || !isDev,
    other: true || !isDev,
  },
  // https://biz.shtonghuan.com
  backEnd: process.env.VUE_APP_API + '/api', //注意history模式可能有问题
};
Object.freeze(config);
export default config;
