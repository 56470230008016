
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { cloneDeep, get } from 'lodash';
  import { computed, defineComponent, PropType, reactive, ref, toRaw, Ref, inject } from 'vue';
  import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
  import { LabeledValue } from 'ant-design-vue/lib/select';

  import { IFormPatientDTO, IPatientMobileDTO, IsBoolean } from '@/type/formContent';
  import {
    IFormMedicationDTO,
  } from '@/type/formMedicationDTO';
  import { DictionaryTypeEnum, DictionaryVo } from '@/type/api';
  import dictionary, { filterAllRegion, StaticDictionaryTypeEnum } from '@/lib/dictionary';

  import {
    computedLabeledValueModel,
    handePopupContainerByClass,
    truthFalseLists,
    AllergiesLists,
    handleFilterOptionCaseInsensitive,
  } from '../../utils';
  import FormSlice from '../FormSlice/index.vue';
  import MobileDeleteConfirmDialog from '../MobileDeleteConfirmDialog/index.vue';
  import PatientSplitDialog from '../PatientSplitDialog/index.vue';

  import EffectHandler from './effectHandler';
  import { deserializationPatientForm, convertPatientForm, genders, genPatientMobileDTO, relationships } from './utils';
  import rules, { vaildateMobile } from './rules';
  import { IFormSubmitCallback, ValidateFailedCallback } from '../../interface';
  import { genBirthHeightDoubt, genBirthWeightDoubt, genParentHeightDoubt, genBirthdayDoubt } from './optionModel';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import moment, { Moment } from 'moment';

  export default defineComponent({
    name: 'patient-form',
    emits: ['submit'],
    components: {
      FormSlice,
      MinusCircleOutlined,
      PlusOutlined,
      MobileDeleteConfirmDialog,
      PatientSplitDialog,
    },
    props: {
      formValue: {
        type: Object as PropType<IFormPatientDTO<string>>,
        default: null,
      },
    },
    setup(props) {
      const modelRef = reactive(cloneDeep(toRaw(convertPatientForm(props.formValue)))) as IFormPatientDTO<Moment>;
          // 首单用药，如果是首单则需要对是否自营做默认值
      const fisrtDetail: Ref<IFormMedicationDTO<moment.Moment, string[]> | null> = inject('fisrtDetail', ref(null));
      const formRef = ref();
      const earlyWarningRule:Ref<any> = inject('earlyWarningRule', ref(null));

      const effectHandler = EffectHandler(modelRef);

      const haveSameBaseInfo = computed(() => {
        return (modelRef.haveSameBaseInfo || '').toString() === '1';
      });

      // 是否展示过敏史
      const showAllergiesHistory = computed(() => modelRef.isHaveAllergiesHistory === IsBoolean.TRUE);
      // 是否展示肿瘤史
      const showCancerHistory = computed(() => modelRef.isHaveCancerHistory === IsBoolean.TRUE);
      // 是否展示生长激素
      const showGrowthHormone = computed(() => modelRef.isUsedGrowthHormone === IsBoolean.TRUE);

      const provinces = ref<DictionaryVo<StaticDictionaryTypeEnum>[]>([]);
      const cities = ref<DictionaryVo<StaticDictionaryTypeEnum>[]>([]);
      const nations = ref<DictionaryVo<StaticDictionaryTypeEnum>[]>([]);

      const provinceModel = computedLabeledValueModel(modelRef, {
        idField: 'residedProvinceId',
        nameField: 'residedProvinceName',
      });

      const cityModel = computedLabeledValueModel(modelRef, {
        idField: 'residedCityId',
        nameField: 'residedCityName',
      });

      const nationModel = computedLabeledValueModel(modelRef, {
        idField: 'nationId',
        nameField: 'nationName',
      });

      const handleProvinceChange = async (val: LabeledValue) => {
        modelRef.residedCityId = '';
        modelRef.residedCityName = '';

        cities.value = filterAllRegion(
          await dictionary.getStaticDictionary({
            type: DictionaryTypeEnum.City,
            jinsaiParentId: val.value as string,
          }),
          DictionaryTypeEnum.City,
        );
      };

      dictionary
        .getStaticDictionary({ type: DictionaryTypeEnum.Province })
        .then((data) => (provinces.value = filterAllRegion(data, DictionaryTypeEnum.Province)));
      dictionary.getStaticDictionary({ type: DictionaryTypeEnum.Nation }).then((data) => (nations.value = data));
      if (modelRef.residedProvinceId) {
        dictionary
          .getStaticDictionary({ type: DictionaryTypeEnum.City, jinsaiParentId: modelRef.residedProvinceId })
          .then((data) => (cities.value = filterAllRegion(data, DictionaryTypeEnum.City)));
      }

      /**
       * 手机号相关
       */
      const disableAddMobileBtn = computed(() => modelRef.mobiles.length >= 5);
      const activeMobiles = computed(() => (get(modelRef, 'mobiles') || []).filter((item) => !item.deleted));
      const handleAddMobile = () => {
        modelRef.mobiles.push(genPatientMobileDTO());
      };
      const handleDeleteMobile = (id: string) => {
        if (activeMobiles.value.length === 1) {
          return;
        }

        const idx = modelRef.mobiles.findIndex((item) => item.id === id);

        if (idx === -1 || modelRef.mobiles[idx].deleted) {
          return;
        }

        if (modelRef.mobiles[idx].isNew) {
          modelRef.mobiles.splice(idx, 1);
        } else {
          deleteMobileDialogVisible.value = true;
          deleteMobileItem.value = modelRef.mobiles[idx];
        }
      };

      const deleteMobileItem = ref<IPatientMobileDTO | null>(null);
      const deleteMobileDialogVisible = ref(false);
      const handleDeleteMobileConfirm = (mobile: IPatientMobileDTO) => {
        const idx = modelRef.mobiles.findIndex((item) => item.id === mobile.id);

        if (idx === -1) {
          return;
        }

        modelRef.mobiles.splice(idx, 1, mobile);
      };

      const patientSplitDialogVisible = ref(false);

      const getFormValue = () => {
        return deserializationPatientForm(cloneDeep(toRaw(modelRef)));
      };

      const handleSubmit = async (
        cb: IFormSubmitCallback,
        validateFailedCb: ValidateFailedCallback<ValidateErrorEntity<IFormPatientDTO<Moment>>>,
      ) => {
        formRef.value
          .validate()
          .then(() => {
            cb(getFormValue());
          })
          .catch((err: ValidateErrorEntity<IFormPatientDTO<Moment>>) => {
            validateFailedCb(err);
          });
      };
      const disabledBirthdayDate = (e) => {
        return e && e > moment().endOf('day');
      };
      const birthdayChange = (e) => {
        const selectDate = e.startOf('day').valueOf();
        const nowDate = moment().startOf('day').valueOf();
        const diffDay = (nowDate - selectDate) / 86400000;

        const year = Math.floor(diffDay / 365);
        const month = Math.floor((diffDay % 365) / 31);
        let age = '';
        if (year) {
          age = `${year}岁`;
        }
        if (month) {
          age += `${month}个月`;
        }
        if (!age) {
          age = '0岁';
        }
        modelRef.age = age;
      };
      if (!modelRef.age && modelRef.birthday) {
        birthdayChange(moment(modelRef.birthday));
      }
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
        modelRef,
        formRef,
        rules,
        vaildateMobile,
        effectHandler,
        disabledBirthdayDate,
        birthdayChange,

        haveSameBaseInfo,

        showAllergiesHistory,
        showCancerHistory,
        showGrowthHormone,

        provinces,
        cities,
        nations,
        genders,
        relationships,
        truthFalseLists,
        AllergiesLists,

        provinceModel,
        cityModel,
        nationModel,

        disableAddMobileBtn,
        activeMobiles,
        handleAddMobile,
        handleDeleteMobile,
        deleteMobileItem,
        deleteMobileDialogVisible,
        handleDeleteMobileConfirm,

        patientSplitDialogVisible,

        getFormValue,
        handleSubmit,
        handleProvinceChange,

        birthdayDoubt: genBirthdayDoubt(modelRef, fisrtDetail, earlyWarningRule),
        birthHeightDoubt: genBirthHeightDoubt(modelRef),
        birthWeightDoubt: genBirthWeightDoubt(modelRef),
        fatherHeightDoubt: genParentHeightDoubt(modelRef, 'fatherHeight', earlyWarningRule),
        motherHeightDoubt: genParentHeightDoubt(modelRef, 'motherHeight', earlyWarningRule),

        handleFilterOptionCaseInsensitive,

        handlePopupContainer: handePopupContainerByClass('patient-form'),
      };
    },
  });
