import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    class: "case-reject-dialog",
    title: "打回数据",
    onOk: _ctx.handleSubmit,
    onCancel: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formState,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { class: "reject-base-info" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    labelCol: _ctx.staticLabelCol,
                    wrapperCol: _ctx.staticWrapperCol,
                    label: "数据来源"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.importType), 1)
                    ]),
                    _: 1
                  }, 8, ["labelCol", "wrapperCol"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    labelCol: _ctx.staticLabelCol,
                    wrapperCol: _ctx.staticWrapperCol,
                    label: "审核客服"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.recordUserName), 1)
                    ]),
                    _: 1
                  }, 8, ["labelCol", "wrapperCol"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    labelCol: _ctx.staticLabelCol,
                    wrapperCol: _ctx.staticWrapperCol,
                    label: "审核时间"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.recordUpdateTime), 1)
                    ]),
                    _: 1
                  }, 8, ["labelCol", "wrapperCol"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    labelCol: _ctx.staticLabelCol,
                    wrapperCol: _ctx.staticWrapperCol,
                    label: "采集模块"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.recordModule), 1)
                    ]),
                    _: 1
                  }, 8, ["labelCol", "wrapperCol"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            ref: "causeRejection",
            label: "驳回原因",
            name: "causeRejection"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.formState.causeRejection,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.causeRejection) = $event)),
                placeholder: "请选择驳回原因",
                "show-search": "",
                onChange: _cache[1] || (_cache[1] = (val) => _ctx.causeRejectionChange(val)),
                allowClear: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.causeRejectionList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.jinsaiId,
                      title: item.jinsaiId
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["title"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }, 512),
          (_ctx.secondaryCauseArray.length)
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 0,
                ref: "secondaryCause",
                label: "二级原因",
                name: "secondaryCause"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.secondaryCause,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.secondaryCause) = $event)),
                    placeholder: "请选择二级原因",
                    onChange: _cache[3] || (_cache[3] = (val) => _ctx.secondaryCauseChange(val)),
                    mode: "multiple",
                    allowClear: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondaryCauseArray, (item) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: item.name,
                          title: item.name
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["title"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }, 512))
            : _createCommentVNode("", true),
          (_ctx.recordRejectReasonName == '其他' || _ctx.secondaryCauseList.includes('自行备注'))
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 1,
                ref: "recordRejectReason",
                label: _ctx.recordRejectReasonName == '其他' ? '其他，请描述' : _ctx.secondaryCauseList.includes('自行备注') ? '备注，请描述' : '',
                name: "recordRejectReason",
                labelCol: _ctx.rowCol,
                wrapperCol: _ctx.rowCol
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    class: "float-input-count",
                    value: _ctx.formState.recordRejectReason,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.recordRejectReason) = $event)),
                    showCount: "",
                    maxlength: 200,
                    placeholder: "请输入"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label", "labelCol", "wrapperCol"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}