/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidationRule } from 'ant-design-vue/lib/form/Form';
import { RuleObject } from 'ant-design-vue/es/form/interface';

import { ObjectMap } from '@/type/helper';
import RegularHelper from '@/utils/regularHelper';
import { IPatientMobileDTO } from '@/type/formContent';

const rules: ObjectMap<ValidationRule[]> = {
  name: [{ required: true, message: '请输入患者姓名', trigger: 'blur' }],
  sex: [{ required: true, message: '请选择患者性别', trigger: 'change' }],
  birthday: [{ required: true, message: '请选择出生日期', trigger: 'change', type: 'object' }],
  residedProvinceId: [{ required: true, message: '请选择现居住省份', trigger: 'change' }],
  residedCityId: [{ required: true, message: '请选择现居住城市', trigger: 'change' }],
  // relationShip: [{ required: true, message: '请选择家长与孩子的关系', trigger: 'change' }],
  // parentName: [{ required: true, message: '请输入家长的姓名', trigger: 'blur' }],
  fatherHeight: [
    { required: true, message: '请输入父亲身高', trigger: 'blur' },
    {
      async validator(rule: RuleObject, value: string): Promise<any> {
        if (RegularHelper.isUnsignedFloat.test(value)) {
          if (Number(value) > 200) {
            return Promise.reject('身高不能超过200');
          }
          return Promise.resolve();
        }

        return Promise.reject('请输入正确的身高');
      },
      trigger: 'blur',
    },
  ],
  motherHeight: [
    { required: true, message: '请输入母亲身高', trigger: 'blur' },
    {
      async validator(rule: RuleObject, value: string): Promise<any> {
        if (RegularHelper.isUnsignedFloat.test(value)) {
          if (Number(value) > 200) {
            return Promise.reject('身高不能超过200');
          }
          return Promise.resolve();
        }

        return Promise.reject('请输入正确的身高');
      },
      trigger: 'blur',
    },
  ],
  idNumber: [
    {
      async validator(rule: RuleObject, value: string): Promise<any> {
        if (!value || RegularHelper.isIdNumber.test(value)) {
          return Promise.resolve();
        }

        return Promise.reject('请输入正确的身份证号码');
      },
      trigger: 'blur',
    },
  ],
  cumulativeMonths: [
    {
      async validator(rule: RuleObject, value: string): Promise<any> {
        if (!value || RegularHelper.isUnsignedInteger.test(value)) {
          return Promise.resolve();
        }

        return Promise.reject('请输入数字');
      },
      trigger: 'blur',
    },
  ],
};

export const vaildateMobile = (item: IPatientMobileDTO): ValidationRule[] => [
  {
    async validator(rule: RuleObject, value: string): Promise<any> {
      if (RegularHelper.isMobilePhone.test(value) || !!item.deleted) {
        return Promise.resolve();
      }

      return Promise.reject('请输入正确的手机号');
    },
    trigger: 'blur',
  },
];

export default rules;
