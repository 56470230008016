import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_input, {
      allowClear: "",
      onInput: _ctx.handleInput,
      value: _ctx.val,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      onKeydown: _ctx.handleEnterdown,
      placeholder: _ctx.placeholder
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_a_tooltip, { title: "点击搜索" }, {
          default: _withCtx(() => [
            _createVNode(_component_SearchOutlined, { onClick: _ctx.handleSearch }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onInput", "value", "onKeydown", "placeholder"])
  ]))
}