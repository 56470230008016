
  import { get } from 'lodash';
  import { computed, defineComponent, PropType } from 'vue';

  import { ICaseData } from '@/type/form';

  import { matchFormId } from '../CaseCascaderForm/utils';
  import { CasePreviewMode } from '../CasePreview/interface';

  import { previewModes, getCasesByParams } from './utils';

  export default defineComponent({
    name: 'case-mode-switcher',
    emits: ['update:case', 'update:previewMode', 'update:rejected'],
    props: {
      previewMode: {
        type: String as PropType<CasePreviewMode>,
        default: CasePreviewMode.ALL,
      },
      rejected: {
        type: Boolean,
        default: false,
      },
      case: {
        type: Object as PropType<ICaseData>,
        default: null,
      },
      cases: {
        type: Array as PropType<ICaseData[]>,
        default: () => [],
      },
    },
    setup(props, context) {
      const currentRecordUserId = computed(() => get(props, 'case.recordUserId', ''));
      const currentFormId = computed(() => matchFormId(get(props, 'case.meta.formId', '')));

      const handlePreviewModeChange = (mode: CasePreviewMode) => {
        context.emit('update:previewMode', mode);

        const nextCase = getCasesByParams(
          props.cases,
          currentRecordUserId.value,
          currentFormId.value,
          mode,
          props.rejected,
        );

        if (nextCase === null || nextCase.id !== get(props, 'case.id')) {
          context.emit('update:case', nextCase);
        }
      };

      const handleRejectedChange = (rejected: boolean) => {
        context.emit('update:rejected', rejected);
        context.emit(
          'update:case',
          getCasesByParams(props.cases, currentRecordUserId.value, currentFormId.value, props.previewMode, rejected),
        );
      };

      return {
        previewModes,
        handlePreviewModeChange,
        handleRejectedChange,
      };
    },
  });
