import { IFormTreatmentDTO } from '@/type/formTreatmentDTO';
import RegularHelper from '@/utils/regularHelper';

export default class VisitHeightHelper {
  static hasDoubt(modelRef: IFormTreatmentDTO<moment.Moment>, earlyWarningRule: any): [boolean, string] {
    let message = '';
    let hasDoubt = false;

    if (!RegularHelper.isUnsignedFloat.test(modelRef.visitHeight)) {
      return [hasDoubt, message];
    }

    const visitHeight = +modelRef.visitHeight;
    const { minFirstDiagnosisHeight, maxFirstDiagnosisHeight } = earlyWarningRule || {};
    console.log('----minFirstDiagnosisHeight----', minFirstDiagnosisHeight, maxFirstDiagnosisHeight)
    if (visitHeight < minFirstDiagnosisHeight) {
      hasDoubt = true;
      message = `首次确诊身高＜${minFirstDiagnosisHeight}cm`;
    }
    // if (visitHeight > maxFirstDiagnosisHeight) {
    //   hasDoubt = true;
    //   message = `首次确诊身高>${maxFirstDiagnosisHeight}cm`;
    // }
    if (visitHeight <= 200) {
      if (visitHeight > maxFirstDiagnosisHeight) {
        hasDoubt = true;
        message = `首次确诊身高>${maxFirstDiagnosisHeight}cm`;
      }
    }
    return [hasDoubt, message];
  }
}
