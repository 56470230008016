import http from './http';
import { requestHandler, requestBooleanHandler } from './http';
import qs from 'qs';
import axios, { AxiosResponse } from 'axios';
import { BaseRes, UserQo, DictionaryVo, DictionaryQo, DictionaryTypeEnum, UserNameVo, Hospital } from '@/type/api';

type ServiceRes<T> = Promise<BaseRes<T>>;
export function getDictionaryList<T extends DictionaryTypeEnum>(params: DictionaryQo<T>): Promise<DictionaryVo<T>[]> {
  return requestHandler<DictionaryVo<T>[]>({
    service: () => http.get('/dictionary/list?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取字典失败',
  });
}
export function getBatchCode<T extends DictionaryTypeEnum>(params: DictionaryQo<T>): ServiceRes<DictionaryVo<T>[]> {
  console.log(params, '这是批号参数');
  return http.get('/dictionary/getBatchCode?' + qs.stringify(params));
}
export function getEPenSpec(): Promise<string[]> {
  return requestHandler<string[]>({
    service: () => http.get('/dictionary/getEpenSpeclist'),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取规格失败',
  });
}
export function getEPenPrice(params: { hospitalId?: string; spec?: string; batchCode?: string }): Promise<string[]> {
  return requestHandler<string[]>({
    service: () => http.get('/dictionary/getElectronicPenPrice?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取发货价失败',
  });
}

export function getEPenBatchCode(params: { hospitalId: string; spec?: string }): Promise<string[]> {
  return requestHandler<string[]>({
    service: () => http.get('/dictionary/getElectronicPenBatchCode?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取电子笔批号失败',
  });
}
export function getEPenStoreNum(params: {
  hospitalId: string;
  batchCode: string;
  spec?: string;
  price?: string;
}): Promise<string> {
  return requestHandler<string>({
    service: () => http.get('/dictionary/getElectronicPenStoreNum?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: '',
    errorMessage: '获取批号结余库存失败',
  });
}
export function batchInviteMembers(params: UserQo[]): Promise<boolean> {
  return requestBooleanHandler({
    service: () => http.post('/project/batchInviteMembers', params),
    dataPath: 'data',
    defaultValue: false,
    errorMessage: '邀请失败',
  });
}

export function getSystemStatus(): Promise<void> {
  return requestHandler<void>({
    service: () => http.get('/system/status'),
    dataPath: 'data',
    defaultValue: void 0,
    errorMessage: '提示',
    onSuccess() {
      window.location.replace('/login');
    },
  });
}

export function getUserName(params: { phone: string }): Promise<UserNameVo> {
  return requestHandler<UserNameVo>({
    service: () => http.get('/projectSite/getUserName?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: {},
    errorMessage: '获取用户姓名失败',
  });
}
export function getHospital(params: { hospitalName: string }): Promise<Hospital[]> {
  return requestHandler<Hospital[]>({
    service: () => http.get('/dictionary/getHospital?' + qs.stringify(params)),
    dataPath: 'data',
    defaultValue: [],
    errorMessage: '获取医院失败',
  });
}
export function getCurVersion(): Promise<AxiosResponse<string>> {
  return axios.get('/meta.txt');
}
