import { isPc } from '@/lib/utils';
import { useRouter } from 'vue-router';

const useRouteBeforeEach = (): void => {
  const router = useRouter();
  router.beforeEach((to, from, next) => {
    if (!isPc() && to.name !== 'noweb') {
      const { h5Page, h5Params } = to.meta;
      if (h5Page) {
        const { target_uri = '', code = '' } = to.query;
        const href = `/h5${h5Page}?compname=${(to.name as string) || 'default'}${h5Params ? `&${h5Params}` : ''}${
          target_uri ? `&target_uri=${target_uri}` : ''
        }${code ? `&code=${code}` : ''}`;
        location.href = href;
        return;
      } else {
        next('noweb');
        return;
      }
    }
    if (to.name != 'problemFeedback') {
      next();
    }
  });
};

export default useRouteBeforeEach;
