/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDropdownItem } from '@/type/common';
import { IsBoolean } from '@/type/formContent';
import RegularHelper from '@/utils/regularHelper';
import { RuleObject } from 'ant-design-vue/lib/form/interface';
import { LabeledValue } from 'ant-design-vue/lib/select';
import { get, isPlainObject, isString } from 'lodash';
import moment from 'moment';
import { computed, WritableComputedRef } from 'vue';
import { IDoubtTuple, IFormDoubt, LabeledValueModelConfig } from './interface';

export const AllergiesLists: IDropdownItem<IsBoolean>[] = [
  { label: '是', value: IsBoolean.TRUE },
  { label: '否', value: IsBoolean.FALSE },
  { label: '未知', value: IsBoolean.UNKNOW },
];

export const truthFalseLists: IDropdownItem<IsBoolean>[] = [
  { label: '是', value: IsBoolean.TRUE },
  { label: '否', value: IsBoolean.FALSE },
];
export const isDiscounts: IDropdownItem[] = [
  { label: '是', value: '是' },
  { label: '否', value: '否' },
];
export const discounts: IDropdownItem[] = [
  { label: '5折以上', value: '5折以上' },
  { label: '5折（包含）以下', value: '5折（包含）以下' },
];

export const computedLabeledValueModel = <T, K extends keyof T>(
  modelRef: T,
  config: LabeledValueModelConfig<T, K>,
): WritableComputedRef<LabeledValue> => {
  return computed({
    get: (): LabeledValue => {
      const result = modelRef[config.idField]
        ? {
            label: modelRef[config.nameField],
            key: modelRef[config.idField],
            value: modelRef[config.idField],
          }
        : undefined;

      return result as unknown as LabeledValue;
    },
    set: (opt: LabeledValue) => {
      console.log(opt);
      modelRef[config.idField] = get(opt, 'value', '') as unknown as T[LabeledValueModelConfig<T, K>['idField']];
      modelRef[config.nameField] = get(opt, 'label.children', '');
    },
  });
};

export const computedStringModel = <T>(modelRef: T, field: string): WritableComputedRef<string> => {
  return computed({
    get: (): string => {
      return modelRef[field] as string;
    },
    set: (opt: string) => {
      console.log(opt);
      modelRef[field] = opt;
    },
  });
};

export const resolveAsyncEmptyList = (): Promise<any[]> => Promise.resolve([]);

export const handleFilterOptionCaseInsensitive = (inputVal: string, option: any): boolean => {
  console.log(get(option, 'title'));
  return (get(option, 'title') || '').toLocaleLowerCase().includes((inputVal || '').toLocaleLowerCase());
};

export const OPTION_OTHER = '其他';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EmptyAsyncFunc = async () => {};

export const EMPTY_DOUBT = Object.freeze<IFormDoubt>({
  help: undefined,
  class: '',
});

export const handePopupContainerByClass = (className: string) => () => {
  return document.getElementsByClassName(className)[0];
};

export const NONE_DOUBT_TUPLE: IDoubtTuple = [false, ''];

// eslint-disable-next-line @typescript-eslint/ban-types
export const trimFormValue = <T extends object>(formValue: T): T => {
  if (isPlainObject(formValue)) {
    Object.keys(formValue).forEach((key) => {
      formValue[key] = isString(formValue[key]) ? formValue[key].trim() : formValue[key];
    });
  }

  return formValue;
};

export const generateNonnegativeValidator = (fieldCn: string, type: 'integer' | 'float') => {
  let numberRegular: RegExp;
  let unsignedNumberRegular: RegExp;

  if (type === 'integer') {
    numberRegular = RegularHelper.isInteger;
    unsignedNumberRegular = RegularHelper.isUnsignedInteger;
  } else if (type === 'float') {
    numberRegular = RegularHelper.isFloat;
    unsignedNumberRegular = RegularHelper.isUnsignedFloat;
  } else {
    throw new Error(`type参数错误, 应为integer或float, 实际传入参数为${type}`);
  }

  return async (rule: RuleObject, value: string): Promise<void> => {
    if (numberRegular.test(value)) {
      if (unsignedNumberRegular.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(`${fieldCn}不得为负数`);
    }

    return Promise.reject(`${fieldCn}只能为数字`);
  };
};

export const formatDateWithNone = (date?: string, dateFmt = 'YYYY-MM-DD') => {
  return date ? moment(date).format(dateFmt) : '';
};
