
  import { defineComponent, toRefs } from 'vue';
  import { state } from '@/lib/state';
  export default defineComponent({
    props: {
      listName: {
        required: true,
        type: String,
      },
    },
    setup() {
      const { loading } = toRefs(state);
      return {
        loading,
      };
    },
  });
