import { uniqueId } from 'lodash';

import { IFormEPenDTO } from '@/type/formEPenDTO';
import { isShowMedicationDurationExplainRemark } from './optionModel';
import { IFormPreviewGroup } from '../FormPreview/interface';
import { formatDateWithNone } from '../../utils';

const previewConfig: IFormPreviewGroup<IFormEPenDTO>[] = [
  {
    id: uniqueId('EpenForm'),
    groupName: false,
    items: [
      {
        label: '购买省份',
        fieldName: 'purchaseProvinceName',
      },
      { label: '购买省份医院', fieldName: 'purchaseHospitalName' },
      {
        label: '购买日期',
        fieldName: 'purchaseDate',
        format: (val) => formatDateWithNone(val),
      },
      { label: '规格', fieldName: 'specificationsName' },
      { label: '批号', fieldName: 'epenBatchNumber' },
      { label: '发货价(元/支)', fieldName: 'deliveryPrice', isShow: isShowMedicationDurationExplainRemark },
      { label: '批号结余库存', fieldName: 'epenBatchBalanceInventory' },
      {
        label: '序列号',
        fieldName: 'serialNumber',
      },
      { label: '购笔数量', fieldName: 'purchaseQuantity' },
      { label: '退笔数量', fieldName: 'epenWithdrawalNumber', isShow: isShowMedicationDurationExplainRemark },
      { label: '备注', fieldName: 'epenRemark' },
    ],
  },
];

export default previewConfig;
