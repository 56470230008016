import http from './http';
import {
  BaseRes,
  // MessagePhoneCodeQo,
  // LoginQo,
  // UserEntityVo,
  // ResetMobileQo,
  // sendValidCodeQo,
} from '@/type/api';
const serviceUrl = '/picture';
type ServiceRes<T> = Promise<BaseRes<T>>;
export function getImageAddress(params: { key: string }): ServiceRes<string> {
  return http.post(serviceUrl + '/getImageAddress', params);
}
export function uploadPic(file: Blob): ServiceRes<string> {
  console.log(file);
  const formData = new FormData();
  formData.append('file', file);
  return http.post(serviceUrl + '/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
// export function getCaptcha(): ServiceRes<any> {
//   return http.get(serviceUrl + '/getCaptcha');
// }
