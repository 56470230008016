import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_preview = _resolveComponent("form-preview")!

  return (_ctx.data)
    ? (_openBlock(), _createBlock(_component_form_preview, {
        key: 0,
        class: _normalizeClass(['from-preview-factory', _ctx.formClass]),
        configs: _ctx.configs,
        data: _ctx.data,
        history: _ctx.history
      }, null, 8, ["class", "configs", "data", "history"]))
    : _createCommentVNode("", true)
}