import { ValidationRule } from 'ant-design-vue/lib/form/Form';
import { ObjectMap } from '@/type/helper';
import { RuleObject } from 'ant-design-vue/lib/form/interface';
import RegularHelper from '@/utils/regularHelper';
// 请选择
// 请填写

const phoneValidator = (rule: RuleObject, value: string): Promise<string | void> => {
  if (!value || RegularHelper.isMobilePhone.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject('请输入正确的手机号');
};

const numValidator = (rule: RuleObject, value: string): Promise<string | void> => {
  if (RegularHelper.isInteger.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject('请输入有效整数');
};

const giveNumValidator = (rule: RuleObject, value: string): Promise<string | void> => {
  if (!value || String(value).length === 0) {
    return Promise.resolve();
  }
  return numValidator(rule, value);
};
export const rules: ObjectMap<ValidationRule[]> = {
  organizationId: [{ required: true, message: '请选择机构名称', trigger: 'change' }],
  productId: [{ required: true, message: '请选择产品名称', trigger: 'change' }],
  organizationProvinceId: [{ required: true, message: '请选择省', trigger: 'change' }],
  organizationAreaId: [{ required: true, message: '请选择市', trigger: 'change' }],
  payTime: [{ required: true, message: '请选择支付/结算时间', trigger: 'change' }],
  salesNum: [{ required: true, validator: numValidator, trigger: 'blur' }],
  terminalSales: [{ required: true, validator: numValidator, trigger: 'blur' }],
  giveNum: [{ validator: giveNumValidator, trigger: 'blur' }],
  firstLevelDepartmentName: [{ min: 0, max: 500, message: '长度不超过500', trigger: 'change' }],
  secondLevelDepartmentName: [{ min: 0, max: 500, message: '长度不超过500', trigger: 'change' }],
  khCode: [{ min: 0, max: 100, message: '长度不超过100', trigger: 'change' }],
  khName: [{ min: 0, max: 50, message: '长度不超过50', trigger: 'change' }],
  phone: [{ validator: phoneValidator, trigger: 'blur' }],
  patientType: [{ min: 0, max: 500, message: '长度不超过500', trigger: 'change' }],
  subjectId: [{ min: 0, max: 500, message: '长度不超过500', trigger: 'change' }],
  orderNumber: [{ min: 0, max: 500, message: '长度不超过500', trigger: 'change' }],
  remarks: [{ min: 0, max: 500, message: '长度不超过500', trigger: 'change' }],
  khId: [
    // { required: true, message: '请填写KHID', trigger: 'change' },
    { min: 0, max: 50, message: '长度不超过50', trigger: 'change' },
  ],

  healthyAdviser: [{ min: 1, max: 50, message: '长度不超过50', trigger: 'change' }],
};

export default rules;
