import { IFormLaboratoryDTO } from '@/type/formLaboratoryDTO';
import { genDefaultBaseFormDTO } from '@/views/form/utils';

export const genDefaultFormLaboratoryDTO = (): IFormLaboratoryDTO => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '实验室检查信息',

  /* 骨龄检查 */
  boneAge: '',
});
