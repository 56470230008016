import { reactive } from 'vue';
import { UserEntityVo } from '@/type/api';
import { authz } from '@/services/usercenter';
import { useRouter } from 'vue-router';
/**
 * 提示信息级别
 *
 * 此处的值除了SILENCE外, 其余枚举类型均使用了位移进行赋值
 * 后续若新增枚举类型, 请严格遵守「位移赋值」的规则
 *
 * @enum
 */
const router = useRouter();
export enum NotifyLevel {
  /**
   * 静默, 不做任何提示
   */
  SILENCE = 0,
  /**
   * 允许提醒通知
   */
  INFO = 1 << 0,
  /**
   * 允许成功通知
   */
  SUCCESS = 1 << 1,
  /**
   * 允许警告通知
   */
  WARNING = 1 << 2,
  /**
   * 允许失败通知
   */
  ERROR = 1 << 3,
  /**
   * 允许所有通知
   */
  ALL = INFO | SUCCESS | WARNING | ERROR,
}

export enum LocalLoginStatus {
  /** 使用OA返回的的type进行本项目登录中 */
  LOGINGING = 'logining',
  /** 本项目登录成功 */
  SUCCESS = 'success',
  /** 本项目登录 无此账号 */
  NOUSER = 'noUser',
  /** 本项目登录 对应账号未在成员管理配置权限 */
  NOPERMISSION = 'noPermission',
}

export interface State {
  loading: boolean;
  /** 需要登录 */
  needLogin: boolean;
  /** 金赛OA 登录/登出地址 */
  jinsaiOAUri?: string;
  /** 金赛OA后，本地验证是否失败 */
  localLoginStatus: LocalLoginStatus;
  userInfo: UserEntityVo | null;
  notifyLevel: NotifyLevel;
}
export const state: State = reactive({
  loading: false,
  needLogin: false,
  localLoginStatus: LocalLoginStatus.LOGINGING,
  userInfo: null,
  notifyLevel: NotifyLevel.ALL,
});

export const loginTest = (): Promise<UserEntityVo | null> => {
  return new Promise((resolve, reject) => {
    authz()
      .then((res) => {
        if (res) {
          state.userInfo = res;
        } else {
          state.userInfo = null;
          if (window.location.pathname != '/login') {
            window.location.href = location.origin + '/login';
          }
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        state.userInfo = null;
      });
  });
};
