
  import { defineComponent, ref, watch } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import { state } from '@/lib/state';
  import { getCurVersion } from '@/services/common';
  import { useRoute } from 'vue-router';
  import { goJinSaiOA, useAuthTest } from '@/components/Login/useLogin';
  import { isPc } from '@/lib/utils';
  import useRouteBeforeEach from '@/router/hooks';
  import { CONSTANS_KEY, useLocalStorageItem } from './lib/constans';
  export default defineComponent({
    setup() {
      const route = useRoute();
      const version = ref('');
      const versionModal = ref(false);
      const localItem = useLocalStorageItem(CONSTANS_KEY.GHOFFLINE_AUDIT_COLUMNS);
      useRouteBeforeEach();
      if (isPc()) {
        // 进入页面 进行登录检测
        useAuthTest();
      }
      /** 监听是否要登陆 */
      watch(
        () => state.needLogin,
        (newVal) => {
          if (newVal) {
            localItem.clearItem();
            if (route.name !== 'login') {
              goJinSaiOA();
            }
          }
        },
      );
      /** 获取当前服务器版本 */
      const handleVersion = () => {
        getCurVersion()
          .then((ver) => {
            console.log(ver);

            if (version.value) {
              if (version.value !== ver.data) {
                versionModal.value = true;
              } else {
                setTimeout(handleVersion, 60000);
              }
            } else {
              version.value = ver.data;
              setTimeout(handleVersion, 60000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      handleVersion();

      return { locale: zhCN, versionModal };
    },
  });
