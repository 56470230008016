
  import { defineComponent, onMounted, PropType, reactive, ref, toRaw, toRefs } from 'vue';
  import { IFormGHOfflineDTO } from '@/type/formGHOfflineDTO';
  import { Option } from '@/type/common';
  import { cloneDeep, get } from 'lodash';
  import dictionary from '@/lib/dictionary';
  import { DictionaryTypeEnum } from '@/type/api';
  import { filterOption } from '@/lib/utils';
  // import { getCategoryList, getSpecsList } from '@/services/ghoffline';
  import useRules from './rules';
  import { useRoute } from 'vue-router';

  type Opt_keys =
    | 'jobOpts'
    | 'provinceOpts'
    | 'cityOpts'
    | 'hospitalOpts'
    | 'specOpts'
    | 'categoryOpts'
    | 'deliveryTypeOpts';
  export default defineComponent({
    props: {
      formValue: {
        type: Object as PropType<IFormGHOfflineDTO>,
        default: null,
      },
    },
    setup(props) {
      const route = useRoute();
      const formState = reactive(cloneDeep(toRaw(props.formValue)));
      const formRef = ref();
      const opts = reactive<Record<Opt_keys, Option[]>>({
        jobOpts: [] as Option[],
        /** 省 列表 */
        provinceOpts: [] as Option[],
        /** 市 列表 */
        cityOpts: [] as Option[],
        /** 医院 列表 */
        hospitalOpts: [] as Option[],
        /** 品规简称 列表 */
        specOpts: [] as Option[],
        /** 三级品种 列表*/
        categoryOpts: [] as Option[],
        /** 配送方式 列表 */
        deliveryTypeOpts: [
          { id: '直发', name: '直发' },
          { id: '商配', name: '商配' },
        ],
      });
      const rules = useRules(get(route, 'params.subjectFormId', ''));
      const getFormValue = () => {
        return cloneDeep(toRaw(formState));
      };
      const getProvinceOpts = () => {
        dictionary
          .getStaticDictionary({
            type: DictionaryTypeEnum.Province,
          })
          .then((res) => {
            res.map((province) => {
              opts.provinceOpts.push({
                id: province.jinsaiId,
                name: province.name,
              });
            });
          });
      };
      const getCityOpts = (provinceId: string) => {
        dictionary
          .getStaticDictionary({
            type: DictionaryTypeEnum.City,
            jinsaiParentId: provinceId,
          })
          .then((res) => {
            opts.cityOpts = res.map((city) => {
              return {
                id: city.jinsaiId,
                name: city.name,
              };
            });
          });
      };
      const getHospitalOpts = (cityId: string) => {
        dictionary
          .getStaticDictionary({
            type: DictionaryTypeEnum.Hospital,
            cityId: cityId,
          })
          .then((res) => {
            opts.hospitalOpts = res.map((hospital) => {
              return {
                id: hospital.jinsaiId,
                name: hospital.name,
              };
            });
          });
      };
      // const getSpecsOpts = () => {
      //   getSpecsList().then((res) => {
      //     opts.specOpts = res.map((spec) => ({ id: spec.jinsaiId, name: spec.value }));
      //   });
      // };
      // const getCategoryOpts = () => {
      //   getCategoryList().then((res) => {
      //     opts.categoryOpts = res.map((category) => ({ id: category, name: category }));
      //   });
      // };
      const handleProvince = (value: string, opt: Option) => {
        if (!value) {
          formState.provinceName = '';
          formState.cityId = '';
          formState.cityName = '';
          formState.hospitalId = '';
          formState.hospitalName = '';
          opts.cityOpts = [];
          opts.hospitalOpts = [];
          return;
        }
        formState.provinceName = opt.name;
        getCityOpts(value);
      };
      const handleCity = (value: string, opt: Option) => {
        if (!value) {
          formState.cityName = '';
          formState.hospitalId = '';
          formState.hospitalName = '';
          opts.hospitalOpts = [];
          return;
        }
        formState.cityName = opt.name;
        getHospitalOpts(value);
      };
      const handleHospital = (value: string, opt: Option) => {
        formState.hospitalName = opt.name;
      };
      const handleSpec = (value: string, opt: Option) => {
        formState.specName = opt.name;
      };
      onMounted(() => {
        getProvinceOpts();
        formState.provinceId && getCityOpts(formState.provinceId);
        formState.cityId && getHospitalOpts(formState.cityId);
        // getSpecsOpts();
        // getCategoryOpts();
      });
      return {
        ...toRefs(opts),
        rules,
        formRef,
        formState,
        filterOption,
        getFormValue,
        handleProvince,
        handleCity,
        handleHospital,
        handleSpec,
      };
    },
  });
