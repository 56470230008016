import { UnwrapNestedRefs } from '@vue/reactivity/dist/reactivity';
import { IFormMedicationDTO } from '@/type/formMedicationDTO';
import { IDoubtTuple } from '../../../interface';
import moment, { Moment } from 'moment';
/**
 * 如果用药规格为：【GH54IU】/【GH54IU(卡式瓶)】，在 剂量信息 部分中的字段【注射剂量单位】如果选中IU/w时，提示：长效注射单位疑似异常
 */
export default class DrugWithdrawalNumberHelper {
  static hasDoubt(
    modelRef: UnwrapNestedRefs<IFormMedicationDTO<moment.Moment, string[]>>,
    dictionaryList: any,
  ): IDoubtTuple {
    const specificationsName = modelRef.specificationsName;
    const injectionUnit = modelRef.injectionUnit;
    if ((specificationsName == 'GH54IU' || specificationsName == 'GH54IU(卡式瓶)') && injectionUnit == 'IU/w') {
      return [true, '长效注射单位疑似异常'];
    }
    return [false, ''];
  }
}
