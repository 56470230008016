
  import { defineComponent, onMounted, reactive, ref } from 'vue';
  import { DownloadOutlined, UploadOutlined } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';
  import { downLoadMoudle, downLoadPatientError, upload } from '@/services/patientImport';
  import { v4 as uuid } from 'uuid';
  export default defineComponent({
    components: {
      DownloadOutlined,
      UploadOutlined,
    },
    setup() {
      const state = ref(1);
      const fileList = ref([]);
      const fileName = ref('');
      const sign = ref('');
      const num = reactive({
        successNum: 0,
        failedNum: 0,
      });

      const handleChange = () => {
        // console.log(fileList.value);
        fileList.value = [];
      };
      const beforeUpload = (file: any) => {
        const hide = message.loading('正在上传，请稍候...', 0);
        fileName.value = file.name;
        sign.value = uuid();
        const fileData = new FormData();
        console.log(file);
        fileData.append('fileData', file);
        fileData.append('sign', sign.value);
        upload(fileData).then((res: any) => {
          console.log(res, '成功啦');
          if (res.data.msg == '上传成功') {
            setTimeout(hide, 0);
            state.value = 2;
            num.successNum = res.data.successNum;
            message.success(res.data.msg);
          } else {
            setTimeout(hide, 0);
            state.value = 3;
            message.error(res.data.msg);
            num.successNum = res.data.successNum;
            num.failedNum = res.data.failedNum;
          }
        });
        return false;
      };
      const downLoad = () => {
        downLoadMoudle().then((res: any) => {
          console.log(res, '这是数据');
          const blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' });
          const fileName = '批量上传导入模板.xlsx';
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
        });
      };
      const downLoadError = () => {
        console.log(sign.value);
        downLoadPatientError(sign.value).then((res: any) => {
          console.log(res, '这是数据');
          const blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' });
          const fileName = '失败数据.xlsx';
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
        });
      };
      onMounted(() => {
        document.title = '非公机构销量线下审核';
      });
      return {
        state,
        handleChange,
        fileList,
        beforeUpload,
        fileName,
        downLoad,
        downLoadError,
        num,
      };
    },
  });
