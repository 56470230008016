
  import { debounce, get } from 'lodash';
  import { computed, defineComponent, inject, provide, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import { FormModuleType, FormType, ModuleFormTypeMapDict,FormTypeTransDict, } from '@/type/form';
  import { FormOperateType, FormStatus, IBaseFormDTO, IFormDetailHistory, FormSource,} from '@/type/formContent';
  import { ObjectMap } from '@/type/helper';
  import * as FormService from '@/services/form';

  import FormPreviewFactory from './components/FormPreviewFactory/index.vue';
  import FormFactory from './components/FormFactory/index.vue';
  import createFormDetailByType from './initForm';
  import { operableModule } from '../list/utils';
  import { CREATE_SUBJECT_ID } from '../utils';
  import { preSerializationFormValue } from './preSerialization';
  import bus from '@/utils/EventBus';
  export default defineComponent({
    name: 'form-content',
    components: {
      FormFactory,
      FormPreviewFactory,
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const isContainerMounted = inject('isContainerMounted', ref(false));

      const formRef = ref();
      const formValue = ref<IBaseFormDTO | null>(null);
      const formHistory = ref<ObjectMap<IFormDetailHistory[]>>({});
      // 预警规则参数服务端可配置
      const dictionaryList = ref<any>({});
      const isSaving = ref(false);
      // 机构端用药信息详情点击提交并继续，底部按钮应该置灰
      const continueDisabled = ref(false);
      const operateType = computed(() => {
        let type: FormOperateType = get(route, 'query.type');

        if (
          ![FormOperateType.PREVIEW, FormOperateType.CREATE, FormOperateType.MODIFY, FormOperateType.CONTINUE].includes(
            type,
          )
        ) {
          type = FormOperateType.PREVIEW;
        }

        return type;
      });
      provide('operateType', operateType);

      const isPreviewType = computed(() => operateType.value === FormOperateType.PREVIEW);
      // 如果有小数则最多保留两位小数，并且不四舍五入
      const TotalTwoFun = (text) => {
        if (!text) return;
        return Number(text.toString().match(/^\d+(?:\.\d{0,2})?/)).toString();
      };
      const fetchFormDetail = async (subjectFormId: string, operateType: FormOperateType) => {
        const formId = ModuleFormTypeMapDict[get(route, 'params.moduleId')];
        const subjectId = get(route, 'params.subjectId');

        if (!formId || !subjectId) {
          console.error('获取表单详情参数不足');
          return;
        }

        const res = await FormService.getFormDetail({
          formId,
          subjectId,
          subjectFormId,
          operateType,
        });
        // 用药信息详情页面机构端上传的数据保留两位小数
        if (res.content.source == FormSource.ORG) {
          if (res.content.actualDeliveryPrice) {
            res.content.actualDeliveryPrice = TotalTwoFun(res.content.actualDeliveryPrice) as string;
          }
          // 用药信息详情的查看和编辑页面才会执行下面的方法
          if (route.name && route.name == 'formContent' && FormTypeTransDict[formId] == '用药信息') {
            res?.history?.actualDeliveryPrice.map((item, index) => {
              if (item.value) {
                item.value = TotalTwoFun(item.value) as string;
              }
            });
          }
        }
        formValue.value = preSerializationFormValue(res.content, formId, operateType === FormOperateType.CREATE);
        console.log(formValue);
        // 发送数据
        // bus.emit('formValue', formValue.value);

        formHistory.value = res.history;
      };
    //   // 预警规则参数服务端可配置
    //   const fetchDictionaryList = async () => {
    //     const res = await FormService.dictionaryList({ type: 'EarlyWarningRule' });
    //     if (res.length) {
    //       dictionaryList.value = JSON.parse(res[0]['remark']);
    //       // 发送数据
    //       bus.emit('dictionaryList', dictionaryList.value);
    //     }
    //   };

      const initFormDetail = async () => {
        const formId = ModuleFormTypeMapDict[get(route, 'params.moduleId')];
        const subjectId = get(route, 'params.subjectId');
        const subjectFormId = get(route, 'params.subjectFormId');

        if (!formId || !subjectId || !subjectFormId) {
          console.error('获取表单详情参数不足');
          return;
        }

        const isAutoMapForm = [FormType.TREATMENT, FormType.MEDICATION, FormType.E_PEN].includes(formId);
        const isCreateMode = operateType.value === FormOperateType.CREATE;

        if (isCreateMode && !isAutoMapForm) {
          formValue.value = createFormDetailByType(formId);
          formHistory.value = {};
        } else {
          await fetchFormDetail(
            isCreateMode && isAutoMapForm ? '' : subjectFormId,
            isCreateMode && isAutoMapForm ? FormOperateType.CREATE : operateType.value,
          );
        }
        // fetchDictionaryList();
      };

      initFormDetail();

      const isShowTemporarySubmit = computed(() => {
        return get(formValue.value, 'status') !== FormStatus.SUBMITTED;
      });

      const isShowPatientEditBtn = computed(() => {
        return (
          get(route, 'params.moduleId') === FormModuleType.PATIENT && operateType.value === FormOperateType.PREVIEW
        );
      });

      const isMedicationModule = computed(() => {
        return get(route, 'params.moduleId') === FormModuleType.MEDICATION;
      });
      const submitStay = computed(() => {
        const moduleId = get(route, 'params.moduleId');
        return [FormModuleType.NOURISHMENT].includes(moduleId);
      });
      const showSubmit = computed(() => {
        const moduleId = get(route, 'params.moduleId');
        return ![FormModuleType.GHOFFLINE].includes(moduleId);
      });

      const isMountFooter = computed(() => {
        const isOperableModule = operableModule.includes(get(route, 'params.moduleId'));

        if (!isContainerMounted.value) {
          return false;
        }

        if (isShowPatientEditBtn.value) {
          return true;
        }

        return isOperableModule && !isPreviewType.value;
      });

      const isDisableBtn = computed(() => {
        const moduleId = get(route, 'params.moduleId');
        if (FormModuleType.GHOFFLINE === moduleId) {
          return (
            // 已红冲 已删除
            [FormStatus.HONG_CHONG, FormStatus.DELETED].includes(get(formValue.value, 'status') as FormStatus) ||
            isSaving.value
          );
        }
        return (
          // 已驳回  已红冲  已删除
          [FormStatus.REJECTED, FormStatus.HONG_CHONG, FormStatus.DELETED].includes(
            get(formValue.value, 'status') as FormStatus,
          ) || isSaving.value
        );
      });



      // const isDisableBtn = computed(() => {
      //   const moduleId = get(route, 'params.moduleId');
      //   const formId = ModuleFormTypeMapDict[moduleId];
      //   // 用药信息
      //   const routerNmae = FormTypeTransDict[formId];
      //   // 机构端不能多次提交(用药信息详情页面)
      //   if (routerNmae == '用药信息' && get(formValue.value, 'source') == 7) {
      //     if (FormModuleType.GHOFFLINE === moduleId) {
      //       return (
      //         // 已红冲 已删除
      //          ([FormStatus.HONG_CHONG, FormStatus.DELETED, FormStatus.SUBMITTED].includes(get(formValue.value, 'status') as FormStatus) ||
      //         isSaving.value)
      //       );
      //     }
      //     return (
      //       // 已驳回  已红冲  已删除
      //       ([FormStatus.REJECTED, FormStatus.HONG_CHONG, FormStatus.DELETED, FormStatus.SUBMITTED].includes(
      //         get(formValue.value, 'status') as FormStatus,
      //       ) || isSaving.value)
      //     );
      //   };
      //   if (FormModuleType.GHOFFLINE === moduleId) {
      //     return (
      //       // 已红冲 已删除
      //       [FormStatus.HONG_CHONG, FormStatus.DELETED].includes(get(formValue.value, 'status') as FormStatus) ||
      //       isSaving.value
      //     );
      //   }
      //   return (
      //     // 已驳回  已红冲  已删除
      //     [FormStatus.REJECTED, FormStatus.HONG_CHONG, FormStatus.DELETED].includes(
      //       get(formValue.value, 'status') as FormStatus,
      //     ) || isSaving.value
      //   );
      // });

      const handleEditPatientModule = () => {
        const formId = get(route, 'params.moduleId');
        const subjectId = get(route, 'params.subjectId');
        const subjectFormId = get(route, 'params.subjectFormId');

        if (!formId || !subjectId || !subjectFormId) {
          return;
        }

        router.push(`/form/content/${formId}/${subjectId}/${subjectFormId}?type=${FormOperateType.MODIFY}`);
      };

      const handleContinue = async (subjectFormId: string) => {
        const formId = get(route, 'params.moduleId');
        const subjectId = get(route, 'params.subjectId');

        formValue.value = null;

        router.push(`/form/content/${formId}/${subjectId}/${CREATE_SUBJECT_ID}?type=${FormOperateType.CREATE}`);
        await fetchFormDetail(subjectFormId, FormOperateType.CONTINUE);

        if (formValue.value) {
          (formValue.value as IBaseFormDTO).status = FormStatus.UN_FILL;
        }
      };

      const handleBeforSave = () => {
        isSaving.value = true;
      };
      const handleAfterSave = () => {
        isSaving.value = false;
      };
      provide('handleAfterSave', handleAfterSave);

      const handleSubmit = debounce((isContinue: boolean) => {
        formRef.value && formRef.value.submit(isContinue, false, handleContinue);
      });

      const handleSubmitAndStay = debounce(() => {
        formRef.value && formRef.value.submit(true, true);
      });

      const handleTemporarySubmit = debounce(() => {
        formRef.value && formRef.value.temporarySubmit(submitStay.value, submitStay.value);
      });
      // // 机构端用药信息详情点击提交并继续，底部按钮应该置灰
      // const continueDisabledChange = () => {
      //   if (get(formValue.value, 'source') == 7) {
      //     continueDisabled.value = true;
      //   }
      // };
      // provide('continueDisabledChange', continueDisabledChange);

      return {
        isContainerMounted,
        formRef,
        formValue,
        formHistory,
        isShowTemporarySubmit,
        isMedicationModule,
        isShowPatientEditBtn,
        isDisableBtn,
        handleEditPatientModule,
        handleContinue,
        handleSubmit,
        handleSubmitAndStay,
        handleTemporarySubmit,
        isPreviewType,
        isMountFooter,
        submitStay,
        showSubmit,
        isSaving,
        handleBeforSave,
        handleAfterSave,
        continueDisabled,
        dictionaryList
      };
    },
  });
