
  import { defineComponent, ref, watchEffect } from 'vue';
  import { getImageAddress } from '@/services/picture';
  import { state } from '@/lib/state';
  import { message } from 'ant-design-vue';
  export default defineComponent({
    props: {
      url: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const val = ref(props.url || '/img/usersicon64.png');
      const handleAvatar = (key?: string) => {
        key &&
          getImageAddress({ key }).then((res) => {
            if (res.status === 0) {
              val.value = res.data;
            } else {
              message.error(res.errorMessage);
            }
          });
      };
      watchEffect(() => {
        val.value = props.url || '/img/usersicon64.png';
      });
      watchEffect(() => {
        handleAvatar(state.userInfo?.authImage);
      });
      return { val };
    },
  });
