
  import { IDropdownItem } from '@/type/common';
  import { SelectProps } from 'ant-design-vue/lib/vc-select';
  import { debounce } from 'lodash';
  import { defineComponent, onMounted, PropType, reactive, toRefs } from 'vue';

  export default defineComponent({
    name: 'SearchSelect',
    props: {
      placeholder: {
        type: String,
        default: () => '请输入',
      },
      value: {
        type: [String, Number, Array],
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
      bindProps: {
        type: Object as PropType<SelectProps>,
        default: () => ({}),
      },
    },
    emits: ['update:value', 'search', 'change'],
    setup(props, { emit }) {
      const state = reactive({
        nameLoading: true,
        opts: [] as IDropdownItem[],
        isDefaultList: false,
      });

      const serachList = (search = '') => {
        state.nameLoading = true;
        if (!search && state.isDefaultList) {
          state.nameLoading = false;
          return;
        }
        emit('search', search, (list: IDropdownItem[]) => {
          if (!search) {
            state.isDefaultList = true;
          } else {
            state.isDefaultList = false;
          }
          state.opts = list;
          state.nameLoading = false;
        });
      };
      const handleSearch = debounce((value: string) => {
        serachList(value);
      }, 300);

      const handleChange = (value: string | string[]) => {
        emit('update:value', value);
        emit('change', value);
        if (!value || value.length === 0) {
          serachList();
        }
      };

      const handleDropDownVisible = (open: boolean) => {
        if (!open) {
          serachList();
        }
      };

      onMounted(() => {
        serachList();
      });

      return { ...toRefs(state), handleSearch, handleChange, handleDropDownVisible };
    },
  });
