/* eslint-disable @typescript-eslint/no-empty-function */
import { LabeledValue } from 'ant-design-vue/lib/select';
import { Ref, ref, computed, ComputedRef, inject } from 'vue';

import dictionary, { filterAllRegion, StaticDictionaryTypeEnum } from '@/lib/dictionary';
import { DictionaryTypeEnum, DictionaryVo } from '@/type/api';
import { IFormDoubt } from '../../interface';
import { IFormTreatmentDTO, IndicationGroupType } from '@/type/formTreatmentDTO';

import { computedLabeledValueModel, EMPTY_DOUBT } from '../../utils';
import { departmentLabelsForChild, departmentLabelsForAdult } from './utils';
import { TreatmentFormEffectHandler } from './interface';
import { LoadingHandler, ModelGeneratorReturnType } from '../../interface';
import VisitHeightHelper from './itemHelpers/visitHeightHelper'
import VisitWeightHelper from './itemHelpers/visitWeightHelper'

/**
 * 生成适应症model, 适应症列表
 *
 * @param modelRef 表单model实例
 * @param effectHandler 表单联动handler
 * @returns {ModelGeneratorReturnType}
 */
export const genIndicationModel = (
  modelRef: IFormTreatmentDTO<moment.Moment>,
  effectHandler: TreatmentFormEffectHandler,
): ModelGeneratorReturnType => {
  const indicationModel = computedLabeledValueModel(modelRef, {
    idField: 'indicationId',
    nameField: 'indicationName',
  });

  const indications = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  const handleIndicationChange = async (val: LabeledValue) => {
    effectHandler.indicationId(val.value);
  };

  return [indicationModel, indications, handleIndicationChange];
};

/**
 * 生成患者群体model, 患者群体列表, 选择患者群体回调
 *
 * @param modelRef 表单model实例
 * @param effectHandler 表单联动handler
 * @param indications 适应症列表
 * @param loadingHandler loading状态控制
 * @returns {ModelGeneratorReturnType}
 */
export const genIndicationGroupModel = (
  modelRef: IFormTreatmentDTO<moment.Moment>,
  effectHandler: TreatmentFormEffectHandler,
  indications: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const indicationGroupModel = computedLabeledValueModel(modelRef, {
    idField: 'indicationGroupId',
    nameField: 'indicationGroupName',
  });
  const domainLabels = ref([] as Array<DictionaryVo<DictionaryTypeEnum>>);
  const indicationGroups = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);
  const departmentLabels = ref([] as Array<string>);
  const handleIndicationGroupChange = async (val: LabeledValue, disableEffect = false) => {
    !disableEffect && loadingHandler && loadingHandler(true);
    // console.log(val);
    // console.log(departmentLabelsForChild, departmentLabelsForAdult);
    if (val.key === IndicationGroupType.AGHD) {
      departmentLabels.value = departmentLabelsForAdult;
    } else if (val.key === IndicationGroupType.CGHD) {
      departmentLabels.value = departmentLabelsForChild;
    }
    domainLabels.value = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.DomainLabel,
      jinsaiParentId: val.value as string,
    });
    indications.value = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.Indication,
      jinsaiParentId: val.value as string,
    });

    if (!disableEffect) {
      effectHandler.indicationGroupId();
      loadingHandler && loadingHandler(false);
      modelRef.domainLabel = '';
      modelRef.departmentLabel = '';
    }
  };

  return [indicationGroupModel, indicationGroups, handleIndicationGroupChange, domainLabels, departmentLabels];
};

/**
 * 生成首诊医生, 医生列表
 *
 * @param modelRef 表单model实例
 * @returns {ModelGeneratorReturnType}
 */
export const genVisitedDoctorModel = (modelRef: IFormTreatmentDTO<moment.Moment>): ModelGeneratorReturnType => {
  const visitedDoctorModel = computedLabeledValueModel(modelRef, {
    idField: 'visitedDoctorId',
    nameField: 'visitedDoctorName',
  });

  const visitedDoctors = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  return [visitedDoctorModel, visitedDoctors, async () => {}];
};

/**
 * 生成首诊医院model, 医院列表, 选择医院回调
 *
 * @param modelRef 表单model实例
 * @param effectHandler 表单联动handler
 * @param doctors 医生列表ref
 * @param loadingHandler loading状态控制
 * @returns {ModelGeneratorReturnType}
 */
export const genVisitedHospitalModel = (
  modelRef: IFormTreatmentDTO<moment.Moment>,
  effectHandler: TreatmentFormEffectHandler,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const visitedHospitalModel = computedLabeledValueModel(modelRef, {
    idField: 'visitedHospitalId',
    nameField: 'visitedHospitalName',
  });

  const visitedHospitals = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  const handleHospitalChange = async (val: LabeledValue, disableEffect = false) => {
    !disableEffect && loadingHandler && loadingHandler(true);

    doctors.value = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.Doctor,
      hospitalId: val.value as string,
    });

    if (!disableEffect) {
      effectHandler.visitedHospitalId();
      loadingHandler && loadingHandler(false);
    }
  };

  return [visitedHospitalModel, visitedHospitals, handleHospitalChange];
};

/**
 * 生成首诊城市, 城市列表, 选择城市回调
 *
 * @param modelRef 表单model实例
 * @param effectHandler 表单联动handler
 * @param doctors 医生列表ref
 * @param hospitals 医院列表ref
 * @param loadingHandler loading状态控制
 * @returns {ModelGeneratorReturnType}
 */
export const genVisitedCityModel = (
  modelRef: IFormTreatmentDTO<moment.Moment>,
  effectHandler: TreatmentFormEffectHandler,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  hospitals: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const visitedCityModel = computedLabeledValueModel(modelRef, {
    idField: 'visitedCityId',
    nameField: 'visitedCityName',
  });

  const visitedCities = ref<DictionaryVo<StaticDictionaryTypeEnum>[]>([]);

  const handleCityChange = async (val: LabeledValue, disableEffect = false) => {
    !disableEffect && loadingHandler && loadingHandler(true);

    hospitals.value = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.Hospital,
      cityId: val.value as string,
    });

    if (!disableEffect) {
      doctors.value = [];
      effectHandler.visitedCityId();
      loadingHandler && loadingHandler(false);
    }
  };

  return [visitedCityModel, visitedCities, handleCityChange];
};

/**
 * 生成首诊省份, 省份列表, 选择省份回调
 *
 * @param modelRef 表单model实例
 * @param effectHandler 表单联动handler
 * @param doctors 医生列表ref
 * @param hospitals 医院列表ref
 * @param cities 城市列表ref
 * @param loadingHandler loading状态控制
 * @returns {ModelGeneratorReturnType}
 */
export const genVisitedProvinceModel = (
  modelRef: IFormTreatmentDTO<moment.Moment>,
  effectHandler: TreatmentFormEffectHandler,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  hospitals: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  cities: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const visitedProvinceModel = computedLabeledValueModel(modelRef, {
    idField: 'visitedProvinceId',
    nameField: 'visitedProvinceName',
  });

  const visitedProvinces = ref<DictionaryVo<StaticDictionaryTypeEnum>[]>([]);

  const handleProvinceChange = async (val: LabeledValue, disableEffect = false) => {
    !disableEffect && loadingHandler && loadingHandler(true);

    cities.value = filterAllRegion(
      await dictionary.getStaticDictionary({
        type: DictionaryTypeEnum.City,
        jinsaiParentId: val.value as string,
      }),
      DictionaryTypeEnum.City,
    );

    if (!disableEffect) {
      doctors.value = [];
      hospitals.value = [];
      effectHandler.visitedProvinceId();
      loadingHandler && loadingHandler(false);
    }
  };

  return [visitedProvinceModel, visitedProvinces, handleProvinceChange];
};

export const genVisitWeightDoubt = (modelRef: IFormTreatmentDTO<moment.Moment>): ComputedRef<IFormDoubt> => {
    return computed<IFormDoubt>(() => {
      const earlyWarningRule:Ref<any> = inject('earlyWarningRule', ref(null));
      const [hasDoubt, message] = VisitWeightHelper.hasDoubt(modelRef, earlyWarningRule.value);
  
      return hasDoubt
        ? {
            class: 'form-item-warning',
            help: message,
          }
        : EMPTY_DOUBT;
    });
  };
  
  export const genVisitHeightDoubt = (modelRef: IFormTreatmentDTO<moment.Moment> ): ComputedRef<IFormDoubt> => {
    return computed<IFormDoubt>(() => {
       const earlyWarningRule:Ref<any> = inject('earlyWarningRule', ref(null));
      const [hasDoubt, message] = VisitHeightHelper.hasDoubt(modelRef, earlyWarningRule.value);
  
      return hasDoubt
        ? {
            class: 'form-item-warning',
            help: message,
          }
        : EMPTY_DOUBT;
    });
  };
