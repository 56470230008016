import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc3376dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-toolbar" }
const _hoisted_2 = { class: "toolbar-item text-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_MinusOutlined = _resolveComponent("MinusOutlined")!
  const _component_UndoOutlined = _resolveComponent("UndoOutlined")!
  const _component_RedoOutlined = _resolveComponent("RedoOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_button, {
      class: "toolbar-item",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleZoomIn()))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_PlusOutlined)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fixedZoom) + " %", 1),
    _createVNode(_component_a_button, {
      class: "toolbar-item",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleZoomOut()))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_MinusOutlined)
      ]),
      _: 1
    }),
    _createVNode(_component_a_button, {
      class: "toolbar-item",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hanlleRotate(-90)))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_UndoOutlined, { rotate: 90 })
      ]),
      _: 1
    }),
    _createVNode(_component_a_button, {
      class: "toolbar-item",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hanlleRotate(90)))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_RedoOutlined, { rotate: -90 })
      ]),
      _: 1
    })
  ]))
}