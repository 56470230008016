
  import { defineComponent, PropType, ref } from 'vue';

  import { IBaseFormDTO, IFormDetailHistory } from '@/type/formContent';
  import { ObjectMap } from '@/type/helper';
  import { genDefaultBaseFormDTO } from '@/views/form/utils';

  import FormPreviewContent from '../FormPreviewContent/index.vue';
  import FormSlice from '../../../FormSlice/index.vue';
  import FormItemHistoryDialog from '../../../FormItemHistoryDialog/index.vue';

  import { IFormPreviewConfig, IFormPreviewGroup } from '../../interface';

  export default defineComponent({
    name: 'form-preview-group',
    components: {
      FormPreviewContent,
      FormSlice,
      FormItemHistoryDialog,
    },
    props: {
      history: {
        type: Object as PropType<ObjectMap<IFormDetailHistory[]>>,
        default: () => ({}),
      },
      data: {
        type: Object as PropType<IBaseFormDTO>,
        default: () => genDefaultBaseFormDTO(),
      },
      config: {
        type: Object as PropType<IFormPreviewGroup>,
        default: null,
      },
    },
    setup() {
      const currentHistories = ref<IFormDetailHistory[]>([]);
      const currentConfig = ref<IFormPreviewConfig<IBaseFormDTO> | null>(null);
      const historyDialogVisible = ref(false);

      const showHistories = (histories: IFormDetailHistory[], conf: IFormPreviewConfig<IBaseFormDTO>) => {
        currentHistories.value = histories;
        currentConfig.value = conf;
        historyDialogVisible.value = true;
      };

      return {
        currentHistories,
        currentConfig,
        historyDialogVisible,
        showHistories,
      };
    },
  });
