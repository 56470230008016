
  import { defineComponent, reactive, ref, toRefs, getCurrentInstance, onMounted } from 'vue';
  import { RollbackOutlined } from '@ant-design/icons-vue';
  import ManageListFrame from '@/components/frame/ManageListFrame.vue';
  // import CustomTable from '@/components/customTable';
  import { batchInviteMembers, getUserName } from '@/services/common';
  import { DictionaryTypeEnum, DictionaryVo, UserQo } from '@/type/api';
  import dictionary, { StaticDictionaryTypeEnum } from '@/lib/dictionary';
  import notify from '@/utils/notify';
  import RegulerHelper from '@/utils/regularHelper';
  import { trim } from 'lodash';

  import { setRouter } from './hooks';

  class User implements UserQo {
    constructor(params?: Partial<User>) {
      Object.assign(this, params);
      this.key = Date.now();
      Object.defineProperty(this, 'userRegion', {
        // set() {},
        get() {
          return this.cityIdList.map((cityId) => {
            return { cityId };
          });
        },
      });
      Object.defineProperty(this, 'userHospital', {
        // set() {},
        get() {
          return this.hospitalIdList.map((hospitalId) => {
            return { hospitalId };
          });
        },
      });
    }
    key: number;
    roleId?: number;
    mobile = '';
    username = '';
    jinsaiId = '';
    readonly userRegion;
    readonly userHospital;
    cityIdList: string[] = [];
    hospitalIdList: string[] = [];

    hospitalList: DictionaryVo<StaticDictionaryTypeEnum>[] = [];
    // hospitalIds = [];
  }

  export default defineComponent({
    components: { ManageListFrame, RollbackOutlined },
    setup() {
      const formRef = ref();
      const phoneValid = async (rule, value: string) => {
        const { isMobilePhone } = RegulerHelper;

        if (!isMobilePhone.test(value)) {
          return Promise.reject('请输入正确手机号');
        }
        return Promise.resolve();
      };
      const rules = {
        roleId: [{ required: true, message: '请选择角色权限', trigger: 'change', type: 'number' }],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: phoneValid, trigger: 'blur' },
        ],
        username: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        jinsaiId: [{ required: true, message: '请输入人力工号', trigger: 'blur' }],
        userRegion: [{ required: true, message: '请选择省市', trigger: 'change', type: 'array' }],
        userHospital: [{ required: true, message: '请选择用户所属医院', trigger: 'change', type: 'array' }],
      };
      const curComp = ref();
      const opts = reactive({
        rightOpt: [
          { id: 2, name: '录入员' },
          { id: 3, name: '区域管理员' },
          { id: 4, name: '平台管理员' },
        ],

        areaTree: [] as DictionaryVo<DictionaryTypeEnum.Province | DictionaryTypeEnum.City>[],
      });
      dictionary.getCurDictionary({ typeList: [DictionaryTypeEnum.Province, DictionaryTypeEnum.City] }).then((res) => {
        if (res) {
          opts.areaTree = res
            .filter((item) => item.type == DictionaryTypeEnum.Province)
            .map((item) => {
              item.children = res.filter((subItem) => subItem.jinsaiParentId == item.jinsaiId);
              return item;
            });
        }
      });

      // const user = {
      //   key: Date.now(),
      //   right: null,
      //   phone: null,
      //   name: null,
      //   number: null,
      //   area: [],
      //   hospitalList: [],
      // };
      const handleRow = () => {
        return Object.assign(new User(), {
          ref: ref(),
        });
      };

      const userList = ref([handleRow()]);
      const toRouter = setRouter();
      const addRow = () => {
        userList.value.push(handleRow());
      };
      const handleValid = async () => {
        return new Promise((resolve) => {
          let isPass = true;
          userList.value.forEach((user, i) => {
            const curRef = Array.isArray(curComp.value.refs['form' + i])
              ? curComp.value.refs['form' + i][0]
              : curComp.value.refs['form' + i];
            curRef
              .validate()
              .then((valid) => {
                valid || (isPass = valid);
                if (i === userList.value.length - 1) {
                  resolve(isPass);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
        });
      };
      const handleSave = async () => {
        try {
          const valid = await handleValid();
          if (valid) {
            const params = userList.value.map((user) => {
              let { roleId, mobile, username, jinsaiId, userRegion, userHospital } = user;
              jinsaiId = trim(jinsaiId);
              return {
                roleId,
                mobile,
                username,
                jinsaiId,
                userHospital,
                userRegion,
              };
            });
            batchInviteMembers(params).then((res) => {
              if (res) {
                notify.success({ message: '邀请成功' });
                toRouter('user');
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      };
      const handleDelete = (i: number) => {
        userList.value.length > 1 && userList.value.splice(i, 1);
      };
      const handleUserName = (e, i: number) => {
        console.log(e.target.value);
        const { value } = e.target;
        phoneValid(null, value)
          .then(() => {
            getUserName({ phone: value })
              .then((res) => {
                console.log(res);

                Object.assign(userList.value[i], res);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      };
      const handleHospitalList = (i: number) => {
        const { cityIdList } = userList.value[i];

        dictionary.getHospitalByCityIdList(cityIdList).then((res) => {
          // console.log(res);
          userList.value[i].hospitalList = res;
        });
      };
      onMounted(() => {
        const cur = getCurrentInstance();

        curComp.value = cur;
      });
      return {
        ...toRefs(opts),
        toRouter,
        userList,
        addRow,
        formRef,
        handleValid,
        handleSave,
        handleDelete,
        handleHospitalList,
        rules,
        handleUserName,
      };
    },
  });
